@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;900&display=swap);
@import url(https://code.highcharts.com/css/highcharts.css);
@media screen {
	.ant-layout-content {
		margin-left: 200px;
		background-color: white;
	}
}

.ant-tabs-tab-next-icon:before,
.ant-tabs-tab-prev-icon:before {
	color: #2593fc;
	width: 20px;
	border: solid;
	border-color: #2593fc;
	border-width: thin;
	border-radius: 25px;
}

.App {
	text-align: center;
}

body {
	margin: 0;
	padding: 0;
	font-family: 'Helvetica';
}

.pointer {
	cursor: pointer;
}

.flex-content {
	display: flex;
	justify-content: center;
	align-items: center;
}

.flex-column {
	flex-direction: column;
}

.flex-1 {
	flex: 1 1;
}

.ant-layout {
	min-height: 100vh;
}

.ant-layout-content {
	padding: 16px;
	display: flex;
}

.content-wrapper {
	background-color: white;
	padding: 20px;
	min-height: 500px;
	/* flex: 1; */
	width: 100%;
	display: flex;
	top: -13px;
	position: relative;
}

.ant-layout-sider {
	overflow: auto;
	height: 100vh;
	position: fixed;
	left: 0;
}

.ant-layout-content {
	margin-left: 200px;
	background-color: white;
}

.ant-menu-item {
	width: 100%;
}

.ant-menu {
	background-color: black;
}

.ant-menu-item:first-child {
	font-size: 18.5px;
	font-weight: bold;
}

.ant-menu-item:last-child {
	font-size: 18px;
	font-weight: bold;
}

.ant-layout-sider {
	max-width: 213px !important;
	width: 213px !important;
}

.ant-layout-sider-children {
	display: flex;
	background-color: black;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected {
	background-color: inherit !important;
}

.w-100 {
	width: 100%;
	max-width: 100%;
}

.w-50 {
	width: 50%;
	max-width: 50%;
}

.m-top {
	margin-top: 16px;
}

.m-bottom {
	margin-bottom: 16px;
}

.d-flex {
	display: flex;
}

.justify-content-between {
	justify-content: space-between;
}

.input_field {
	display: flex;
	flex-direction: column;
	margin-bottom: 12px;
}

.input_field > div {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.input_field .close-icon {
	font-size: 15px;
	margin-left: 0.5rem;
	cursor: pointer;
}

.input_field .text-editor-wrapper {
	border: 1px solid #d9d9d9;
	border-radius: 4px;
	min-height: 200px;
}

.text-editor-wrapper .text-editor {
	padding: 4px 11px;
}

.check_field {
	width: -webkit-fit-content !important;
	width: -moz-fit-content !important;
	width: fit-content !important;
}

.input_field span.red-text {
	padding-top: 2px;
	font-size: 14px;
	color: red;
}

.ant-input-number {
	width: 100%;
}

.input_field .date-wrapper-farsi {
	display: flex;
	align-items: center;
}

.input_field .date-wrapper-farsi .date-wrapper-farsi-selector {
	flex: 1 1;
}

.input_field .date-wrapper-farsi-value {
	flex: 0 1;
	padding-right: 16px;
}

.field-upload-wrapper {
	display: flex;
	flex-direction: column;
	margin-bottom: 12px;
}
.upload-wrapper {
	display: flex;
}

.ant-upload-select {
	flex: 0 1;
}
.ant-upload-list {
	flex: 1 1;
}
.input_field .ant-radio-wrapper {
	color: #ffffff;
}
.input_field .ant-radio-wrapper .ant-radio-inner {
	background-color: #ffffff;
}
.input_field .ant-radio-wrapper .ant-radio-inner::after {
	background-color: #1890ff;
}

.admin-dash-card {
	background-color: #2626d8;
	color: #ffffff;
	margin: 2rem 0rem;
	padding: 1.5rem;
	width: 75%;
}
.admin-dash-card .card-description {
	font-size: 13px;
}
.admin-dash-card .card-icon {
	margin-right: 1.5rem;
	width: 8rem;
}
.admin-dash-card .card-title {
	font-size: 16px;
}
.card-link {
	margin-top: 0.5rem;
	text-decoration: underline;
}

.app_container-content-tabs_content {
	padding: 8px;
}

.user_information {
	padding: 4px;
}

.user_information > * {
	padding: 4px;
}
.list_users {
	padding-top: 30px;
}

.admin-tiers-wrapper .ant-tabs-nav .ant-tabs-ink-bar,
.admin-user-content .ant-tabs-nav .ant-tabs-ink-bar,
.user-container .ant-tabs-nav .ant-tabs-ink-bar {
	height: 6px;
	background-color: #ffffff;
}
.user-container .ant-tabs-nav .ant-tabs-ink-bar {
	top: 0px;
}
.admin-tiers-wrapper .ant-tabs-nav .ant-tabs-tab,
.user-container .ant-tabs-nav .ant-tabs-tab {
	font-size: 15px;
	color: #ffffff;
	font-weight: bold;
}
.user-container .ant-tabs-nav .ant-tabs-tab:hover {
	color: #ffffff;
}
.admin-tiers-wrapper .ant-tabs-nav::before,
.admin-user-content .ant-tabs-nav::before,
.user-container .ant-tabs-nav::before {
	border-bottom: 1px solid #ffffff;
}
.admin-user-container .ant-table-thead > tr > th {
	background-color: #4e4efb;
	color: #ffffff;
}
.admin-user-container .ant-table-tbody > tr > td {
	background-color: #0000d1;
	color: #ffffff;
	border-bottom: 1px solid #0000d1;
}
.admin-user-container .ant-table-tbody > tr.ant-table-placeholder:hover > td {
	background-color: #0000ff;
}
.admin-user-container .ant-table-tbody > tr.ant-table-row:hover > td {
	background-color: #0000ff;
}
.admin-user-container .ant-empty .ant-empty-image .ant-empty-img-simple-ellipse,
.admin-user-container .ant-empty .ant-empty-image .ant-empty-img-simple-path {
	fill: #7d9ac9;
}
.admin-user-container .ant-empty .ant-empty-image .ant-empty-img-simple-g {
	stroke: #7d9ac9;
}
.admin-user-container .ant-empty .ant-empty-description {
	color: #ffffff;
}
.admin-user-container .admin-wallet-icon {
	margin-right: 1rem;
	width: 5rem;
}
.admin-user-container .user-donut-chart-wrapper {
	border-top: 1px solid grey;
	height: 25rem;
}
.admin-user-content {
	margin: 24px 0px;
}
.admin-user-content .ant-breadcrumb,
.admin-user-content .ant-breadcrumb-separator {
	color: #c4c4c4;
}
.admin-user-content .ant-breadcrumb a:hover {
	color: #ffffff;
}
.admin-user-content .ant-breadcrumb > span:last-child {
	color: #ffffff;
}
.user-icon {
	margin-right: 5px;
	width: 3rem;
}
.user-details {
	color: #ffffff;
	font-size: 18px;
	margin: 10px 0px;
}
.user-details .user-seperator {
	background-color: #ffffff;
	height: 20px;
	margin: 0px 14px;
	width: 1px;
}
.about-icon .freeze-icon {
	fill: #c4c4c4;
}
.about-icon .flag-icon,
.about-icon .otp-key {
	fill: none;
	stroke: #c4c4c4;
}
.about-info:first-child {
	border-left: none;
}
.about-info {
	padding: 15px 10px;
	border-left: 1px solid #c4c4c4;
	width: 16rem;
}
.about-info .percentage-txt {
	font-size: 42px;
	letter-spacing: 0px;
	color: #ffffff;
	opacity: 0.6;
}
.about-info .percentage-txt-active {
	/* color: #FFFFFF; */
	opacity: 1;
}
.about-info .info-txt {
	white-space: nowrap;
}
.about-info .verification-icon {
	width: 15px;
	position: relative;
	bottom: 10px;
	right: 3px;
}
.about-icon {
	width: 3rem;
	margin-left: 5px;
}
.about-icon-active .about-icon .flag-icon {
	fill: #ff0000;
}
.about-icon-active .freeze-icon {
	fill: #00edff;
}
.about-icon-active .about-icon .otp-key {
	fill: #ffffff;
}
.info-link {
	cursor: pointer;
	text-decoration: underline;
}
.about-info-content {
	text-align: right;
}
.about-wrapper {
	padding: 0px 15px;
}
.about-verification-content {
	flex: 1 1;
	margin-right: 12px;
}
.about-notes-content {
	width: 18rem;
}
.about-notes-text {
	margin-bottom: 2rem;
}
.about-notes-content .ant-btn {
	width: auto;
}
.about-title {
	border-bottom: 1px solid #c4c4c4;
	font-weight: bold;
	padding: 10px;
	margin-bottom: 8px;
}
.verification-docs-container {
	margin-bottom: 5px;
	width: 20rem;
}
.admin-user-container .ant-modal-confirm-btns {
	display: flex;
}
.admin-content-wrapper .blue-link {
	color: #ffffff !important;
	text-decoration: underline;
}
.admin-content-wrapper h1,
.admin-content-wrapper h2,
.admin-content-wrapper h3,
.admin-content-wrapper h4,
.admin-content-wrapper h5,
.admin-content-wrapper h6,
.admin-user-container .ant-modal-body h1,
.admin-user-container .ant-modal-body h3,
.user-data-form h1,
.user-data-form h3 {
	color: #ffffff;
}
.user-info-container,
.user-role-container,
.user-level-container {
	display: flex;
	justify-content: space-between;
	padding-top: 10px;
}
.user-info-container {
	width: 25rem;
}
.user-role-container {
}
.user-level-container {
}
.user-info-separator {
	border-right: 1px solid #c4c4c4;
	margin: 0rem 2rem;
	width: 1px;
	height: 4rem;
}
.user-data-form .user-edit-icon {
	margin-right: 1rem;
	width: 3rem;
}
.user-data-form .ant-picker .ant-picker-clear path,
.user-data-form .ant-picker .ant-picker-suffix path,
.user-data-form .ant-select .ant-select-arrow path {
	fill: #ffffff;
}
.user-data-form
	.ant-select-single:not(.ant-select-customize-input)
	.ant-select-selector,
.user-data-form .ant-input,
.user-data-form .ant-picker-input > input,
.user-data-form .ant-picker {
	background-color: #0000ff;
	color: #ffffff;
}
.download-csv-table {
	border: 1px solid #ffffff;
	padding: 2px 5px;
}
.user-info-icon {
	width: 35px;
	position: relative;
	bottom: 5px;
}
.user-info-label {
	margin-left: 15px;
}
.admin-user-container .ant-table-pagination.ant-pagination {
	margin: 16px 0;
	background: #ffffff;
	border-radius: 50px;
	padding: 15px;
}
.levels-icon {
	width: 57px;
	position: relative;
	bottom: 15px;
}
.select-level-icon {
	width: 3rem;
	position: relative;
	top: 0.2rem;
}
.user-data-form
	.ant-select-single:not(.ant-select-customize-input)
	.ant-select-selector {
	height: 50px;
}
.header-section {
	border-bottom: 1px solid rgb(211, 211, 211, 0.3);
	background-color: #0000c1;
}
.modal-confirm-title {
	font-size: 20px;
	font-weight: bold;
	color: #ffffff;
}
.user-role-container .user-icon {
	font-size: 30px;
}
.user-discount-wrapper .ant-form {
	color: inherit;
}
.user-discount-wrapper .title {
	font-size: 28px;
	font-weight: bold;
	margin-bottom: 1rem;
}
.user-discount-wrapper .box-content {
	border: 1px solid #ffffff;
	padding: 2rem;
	margin: 1.5rem 0;
}
.user-discount-wrapper .button-wrapper {
	display: flex;
	justify-content: space-between;
	margin-top: 2rem;
}
.user-discount-wrapper .button-wrapper .ant-btn {
	width: 225px;
}

.admin-user-content .ant-card-head-wrapper {
    display: flex;
    flex-direction: column;
}
.admin-user-content .ant-card-extra {
	padding: 0;
}
.controls-wrapper {
	display: flex;
	margin-bottom: 16px;
}

.upperCase {
	text-transform: uppercase;
}

.app-wrapper .ant-alert {
	margin-bottom: 16px;
}

.controls-search {
	flex: 3 1;
	flex-direction: column;
}

.controls-refresh {
	flex: 1 1;
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
}

.filters-wrapper {
	display: flex;
	justify-content: space-between;
}

.filters-wrapper-filters {
	/* flex: 2; */
	display: flex;
	flex-direction: column;
}

.filters-wrapper-buttons {
	/* flex: 1; */
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}
.ant-picker-input > input,
.admin-financials-wrapper .filters-wrapper-filters .ant-select-single:not(.ant-select-customize-input)	.ant-select-selector,
.admin-financials-wrapper .filters-wrapper-filters .ant-picker {
	background-color: #0000ff;
	color: #ffffff;
}
.admin-financials-wrapper .filters-wrapper-filters {
	flex-direction: row;
}
.admin-financials-wrapper .filters-wrapper-buttons {
	align-self: flex-end;
	flex: none;
	margin-bottom: 8px;
}
.blue-admin-table .ant-table-row-expand-icon {
	background-color: #0000ff;
	border-color: #c4c4c4;
}
.admin-financials-wrapper .ant-select .ant-select-selection-placeholder {
	opacity: 0.6;
}
.admin-financials-wrapper .ant-select .ant-select-arrow,
.admin-financials-wrapper .ant-picker .ant-picker-suffix {
	color: #ffffff;
}
.blue-admin-table .ant-table-row-expand-icon::before,
.blue-admin-table .ant-table-row-expand-icon::after {
	background-color: #ffffff;
}
.filter-button {
	min-width: 50%;
}

.filters-wrapper-buttons .filter-button:not(:last-child) {
	margin-bottom: 16px;
}

.admin-financials-wrapper .filter-alert {
	margin-bottom: 8px;
	opacity: 0.95;
}

.filter-select-wrapper,
.filter-input-wrapper {
	/* display: flex; */
	margin-bottom: 8px;
}

.filter-select-label,
.filter-input-label {
	flex: 1 1;
	display: flex;
	align-items: center;
	/* justify-content: flex-end; */
	padding-bottom: 5px;
}

.select-container,
.input-container {
	flex: 3 1;
}

.input-description {
	padding-top: 4px;
	color: grey;
	font-size: 0.75em;
}

.filter-date-field {
	width: 100%;
}

.adjacent-fields {
	width: 50%;
}

.flx-3 {
	flex: 3 1;
	margin-left: 16px;
}

.validate-wrapper .anchor {
	text-decoration: underline !important;
	cursor: pointer;
}

.Validate-Dismiss-popup {
	padding: 1rem;
	color: #FFFFFF;
}

.Validate-Dismiss-popup .ant-btn {
	color: #FFFFFF;
}
.Validate-Dismiss-popup .title{
    font-size: 24px;
    font-weight: bold;
}
.Validate-Dismiss-popup .confirm-container {
	border: 1px solid #ffffff;
	padding: 1.5rem;
}
.Validate-Dismiss-popup .legend {
	background-color: #0000ff;
	left: 1rem;
	top: 12px;
	padding: 5px;
	position: relative;
}
.Validate-Dismiss-popup .field-label {
	color: #FFFFFF;
}
.Validate-Dismiss-popup .edit-link-wrapper {
    position: relative;
}
.Validate-Dismiss-popup .edit-link {
    position: absolute;
    bottom: 28px;
    right: 16px;
    text-decoration: underline;
	cursor: pointer;
}
.Validate-Dismiss-popup .ant-input {
	width: 80%;
}
.investment-buttons {
	display: flex;
	justify-content: space-between;
}
.investment-buttons button {
	margin: 0 5px 5px 0;
}
.insfcnt-balance {
	padding: 13px;
    border: 1px solid #484848;
    border-radius: 5px;
    color: #727272;
}
.inv-table-wrapper {
    border: 1px solid #484848;
    margin: 15px 0 25px;
    padding: 16px 8px 0 8px;
    position: relative;
}
.inv-table-title {
	position: absolute;
    top: -14px;
    background-color: #202020;
    padding: 0 10px;
    font-size: 16px;
	color: #3cbd07;
}
.inv-table-title.itt-orange{
    color: #ffb240;
}
.inv-table-wrapper .table th,
.inv-table-wrapper .table td {
	border-bottom: none;
	border-top: 1px solid #484848;
}
.inv-table-wrapper .table th {
	border-top: none;
}
.white-theme .inv-table-title {
	background-color: #f8f8f8;
}
.ant-btn-warning {
    color: #000;
    background: #ffb240;
    border: none;
}
.ant-btn-warning:hover, .ant-btn-warning:focus {
	color: #000;
    background: #ffbb55;
}
.ant-btn-secondary {
	color: #000;
	border: none;
}
.ant-btn-secondary:hover, .ant-btn-secondary:focus {
	color: #464646;
}
.investment-table tr {
	border-bottom: 1px solid #7070bf;
}
.investment-table td, .investment-table th {
	padding: 3px 5px;
	min-width: 85px;
}
.investment-table td:first-child {
	min-width: 180px;
}
.wizard-container {
	align-items: center;
	background-color: #0808df;
	display: flex;
	height: 100%;
	justify-content: center;
	flex-direction: column;
}
.header {
	font-size: 40px;
	font-weight: bold;
	margin: 1rem 0rem;
}
.description {
	font-size: 16px;
	color: #c4c4c4;
}
.btn-container {
	margin: 2rem 0rem;
}
.wizard-container .ant-btn {
	background-color: #c4c4c4;
	color: #0000ff;
	font-size: 16px;
	font-weight: bold;
	width: 100%;
}
.content {
	color: #c4c4c4;
	text-align: center;
	width: 70rem;
}
.step-container {
	display: flex;
	margin-top: 3rem;
}
.step-container .ant-steps-item {
	white-space: nowrap;
}
.steps,
.step-content {
	flex: 1 1;
}
.steps {
	/* border-right: 1px solid #C4C4C4; */
}
.step-content {
	margin: 5px 10px 10px 20px;
}
.world-map {
	width: 20rem;
	margin-bottom: 1rem;
}
.step-link {
	color: #c4c4c4;
	text-decoration: underline;
	cursor: pointer;
}
.step-title {
	text-align: left;
	padding-left: 2rem;
}
.select-coin-text {
	font-size: 16px;
	margin-bottom: 0.5rem;
}
.title-text {
	font-size: 16px;
	color: #ffffff;
	margin-bottom: 0.5rem;
}
.cross {
	font-size: 18px;
	margin: 0px 5px 0px 0px;
}
.last {
	border-bottom: none !important;
}
.security-content .form-wrapper {
	margin-top: 2rem;
}
.security-content {
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.asset-content {
	text-align: left;
	height: 28rem;
	overflow-y: auto;
}
.coin-wrapper {
	margin-top: 2.5rem;
	padding-bottom: 1rem;
	border-bottom: 1px solid #c4c4c4;
}
.coin-wrapper .ant-select-arrow {
	color: #c4c4c4;
}
.coin-wrapper .ant-select {
	height: 50px;
	width: 20rem;
}
.coin-wrapper
	.ant-select-single:not(.ant-select-customize-input)
	.ant-select-selector {
	height: 50px;
}
.asset-list .select-coin-text,
.asset-list .title-text {
	margin-right: 0.5rem;
}
.asset-list .currency_ball-wrapper {
	margin-right: 8px;
}
.asset-list {
	display: flex;
	align-items: center;
	margin: 0.5rem;
}
.asset-btn-wrapper {
	text-align: center;
}
.asset-btn-wrapper .btn-container {
	width: 60%;
	margin-left: auto;
	margin-right: auto;
}
.small-text {
	font-size: 12px;
	margin-left: 1.4rem;
	color: #808083;
}
.asset-content .candle-icon {
	width: 74px;
}
.wallet-icon {
	position: relative;
	width: 36px;
	margin: 5px auto;
}
.box {
	display: flex;
	width: 75px;
	height: 50px;
	background-color: #050596;
	position: relative;
	bottom: 2rem;
	right: 2.5rem;
}
.interface {
	left: 5px;
}
.box .interface_container {
	margin: auto;
	margin-top: 7px;
}
.wizard-container .sell {
	border: 1px solid #808080;
	width: 60px;
	height: 18px;
}
.wizard-container .sell .label {
	font-size: 10px;
	position: relative;
	bottom: 3px;
	left: 18px;
	color: #808080;
}
.wizard-container .buy {
	width: 60px;
	height: 18px;
	border: 1px solid #000000;
	margin-top: 0.25rem;
	color: #000000;
	background-color: #808080;
}
.wizard-container .buy .label {
	font-size: 10px;
	position: relative;
	bottom: 3px;
	left: 18px;
	color: #000000;
}
.ant-radio-inner {
	background-color: #000000;
}
.ant-radio-checked .ant-radio-inner {
	border-color: #000000;
}
.ant-radio-inner::after {
	background-color: #ffffff;
}
.wizard-container .interface-box .ant-radio-wrapper {
	padding: 12px 10px;
	height: 70px;
	color: #ffffff;
	width: 100%;
}
.wizard-container .ant-radio-wrapper-checked {
	border: 1px solid #c4c4c4;
}
.ant-radio-group {
	width: 100%;
	margin-top: 21px;
}
.sub-radio {
	margin-top: 12px;
}
.wizard-container .ant-radio-wrapper {
	display: block;
	padding: 24px 12px;
}
.ant-steps-item-icon {
	width: 20px;
	height: 20px;
	font-size: 10px;
	line-height: 22px;
	margin-top: 8px;
	background-color: green;
	border-color: green;
}
.ant-steps-item-tail {
	display: none;
}
.ant-steps-item-content {
	padding: 0px 10px;
}
.ant-steps-item-tail::after {
	display: none;
}
.ant-steps-item-finish .ant-steps-item-icon {
	background-color: green;
	border-color: green;
}
.ant-steps-item-active .ant-steps-item-icon,
.ant-steps-item-wait .ant-steps-item-icon {
	display: none;
}
.ant-steps-item-active .ant-steps-item-content,
.ant-steps-item-wait .ant-steps-item-content {
	margin-left: 34px;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
	color: #ffffff !important;
}
.ant-steps-item-title {
	color: #c4c4c4 !important;
}
.ant-steps-item-active .ant-steps-item-title {
	font-weight: bold;
	color: #ffffff !important;
}
.ant-steps-item-active .ant-steps-item-content {
	background-color: #000000;
	min-width: 15rem;
	width: 70%;
}
.ant-steps-vertical .ant-steps-item-content {
	min-height: auto;
	margin-top: 5px;
	margin-bottom: 5px;
}
.btn-container {
	margin: 2rem 0rem;
}
.setup-field-content {
	text-align: left;
	width: 22rem;
}
.ant-input-affix-wrapper,
.ant-input,
.ant-input-number,
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
	background-color: #0000ff;
	color: #c4c4c4;
}
.ant-input-affix-wrapper:hover,
.ant-input-affix-wrapper:focus,
.ant-input:hover,
.ant-input:focus,
.ant-input:active,
.ant-input-number:hover,
.ant-input-number:focus {
	border-color: #c4c4c4;
	box-shadow: #c4c4c4;
}
.ant-input-password-icon {
	color: #c4c4c4;
}
.ant-radio-disabled .ant-radio-inner {
	background-color: #050596;
	border-color: #050596 !important;
}
.ant-radio-disabled + span {
	color: #808088;
}
.form-wrapper {
	width: 22rem;
}
.setup-field-label-desc {
	font-size: 12px;
	color: #c4c4c4;
}
.setup-field-label {
	font-size: 15px;
	color: #ffffff;
	margin-bottom: 0.5rem;
}

.step-separator {
	width: 1px;
	height: 18rem;
	background-color: #c4c4c4;
	margin: 3rem 2rem;
}

.wizard-container .slider__button {
	color: #c4c4c4 !important;
}
.icon-wrapper {
	display: flex;
	justify-content: center;
}
.icon-align {
	width: 18rem;
}
.exchange-complete-btn {
	width: 25rem !important;
	height: 50px;
}
.wizard-complete-status .anticon {
	font-size: 85px;
	color: #c4c4c4 !important;
}
.margin {
	margin-left: 3rem;
}
.preview-container {
	border-top: 1px solid #c4c4c4;
	border-bottom: 1px solid #c4c4c4;
}
.preview-heading {
	border-top: 1px solid #c4c4c4;
	margin-top: 1px;
	text-align: left;
}
.preview-time {
	text-align: right;
}
.option-list {
	display: flex;
}
.option-label {
	color: #ffffff;
	margin-right: 5px;
	text-align: right;
	width: 40%;
}
.option-value {
	flex: 1 1;
	text-align: left;
}
.previewButton {
	margin: 1rem 0rem;
}
.asset-content .disabled-area {
	opacity: 0.6;
	pointer-events: none;
}

.app_container {
	background-color: #eee;
	min-height: calc(100vh - 8px);
	display: flex;
	flex-direction: column;
}

.app_header {
	background-color: #222;
	height: 56px;
	padding: 8px 16px;
	color: white;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex: 0 1;
}

.app_container-content-wrapper {
	width: 100%;
	display: flex;
	flex: 1 1;
}

.app_container-content {
	/* max-width: 1024px;
	margin: 8px auto;
	flex-direction: column; */
	width: 100%;
	/* flex: 1; */
}
.app_container-content .ant-tabs,
.app_container-content h2,
.ant-tabs-tab:hover,
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
	color: #ffffff;
}
.admin-content-head {
	border-bottom: 1px solid #c4c4c4;
	color: #ffffff;
	font-size: 38px;
	padding: 5px 24px;
}
.content-wrapper {
	margin: 24px;
}
.ant-layout-content .content-wrapper {
	background-color: #0000d1;
	overflow-y: auto;
	margin: 0px;
	padding: 0px 24px;
	height: 100%;
	top: 0px;
}

.app_container-sidebar {
	width: 200px;
	display: flex;
	flex-direction: column;
	padding: 16px;
}

.app_container-wrapper {
	flex: 1 1;
	padding: 16px;
	overflow-y: auto;
	max-height: calc(100vh - 104px);
}

.my-drawer {
	position: relative;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
}
.my-drawer .am-drawer-sidebar {
	background-color: #fff;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
}
.my-drawer .am-drawer-sidebar .am-list {
	width: 300px;
	padding: 0;
}
.admin-top-bar {
	height: 38px;
	background-color: #0f1114;
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: #ffffff;
}
.admin-top-header {
	font-size: 18px;
}
.top-box-menu {
	border: 1px solid #c4c4c4;
	border-radius: 5px;
	color: #c4c4c4;
	margin: 0px 10px;
	padding: 1px 5px;
}
.ant-layout .ant-layout-sider,
.ant-layout .ant-layout-content {
	height: calc(100vh - 38px) !important;
}
.ant-layout {
	min-height: calc(100vh - 38px) !important;
}
.ant-layout .ant-layout-content {
	background-color: #0000ff;
	flex-direction: column;
	margin-left: 310px;
	padding: 0px;
}
.ant-layout-sider .ant-layout-sider-children .ant-menu .ant-menu-item {
	color: #ffffff;
}
.ant-layout-sider .ant-layout-sider-children .ant-menu {
	background-color: #0000ff;
	color: #ffffff;
}
.ant-layout-sider
	.ant-layout-sider-children
	.ant-menu
	.ant-menu-item:last-child {
	font-size: 14px;
}
.ant-layout-sider .ant-layout-sider-children {
	background-color: #0000ff;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
	background-color: #0000ff;
}
.menu-wrapper {
	border-right: 1px solid #c4c4c4;
	width: 100%;
}
.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
	border: none;
}
.sidebar-icon {
	margin-right: 8px;
	margin-top: 5px;
	width: 2.5rem;
}
.flex-menu {
	display: flex;
	align-items: center;
}
.exchange-title {
	color: #ffffff;
	font-size: 22px;
}
.custom-side-menu {
	height: auto !important;
	padding: 0px !important;
}
.sidebar-exchange-menu .exchange-title {
	font-size: 16px;
}
.sidebar-exchange-menu,
.sidebar-menu {
	padding: 0.8rem 1.5rem;
	width: 100%;
	font-weight: bold;
	line-height: 2;
	padding-left: 5rem;
}
.sidebar-exchange-menu {
	padding-left: 2rem;
}
.active-exchange-menu,
.active-side-menu {
	position: relative;
}
.active-exchange-menu::before,
.active-side-menu::before {
	content: '';
	width: 0;
	height: 100%;
	border-left: 3px solid #ffffff;
	border-right: 3px solid #ffffff;
	position: absolute;
	left: 0;
	top: 0;
}
.active-side-menu::after {
	content: '';
	width: 0;
	height: 0;
	border-top: 24px solid transparent;
	border-bottom: 24px solid transparent;
	border-left: 8px solid #ffffff;
	position: absolute;
	left: 6px;
	top: 0;
}
.active-exchange-menu::after {
	content: '';
	width: 0;
	height: 0;
	border-top: 40px solid transparent;
	border-bottom: 40px solid transparent;
	border-left: 8px solid #ffffff;
	position: absolute;
	left: 6px;
	top: 0%;
}
.bottom-side-top {
	height: 1px;
	background-color: #c4c4c4;
	margin: 0.2rem 3rem 0.2rem 3rem;
}
.admin-content-wrapper {
	background-color: #0000d1;
	margin-top: 1rem;
}
.link-icon {
	width: 14px;
}
.role-section {
	display: flex;
	padding: 25px;
	border-bottom: 1px solid #f0f0f0;
}
.main-label {
	color: #ffffff;
	margin: 0rem 9rem;
	font-size: 18px;
	font-weight: bold;
}
.sub-label {
	position: absolute;
	top: 4rem;
	left: 11.2rem;
}
.sider-icons {
	width: 85px;
	position: absolute;
	top: 1rem;
	left: 2rem;
}
.bg-black {
	background-color: #0f1114;
}
.bg-grey {
	background-color: #dadada;
}
.bg-orange {
	background-color: #e66b00;
}
.bg-yellow {
	background-color: #ffff00;
}
.black {
	color: #101000;
}

.black-header {
	position: relative;
	/* top: -1.5%;
	left: -0.6%; */
	font-size: 50px;
	background-color: black;
	color: white;
	/* width: 120%; */
}
.black-text {
	padding-left: 10px;
}

.main-header {
	display: flex;
	margin-top: -1%;
}
.main-container {
	margin-top: -2%;
}
.number-user {
	flex: 1.3 1;
}
.chart-title {
	flex: 1 1;
}

.row-1 {
	display: flex;
}
.sparks {
	flex: 1.5 1;
	width: 100%;
	padding-right: 50px;
	padding-top: 1.5%;
}
/* svg {
	border: 2px solid black;
} */

.fees {
	flex: 1 1;
}
.row-2 {
	width: 60%;
}

.verification_data_container {
	display: flex;
	margin-right: 15px;
}

.verification_data_container > div {
	flex: 1 1;
	width: 100%;
	padding: 8px;
}

.verification_data_container h2 {
	margin-top: 0;
}
.verification_block {
	display: flex;
	flex-direction: column;
	margin: 0px 10px;
	width: 15rem;
}
.block-title {
	font-weight: bold;
}

.verification_img {
	max-width: 350px;
}
.verified-container {
	width: 15rem !important;
}

.verification_data_container--actions {
	display: flex;
	justify-content: flex-end;
}

.verification_data_container--actions > *:not(:last-child) {
	margin-right: 12px;
}

.jsonkey-wrapper {
	display: flex;
}

.jsonkey-wrapper pre {
	padding-left: 8px;
	color: #ffffff;
}
.ant-card,
.ant-card-head,
.ant-card-type-inner .ant-card-head {
	background-color: #0000ff;
	color: #ffffff;
}
.ant-card-bordered {
	border: 1px solid #c4c4c4;
}
.ant-modal-header {
	border: 1px solid #0000ff;
}
.ant-modal-header .ant-modal-title {
	color: #ffffff;
	font-weight: bold;
}
.ant-modal-header,
.ant-modal-content,
.ant-modal-confirm-body .ant-modal-confirm-content {
	background-color: #0000ff;
	color: #ffffff;
}
.ant-modal-close-icon path {
	fill: #ffffff;
}
.verification-confirm-modal {
	height: 15rem;
}
.verification-confirm-modal .ant-btn {
	width: 9rem;
}
.green-btn.ant-btn-primary[disabled] {
	opacity: 0.7;
}
.verification-confirm-modal .ant-btn,
.green-btn {
	background-color: #288500 !important;
	color: #ffffff !important;
}
.ant-upload-list {
	color: #ffffff;
}
.verification-form .ant-input,
.verification-form
	.ant-select-single:not(.ant-select-customize-input)
	.ant-select-selector {
	background-color: #0000ff;
	color: #ffffff;
}
.verification-form .ant-upload-text-icon path {
	fill: #ffffff;
}

@media screen {
	.ant-layout-content {
		margin-left: 200px;
		background-color: white;
	}
}

.ant-tabs-tab-next-icon:before,
.ant-tabs-tab-prev-icon:before {
	color: #2593fc;
	width: 20px;
	border: solid;
	border-color: #2593fc;
	border-width: thin;
	border-radius: 25px;
}

.App {
	text-align: center;
}

body {
	margin: 0;
	padding: 0;
	font-family: 'Helvetica';
}

.pointer {
	cursor: pointer;
}

.flex-content {
	display: flex;
	justify-content: center;
	align-items: center;
}

.flex-column {
	flex-direction: column;
}

.flex-1 {
	flex: 1 1;
}

.ant-layout {
	min-height: 100vh;
}

.ant-layout-content {
	padding: 16px;
	display: flex;
}

.content-wrapper {
	background-color: white;
	padding: 20px;
	min-height: 500px;
	/* flex: 1; */
	width: 100%;
	display: flex;
	top: -13px;
	position: relative;
}

.ant-layout-sider {
	overflow: auto;
	height: 100vh;
	position: fixed;
	left: 0;
}

.ant-layout-content {
	margin-left: 200px;
	background-color: white;
}

.ant-menu-item {
	width: 100%;
}

.ant-menu {
	background-color: black;
}

.ant-menu-item:first-child {
	font-size: 18.5px;
	font-weight: bold;
}

.ant-menu-item:last-child {
	font-size: 18px;
	font-weight: bold;
}

.ant-layout-sider {
	max-width: 213px !important;
	width: 213px !important;
}

.ant-layout-sider-children {
	display: flex;
	background-color: black;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected {
	background-color: inherit !important;
}

.w-100 {
	width: 100%;
	max-width: 100%;
}

.w-50 {
	width: 50%;
	max-width: 50%;
}

.m-top {
	margin-top: 16px;
}

.m-bottom {
	margin-bottom: 16px;
}

.d-flex {
	display: flex;
}

.justify-content-between {
	justify-content: space-between;
}

.admin-user-container .ant-table .green-btn {
	background-color: #288500 !important;
	color: #ffffff;
}

.blue-admin-table .ant-pagination-item-active,
.blue-admin-table .ant-pagination-item-active:focus,
.blue-admin-table .ant-pagination-item-active:hover,
.blue-admin-table .ant-pagination-prev:hover .ant-pagination-item-link,
.blue-admin-table .ant-pagination-next:hover .ant-pagination-item-link {
	border-color: #288500;
	color: #288500;
}
.blue-admin-table .ant-pagination-disabled .ant-pagination-item-link,
.blue-admin-table .ant-pagination-disabled:hover .ant-pagination-item-link,
.blue-admin-table .ant-pagination-disabled:focus .ant-pagination-item-link {
	color: rgba(0, 0, 0, 0.25);
	border-color: #d9d9d9;
	cursor: not-allowed;
}
.blue-admin-table .ant-pagination-item:focus,
.blue-admin-table .ant-pagination-item:hover {
	border-color: #288500 !important;
}
.blue-admin-table .ant-pagination-item:focus a,
.blue-admin-table .ant-pagination-item:hover a {
	color: #288500;
}

.controls-wrapper {
	display: flex;
	margin-bottom: 16px;
}

.upperCase {
	text-transform: uppercase;
}

.app-wrapper .ant-alert {
	margin-bottom: 16px;
}

.controls-search {
	flex: 3 1;
	flex-direction: column;
}

.controls-refresh {
	flex: 1 1;
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
}

.filters-wrapper {
	display: flex;
	justify-content: space-between;
}

.filters-wrapper-filters {
	/* flex: 2; */
	display: flex;
	flex-direction: column;
}

.filters-wrapper-buttons {
	/* flex: 1; */
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.admin-deposit-wrapper
	.filters-wrapper-filters
	.ant-select-single:not(.ant-select-customize-input)
	.ant-select-selector,
.admin-deposit-wrapper .filters-wrapper-filters .ant-picker {
	background-color: #0000ff;
	color: #ffffff;
}
.admin-deposit-wrapper .filters-wrapper-filters {
	flex-direction: row;
}
.admin-deposit-wrapper .filters-wrapper-buttons {
	align-self: flex-end;
	flex: none;
	margin-bottom: 8px;
}
.blue-admin-table .ant-table-row-expand-icon {
	background-color: #0000ff;
	border-color: #c4c4c4;
}
.admin-deposit-wrapper .ant-select .ant-select-selection-placeholder {
	opacity: 0.6;
}
.admin-deposit-wrapper .ant-select .ant-select-arrow,
.admin-deposit-wrapper .ant-picker .ant-picker-suffix {
	color: #ffffff;
}
.blue-admin-table .ant-table-row-expand-icon::before,
.blue-admin-table .ant-table-row-expand-icon::after {
	background-color: #ffffff;
}
.filter-button {
	min-width: 50%;
}

.filters-wrapper-buttons .filter-button:not(:last-child) {
	margin-bottom: 16px;
}

.admin-deposit-wrapper .filter-alert {
	margin-bottom: 8px;
	opacity: 0.95;
}

.filter-select-wrapper,
.filter-input-wrapper {
	/* display: flex; */
	margin-bottom: 8px;
}

.filter-select-label,
.filter-input-label {
	flex: 1 1;
	display: flex;
	align-items: center;
	/* justify-content: flex-end; */
	padding-bottom: 5px;
}

.select-container,
.input-container {
	flex: 3 1;
}

.input-description {
	padding-top: 4px;
	color: grey;
	font-size: 0.75em;
}

.filter-date-field {
	width: 100%;
}

.adjacent-fields {
	width: 50%;
}

.flx-3 {
	flex: 3 1;
	margin-left: 16px;
}

.validate-wrapper .anchor {
	text-decoration: underline !important;
	cursor: pointer;
}

.Validate-Dismiss-popup {
	padding: 1rem;
	color: #FFFFFF;
}

.Validate-Dismiss-popup .ant-btn {
	color: #FFFFFF;
}
.Validate-Dismiss-popup .title{
    font-size: 24px;
    font-weight: bold;
}
.Validate-Dismiss-popup .confirm-container {
	border: 1px solid #ffffff;
	padding: 1.5rem;
}
.Validate-Dismiss-popup .legend {
	background-color: #0000ff;
	left: 1rem;
	top: 12px;
	padding: 5px;
	position: relative;
}
.Validate-Dismiss-popup .field-label {
	color: #FFFFFF;
}
.Validate-Dismiss-popup .edit-link-wrapper {
    position: relative;
}
.Validate-Dismiss-popup .edit-link {
    position: absolute;
    bottom: 28px;
    right: 16px;
    text-decoration: underline;
	cursor: pointer;
}
.Validate-Dismiss-popup .ant-input {
	width: 80%;
}
.edit_icon {
	height: 1rem;
	width: 1rem;
}
.verification_label {
	display: flex;
	flex: 1 1;
}
.verification_amount {
	flex: 2 1 !important;
}
.verification_content {
	display: flex;
	flex: 1 1;
}
.verification_text {
	display: flex;
	flex: 1 1;
}

@media screen {
	.ant-layout-content {
		margin-left: 200px;
		background-color: white;
	}
}

.ant-tabs-tab-next-icon:before,
.ant-tabs-tab-prev-icon:before {
	color: #2593fc;
	width: 20px;
	border: solid;
	border-color: #2593fc;
	border-width: thin;
	border-radius: 25px;
}

.App {
	text-align: center;
}

body {
	margin: 0;
	padding: 0;
	font-family: 'Helvetica';
}

.pointer {
	cursor: pointer;
}

.flex-content {
	display: flex;
	justify-content: center;
	align-items: center;
}

.flex-column {
	flex-direction: column;
}

.flex-1 {
	flex: 1 1;
}

.ant-layout {
	min-height: 100vh;
}

.ant-layout-content {
	padding: 16px;
	display: flex;
}

.content-wrapper {
	background-color: white;
	padding: 20px;
	min-height: 500px;
	/* flex: 1; */
	width: 100%;
	display: flex;
	top: -13px;
	position: relative;
}

.ant-layout-sider {
	overflow: auto;
	height: 100vh;
	position: fixed;
	left: 0;
}

.ant-layout-content {
	margin-left: 200px;
	background-color: white;
}

.ant-menu-item {
	width: 100%;
}

.ant-menu {
	background-color: black;
}

.ant-menu-item:first-child {
	font-size: 18.5px;
	font-weight: bold;
}

.ant-menu-item:last-child {
	font-size: 18px;
	font-weight: bold;
}

.ant-layout-sider {
	max-width: 213px !important;
	width: 213px !important;
}

.ant-layout-sider-children {
	display: flex;
	background-color: black;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected {
	background-color: inherit !important;
}

.w-100 {
	width: 100%;
	max-width: 100%;
}

.w-50 {
	width: 50%;
	max-width: 50%;
}

.m-top {
	margin-top: 16px;
}

.m-bottom {
	margin-bottom: 16px;
}

.d-flex {
	display: flex;
}

.justify-content-between {
	justify-content: space-between;
}

.admin-chat-feature-wrapper .chat-tabs.ant-tabs {
	width: 100%;
}

.admin-chat-feature-wrapper .banlist {
	max-width: 250px;
}

.admin-chat-feature-wrapper .chat-header {
	color: #ffffff;
	font-size: 18px;
}

.admin-chat-feature-wrapper .chat-header .small-text {
	color: #ffffff;
	font-size: 13px;
	margin: 0 !important;
}
.admin-chat-feature-wrapper .switch-label {
	margin: 0px 5px;
	font-weight: bold;
	font-size: 14px;
	color: #ffffff;
}
.admin-chat-feature-wrapper .switch-wrapper {
	margin: 1rem 0rem 5rem 0rem;
}
.admin-chat-feature-wrapper .switch-wrapper .ant-switch {
	background-color: #000000;
}
.admin-chat-feature-wrapper .switch-label::after {
	color: #808080;
}
.admin-chat-feature-wrapper .switch-wrapper .label-inactive {
	color: #808080;
}
.admin-chat-feature-wrapper .disabled-area {
	opacity: 0.6;
	pointer-events: none;
}

.controls-wrapper {
	display: flex;
	margin-bottom: 16px;
}

.app-wrapper .ant-alert {
	margin-bottom: 16px;
}

.controls-search {
	flex: 3 1;
	flex-direction: column;
}

.controls-refresh {
	flex: 1 1;
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
}

.filters-wrapper {
	display: flex;
	justify-content: space-between;
}

.filters-wrapper-filters {
	flex: 2 1;
	display: flex;
	flex-direction: column;
}

.filters-wrapper-buttons {
	flex: 1 1;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.filter-button {
	min-width: 50%;
}

.filters-wrapper-buttons .filter-button:not(:last-child) {
	margin-bottom: 16px;
}

.filter-alert {
	margin-bottom: 8px;
}

.select-wrapper,
.input-wrapper {
	display: flex;
	margin-bottom: 8px;
}

.select-label,
.input-label {
	flex: 1 1;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding-right: 16px;
}

.select-container,
.input-container {
	flex: 3 1;
}

.input-description {
	padding-top: 4px;
	color: grey;
	font-size: 0.75em;
}

.plugin-service {
	height: 5rem;
	border: 1px solid black;
	width: 50%;
}

.service-heading {
	min-width: 25rem;
	border-right: 1px solid black;
	padding-right: 1rem;
}

.service-button-warning {
	background-color: orange;
}

.service-connected {
	background-color: green;
	padding: 0.3rem 0.5rem;
	border-radius: 5rem;
	color: white;
	width: 8rem;
	text-align: center;
}

.link {
	text-align: center;
	color: blue;
}
.card-width {
	width: 25rem;
}
.admin-plugins-wrapper {
	width: 100%;
}
.plugin-list-container {
	color: #ffffff;
	margin: 0rem auto;
	padding: 2rem 0rem;
	width: 65rem;
}
.plugin-list-container .loading-container {
	height: 5rem;
}
.plugin-list-container .helper-modal-wrapper {
	padding: 0.5rem 1rem;
}
.plugin-list-container .requirement-icon {
	width: 0.75rem;
	/* margin-right: 0.25rem; */
}
.plugin-list-container .description {
	margin: 0.5rem 0rem;
}
.plugin-list-container .plugin-header,
.myplugin-container .plugin-header {
	padding: 0.25rem 0rem 1rem 0rem;
	border-bottom: 1px solid rgb(255, 255, 255, 0.4);
}
.plugin-list-container .small-font {
	font-size: 9px;
	font-weight: bold;
}
.plugin-list-container .plugin-title,
.myplugin-container .plugin-title,
.myplugin-container .header {
	font-size: 18px;
	font-weight: bold;
}
.plugin-list-container .card-container,
.myplugin-container .card-container {
	padding: 2rem 0rem;
	border-bottom: 1px solid #ffffff;
	margin-bottom: 0.5rem;
}
.plugin-list-container .plugin-card {
	background-repeat: no-repeat;
	background-size: 100% 100%;
	cursor: pointer;
	height: 9rem;
	width: 18rem;
	margin: 1rem;
	border-radius: 15px;
	background-color: #0000d1;
}
.plugin-list-container .search-plugin-input .ant-input,
.myplugin-container .search-plugin-input .ant-input {
	border-left: none;
	border-right: none;
	border-top: none;
	border-bottom: 1px solid #ffffff;
	border-radius: 0px;
	background-color: #0000d1;
	color: #c4c4c4;
	box-shadow: none;
}
.plugin-list-container .search-plugin-input .ant-input:focus {
	box-shadow: none;
}
.plugin-list-container p {
	line-height: 1.6;
	font-size: 12px;
	margin-bottom: 4px;
	letter-spacing: -0.1px;
	color: #7f7f7f;
}
.plugin-list-container b {
	font-size: 14px;
}
.plugin-list-container .plugin-icon {
	height: 5rem;
	margin: 0rem 1rem 0rem 0.5rem;
	width: 8rem;
}
.plugin-list-container .cloud-icon {
	height: 3rem;
	width: 3rem;
}
.plugin-list-container .back-btn {
	margin-top: 2rem;
	width: 100%;
}
.plugin-list-container .plugin-list,
.myplugin-container .plugin-list {
	height: 350px;
	overflow: auto;
	overflow-x: hidden;
}
.plugin-list-container .plugin-list .plugin-list-item .install-icon-content {
	text-align: center;
	width: 7.2rem;
}
.plugin-list-container .plugin-list .plugin-list-item .check-icon-verified {
	font-size: 24px;
	color: #00781b;
}
.myplugin-container .plugin-list .removing-item {
	opacity: 0.3;
}
.plugin-list-container .plugin-list .plugin-list-item,
.myplugin-container .plugin-list-item {
	margin: 8px 0px;
	cursor: pointer;
}
.plugin-list-container .plugin-list .plugin-list-title,
.myplugin-container .plugin-list-title {
	font-size: 16px;
	font-weight: bold;
}
.plugin-list-container .plugin-list .plugin-list-author,
.myplugin-container .plugin-list-author {
	color: #b6b6b6;
	font-size: 11px;
}
.plugin-list-container .plugin-list .plugin-author-align,
.myplugin-container .plugin-author-align {
	position: relative;
	top: 5px;
	left: 3px;
}
.plugin-list-container .plugin-list .plugin-list-bio,
.myplugin-container .plugin-list-bio {
	font-size: 13px;
}
.plugin-list-container .plugin-list .plugin-list-icon,
.myplugin-container .plugin-list-icon {
	height: 5rem;
	margin: 5px 12px 5px 3px;
	width: 5rem;
}
.plugin-list-container .plugin-list .icon-placeholder {
	background-color: #dfdfdf;
	border-radius: 5px;
}
.plugin-list-container .plugin-list .add-btn,
.myplugin-container .plugin-list-item .add-btn {
	padding: 2px 10px;
	background-color: #00781b;
	border-radius: 13px;
	color: #ffffff;
	cursor: pointer;
	min-width: 7.5rem;
	text-align: center;
}
.plugins-wrapper .ant-breadcrumb {
	font-size: 12px;
	font-weight: bold;
	cursor: pointer;
	margin-top: 1rem;
}

.plugin-divider {
	border-bottom: 1px solid rgb(0, 0, 0, 0.3);
	position: relative;
	right: 24px;
	top: 5rem;
	width: calc(100% + 48px);
}
.plugin-details-wrapper {
	color: #ffffff;
	margin: 1rem auto;
	width: 65rem;
	padding-bottom: 5rem;
}
.plugin-details-wrapper .plugin-icon,
.admin-plugin-modal-wrapper .plugin-icon {
	width: 65px;
	height: 65px;
}
.plugin-details-wrapper .main-content {
	margin: 2rem auto;
}
.plugin-details-wrapper .main-content .plugin-card {
	height: 210px;
	width: 320px;
	background-color: #0000d1;
}
.plugin-details-wrapper .main-content .anticon {
	position: relative;
	top: 3px;
	right: 3px;
}
.plugin-details-wrapper .main-content .anticon-clock-circle {
	font-size: 18px;
	top: 0px;
}
.plugin-details-wrapper .plugin-carousel-wrapper {
	width: 33rem;
}
.plugin-details-wrapper .btn-wrapper {
	width: 310px;
}
.plugin-details-wrapper .btn-wrapper .small-txt {
	font-size: 11px;
	position: relative;
	left: 38px;
}
.plugin-details-wrapper .btn-wrapper .ant-btn {
	margin: 1rem 0 1rem 0;
	width: 150px;
	height: 33px;
	border-radius: 15px;
}
.plugin-details-wrapper .btn-wrapper .add-btn {
	width: 150px;
	height: 33px;
	background-color: #00781b;
}
.plugin-details-wrapper .btn-wrapper .update-btn {
	width: 150px;
	height: 33px;
	background-color: #cb7300;
}
.plugin-details-wrapper .btn-wrapper .remove-btn,
.admin-plugin-modal-wrapper .remove-btn {
	background-color: #780000;
}
.plugin-details-wrapper .btn-wrapper .config-btn {
	background-color: #00781b;
}
.plugin-details-wrapper .icon-wrapper {
	display: flex;
	justify-content: center;
}
.plugin-details-wrapper .icon-wrapper .card-icons {
	width: 18rem;
}
.plugin-details-wrapper .about-contents {
	color: #ffffff;
	font-size: 12px;
	margin: 2rem 0;
}
.plugin-details-wrapper .about-contents h2 {
	font-size: 12px;
}
.plugin-details-wrapper .about-label {
	margin-top: 2rem;
	font-size: 20px;
	border-bottom: 3px solid #ffffff;
	width: 60px;
}

.admin-plugin-modal-wrapper .plugin-removal-icon {
	width: 150px;
}
.admin-plugin-modal-wrapper h2 {
	font-size: 19px;
}
.admin-plugin-modal-wrapper .add-btn {
	background-color: #00781b;
	width: 100%;
}
.admin-plugin-modal-wrapper .ant-divider {
	border-color: rgb(255, 255, 255, 0.3);
}
.admin-plugin-modal-wrapper .remove-wrapper {
	text-align: center;
}
.admin-plugin-modal-wrapper .remove-wrapper .ant-btn,
.admin-plugin-modal-wrapper .confirm-plugin-wrapper .ant-btn,
.admin-plugin-modal-wrapper .btn-wrapper .ant-btn {
	width: 190px;
}
.admin-plugin-modal-wrapper h5 {
	color: #ffffff;
	margin: 1rem 0;
}
.admin-plugin-modal-wrapper .ant-radio-wrapper {
	color: #ffffff;
}
.admin-plugin-modal-wrapper .plugin-file-wrapper {
	margin: 1rem 0rem;
}
.admin-plugin-modal-wrapper .plugin-file-wrapper input {
	opacity: 0;
	position: fixed;
	left: -999px;
}
.admin-plugin-modal-wrapper .plugin-file-container {
	width: 100%;
	padding-left: 100px;
	border: 1px solid #b9b9b9;
	display: block;
	white-space: nowrap;
	position: relative;
	height: 88px;
}
.admin-plugin-modal-wrapper .plugin-img-content {
	top: 0px;
	left: 0px;
	display: block;
	height: 86px;
	object-fit: fill;
	cursor: pointer;
	border-left: 1px solid #b9b9b9;
}
.admin-plugin-modal-wrapper .anticon-download {
	font-size: 64px;
	position: absolute;
	left: 18px;
	top: 10px;
}
.admin-plugin-modal-wrapper .ant-radio-group {
	color: #ffffff;
}
.admin-plugin-modal-wrapper .upload-link {
	position: absolute;
	top: 27px;
	left: 120px;
	font-size: 14px;
	text-decoration: underline;
	cursor: pointer;
}
.admin-plugin-modal-wrapper .url-path {
	font-size: 13px;
}
.admin-plugin-modal-wrapper .plugins-icon,
.config-wrapper .plugins-icon {
	width: 70px;
	height: 70px;
	position: relative;
	top: 2.5rem;
}

.config-wrapper {
	margin-top: 2rem;
	color: #ffffff;
}
.config-wrapper .config-content {
	border-top: 1px solid rgb(255, 255, 255, 0.3);
}
.config-wrapper .config-content .plugin-config-btn {
	width: 240px;
	margin-top: 3rem;
	background-color: #00781b;
}
.config-wrapper .config-content .plugin-config-labels {
	color: #ffffff;
}
.config-wrapper .config-content .plugin-script-container {
	border: none;
	height: 80vh;
	width: 100%;
}
.config-wrapper .config-content .plugin-script-container,
.config-wrapper .config-content .plugin-script-container .table {
	background-color: #0000d1;
	color: #ffffff;
}

.admin-plugin-container .ant-tabs-content-holder {
	margin: 0 auto;
	width: 100%;
}

.myplugin-container {
	margin: 0 auto;
	width: 65rem;
}

.myplugin-container .ant-btn {
	color: #ffffff;
	background-color: #00781b;
	border-color: #00781b;
}

.myplugin-container .my-plugin-content {
	padding-bottom: 2rem;
	border-bottom: 1px solid #ffffff;
	margin-bottom: 0.5rem;
}
.myplugin-container .plugin-list-item .update-btn {
	padding: 2px 10px;
	border-radius: 13px;
	color: #ffffff;
	background-color: #cb7300;
	border-color: #cb7300;
	cursor: pointer;
	min-width: 7.5rem;
	text-align: center;
}
.myplugin-container .plugin-list-item .small-circle,
.plugin-details-wrapper .btn-wrapper .small-circle {
	height: 10px;
	width: 10px;
	border-radius: 50%;
	background-color: #f28041;
	margin: 4px;
}
.myplugin-container .plugin-list-item .update-txt,
.plugin-details-wrapper .btn-wrapper .update-txt {
	color: #f28041;
	font-size: 12px;
}
.plugin-details-wrapper .btn-wrapper .update-warning {
	width: 280px;
}

.admin-plugins-wrapper .ant-breadcrumb,
.admin-plugins-wrapper .ant-breadcrumb-separator {
	color: #c4c4c4;
}
.admin-plugins-wrapper .ant-breadcrumb a:hover {
	color: #ffffff;
}
.admin-plugins-wrapper .ant-breadcrumb > span:last-child {
	color: #ffffff;
}

.spinner-container {
	color: #ffffff;
	display: flex;
	align-items: center;
}

.spinner-container img {
	width: 35px;
	height: 35px;
}

.admin-earnings-container .tab-contents {
	margin-top: 4rem;
}
.admin-earnings-container .icon-holder {
	width: 5rem;
	margin: 10px 0;
	height: 4rem;
	position: relative;
}
.admin-earnings-container .description-width {
	width: 58rem;
}
.admin-earnings-container .cloudIcon {
	width: 5rem;
	height: 5rem;
}
.settle-modal-page .margin-top-bottom {
	margin: 15px 0;
}
.settle-modal-page .heading {
	font-size: 22px;
	font-weight: bold;
}
.settle-modal-page .dollar-icon {
	background-color: #008a00;
	width: 24px;
	color: white;
	border-radius: 50%;
	font-size: 16px;
	font-weight: bold;
	margin-right: 5px;
}
.settle-modal-page .modal-content {
	border: 1px solid white;
	background-color: inherit;
	color: inherit;
	margin: 20px 0 20px 10px;
	padding: 0 0 15px 15px;
}
.settle-modal-page .modal-content .legend {
	position: relative;
	bottom: 13px;
	left: 3px;
	background-color: blue;
	width: 16rem;
}
.settle-modal-page .modal-button {
	width: 18rem;
	background-color: #288500;
	color: white;
	font-weight: bold;
	margin: 5px;
}
.settle-modal-page .modal-content .title-content {
	font-size: 16px;
}
.admin-earnings-container .dollar-icon {
	background-color: #008a00;
	width: 24px;
	position: absolute;
	top: 0px;
	color: white;
	border-radius: 50%;
	left: 3.5rem;
	font-size: 16px;
	font-weight: bold;
}
.admin-earnings-container .button {
	width: 12rem;
	background-color: #288500;
	color: white;
	font-weight: bold;
}

.terminating-tooltip .ant-tooltip-inner {
	background-color: blue;
	border: 1px solid black;
	color: white;
	padding: 12px;
}

.admin-earnings-container .icon-wrapper {
	width: 12rem;
}
.admin-earnings-container .content-wrapper .w-100 {
	width: 100%;
}
.admin-earnings-container .content-wrapper .ant-tabs {
	color: #ffffff;
}
.admin-earnings-container .ant-tabs-nav .ant-tabs-ink-bar {
	height: 6px;
	background-color: #ffffff;
}
.admin-earnings-container .ant-tabs-nav .ant-tabs-ink-bar {
	top: 0px;
}
.admin-earnings-container .ant-tabs-tab .ant-tabs-tab-btn {
	opacity: 0.6;
}
.admin-earnings-container .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
	font-weight: bold;
	opacity: 1;
}
.admin-earnings-container .ant-tabs-nav .ant-tabs-tab {
	font-size: 15px;
	color: #ffffff;
	font-weight: bold;
}
.admin-earnings-container .ant-tabs-nav .ant-tabs-tab:hover {
	color: #ffffff;
}
.admin-earnings-container .ant-tabs-nav::before {
	border-bottom: none;
}
.admin-earnings-container .title {
	font-size: 22px;
	font-weight: bold;
	margin-bottom: 0.5rem;
}
.admin-earnings-container .table-container {
	margin-top: 2rem;
}
.admin-earnings-container .title-wrapper {
	display: flex;
	justify-content: space-between;
	margin-bottom: 1rem;
}
.admin-earnings-container .download-btn {
	width: 100px;
	font-weight: bold;
	background-color: #0000ff;
	color: #ffffff;
	border-color: #ffffff;
	text-decoration: underline;
	cursor: pointer;
}
.ant-table-row-expand-icon {
	color: #2930ff;
	border-color: #2930ff;
}
.admin-earnings-container .id-wrapper {
	background-color: #288500;
	width: 35px;
	height: 25px;
	padding: 4px;
	font-size: 12px;
	color: #fff;
}

.admin-earnings-container .ant-table-thead > tr > th {
	background-color: #4e4efb;
	color: #ffffff;
	padding: 0rem 1rem;
}
.admin-earnings-container .ant-table-tbody > tr > td {
	background-color: #0000d1;
	color: #ffffff;
	border-bottom: 1px solid #0000d1;
}
.admin-earnings-container
	.ant-table-tbody
	> tr.ant-table-placeholder:hover
	> td {
	background-color: #0000ff;
}
.admin-earnings-container .ant-table-tbody > tr.ant-table-row:hover > td {
	background-color: #0000ff;
}
.admin-earnings-container
	.ant-empty
	.ant-empty-image
	.ant-empty-img-simple-ellipse,
.admin-earnings-container
	.ant-empty
	.ant-empty-image
	.ant-empty-img-simple-path {
	fill: #7d9ac9;
}
.admin-earnings-container .ant-empty .ant-empty-image .ant-empty-img-simple-g {
	stroke: #7d9ac9;
}
.admin-earnings-container .ant-empty .ant-empty-description {
	color: #ffffff;
}
.admin-earnings-container .ant-table-pagination.ant-pagination {
	margin: 16px 0;
	background: #ffffff;
	border-radius: 50px;
	padding: 15px;
}
.transfer-confirmation-popup {
	padding: 1rem;
}
.transfer-confirmation-popup .confirm-container {
	border: 1px solid #ffffff;
	padding: 1.5rem;
}
.transfer-confirmation-popup .legend {
	background-color: #0000ff;
	left: 1rem;
	top: 12px;
	padding: 5px;
	position: relative;
}
.admin-earnings-container .fields-container {
	display: flex;
	margin-top: 10px;
}
.admin-earnings-container .ant-picker {
	background-color: blue;
}
.admin-earnings-container .ant-picker input {
	color: white;
}
.admin-earnings-container .ant-select-arrow {
	color: white;
}
.admin-earnings-container .ant-picker .anticon-swap-right {
	color: white;
}
.admin-earnings-container .ant-picker .anticon-calendar {
	color: white;
}

.investment-plans-forms {
	display: flex;
	flex-wrap: wrap;
}
.investment-plan-form {
	flex: 1 1 33%;
	padding: 10px;
}
.Trade-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: auto;
}
.content {
	color: #ffffff;
	font-weight: bold;
	font-size: 15px;
}

.master-admin-img {
	width: 150px;
	margin: 30px 0px;
}

.Trade-wrapper .ant-btn {
	width: 300px;
	background-color: #288500;
	color: #ffffff;
}

.general-wrapper {
	color: #ffffff;
	margin-top: 2rem;
}
.general-wrapper .ant-input,
.general-wrapper .ant-select {
	width: 300px;
	margin: 1rem 0rem;
}
.minimal-btn {
	width: 10rem !important;
}
.wizard-container .sub-title,
.ant-modal-body .sub-title,
.admin-content-wrapper .sub-title {
	color: #ffffff;
	font-size: 15px;
	font-weight: bold;
}
.admin-content-wrapper .divider,
.ant-modal-body .divider {
	border-top: 1px solid #c4c4c4;
	margin: 2rem 0;
	width: 500px;
}
.wizard-container .description {
	/* width: 550px; */
}
.ant-modal-body .description,
.admin-content-wrapper .description,
.wizard-container .description {
	color: #ffffff;
	margin: 5px 0px;
	font-size: 13px;
}
.file-wrapper {
	margin: 1rem 0rem;
}
.new-tier-form .file-container {
	padding-left: 100px;
}
.new-tier-form .file-wrapper .icon-img {
	width: 90px;
}
.file-wrapper .icon-img {
	width: 150px;
}
.file-wrapper .file-img-content,
.file-wrapper img {
	position: absolute;
	top: 0px;
	left: 0px;
	display: block;
	width: 90px;
	height: 86px;
	object-fit: fill;
	cursor: pointer;
}
.file-wrapper label {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 86px;
	border-left: 1px solid #b9b9b9;
	color: #ffffff;
}
.general-wrapper .ant-collapse-header {
	color: #ffffff !important;
}
.file-container .ant-spin {
	position: absolute;
	top: 25px;
	left: 25px;
}
.file-container label .anchor {
	color: #ffffff;
	text-decoration: underline;
	cursor: pointer;
}
.file-container .ant-form-item-control {
	line-height: 1;
}
.file-container input {
	opacity: 0;
	position: fixed;
	left: -999px;
}
.file-container::after {
	content: '';
	display: table;
	clear: both;
}
.general-edit-link,
.general-edit-link-position {
	color: #ffffff;
	text-decoration: underline;
	cursor: pointer;
}
.general-edit-link-position {
	position: relative;
	left: 310px;
	top: 35px;
}
.file-wrapper input {
	opacity: 0;
	position: fixed;
	left: -999px;
}
.file-wrapper input::after {
	content: '';
	display: table;
	clear: both;
}
.file-container {
	border: 1px solid #b9b9b9;
	width: 420px;
	display: block;
	padding-left: 150px;
	white-space: nowrap;
	position: relative;
	height: 88px;
}
.general-wrapper .ant-btn {
	height: 40px;
	width: 250px;
	background-color: #288500;
	color: #ffffff;
	border-color: #1890ff;
}
.text-area-wrapper {
	margin-bottom: 10px;
}
.text-area-wrapper .description {
	font-size: 12px;
}
.small-text {
	font-size: 12px;
	margin-left: 1.4rem;
	color: #ffffff;
}
.interface-box .feature-icon {
	width: 74px;
	stroke: #808080;
	fill-opacity: 0.5;
}
.interface-box .feature-chat-icon {
	width: 42px;
	margin: 4px 16px;
	stroke: #808080;
	fill-opacity: 0.5;
}
.interface-box .ant-checkbox-wrapper-checked .feature-icon,
.interface-box .ant-checkbox-wrapper-checked .feature-chat-icon {
	stroke: #ffffff;
	fill-opacity: 1;
}
.interface-box .checkbox-txt,
.interface-box .small-text {
	color: #808080;
}
.interface-box .ant-checkbox-wrapper-checked .checkbox-txt,
.interface-box .ant-checkbox-wrapper-checked .small-text {
	color: #ffffff;
}
.interface-box .ant-checkbox-wrapper {
	display: flex;
	align-items: center;
	margin-left: 8px;
}
.interface-box .candle-icon,
.radio-btn-wrapper .candle-icon {
	position: absolute;
	right: 0;
	top: 0;
	width: 74px;
}
.wallet-icon {
	position: relative;
	width: 36px;
	margin: 5px auto;
}
.feature-trade-box {
	display: flex;
	width: 75px;
	height: 50px;
	background-color: #050596;
}
.box {
	display: flex;
	width: 75px;
	height: 50px;
	background-color: #050596;
	position: relative;
	bottom: 2rem;
}
.feature-trade-box .interface_container,
.box .interface_container {
	margin: auto;
	margin-top: 7px;
}
.general-wrapper .sell {
	border: 1px solid #808080;
	width: 60px;
	height: 18px;
}
.general-wrapper .sell .label {
	font-size: 10px;
	position: relative;
	bottom: 3px;
	left: 18px;
	color: #808080;
}
.general-wrapper .buy {
	width: 60px;
	height: 18px;
	border: 1px solid #000000;
	margin-top: 0.25rem;
	background-color: #808080;
}
.general-wrapper .buy .label {
	font-size: 10px;
	position: relative;
	bottom: 3px;
	left: 18px;
	color: #000000 !important;
}
.ant-checkbox-wrapper-checked .buy {
	background-color: #ffffff;
}
.ant-checkbox-wrapper-checked .sell {
	border: 1px solid #ffffff;
}
.ant-checkbox-wrapper-checked .sell .label {
	color: #ffffff;
}
.general-wrapper .ant-radio-wrapper {
	height: 100px;
	width: 420px;
}
.general-wrapper .ant-radio-group {
	width: 100%;
	margin-top: 21px;
	display: flex;
	flex-direction: column;
}
.general-wrapper .ant-radio-wrapper span:last-child {
	color: #ffffff;
}
.bottom-description {
	margin-top: 1rem;
}
.file-container p {
	color: #a8a8ef;
}
.section-wrapper {
	width: 220px;
}
.center-content {
	display: flex;
	justify-content: center;
	align-items: center;
}
.coins-list .ant-select-selector {
	height: 48px !important;
}
h2,
.email-config-form,
.ant-checkbox-wrapper span,
.description-wrapper,
.bottom-description,
.custom-form-wrapper {
	color: #ffffff;
}
.admin-general-description-tip .ant-tooltip-arrow {
	display: none;
}

.admin-general-description-tip .ant-tooltip-inner {
	padding: 0px;
	min-width: 0px;
}
.admin-general-description-tip {
	max-width: 0px;
}

.general-description-tip-right {
	padding-left: 0px;
}
.help-icon {
	width: 600px;
	height: 150px;
	position: relative;
}
.description_note {
	right: 8px;
	bottom: 3px;
}
.referral_badge_note {
	bottom: 60px;
	right: 7px;
}
.description_footer {
	bottom: 42px;
	right: 4px;
}
.custom-form-wrapper {
	display: flex;
	margin: 1.5rem 0rem;
	width: 70%;
}
.section-wrapper {
	width: 220px;
}
.center-content {
	display: flex;
	justify-content: center;
	align-items: center;
}
.divider-vertical {
	display: inline-block;
	width: 0px;
	height: 18rem;
	border-left: 1px solid #707070;
	margin: 0rem 1rem;
}
.divider-horizontal {
	display: inline-block;
	width: 5rem;
	height: 0px;
	border-top: 1px solid #707070;
	margin: 1rem 0rem;
}

.description-wrapper .disable-referral,
.referral-link-section .disabled-area,
.general-wrapper .disabled-area {
	opacity: 0.6;
	pointer-events: none;
}
.tiers-container {
	border: 1px solid #c4c4c4;
	border-bottom: none;
	max-width: 50rem;
	min-width: 25rem;
	padding: 12px;
	color: #ffffff;
}
.tiers-container:last-child {
	border-bottom: 1px solid #c4c4c4;
}
.tiers-container .tier-icon {
	height: 3rem;
	width: 3rem;
}
.tiers-container .description {
	width: auto;
}
.requirement-divider {
	background-color: #c4c4c4;
	height: 1px;
	margin: 15px 0px;
}
.new-tier-form .file-container {
	width: 100%;
}
.tiers-confirm-warning {
	background-color: #0000d1;
	padding: 8px;
	margin: 10px 0px;
}
.warning-txt {
	color: #f28041;
}
.new-tier-form .text-editor-wrapper {
	border: 1px solid #d9d9d9;
	border-radius: 4px;
	min-height: 200px;
}
.new-tier-form .text-editor-toolbar {
	color: black;
}
.table-tier-icon {
	height: 2.5rem;
	width: 2.5rem;
}
.limit-status-icon {
	width: 2rem;
}
.custom-column-td {
	padding: 8px 16px;
}
.column-divider {
	border-bottom: 1px solid #c4c4c4;
}
.fee-indicator-icon {
	width: 8rem;
}
.tier-section-icon {
	width: 4rem;
}
.admin-link-highlight {
	color: #ffffff;
	text-decoration: underline;
	cursor: pointer;
}
.admin-link-highlight:hover {
	text-decoration: none;
}

.admin-tiers-wrapper td.type-column {
	padding: 0px !important;
}
.admin-tiers-wrapper .ant-table-thead > tr > th {
	background-color: #4e4efb;
	color: #ffffff;
}
.admin-tiers-wrapper .ant-table-tbody > tr > td {
	background-color: #0000d1;
	color: #ffffff;
	border-bottom: 1px solid #c4c4c4;
}
.admin-tiers-wrapper .ant-table-tbody > tr.ant-table-placeholder:hover > td {
	background-color: #0000ff;
}
.admin-tiers-wrapper .ant-table-tbody > tr.ant-table-row:hover > td {
	background-color: #0000ff;
}
.admin-tiers-wrapper .ant-empty .ant-empty-image .ant-empty-img-simple-ellipse,
.admin-tiers-wrapper .ant-empty .ant-empty-image .ant-empty-img-simple-path {
	fill: #7d9ac9;
}
.admin-tiers-wrapper .ant-empty .ant-empty-image .ant-empty-img-simple-g {
	stroke: #7d9ac9;
}
.admin-tiers-wrapper .ant-empty .ant-empty-description {
	color: #ffffff;
}
.admin-tiers-wrapper .ant-table-pagination.ant-pagination {
	margin: 16px 0;
	background: #ffffff;
	border-radius: 50px;
	padding: 15px;
}
.admin-tiers-wrapper h1,
.admin-tiers-wrapper h3 {
	color: #ffffff;
}

.admin-tiers-wrapper .ant-pagination-item-active,
.admin-tiers-wrapper .ant-pagination-item-active:focus,
.admin-tiers-wrapper .ant-pagination-item-active:hover {
	border-color: #288500 !important;
}

.admin-tiers-wrapper .ant-table-tbody {
	overflow-x: auto;
}

.admin-tiers-wrapper .ant-table-container {
	overflow-x: auto;
}

.admin-roles-wrapper .role-icon {
	width: 5rem;
}
.admin-roles-wrapper .admin-link {
	text-decoration: underline;
	cursor: pointer;
}
.role-type-container {
	border: 1px solid #c4c4c4;
}
.admin-roles-wrapper .revoke-btn {
	background-color: #f28041;
	color: #ffffff;
}
.admin-roles-wrapper .line-separator {
	background-color: #c4c4c4;
	width: 1px;
}
.admin-roles-wrapper .role-description-box {
	border-bottom: 1px solid #c4c4c4;
	height: 135px;
	position: relative;
}
.admin-roles-wrapper .role-label {
	font-size: 20px;
	font-weight: bold;
}
.admin-roles-wrapper .role-supervisor {
	background-color: #000000;
}
.admin-roles-wrapper .role-kyc {
	background-color: #dadada;
	color: #000000;
}
.admin-roles-wrapper .role-communication {
	background-color: #e66b00;
	color: #000000;
}
.admin-roles-wrapper .role-support {
	background-color: #ffff00;
	color: #000000;
}
.edit-role-icon {
	width: 3rem;
}
.admin-roles-wrapper .divider {
	border-top: 1px solid #c4c4c4;
	margin: 2rem 0;
	width: 100%;
}
.admin-roles-wrapper h1,
.admin-roles-wrapper h3 {
	color: #ffffff;
}
.admin-roles-wrapper .table-wrapper .ant-table-thead > tr > th {
	background-color: #4e4efb;
	color: #ffffff;
}
.admin-roles-wrapper .table-wrapper .ant-table-tbody > tr > td {
	background-color: #0000d1;
	color: #ffffff;
	border-bottom: 1px solid #0000d1;
}
.admin-roles-wrapper
	.table-wrapper
	.ant-table-tbody
	> tr.ant-table-placeholder:hover
	> td {
	background-color: #0000ff;
}
.admin-roles-wrapper
	.table-wrapper
	.ant-table-tbody
	> tr.ant-table-row:hover
	> td {
	background-color: #0000ff;
}
.admin-roles-wrapper
	.table-wrapper
	.ant-empty
	.ant-empty-image
	.ant-empty-img-simple-ellipse,
.admin-roles-wrapper
	.table-wrapper
	.ant-empty
	.ant-empty-image
	.ant-empty-img-simple-path {
	fill: #7d9ac9;
}
.admin-roles-wrapper
	.table-wrapper
	.ant-empty
	.ant-empty-image
	.ant-empty-img-simple-g {
	stroke: #7d9ac9;
}
.admin-roles-wrapper .table-wrapper .ant-empty .ant-empty-description {
	color: #ffffff;
}
.admin-roles-wrapper .ant-table-pagination.ant-pagination {
	margin: 16px 0;
	background: #ffffff;
	border-radius: 50px;
	padding: 15px;
}
.admin-roles-wrapper .ant-pagination-item-active,
.admin-roles-wrapper .ant-pagination-item-active:focus,
.admin-roles-wrapper .ant-pagination-item-active:hover {
	border-color: #288500 !important;
}

.admin-roles-wrapper .sub-title {
	color: #ffffff;
}
.admin-roles-wrapper .ant-form .ant-select .ant-select-selector {
	height: 30px;
	padding: 3px 8px;
}
.admin-roles-wrapper .ant-input:focus,
.ant-input:active {
	border: 1px solid #c4c4c4;
}
.admin-roles-wrapper .ant-form-item {
	margin: 0;
}
.admin-roles-wrapper .disable-area {
	opacity: 0.4;
	pointer-events: none;
}
.admin-roles-wrapper .disable-area::before {
	content: ' ';
	display: block;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
}

.upgrade-section {
	background-color: #3333ff;
	color: #ffffff;
	padding: 20px;
}
.upgrade-section .button-wrapper .ant-btn {
	background-color: #e97c00;
	color: #ffffff;
	font-size: 14px !important;
}
.admin-roles-wrapper .role-description-box .upgrade-text {
	position: absolute;
	z-index: 100;
	color: #ffffff;
	top: 55px;
	left: 12px;
}

:root {
	/*_________________________________*/

	/*COLOR MANAGEMENT*/

	/*_________________________________*/

	--base_background: #ffffff;
	--base_top-bar-navigation: #12161c;
	--base_secondary-navigation-bar: #f2f2f3;
	--base_wallet-sidebar-and-popup: #ffffff;
	--base_footer: #12161c;

	--theme_color_1: #f0b90b;

	--labels_important-active-labels-text-graphics: #000000;
	--labels_secondary-inactive-label-text-graphics: #808080;
	--calculated_important-border: #000000b3;
	--calculated_secondary-border: #00000054;
	--calculated_footer_border: #ffffff26;
	--calculated_super-pale_label-text-graphics: #00000026;
	--labels_fields: #f0f1f1;
	--labels_inactive-button: #535353;

	--trading_selling-related-elements: #ee4036;
	--trading_buying-related-elements: #00a69c;

	--specials_buttons-links-and-highlights: #0066b4;
	--specials_chat-messages: #98ccb2;
	--specials_my-username-in-chat: #ffff00;
	--specials_checks-okay-done: #008000;
	--specials_pending-waiting-caution: #f6921e;
	--specials_notifications-alerts-warnings: #ed1c24;

	/*_________________________________*/

	/*Calculated colors*/

	/*_________________________________*/

	--calculated_market-card_gradient_background: #ffffff;
	--calculated_trading_selling-sparkline-fill: #ee40364d;
	--calculated_trading_buying-sparkline-fill: #00a69c4d;
	--calculated_trading_selling-orderbook-bar: #ee40361f;
	--calculated_trading_buying-orderbook-bar: #00a69c1f;
	--calculated_trading_buying-related-text: #000000;
	--calculated_trading_selling-related-text: #ffffff;
	--calculated_base_modal-overlay: #ffffffbf;
	--calculated_landing_background: #ffffff00;
	--calculated_landing_markets_table: #fffffff0;
	--calculated_specials_highlight-box: #0066b41a;
	--calculated_base_top-bar-navigation_text: #ffffff;
	--calculated_base_top-bar-navigation_text-inactive: #ffffffb3;
	--calculated_base_footer_text: #ffffff;
	--calculated_trad-view_watermark: #0000001a;
	--calculated_trad-view_axis: #00000080;
	--calculated_trad-view_text: #000000cc;
	--calculated_specials_notifications-alerts-inactive: #ed1c24bf;
	--calculated_specials_notifications-alerts-text: #ffffff;
	--calculated_specials_notifications-pending-text: #ffffff;
	--calculated_specials_notifications-success-text: #ffffff;

	/*_________________________________*/

	/*_____Remaining_____*/
	--dark-accordion-arrow-active: #ffffff;

	/*______coins______*/
	--coin-eur: #06773d;
	--coin-eur-text: #ffffff;
	--coin-usd: #01592e;
	--coin-usd-text: #ffffff;
	--coin-jpy: #e0dfc7;
	--coin-jpy-text: #000000;
	--coin-gbp: #482e6e;
	--coin-gbp-text: #ffffff;
	--coin-chf: #ed1c24;
	--coin-chf-text: #ffffff;
	--coin-cad: #d7ce58;
	--coin-cad-text: #000000;
	--coin-aud: #19441f;
	--coin-aud-text: #ffffff;
	--coin-nzd: #b3498c;
	--coin-nzd-text: #ffffff;
	--coin-zar: #d8d3a0;
	--coin-zar-text: #000000;
	--coin-hkd: #f48f9b;
	--coin-hkd-text: #000000;
	--coin-krw: #f9ee02;
	--coin-krw-text: #000000;
	--coin-cny: #ec008c;
	--coin-cny-text: #ffffff;
	--coin-sgd: #720f15;
	--coin-sgd-text: #ffffff;
	--coin-btc: #f7941d;
	--coin-btc-text: #000000;
	--coin-eth: #3e4ea1;
	--coin-eth-text: #ffffff;
	--coin-ltc: #bcbec0;
	--coin-ltc-text: #000000;
	--coin-bch: #8dc541;
	--coin-bch-text: #000000;
	--coin-xrp: #414042;
	--coin-xrp-text: #ffffff;
	--coin-xht: #000000;
	--coin-xht-text: #ffffff;
	--coin-eos: #58595b;
	--coin-eos-text: #ffffff;
	--coin-bnb: #f1ba2a;
	--coin-bnb-text: #000000;
	--coin-link: #1b75bc;
	--coin-link-text: #ffffff;
	--coin-trx: #a22d25;
	--coin-trx-text: #ffffff;
	--coin-ada: #3667b2;
	--coin-ada-text: #ffffff;
	--coin-matic: #2aaae2;
	--coin-matic-text: #ffffff;
	--coin-neo: #bcbec0;
	--coin-neo-text: #000000;
	--coin-etc: #009444;
	--coin-etc-text: #000000;
	--coin-xlm: #808285;
	--coin-xlm-text: #ffffff;
	--coin-xmr: #f15a29;
	--coin-xmr-text: #ffffff;
	--coin-zec: #fbb040;
	--coin-zec-text: #000000;
	--coin-one: #939598;
	--coin-one-text: #ffffff;
	--coin-pax: #163526;
	--coin-pax-text: #ffffff;
	--coin-usdc: #4d7d91;
	--coin-usdc-text: #ffffff;
	--coin-usdt: #2bb673;
	--coin-usdt-text: #ffffff;
	--coin-vet: #454c7d;
	--coin-vet-text: #ffffff;
	--coin-btt: #c49a6c;
	--coin-btt-text: #ffffff;
	--coin-qtum: #96daf7;
	--coin-qtum-text: #000000;
	--coin-algo: #594a42;
	--coin-algo-text: #ffffff;
	--coin-rvn: #662d91;
	--coin-rvn-text: #ffffff;
	--coin-iota: #603913;
	--coin-iota-text: #ffffff;
	--coin-dash: #417ec1;
	--coin-dash-text: #ffffff;
	--coin-bsv: #a09032;
	--coin-bsv-text: #ffffff;
	--coin-ont: #00a79d;
	--coin-ont-text: #ffffff;
	--coin-atom: #796184;
	--coin-atom-text: #ffffff;
	--coin-celr: #726658;
	--coin-celr-text: #ffffff;
	--coin-omg: #8e7fbb;
	--coin-omg-text: #ffffff;
	--coin-hot: #8b5e3c;
	--coin-hot-text: #ffffff;
	--coin-tusd: #002868;
	--coin-tusd-text: #ffffff;
	--coin-dai: #f3c27a;
	--coin-dai-text: #000000;
	--coin-ama: #fa2902;
	--coin-ama-text: #ffffff;
	--coin-xtz: #2c7df7;
	--coin-xtz-text: #ffffff;
	--coin-doge: #c3a634;
	--coin-doge-text: #ffffff;
	--coin-uni: #ff4dab;
	--coin-uni-text: #ffffff;
	--coin-dot: #d80579;
	--coin-dot-text: #ffffff;
}

:root {
	/*_________________________________*/

	/*COLOR MANAGEMENT*/

	/*_________________________________*/

	--base_background: #ffffff;
	--base_top-bar-navigation: #12161c;
	--base_secondary-navigation-bar: #f2f2f3;
	--base_wallet-sidebar-and-popup: #ffffff;
	--base_footer: #12161c;

	--theme_color_1: #f0b90b;

	--labels_important-active-labels-text-graphics: #000000;
	--labels_secondary-inactive-label-text-graphics: #808080;
	--calculated_important-border: #000000b3;
	--calculated_secondary-border: #00000054;
	--calculated_footer_border: #ffffff26;
	--calculated_super-pale_label-text-graphics: #00000026;
	--labels_fields: #f0f1f1;
	--labels_inactive-button: #535353;

	--trading_selling-related-elements: #ee4036;
	--trading_buying-related-elements: #00a69c;

	--specials_buttons-links-and-highlights: #0066b4;
	--specials_chat-messages: #98ccb2;
	--specials_my-username-in-chat: #ffff00;
	--specials_checks-okay-done: #008000;
	--specials_pending-waiting-caution: #f6921e;
	--specials_notifications-alerts-warnings: #ed1c24;

	/*_________________________________*/

	/*Calculated colors*/

	/*_________________________________*/

	--calculated_market-card_gradient_background: #ffffff;
	--calculated_trading_selling-sparkline-fill: #ee40364d;
	--calculated_trading_buying-sparkline-fill: #00a69c4d;
	--calculated_trading_selling-orderbook-bar: #ee40361f;
	--calculated_trading_buying-orderbook-bar: #00a69c1f;
	--calculated_trading_buying-related-text: #000000;
	--calculated_trading_selling-related-text: #ffffff;
	--calculated_base_modal-overlay: #ffffffbf;
	--calculated_landing_background: #ffffff00;
	--calculated_landing_markets_table: #fffffff0;
	--calculated_specials_highlight-box: #0066b41a;
	--calculated_base_top-bar-navigation_text: #ffffff;
	--calculated_base_top-bar-navigation_text-inactive: #ffffffb3;
	--calculated_base_footer_text: #ffffff;
	--calculated_trad-view_watermark: #0000001a;
	--calculated_trad-view_axis: #00000080;
	--calculated_trad-view_text: #000000cc;
	--calculated_specials_notifications-alerts-inactive: #ed1c24bf;
	--calculated_specials_notifications-alerts-text: #ffffff;
	--calculated_specials_notifications-pending-text: #ffffff;
	--calculated_specials_notifications-success-text: #ffffff;

	/*_________________________________*/

	/*_____Remaining_____*/
	--dark-accordion-arrow-active: #ffffff;

	/*______coins______*/
	--coin-eur: #06773d;
	--coin-eur-text: #ffffff;
	--coin-usd: #01592e;
	--coin-usd-text: #ffffff;
	--coin-jpy: #e0dfc7;
	--coin-jpy-text: #000000;
	--coin-gbp: #482e6e;
	--coin-gbp-text: #ffffff;
	--coin-chf: #ed1c24;
	--coin-chf-text: #ffffff;
	--coin-cad: #d7ce58;
	--coin-cad-text: #000000;
	--coin-aud: #19441f;
	--coin-aud-text: #ffffff;
	--coin-nzd: #b3498c;
	--coin-nzd-text: #ffffff;
	--coin-zar: #d8d3a0;
	--coin-zar-text: #000000;
	--coin-hkd: #f48f9b;
	--coin-hkd-text: #000000;
	--coin-krw: #f9ee02;
	--coin-krw-text: #000000;
	--coin-cny: #ec008c;
	--coin-cny-text: #ffffff;
	--coin-sgd: #720f15;
	--coin-sgd-text: #ffffff;
	--coin-btc: #f7941d;
	--coin-btc-text: #000000;
	--coin-eth: #3e4ea1;
	--coin-eth-text: #ffffff;
	--coin-ltc: #bcbec0;
	--coin-ltc-text: #000000;
	--coin-bch: #8dc541;
	--coin-bch-text: #000000;
	--coin-xrp: #414042;
	--coin-xrp-text: #ffffff;
	--coin-xht: #000000;
	--coin-xht-text: #ffffff;
	--coin-eos: #58595b;
	--coin-eos-text: #ffffff;
	--coin-bnb: #f1ba2a;
	--coin-bnb-text: #000000;
	--coin-link: #1b75bc;
	--coin-link-text: #ffffff;
	--coin-trx: #a22d25;
	--coin-trx-text: #ffffff;
	--coin-ada: #3667b2;
	--coin-ada-text: #ffffff;
	--coin-matic: #2aaae2;
	--coin-matic-text: #ffffff;
	--coin-neo: #bcbec0;
	--coin-neo-text: #000000;
	--coin-etc: #009444;
	--coin-etc-text: #000000;
	--coin-xlm: #808285;
	--coin-xlm-text: #ffffff;
	--coin-xmr: #f15a29;
	--coin-xmr-text: #ffffff;
	--coin-zec: #fbb040;
	--coin-zec-text: #000000;
	--coin-one: #939598;
	--coin-one-text: #ffffff;
	--coin-pax: #163526;
	--coin-pax-text: #ffffff;
	--coin-usdc: #4d7d91;
	--coin-usdc-text: #ffffff;
	--coin-usdt: #2bb673;
	--coin-usdt-text: #ffffff;
	--coin-vet: #454c7d;
	--coin-vet-text: #ffffff;
	--coin-btt: #c49a6c;
	--coin-btt-text: #ffffff;
	--coin-qtum: #96daf7;
	--coin-qtum-text: #000000;
	--coin-algo: #594a42;
	--coin-algo-text: #ffffff;
	--coin-rvn: #662d91;
	--coin-rvn-text: #ffffff;
	--coin-iota: #603913;
	--coin-iota-text: #ffffff;
	--coin-dash: #417ec1;
	--coin-dash-text: #ffffff;
	--coin-bsv: #a09032;
	--coin-bsv-text: #ffffff;
	--coin-ont: #00a79d;
	--coin-ont-text: #ffffff;
	--coin-atom: #796184;
	--coin-atom-text: #ffffff;
	--coin-celr: #726658;
	--coin-celr-text: #ffffff;
	--coin-omg: #8e7fbb;
	--coin-omg-text: #ffffff;
	--coin-hot: #8b5e3c;
	--coin-hot-text: #ffffff;
	--coin-tusd: #002868;
	--coin-tusd-text: #ffffff;
	--coin-dai: #f3c27a;
	--coin-dai-text: #000000;
	--coin-ama: #fa2902;
	--coin-ama-text: #ffffff;
	--coin-xtz: #2c7df7;
	--coin-xtz-text: #ffffff;
	--coin-doge: #c3a634;
	--coin-doge-text: #ffffff;
	--coin-uni: #ff4dab;
	--coin-uni-text: #ffffff;
	--coin-dot: #d80579;
	--coin-dot-text: #ffffff;
}

:root {
	/*_________________________________*/

	/*COLOR MANAGEMENT*/

	/*_________________________________*/

	--base_background: #ffffff;
	--base_top-bar-navigation: #12161c;
	--base_secondary-navigation-bar: #f2f2f3;
	--base_wallet-sidebar-and-popup: #ffffff;
	--base_footer: #12161c;

	--theme_color_1: #f0b90b;

	--labels_important-active-labels-text-graphics: #000000;
	--labels_secondary-inactive-label-text-graphics: #808080;
	--calculated_important-border: #000000b3;
	--calculated_secondary-border: #00000054;
	--calculated_footer_border: #ffffff26;
	--calculated_super-pale_label-text-graphics: #00000026;
	--labels_fields: #f0f1f1;
	--labels_inactive-button: #535353;

	--trading_selling-related-elements: #ee4036;
	--trading_buying-related-elements: #00a69c;

	--specials_buttons-links-and-highlights: #0066b4;
	--specials_chat-messages: #98ccb2;
	--specials_my-username-in-chat: #ffff00;
	--specials_checks-okay-done: #008000;
	--specials_pending-waiting-caution: #f6921e;
	--specials_notifications-alerts-warnings: #ed1c24;

	/*_________________________________*/

	/*Calculated colors*/

	/*_________________________________*/

	--calculated_market-card_gradient_background: #ffffff;
	--calculated_trading_selling-sparkline-fill: #ee40364d;
	--calculated_trading_buying-sparkline-fill: #00a69c4d;
	--calculated_trading_selling-orderbook-bar: #ee40361f;
	--calculated_trading_buying-orderbook-bar: #00a69c1f;
	--calculated_trading_buying-related-text: #000000;
	--calculated_trading_selling-related-text: #ffffff;
	--calculated_base_modal-overlay: #ffffffbf;
	--calculated_landing_background: #ffffff00;
	--calculated_landing_markets_table: #fffffff0;
	--calculated_specials_highlight-box: #0066b41a;
	--calculated_base_top-bar-navigation_text: #ffffff;
	--calculated_base_top-bar-navigation_text-inactive: #ffffffb3;
	--calculated_base_footer_text: #ffffff;
	--calculated_trad-view_watermark: #0000001a;
	--calculated_trad-view_axis: #00000080;
	--calculated_trad-view_text: #000000cc;
	--calculated_specials_notifications-alerts-inactive: #ed1c24bf;
	--calculated_specials_notifications-alerts-text: #ffffff;
	--calculated_specials_notifications-pending-text: #ffffff;
	--calculated_specials_notifications-success-text: #ffffff;

	/*_________________________________*/

	/*_____Remaining_____*/
	--dark-accordion-arrow-active: #ffffff;

	/*______coins______*/
	--coin-eur: #06773d;
	--coin-eur-text: #ffffff;
	--coin-usd: #01592e;
	--coin-usd-text: #ffffff;
	--coin-jpy: #e0dfc7;
	--coin-jpy-text: #000000;
	--coin-gbp: #482e6e;
	--coin-gbp-text: #ffffff;
	--coin-chf: #ed1c24;
	--coin-chf-text: #ffffff;
	--coin-cad: #d7ce58;
	--coin-cad-text: #000000;
	--coin-aud: #19441f;
	--coin-aud-text: #ffffff;
	--coin-nzd: #b3498c;
	--coin-nzd-text: #ffffff;
	--coin-zar: #d8d3a0;
	--coin-zar-text: #000000;
	--coin-hkd: #f48f9b;
	--coin-hkd-text: #000000;
	--coin-krw: #f9ee02;
	--coin-krw-text: #000000;
	--coin-cny: #ec008c;
	--coin-cny-text: #ffffff;
	--coin-sgd: #720f15;
	--coin-sgd-text: #ffffff;
	--coin-btc: #f7941d;
	--coin-btc-text: #000000;
	--coin-eth: #3e4ea1;
	--coin-eth-text: #ffffff;
	--coin-ltc: #bcbec0;
	--coin-ltc-text: #000000;
	--coin-bch: #8dc541;
	--coin-bch-text: #000000;
	--coin-xrp: #414042;
	--coin-xrp-text: #ffffff;
	--coin-xht: #000000;
	--coin-xht-text: #ffffff;
	--coin-eos: #58595b;
	--coin-eos-text: #ffffff;
	--coin-bnb: #f1ba2a;
	--coin-bnb-text: #000000;
	--coin-link: #1b75bc;
	--coin-link-text: #ffffff;
	--coin-trx: #a22d25;
	--coin-trx-text: #ffffff;
	--coin-ada: #3667b2;
	--coin-ada-text: #ffffff;
	--coin-matic: #2aaae2;
	--coin-matic-text: #ffffff;
	--coin-neo: #bcbec0;
	--coin-neo-text: #000000;
	--coin-etc: #009444;
	--coin-etc-text: #000000;
	--coin-xlm: #808285;
	--coin-xlm-text: #ffffff;
	--coin-xmr: #f15a29;
	--coin-xmr-text: #ffffff;
	--coin-zec: #fbb040;
	--coin-zec-text: #000000;
	--coin-one: #939598;
	--coin-one-text: #ffffff;
	--coin-pax: #163526;
	--coin-pax-text: #ffffff;
	--coin-usdc: #4d7d91;
	--coin-usdc-text: #ffffff;
	--coin-usdt: #2bb673;
	--coin-usdt-text: #ffffff;
	--coin-vet: #454c7d;
	--coin-vet-text: #ffffff;
	--coin-btt: #c49a6c;
	--coin-btt-text: #ffffff;
	--coin-qtum: #96daf7;
	--coin-qtum-text: #000000;
	--coin-algo: #594a42;
	--coin-algo-text: #ffffff;
	--coin-rvn: #662d91;
	--coin-rvn-text: #ffffff;
	--coin-iota: #603913;
	--coin-iota-text: #ffffff;
	--coin-dash: #417ec1;
	--coin-dash-text: #ffffff;
	--coin-bsv: #a09032;
	--coin-bsv-text: #ffffff;
	--coin-ont: #00a79d;
	--coin-ont-text: #ffffff;
	--coin-atom: #796184;
	--coin-atom-text: #ffffff;
	--coin-celr: #726658;
	--coin-celr-text: #ffffff;
	--coin-omg: #8e7fbb;
	--coin-omg-text: #ffffff;
	--coin-hot: #8b5e3c;
	--coin-hot-text: #ffffff;
	--coin-tusd: #002868;
	--coin-tusd-text: #ffffff;
	--coin-dai: #f3c27a;
	--coin-dai-text: #000000;
	--coin-ama: #fa2902;
	--coin-ama-text: #ffffff;
	--coin-xtz: #2c7df7;
	--coin-xtz-text: #ffffff;
	--coin-doge: #c3a634;
	--coin-doge-text: #ffffff;
	--coin-uni: #ff4dab;
	--coin-uni-text: #ffffff;
	--coin-dot: #d80579;
	--coin-dot-text: #ffffff;
}

/*
@font-face {
	font-family: 'Open Sans';
	src: url('/assets/fonts/OPENSANS-REGULAR.TTF');
	font-weight: normal;
}

@font-face {
	font-family: 'Open Sans Condensed Bold';
	src: url('/assets/fonts/OPENSANSCONDENSED-BOLD.TTF');
	font-weight: bold;
}

@font-face {
	font-family: 'Open Sans Condensed Light';
	src: url('/assets/fonts/OPENSANSCONDENSED-LIGHT.TTF');
	font-weight: normal;
}

@font-face {
	font-family: 'Open Sans Condensed Light Italic';
	src: url('/assets/fonts/OPENSANSCONDENSED-LIGHTITALIC.TTF');
	font-style: italic;
	font-weight: normal;
}

@font-face {
	font-family: 'Open Sans Extrabold';
	src: url('/assets/fonts/OPENSANS-EXTRABOLD.TTF');
	font-weight: bold;
}

@font-face {
	font-family: 'Open Sans Extrabold Italic';
	src: url('/assets/fonts/OPENSANS-EXTRABOLDITALIC.TTF');
	font-style: italic;
	font-weight: bold;
}

@font-face {
	font-family: 'Open Sans Italic';
	src: url('/assets/fonts/OPENSANS-ITALIC.TTF');
	font-style: italic;
}

@font-face {
	font-family: 'Open Sans Light';
	src: url('/assets/fonts/OPENSANS-LIGHT.TTF');
	font-weight: normal;
}

@font-face {
	font-family: 'Open Sans Light Italic';
	src: url('/assets/fonts/OPENSANS-LIGHTITALIC.TTF');
	font-style: italic;
	font-weight: normal;
}

@font-face {
	font-family: 'Open Sans Semibold';
	src: url('/assets/fonts/OPENSANS-SEMIBOLD.TTF');
	font-weight: bold;
}

@font-face {
	font-family: 'Open Sans Semibold Italic';
	src: url('/assets/fonts/OPENSANS-SEMIBOLDITALIC.TTF');
	font-style: italic;
	font-weight: bold;
}

@font-face {
	font-family: 'Raleway';
	src: url('/assets/fonts/raleway/Raleway-Bold.ttf');
	font-weight: bold;
}

@font-face {
	font-family: 'Raleway';
	src: url('/assets/fonts/raleway/Raleway-Regular.ttf');
}

@font-face {
	font-family: 'Raleway Extrabold';
	src: url('/assets/fonts/raleway/Raleway-ExtraBold.ttf');
	font-weight: bold;
}

@font-face {
	font-family: 'Raleway Medium';
	src: url('/assets/fonts/raleway/Raleway-Medium.ttf');
}

@font-face {
	font-family: 'Raleway Medium';
	src: url('/assets/fonts/raleway/Raleway-MediumItalic.ttf');
	font-style: italic;
}

@font-face {
	font-family: 'Raleway Medium';
	src: url('/assets/fonts/raleway/Raleway-Medium.ttf');
}

@font-face {
	font-family: 'Raleway Light';
	src: url('/assets/fonts/raleway/Raleway-Light.ttf');
}
*/
.pointer {
  cursor: pointer; }

.not_allowed_cursor {
  cursor: not-allowed; }

.hidden {
  display: none !important; }

.flex-auto {
  flex: auto; }

.f-0 {
  flex: 0 1; }

.f-0-i {
  flex: 0 1 !important; }

.f-1 {
  flex: 1 1; }

.f-05 {
  flex: 0.5 1; }

.overflow-y {
  overflow-y: auto; }

.overflow-x {
  overflow-x: auto; }

.p-relative {
  position: relative; }

.text_overflow {
  width: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; }

.contrast {
  color: white;
  background: var(--labels_important-active-labels-text-graphics); }

.font-small {
  font-size: 1rem; }

.font-title {
  font-size: 1.8rem; }

.b {
  border: 1px solid red; }

.c {
  border: 1px solid green; }

.d {
  border: 1px solid blue; }

.separator {
  width: 2rem; }

table th {
  text-align: inherit; }

.cursor_disabled {
  cursor: not-allowed; }

.direction_ltr .title-font {
  font-family: "Roboto"; }

.blue-link {
  color: var(--specials_buttons-links-and-highlights) !important; }

.arrow {
  border: solid grey;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  height: 2rem;
  width: 2rem; }
  .arrow.right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg); }
  .arrow.left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg); }
  .arrow.up {
    transform: rotate(-135deg) translate(-1px, 0);
    -webkit-transform: rotate(-135deg) translate(-1px, 0); }
  .arrow.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg); }
  .arrow.small {
    height: 1rem;
    width: 1rem; }

.caps {
  text-transform: uppercase; }

.caps-first {
  text-transform: capitalize; }

.d-inline {
  display: inline; }

.d-inline-block {
  display: inline-block; }

.content-center {
  justify-content: center !important; }

.bold {
  font-weight: bold; }

.justify-content-end {
  justify-content: flex-end; }

.justify-content-start {
  justify-content: flex-start; }

.justify-content-between {
  justify-content: space-between; }

.align-center {
  align-items: center; }

.align-start {
  align-items: flex-start; }

.align-end {
  align-items: flex-end; }

.underline-text {
  text-decoration: underline !important; }

.disabled-link {
  pointer-events: none; }

.object-fit-contain {
  object-fit: contain;
  height: 100%; }

.background-contain {
  background-size: contain; }

.custom-select-style .ant-select-item-option-selected:not(.ant-select-item-option-disabled),
.custom-select-style .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  color: var(--labels_important-active-labels-text-graphics);
  background-color: var(--base_wallet-sidebar-and-popup); }

.custom-select-style .ant-select-item,
.custom-select-style .ant-select-item-option {
  color: var(--labels_secondary-inactive-label-text-graphics); }

.custom-select-style .ant-select-item-option-disabled {
  color: var(--labels_inactive-button); }

.custom-select-style.ant-select-dropdown {
  background-color: var(--base_background); }

.custom-select-input-style.no-border .ant-select-selector {
  border: none !important; }

.custom-select-input-style .ant-select-selector {
  border-radius: 0.35rem !important;
  border-color: var(--calculated_important-border) !important;
  background-color: transparent !important;
  color: var(--labels_important-active-labels-text-graphics) !important;
  height: 20px !important;
  font-weight: 400 !important;
  font-size: 0.9rem !important; }

.custom-select-input-style .ant-select-selection-item {
  line-height: 20px !important;
  text-transform: capitalize; }

.custom-select-input-style .ant-select-selection-search-input {
  height: 20px !important; }

.custom-select-input-style .anticon {
  color: var(--labels_important-active-labels-text-graphics) !important; }

.custom-select-input-style.appbar.ant-select {
  margin: 0 0.4rem !important;
  max-width: 100px !important;
  min-width: 70px; }

.custom-select-input-style.appbar .ant-select-selector {
  border-color: var(--calculated_base_top-bar-navigation_text-inactive) !important;
  color: var(--calculated_base_top-bar-navigation_text-inactive) !important; }

.custom-select-input-style.appbar .anticon {
  color: var(--calculated_base_top-bar-navigation_text-inactive) !important; }

.custom-select-input-style.elevated {
  z-index: 1; }

.custom-select-input-style .ant-select-arrow {
  z-index: -1; }

.border {
  border-color: var(--calculated_secondary-border) !important; }

.border-main {
  border-color: var(--calculated_important-border) !important; }

.border-pale {
  border-color: var(--calculated_super-pale_label-text-graphics) !important; }

.text-align-center {
  text-align: center; }

.align-items-baseline {
  align-items: baseline; }

.no-border {
  border: none !important; }

.flex-direction-column {
  flex-direction: column !important; }

.app_container {
  min-height: 100vh;
  background-color: var(--base_background);
  color: var(--labels_important-active-labels-text-graphics); }
  .app_container.btc .app_bar .app_bar-controllers {
    background-color: var(--coin-btc); }
  .app_container.btc.wallet .sidebar-container .sidebar-actions, .app_container.btc.trade .sidebar-container .sidebar-actions, .app_container.btc.quick-trade .sidebar-container .sidebar-actions {
    border-color: var(--coin-btc); }
  .app_container.eur .app_bar .app_bar-controllers {
    background-color: var(--coin-eur); }
  .app_container.eur.wallet .sidebar-container .sidebar-actions, .app_container.eur.trade .sidebar-container .sidebar-actions, .app_container.eur.quick-trade .sidebar-container .sidebar-actions {
    border-color: var(--coin-eur); }
  .app_container.bch .app_bar .app_bar-controllers {
    background-color: var(--coin-bch); }
  .app_container.bch.wallet .sidebar-container .sidebar-actions, .app_container.bch.trade .sidebar-container .sidebar-actions, .app_container.bch.quick-trade .sidebar-container .sidebar-actions {
    border-color: var(--coin-bch); }
  .app_container.eth .app_bar .app_bar-controllers {
    background-color: var(--coin-eth); }
  .app_container.eth.wallet .sidebar-container .sidebar-actions, .app_container.eth.trade .sidebar-container .sidebar-actions, .app_container.eth.quick-trade .sidebar-container .sidebar-actions {
    border-color: var(--coin-eth); }
  .app_container.ltc .app_bar .app_bar-controllers {
    background-color: var(--coin-ltc); }
  .app_container.ltc.wallet .sidebar-container .sidebar-actions, .app_container.ltc.trade .sidebar-container .sidebar-actions, .app_container.ltc.quick-trade .sidebar-container .sidebar-actions {
    border-color: var(--coin-ltc); }
  .app_container.layout-desktop .app_container-content {
    height: auto;
    flex: 1 1; }
  .app_container.layout-mobile .app_container-content {
    min-height: calc( 100vh - 13rem);
    max-height: calc( 100vh - 13rem);
    max-width: 100vw;
    overflow-y: scroll; }
    .app_container.layout-mobile .app_container-content.no_bottom_navigation {
      height: auto;
      flex: 1 1;
      min-height: calc(100vh);
      max-height: calc(100vh);
      max-width: 100vw;
      overflow-y: scroll; }
    .app_container.layout-mobile .app_container-content .app_container-main.no_bottom_navigation {
      height: 100%; }
  .app_container.layout-mobile .content-with-bar {
    min-height: calc( 100vh - 23rem);
    max-height: calc( 100vh - 23rem);
    max-width: 100vw;
    padding: 1rem;
    margin: 0;
    overflow-y: scroll; }
  .app_container .app_container-content .app_container-main {
    width: 100%;
    height: auto;
    position: relative; }
    .app_container .app_container-content .app_container-main .presentation_container {
      width: 75rem;
      height: inherit; }
  .app_container .app_container-sidebar {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-width: 20rem;
    max-width: 20rem;
    background-color: var(--base_wallet-sidebar-and-popup);
    border-left: 1px solid var(--calculated_super-pale_label-text-graphics);
    padding: 0;
    transition: all 0.2s ease-out 0s; }
    .app_container .app_container-sidebar .sidebar-toggle-wrapper {
      display: flex;
      align-items: center;
      width: 0 !important;
      overflow: visible; }
    .app_container .app_container-sidebar .sidebar-toggle {
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%); }
      .app_container .app_container-sidebar .sidebar-toggle.ant-btn-primary {
        background-color: var(--base_secondary-navigation-bar);
        border-color: var(--calculated_secondary-border); }
      .app_container .app_container-sidebar .sidebar-toggle .anticon {
        color: var(--labels_important-active-labels-text-graphics); }
      .app_container .app_container-sidebar .sidebar-toggle.ant-btn-icon-only {
        height: 30px;
        width: 18px; }
    .app_container .app_container-sidebar.close-sidebar {
      min-width: 0;
      max-width: 0; }
      .app_container .app_container-sidebar.close-sidebar .sidebar-toggle {
        -webkit-transform: translateX(-100%);
                transform: translateX(-100%); }
      .app_container .app_container-sidebar.close-sidebar .sidebar-container {
        overflow: hidden; }
  .app_container .exchange-warning {
    background-color: #fff100; }

.layout-mobile .presentation_container {
  padding-top: 4rem !important; }

.presentation_container {
  width: 100rem;
  max-width: calc(100% - 2rem);
  margin: 1rem auto;
  height: 100%; }
  .presentation_container .inner_container {
    position: relative; }
    .presentation_container .inner_container.with_border_top {
      border-top: 1px solid var(--calculated_super-pale_label-text-graphics); }
    .presentation_container .inner_container.with_border_bottom {
      border-bottom: 1px solid var(--calculated_super-pale_label-text-graphics); }
    .presentation_container .inner_container .information_block {
      position: relative;
      margin-bottom: 1rem; }
      .presentation_container .inner_container .information_block .information_block-text_wrapper .title {
        font-size: 14px; }
      .presentation_container .inner_container .information_block .information_block-text_wrapper .text {
        font-size: 1rem;
        color: var(--labels_secondary-inactive-label-text-graphics); }
        .presentation_container .inner_container .information_block .information_block-text_wrapper .text > p {
          margin: 0.5rem 0; }

.app_container-bottom_bar {
  display: flex;
  flex: 0 1;
  width: 100%;
  max-width: 100vw;
  position: -webkit-fixed;
  position: fixed;
  bottom: 0;
  align-self: flex-end;
  z-index: 9999; }

@media (min-width: 1920px) {
  .font-small,
  .holla-button,
  .field-wrapper,
  .action_notification-text,
  .wallet-assets_block-table,
  .accordion_section_content,
  .verification_container .header-content,
  .verification_container .content-text,
  .table_body-wrapper,
  .wallet_section-title-amount,
  .wallet_section-title,
  .trade_tabs-container .trade_tabs-content,
  .toogle_button-wrapper .toogle-content,
  .trade_block-wrapper .trade_block-content,
  .ohlc_data_wrapper .ohlc_label,
  .ohlc_value,
  .information_block-text_wrapper .text,
  .qr-text,
  .header_title-children,
  .placeholder,
  .notification-content-wrapper,
  .otp_form-section-wrapper .otp_form-section-text,
  .popup_info-wrapper .popup_info-text,
  .otp_secret {
    font-size: 1rem !important; }
  .presentation_container,
  .trade_tabs-container {
    width: 100rem !important; }
  .app_bar-pair-tab {
    min-width: 25rem !important;
    max-width: 25rem !important; }
  .app_container .app_container-sidebar {
    min-width: 25rem;
    max-width: 25rem; } }

@media screen and (max-width: 1096px) {
  .font-small,
  .holla-button,
  .field-wrapper,
  .action_notification-text,
  .wallet-assets_block-table,
  .accordion_section_content,
  .verification_container .content-text,
  .table_body-wrapper,
  .wallet_section-title-amount,
  .wallet_section-title,
  .trade_tabs-container .trade_tabs-content,
  .toogle_button-wrapper .toogle-content,
  .trade_block-wrapper .trade_block-content,
  .ohlc_data_wrapper .ohlc_label,
  .ohlc_value,
  .information_block-text_wrapper .text,
  .qr-text,
  .header_title-children,
  .placeholder,
  .notification-content-wrapper,
  .otp_form-section-wrapper .otp_form-section-text,
  .popup_info-wrapper .popup_info-text,
  .otp_secret {
    font-size: 0.9rem !important; }
  .verification_container .header-content {
    font-size: 1.2rem !important; }
  .app_bar-pair-tab {
    min-width: 19rem !important;
    max-width: 19rem !important; }
  .presentation_container,
  .trade_tabs-container {
    width: 65rem !important; }
  .app_container .app_container-sidebar {
    min-width: 18rem;
    max-width: 18rem; }
  .app_container .sidebar_hub-content {
    margin: 1.3rem !important; } }

.light-auth-body {
  background-repeat: no-repeat;
  background-size: cover;
  position: relative; }
  .light-auth-body:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 130%; }

.exchange-warning {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2; }

.exchange-expired {
  background-color: #ed1c24 !important;
  color: #ffffff !important; }

.exchange-trial {
  background-color: #fff100;
  color: #000000; }

.layout-mobile.auth-wrapper .link_wrapper {
  font-size: 1.2rem; }

.layout-mobile.auth-wrapper .auth-container {
  max-width: unset !important;
  height: 100%; }
  .layout-mobile.auth-wrapper .auth-container > div {
    height: 100%; }
    .layout-mobile.auth-wrapper .auth-container > div .auth_wrapper {
      padding: 0 1rem !important;
      height: 100%; }
  .layout-mobile.auth-wrapper .auth-container .icon_title-wrapper .icon_title-text.title {
    font-size: 5rem; }
  .layout-mobile.auth-wrapper .auth-container .font-small {
    font-size: 1.2rem; }
  .layout-mobile.auth-wrapper .auth-container .auth_form-wrapper .field-wrapper {
    font-size: 1.2rem; }
    .layout-mobile.auth-wrapper .auth-container .auth_form-wrapper .field-wrapper .field-label,
    .layout-mobile.auth-wrapper .auth-container .auth_form-wrapper .field-wrapper .input_field-input {
      font-size: 1.2rem; }

.auth-wrapper {
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow-y: scroll; }
  .auth-wrapper:before {
    position: fixed;
    content: '';
    width: 100%;
    height: 100%; }
  .auth-wrapper.white-theme {
    color: var(--labels_important-active-labels-text-graphics); }
  .auth-wrapper .auth-container {
    max-width: 575px;
    max-height: 100vh;
    z-index: 1; }
    .auth-wrapper .auth-container .auth_wrapper {
      background-color: var(--base_wallet-sidebar-and-popup);
      padding: 4rem 4rem 6rem; }
      .auth-wrapper .auth-container .auth_wrapper .icon_title-svg .hollaEX_logo_gray {
        fill: var(--labels_secondary-inactive-label-text-graphics); }
    .auth-wrapper .auth-container .auth_form-wrapper {
      margin-top: 2rem;
      position: relative; }
  .auth-wrapper .link_wrapper {
    margin: 1rem;
    margin-top: 0; }
    .auth-wrapper .link_wrapper.multi_links {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
      .auth-wrapper .link_wrapper.multi_links > div:not(:last-child) {
        padding-bottom: 0.5rem; }
  .auth-wrapper .blue-link {
    padding-left: 0.25rem; }
  .auth-wrapper .error_text {
    padding-bottom: 0.5rem; }
  .auth-wrapper .button-margin {
    margin-top: 1.5rem; }
  .auth-wrapper .icon_title-wrapper {
    margin-top: 0; }
    .auth-wrapper .icon_title-wrapper.underline:after {
      height: 0.2rem; }
    .auth-wrapper .icon_title-wrapper .icon_title-text {
      margin: 1.5rem auto; }
      .auth-wrapper .icon_title-wrapper .icon_title-text.title {
        text-transform: capitalize;
        font-size: 3rem; }
    .auth-wrapper .icon_title-wrapper .icon_title-svg {
      width: 100%;
      display: flex;
      justify-content: center; }
      .auth-wrapper .icon_title-wrapper .icon_title-svg svg {
        width: 6rem;
        height: 6rem; }
    .auth-wrapper .icon_title-wrapper.holla-logo .icon_title-svg {
      height: inherit; }
      .auth-wrapper .icon_title-wrapper.holla-logo .icon_title-svg svg {
        width: inherit;
        height: 4rem; }
  .auth-wrapper .holla-button {
    margin-top: 1rem; }
  .auth-wrapper .link_wrapper {
    margin: 1.5rem 0 0; }

.footer-wrapper {
  position: relative; }

@media screen and (max-width: 1096px) {
  .auth-wrapper .auth-container {
    max-width: 475px !important; }
    .auth-wrapper .auth-container .auth_wrapper {
      padding: 3rem 4rem 3rem; }
    .auth-wrapper .auth-container .auth_form-wrapper {
      margin-top: 1rem; }
      .auth-wrapper .auth-container .auth_form-wrapper .field-wrapper {
        margin: 0.5rem 0; }
    .auth-wrapper .auth-container .holla-button {
      margin-top: 0.5rem; }
  .auth-wrapper .link_wrapper {
    margin-top: 1rem; }
  .auth-wrapper .icon_title-wrapper .icon_title-svg svg {
    width: 5rem;
    height: 5rem; }
  .auth-wrapper .icon_title-wrapper.holla-logo .icon_title-svg svg {
    height: 3rem; }
  .auth-wrapper .icon_title-wrapper .icon_title-text {
    margin: 0.8rem auto; }
    .auth-wrapper .icon_title-wrapper .icon_title-text.title {
      font-size: 2rem; }
  #rc-imageselect,
  .g-recaptcha {
    transform: scale(0.72);
    -webkit-transform: scale(0.72);
    transform-origin: 0 0;
    -webkit-transform-origin: 0% 0%; } }

@media screen and (max-width: 1096px) and (min-width: 768px) {
  .auth-wrapper .auth-container .auth_form-wrapper .captcha-wrapper {
    height: 5.5rem; } }

@media screen and (max-width: 334px) and (min-width: 280px) {
  .auth-wrapper .auth-container .auth_form-wrapper .captcha-wrapper {
    height: 7rem; }
  #rc-imageselect,
  .g-recaptcha {
    transform: scale(0.6);
    -webkit-transform: scale(0.6);
    transform-origin: 0 0;
    -webkit-transform-origin: 0% 0%; } }

@media screen and (max-width: 460px) and (min-width: 335px) {
  .auth-wrapper .auth-container .auth_form-wrapper .captcha-wrapper {
    height: 7rem; }
  #rc-imageselect,
  .g-recaptcha {
    transform: scale(0.62);
    -webkit-transform: scale(0.62);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0; } }

.information_section {
  font-size: 1rem;
  color: var(--labels_secondary-inactive-label-text-graphics);
  padding: 0 0 0.5rem; }
  .information_section .information_svg {
    fill: var(--labels_secondary-inactive-label-text-graphics); }
  .information_section .information_change {
    color: var(--specials_buttons-links-and-highlights);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content; }
  .information_section > div:not(:first-child) {
    margin-top: 0.75rem; }

.user_verification-form_section {
  padding-bottom: 1rem; }

.direction_ltr .information_section .information_text-text {
  margin-left: 0.5rem; }

.direction_rtl .information_section .information_text-text {
  margin-right: 0.5rem; }

.user-limits .user-limits-table {
  width: 100%;
  font-size: 1rem; }
  .user-limits .user-limits-table thead tr,
  .user-limits .user-limits-table tfoot tr {
    height: 3.5rem;
    vertical-align: bottom; }
  .user-limits .user-limits-table tfoot {
    font-size: 14px; }
    .user-limits .user-limits-table tfoot td {
      white-space: nowrap; }
  .user-limits .user-limits-table .table-row {
    height: 2.5rem;
    color: var(--labels_secondary-inactive-label-text-graphics); }
    .user-limits .user-limits-table .table-row .td-levelball .td-levelball-content {
      border: 1px solid var(--calculated_important-border);
      border-radius: 50%;
      width: 1.5rem;
      height: 1.5rem; }
    .user-limits .user-limits-table .table-row.user-level {
      color: var(--labels_important-active-labels-text-graphics); }
      .user-limits .user-limits-table .table-row.user-level td:not(:first-child) {
        background-color: var(--labels_inactive-button); }
      .user-limits .user-limits-table .table-row.user-level .td-levelball .td-levelball-content {
        color: white;
        background-color: var(--labels_important-active-labels-text-graphics); }
  .user-limits .user-limits-table .table-bottom-border td,
  .user-limits .user-limits-table th {
    position: relative; }
    .user-limits .user-limits-table .table-bottom-border td:not(:first-child),
    .user-limits .user-limits-table th:not(:first-child) {
      border-bottom: 1px solid var(--calculated_important-border);
      padding: 0 0.25rem;
      min-width: 25%; }
  .user-limits .user-limits-table .td-fit {
    width: 1%;
    white-space: nowrap; }
  .user-limits .user-limits-table .td-name {
    min-width: 75px !important; }
  .user-limits .user-limits-table .table-icon > div {
    vertical-align: middle;
    margin: auto; }
  .user-limits .user-limits-table .td-amount {
    direction: ltr; }

.direction_ltr .user-limits .user-limits-table th {
  font-family: "Roboto"; }

.direction_ltr .user-limits .user-limits-table th,
.direction_ltr .user-limits .user-limits-table td {
  text-align: left; }

.direction_ltr .user-limits .user-limits-table .align-opposite {
  text-align: right; }

.direction_ltr .user-limits .user-limits-table .table-icon {
  padding-right: 0.5rem; }

.direction_rtl .user-limits .user-limits-table th,
.direction_rtl .user-limits .user-limits-table td {
  text-align: right; }

.direction_rtl .user-limits .user-limits-table .align-opposite {
  text-align: left; }

.direction_rtl .user-limits .user-limits-table .table-icon {
  padding-left: 0.5rem; }

.user_security-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  padding: 30px;
  background-color: var(--base_wallet-sidebar-and-popup); }
  .user_security-wrapper > *:not(:last-child) {
    margin-bottom: 2rem; }

.otp_form-wrapper {
  display: flex;
  flex-direction: column;
  min-width: 30rem;
  width: 30rem; }
  .otp_form-wrapper .otp_form-section-wrapper {
    border-top: 1px solid var(--calculated_important-border);
    padding: 0.75rem 0; }
    .otp_form-wrapper .otp_form-section-wrapper .otp_form-section-content {
      margin: 1rem 0; }
      .otp_form-wrapper .otp_form-section-wrapper .otp_form-section-content .qr-code-wrapper {
        background: white;
        margin: 0.5rem;
        width: 10rem;
        height: 10rem; }
        .otp_form-wrapper .otp_form-section-wrapper .otp_form-section-content .qr-code-wrapper canvas {
          width: 9rem !important;
          height: 9rem !important; }
    .otp_form-wrapper .otp_form-section-wrapper .otp_form-section-title {
      font-size: 14px;
      font-weight: bold; }
    .otp_form-wrapper .otp_form-section-wrapper .otp_form-section-text {
      font-size: 1rem; }
    .otp_form-wrapper .otp_form-section-wrapper .otp_secret {
      font-size: 1rem;
      background-color: var(--calculated_specials_highlight-box);
      padding: 0.25rem 0.5rem; }
  .otp_form-wrapper .otp_form-fields-wrapper {
    margin-bottom: 1rem; }
  .otp_form-wrapper .icon_title-wrapper .icon_title-text.title {
    font-size: 1.8rem; }
  .otp_form-wrapper .icon_title-wrapper .icon_title-svg {
    width: 6.5rem;
    height: 6.5rem; }
    .otp_form-wrapper .icon_title-wrapper .icon_title-svg svg {
      width: 6.5rem;
      height: 6.5rem; }

.tokens-name,
.tokens-tokenkey {
  word-break: break-word;
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important; }

.tokens-tokenkey {
  max-width: 5rem; }

.tokens-date {
  min-width: 5rem;
  max-width: 5rem; }

.tokens-icon,
.tokens-revoke {
  min-width: 3rem;
  max-width: 3rem; }

.tokens-icon > div {
  display: flex;
  justify-content: center;
  align-items: center; }

.tokens-icon svg {
  width: 2.5rem;
  height: 2.5rem; }

.tokens-icon.small-icon svg {
  padding: 0.325rem 0; }

.tokens-icon-svg svg {
  fill: var(--labels_important-active-labels-text-graphics); }

.checkbutton-input-wrapper--image svg {
  stroke: var(--labels_important-active-labels-text-graphics);
  stroke-width: 1rem; }

.popup_info-wrapper .popup_info-title {
  font-family: "Roboto";
  font-size: 1.3rem;
  border-bottom: 1px solid var(--calculated_secondary-border); }

.popup_info-wrapper .popup_info-text {
  font-size: 1rem; }

.token-no-otp {
  color: var(--labels_inactive-button);
  cursor: not-allowed !important; }

.dumb-field-wrapper.token-value-input .copy-wrapper {
  top: calc(50% - 0.5rem); }

.dumb-field-wrapper.token-value-input .custom {
  word-break: break-all; }

.direction_ltr .dumb-field-wrapper.token-value-input .custom {
  padding-right: 4rem; }

.direction_rtl .dumb-field-wrapper.token-value-input .custom {
  padding-left: 4rem; }

.logout-menu-wrapper {
  color: var(--labels_secondary-inactive-label-text-graphics);
  font-size: 1.75rem;
  padding: 0.5rem 0;
  border-bottom: 1px solid var(--calculated_important-border); }
  .logout-menu-wrapper .logout-icon {
    width: 1.5rem; }

.change-password-form,
.dev-section-wrapper {
  border-radius: 4px;
  margin: 30px 0px 40px;
  background-color: var(--base_wallet-sidebar-and-popup); }

.dev-section-wrapper {
  padding: 30px; }

.change-password-form-wrapper .change-password-form {
  padding: 20px 60px 20px; }
  .change-password-form-wrapper .change-password-form .field-content {
    border-bottom: 1px solid; }

.change-password-form-wrapper .password-btn {
  width: 300px; }

.user-security-container .icon_title-wrapper {
  display: flex;
  justify-content: flex-start !important;
  flex-direction: row;
  align-items: center; }

.layout-mobile .user_security-wrapper .warning_text {
  font-size: 13px !important; }

.layout-mobile .user_security-wrapper .checkbutton-wrapper .checkbutton-input-wrapper {
  width: 240px !important; }
  .layout-mobile .user_security-wrapper .checkbutton-wrapper .checkbutton-input-wrapper .checkbutton-input-wrapper--label {
    font-size: 13px;
    color: var(--specials_buttons-links-and-highlights); }

.layout-mobile .dev-section-wrapper {
  padding: 5px; }
  .layout-mobile .dev-section-wrapper .table-wrapper th,
  .layout-mobile .dev-section-wrapper .table-wrapper td {
    padding: 0; }
  .layout-mobile .dev-section-wrapper .field-error-content .field-error-text {
    margin-top: 10px; }

.layout-mobile .change-password-form-wrapper .change-password-form .field-label-wrapper {
  flex-direction: column; }
  .layout-mobile .change-password-form-wrapper .change-password-form .field-label-wrapper .field-label {
    font-size: 10px; }
  .layout-mobile .change-password-form-wrapper .change-password-form .field-label-wrapper .input-box-field {
    padding: 4px;
    height: 30px;
    margin-top: 5px; }

.layout-mobile .change-password-form-wrapper .change-password-form .input_field-input::-webkit-input-placeholder {
  font-size: 10px; }

.layout-mobile .change-password-form-wrapper .change-password-form .input_field-input::placeholder {
  font-size: 10px; }

.layout-mobile .otp_form-wrapper {
  min-width: auto;
  width: auto; }
  .layout-mobile .otp_form-wrapper .otp_form-section-wrapper .otp_form-section-title {
    font-size: 15px; }
  .layout-mobile .otp_form-wrapper .otp_form-section-wrapper .otp_form-section-text {
    font-size: 13px !important; }
  .layout-mobile .otp_form-wrapper .otp_form-section-content .qr-code-wrapper {
    width: 15rem;
    height: 15rem; }
    .layout-mobile .otp_form-wrapper .otp_form-section-content .qr-code-wrapper canvas {
      width: 14rem !important;
      height: 14rem !important; }

.layout-mobile .accordion_section_content .warning_text {
  font-size: 13px !important; }

@media (max-width: 1060px) {
  .change-password-form-wrapper .change-password-form {
    padding: 10px; }
    .change-password-form-wrapper .change-password-form .field-label-wrapper .field-label {
      font-size: 12px; }
    .change-password-form-wrapper .change-password-form .input_field-input::-webkit-input-placeholder {
      font-size: 12px; }
    .change-password-form-wrapper .change-password-form .input_field-input::placeholder {
      font-size: 12px; }
  .user-security-wrapper .tab_controller-tabs .tab_item {
    font-size: 12px !important; } }

.signup_success-wrapper {
  text-align: center; }
  .signup_success-wrapper .signup_success-content {
    margin: 2rem 0 4rem; }

.terms_label-wrapper {
  padding: 0 0.25rem; }

.signup-warning {
  margin-top: 1.5rem; }

.warning-icon {
  width: 1.5rem;
  margin-right: 1rem; }

.signup-warning-text {
  color: var(--specials_notifications-alerts-warnings); }

.dialog-link {
  color: var(--specials_buttons-links-and-highlights);
  padding: 0 0.25rem; }

.signup-modal-wrapper {
  max-width: 767px;
  max-height: 100%; }

.layout-mobile .signup_success-wrapper {
  height: calc(100% - 4rem) !important; }

.home-page__market-wrapper {
  background-color: var(--calculated_landing_markets_table); }
  .home-page__market-wrapper .table-row {
    color: var(--labels_important-active-labels-text-graphics) !important; }
  .home-page__market-wrapper thead {
    color: var(--labels_important-active-labels-text-graphics) !important;
    background-color: var(--base_wallet-sidebar-and-popup); }
    .home-page__market-wrapper thead tr {
      height: 5rem !important; }
  .home-page__market-wrapper th:nth-child(1) {
    padding-left: 2rem !important; }
  .home-page__market-wrapper td:nth-child(1) {
    padding-left: 1rem !important; }
  .home-page__market-wrapper th:last-child {
    padding-right: 2rem !important; }
  .home-page__market-wrapper td:last-child {
    padding-right: 1rem !important; }
  .home-page__market-wrapper .market-list__footer {
    height: 4.5rem !important; }
  .home-page__market-wrapper .market-list__block-table {
    margin-bottom: 0 !important; }
  .home-page__market-wrapper .price-diff-down {
    color: var(--trading_selling-related-elements);
    padding: 0 5px;
    border-radius: 2px;
    transition: all 0.2s ease-out 0s; }
  .home-page__market-wrapper .glance-price-diff-down.entering, .home-page__market-wrapper .glance-price-diff-down.exiting {
    color: var(--calculated_trading_selling-related-text);
    background-color: var(--trading_selling-related-elements); }
    .home-page__market-wrapper .glance-price-diff-down.entering.glance-trade-tab-price_diff_down:before, .home-page__market-wrapper .glance-price-diff-down.exiting.glance-trade-tab-price_diff_down:before {
      border-color: var(--calculated_trading_selling-related-text); }
  .home-page__market-wrapper .price-diff-up {
    color: var(--trading_buying-related-elements);
    padding: 0 5px;
    border-radius: 2px;
    transition: all 0.2s ease-out 0s; }
  .home-page__market-wrapper .glance-price-diff-up.entering, .home-page__market-wrapper .glance-price-diff-up.exiting {
    color: var(--calculated_trading_buying-related-text);
    background-color: var(--trading_buying-related-elements); }
    .home-page__market-wrapper .glance-price-diff-up.entering.glance-trade-tab-price_diff_up:before, .home-page__market-wrapper .glance-price-diff-up.exiting.glance-trade-tab-price_diff_up:before {
      border-color: var(--calculated_trading_buying-related-text); }
  .home-page__market-wrapper .trade-tab-price_diff_up {
    margin: auto 0; }
  .home-page__market-wrapper .trade-tab-price_diff_up:before {
    content: "";
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-top: none !important;
    border-left: 0.4rem solid transparent !important;
    border-right: 0.4rem solid transparent !important;
    border-bottom: 0.4rem solid var(--trading_buying-related-elements);
    margin-bottom: 0rem;
    display: inline-block;
    margin-bottom: 0.2rem;
    margin-right: 0.5rem;
    position: relative;
    top: calc(50% - 0.6rem); }
  .home-page__market-wrapper .trade-tab-price_diff_down {
    margin: auto 0; }
  .home-page__market-wrapper .trade-tab-price_diff_down:before {
    content: "";
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-bottom: none !important;
    border-left: 0.4rem solid transparent !important;
    border-right: 0.4rem solid transparent !important;
    border-top: 0.4rem solid var(--trading_selling-related-elements);
    margin-top: 0rem;
    display: inline-block;
    margin-right: 0.5rem;
    position: relative;
    top: calc(50% - 0.6rem); }

.home_header_button {
  height: 28px;
  font-weight: bold; }

.layout-mobile .home_header_button {
  height: 24px; }

.layout-mobile .home-page__market-wrapper th:nth-child(1) {
  padding-left: 1rem !important; }

.layout-mobile .home-page__market-wrapper td:nth-child(1) {
  padding-left: 0.5rem !important; }

.layout-mobile .home-page__market-wrapper th:last-child {
  padding-right: 0.1rem !important; }

.layout-mobile .home-page__market-wrapper td:last-child {
  padding-right: 0.5rem !important; }

.home_container {
  background-repeat: no-repeat;
  background-size: cover; }
  .home_container .main-section_button_invert {
    color: #fff;
    background-color: #12161c !important;
    border-radius: 5px; }
  .home_container .main-section_button {
    color: var(--labels_important-active-labels-text-graphics) !important;
    background-color: var(--theme_color_1) !important;
    border-radius: 5px; }
  .home_container .quick_trade-wrapper {
    min-width: 30rem;
    max-width: 40rem; }
  .home_container .exchange-expired {
    background-color: #ed1c24 !important;
    color: #ffffff !important; }
  .home_container .exchange-warning {
    background-color: #fff100;
    color: #000000; }
  .home_container .quick_trade-section {
    background-color: var(--base_background);
    border: 1px solid var(--calculated_secondary-border); }
    .home_container .quick_trade-section > .quick_trade-wrapper {
      flex: 1 1; }
  .home_container .quick_trade-section,
  .home_container .features-container {
    padding: 2rem; }
  .home_container .home-page_overlay {
    width: 100% !important;
    height: 100% !important;
    background: linear-gradient(to bottom, var(--calculated_landing_background), var(--calculated_landing_background)); }
  .home_container .section_1-content {
    position: relative; }
    .home_container .section_1-content .scroll-button {
      position: absolute;
      bottom: 1rem;
      width: 2rem;
      height: 2rem; }
      .home_container .section_1-content .scroll-button svg {
        width: 2rem;
        height: 2rem; }
        .home_container .section_1-content .scroll-button svg .st0 {
          stroke: #4d4d4d;
          stroke-width: 2; }
  .home_container .features-container {
    background: white; }
    .home_container .features-container .features-card_container {
      max-width: 75rem; }
  .home_container .home-card-container {
    border-top: 1px solid var(--calculated_important-border);
    width: 17.5rem;
    margin: 3rem;
    margin-top: 0; }
    .home_container .home-card-container .home-card-icon-wrapper > div {
      display: flex;
      justify-content: center;
      align-items: center; }
      .home_container .home-card-container .home-card-icon-wrapper > div .home-card-icon {
        fill: var(--labels_important-active-labels-text-graphics); }
    .home_container .home-card-container .home-card-icon {
      width: 4rem;
      height: 4rem; }
    .home_container .home-card-container .home-card-text-container {
      font-size: 1rem;
      color: var(--labels_secondary-inactive-label-text-graphics); }
      .home_container .home-card-container .home-card-text-container .home-card-title {
        margin: 0.5rem 0 0.25rem;
        font-weight: bold;
        text-transform: uppercase; }
  .home_container .home-title,
  .home_container .features-title {
    font-size: 3.5rem;
    line-height: normal;
    font-weight: bold;
    text-align: center; }
  .home_container .text-section,
  .home_container .buttons-section {
    margin: 0.5rem; }
  .home_container .text-section {
    font-weight: bold; }
  .home_container .buttons-section--button {
    width: 15rem;
    height: 64px;
    font-size: 1rem;
    border: 1px solid var(--calculated_important-border);
    margin: 0 1rem;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold; }
    .home_container .buttons-section--button.contrast {
      background-color: var(--labels_important-active-labels-text-graphics); }

.home-page_content {
  margin-left: auto;
  margin-right: auto;
  margin-top: 5rem;
  max-width: 100rem; }

.live-markets_header {
  font-size: 1.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 1.5rem;
  color: var(--labels_important-active-labels-text-graphics);
  font-weight: bolder; }

.buttons-section-header {
  margin: 0 0.5rem; }

.home-page_section-wrapper:not(.main-section-wrapper) {
  padding-top: 5rem;
  padding-bottom: 5rem; }

.home-page_section-wrapper.main-section-wrapper {
  padding-bottom: 5rem; }

.direction_ltr .home-card-text-container {
  margin-left: 0.75rem; }

.direction_rtl .home-card-text-container {
  margin-right: 0.75rem; }

.home_app_bar {
  height: 64px;
  min-height: 64px; }
  .home_app_bar .app_bar-icon-logo {
    height: 4rem;
    width: 5rem;
    background-size: contain;
    background-position: left center;
    background-repeat: no-repeat; }
  .home_app_bar .app_bar-icon {
    flex: 0 1;
    padding-right: 8px;
    width: 100%; }

@media (max-width: 767px) {
  .home_container .home-card-container {
    margin: 0 2rem 3rem; }
  .home_container .live-markets_header {
    font-size: 1.5rem !important; }
  .home_container .features-container .features-card_container {
    max-width: 45rem; } }

@media (min-width: 1920px) {
  .home_app_bar .app_bar-icon-logo {
    height: 3rem;
    width: 5rem; } }

.wide-logo {
  width: 150px !important; }

.layout-mobile .button-container {
  min-height: 4.5rem; }

.layout-mobile .currency-list-container .wallet-currency {
  max-width: calc(100vw - 6rem); }

.layout-mobile .currency-list-container .balance-big {
  font-size: 4rem; }

.layout-mobile .currency-list-container .balance-small {
  font-size: 1.5rem; }

.layout-mobile .currency-list-container .button-container .holla-button {
  font-size: 13px !important; }

.layout-mobile .wallet-container .action_notification-wrapper .action_notification-text {
  font-size: 1.2rem !important; }

.layout-mobile .wallet-container .accordion_wrapper .accordion_section {
  border-width: 2px; }
  .layout-mobile .wallet-container .accordion_wrapper .accordion_section .accordion_section_title .accordion_section_content_text {
    font-size: 1.75rem;
    text-transform: uppercase;
    font-weight: bold; }
  .layout-mobile .wallet-container .accordion_wrapper .accordion_section .action_notification-wrapper {
    margin: 0; }

.layout-mobile .wallet-container .wallet-assets_block {
  padding: 6px; }
  .layout-mobile .wallet-container .wallet-assets_block .wallet-assets_block-table thead tr th:nth-child(3) {
    text-align: center; }
  .layout-mobile .wallet-container .wallet-assets_block .wallet-assets_block-table .td-wallet,
  .layout-mobile .wallet-container .wallet-assets_block .wallet-assets_block-table .td-amount,
  .layout-mobile .wallet-container .wallet-assets_block .wallet-assets_block-table .td-name {
    min-width: unset !important; }
  .layout-mobile .wallet-container .wallet-assets_block .wallet-assets_block-table .td-wallet .deposit-withdrawal-wrapper {
    margin: 0 auto;
    width: 18rem; }
    .layout-mobile .wallet-container .wallet-assets_block .wallet-assets_block-table .td-wallet .deposit-withdrawal-wrapper .action_notification-wrapper .action_notification-text {
      font-size: 1.2rem; }
  .layout-mobile .wallet-container .wallet-assets_block .wallet-assets_block-table .currency_ball-wrapper.s {
    width: 2.5rem;
    height: 2.5rem;
    font-size: 0.8rem; }
  .layout-mobile .wallet-container .wallet-assets_block .wallet-assets_block-table .table-bottom-border {
    font-size: 13px !important; }
  .layout-mobile .wallet-container .wallet-assets_block .wallet-assets_block-table tfoot {
    font-size: 11px !important; }
  .layout-mobile .wallet-container .wallet-assets_block .zero-balance-wrapper {
    flex-direction: column; }

.layout-mobile .wallet-container .action-button-wrapper {
  padding: 5px 2px; }

.wallet-container {
  border-top: 1px solid var(--labels_important-active-labels-text-graphics); }
  .wallet-container .wallet-header_block > * {
    margin-bottom: 2rem; }
  .wallet-container .wallet-header_block .wallet-header_block-currency_title {
    font-size: 1.3rem;
    position: relative; }
  .wallet-container .wallet-header_block .coin-icons {
    width: 50px;
    padding-top: 4px; }
  .wallet-container .wallet-header_block .with_price-block_amount-value {
    font-size: 4rem;
    line-height: 4rem; }
  .wallet-container .paper-clip-icon .action_notification-svg {
    background-color: var(--specials_buttons-links-and-highlights);
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;
    display: flex;
    margin-left: 5px; }
    .wallet-container .paper-clip-icon .action_notification-svg svg {
      width: 0.8rem !important;
      height: 0.8rem !important; }
  .wallet-container .wallet-buttons_action {
    margin: 2.5rem 0 3.75rem; }
    .wallet-container .wallet-buttons_action > *:first-child {
      flex: 2 1; }
    .wallet-container .wallet-buttons_action > *:last-child {
      flex: 1 1; }
    .wallet-container .wallet-buttons_action .separator {
      width: 1rem; }
  .wallet-container .accordion_wrapper .accordion_section_content_text {
    color: var(--labels_important-active-labels-text-graphics) !important; }
    .wallet-container .accordion_wrapper .accordion_section_content_text:after {
      border-left-color: var(--calculated_important-border) !important; }
  .wallet-container .wallet-assets_block {
    background-color: var(--base_wallet-sidebar-and-popup);
    padding: 20px;
    overflow-y: auto; }
    .wallet-container .wallet-assets_block .td-wallet {
      min-width: 225px; }
      .wallet-container .wallet-assets_block .td-wallet .deposit-withdrawal-wrapper {
        margin-left: -3rem;
        width: 14rem; }
    .wallet-container .wallet-assets_block .wallet-assets_block-table {
      width: 100%;
      font-size: 1rem;
      margin-bottom: 3rem; }
      .wallet-container .wallet-assets_block .wallet-assets_block-table thead tr,
      .wallet-container .wallet-assets_block .wallet-assets_block-table tfoot tr {
        height: 3.5rem;
        vertical-align: bottom; }
      .wallet-container .wallet-assets_block .wallet-assets_block-table tfoot {
        font-size: 14px; }
        .wallet-container .wallet-assets_block .wallet-assets_block-table tfoot td {
          white-space: nowrap; }
      .wallet-container .wallet-assets_block .wallet-assets_block-table .table-row {
        height: 2.5rem; }
      .wallet-container .wallet-assets_block .wallet-assets_block-table .table-bottom-border td,
      .wallet-container .wallet-assets_block .wallet-assets_block-table th {
        position: relative; }
        .wallet-container .wallet-assets_block .wallet-assets_block-table .table-bottom-border td:not(:first-child),
        .wallet-container .wallet-assets_block .wallet-assets_block-table th:not(:first-child) {
          border-bottom: 1px solid var(--calculated_important-border);
          padding: 1rem 0.25rem; }
      .wallet-container .wallet-assets_block .wallet-assets_block-table .td-fit {
        width: 1%;
        white-space: nowrap; }
      .wallet-container .wallet-assets_block .wallet-assets_block-table .td-name {
        min-width: 200px !important; }
      .wallet-container .wallet-assets_block .wallet-assets_block-table .table-icon > div {
        vertical-align: middle;
        margin: auto; }
      .wallet-container .wallet-assets_block .wallet-assets_block-table .td-amount {
        min-width: 150px !important;
        direction: ltr; }
    .wallet-container .wallet-assets_block .search-field {
      background-color: var(--base_wallet-sidebar-and-popup); }
    .wallet-container .wallet-assets_block .currency-ball {
      width: 30px;
      padding-top: 4px; }
  .wallet-container .accordion_wrapper .action_notification-wrapper {
    position: relative; }
  .wallet-container .action-button-wrapper {
    border: 1px solid var(--specials_buttons-links-and-highlights);
    padding: 5px 12px; }
    .wallet-container .action-button-wrapper:hover {
      background-color: var(--specials_buttons-links-and-highlights); }
      .wallet-container .action-button-wrapper:hover .notification-info {
        color: white !important; }

.show-equals:after {
  content: '=';
  width: auto;
  position: absolute; }

.wallet-notification .action_notification-text {
  font-size: 14px !important; }

.direction_ltr .wallet-container .wallet-header_block {
  font-family: "Roboto"; }

.direction_ltr .wallet-container .wallet-assets_block .wallet-assets_block-table th {
  font-family: "Roboto"; }

.direction_ltr .wallet-container .wallet-assets_block .wallet-assets_block-table th,
.direction_ltr .wallet-container .wallet-assets_block .wallet-assets_block-table td {
  text-align: left; }

.direction_ltr .wallet-container .wallet-assets_block .wallet-assets_block-table .align-opposite {
  text-align: right; }

.direction_ltr .wallet-container .wallet-assets_block .wallet-assets_block-table .table-icon {
  padding-right: 0.5rem; }

.direction_ltr .show-equals:after {
  left: 0; }

.direction_rtl .wallet-container .wallet-assets_block .wallet-assets_block-table th,
.direction_rtl .wallet-container .wallet-assets_block .wallet-assets_block-table td {
  text-align: right; }

.direction_rtl .wallet-container .wallet-assets_block .wallet-assets_block-table .align-opposite {
  text-align: left; }

.direction_rtl .wallet-container .wallet-assets_block .wallet-assets_block-table .table-icon {
  padding-left: 0.5rem; }

.direction_rtl .show-equals:after {
  right: 0; }

.need-help svg {
  width: 1rem !important;
  height: 1rem !important; }

.wallet-search-improvement {
  border-bottom: 1px solid var(--calculated_super-pale_label-text-graphics);
  color: var(--labels_secondary-inactive-label-text-graphics) !important; }

.investment-container {
  border-top: 1px solid #e1e2d8;
  padding-top: 5px; }
  .investment-container .table thead > tr th:last-child {
    width: 175px;
    text-align: center; }
  .investment-container .table tbody > tr td:last-child {
    text-align: center; }
  .investment-container .table tbody > tr svg {
    vertical-align: middle;
    margin-right: 2px; }
  .investment-container .table tbody > tr .small-icon {
    display: inline-block;
    margin-right: 2px;
    width: 1em;
    text-align: center; }
  .investment-container .table td, .investment-container .table th {
    vertical-align: middle; }
  .investment-container .table span.text-button {
    color: red;
    cursor: pointer; }
  .investment-container .inv-overview {
    margin-bottom: 15px;
    display: flex;
    flex-wrap: wrap; }
    .investment-container .inv-overview > div {
      flex: 1 1; }
    @media (max-width: 767px) {
      .investment-container .inv-overview > div {
        margin: 10px 0 0 0; }
      .investment-container .inv-overview table {
        width: 100%; } }
  .investment-container .withdraw-box {
    max-width: 340px;
    margin-left: auto; }
    .investment-container .withdraw-box .ant-radio-group {
      margin: 0 0 5px; }
    .investment-container .withdraw-box .d-flex {
      flex-wrap: wrap; }
    .investment-container .withdraw-box .wf-row {
      flex: 1 0 100%;
      display: flex;
      margin-bottom: 5px; }
      .investment-container .withdraw-box .wf-row .amount-input {
        flex: 1 1 100%;
        border-radius: 5px;
        padding-left: 8px;
        color: #202020; }
      .investment-container .withdraw-box .wf-row label {
        margin: auto 5px auto 0;
        flex: 1 1 200px; }
    .investment-container .withdraw-box .wf-separator {
      display: flex;
      flex: 1 1;
      margin: 8px 0; }
      .investment-container .withdraw-box .wf-separator span {
        flex: 1 1;
        margin: auto 10px;
        border-top: 1px solid #878787; }
    .investment-container .withdraw-box button {
      width: 35px;
      margin: 10px auto 0; }
  .investment-container .user-investment-table tr {
    border-bottom: 1px solid #757575; }
  .investment-container .user-investment-table td, .investment-container .investment-table th {
    padding: 3px 5px;
    min-width: 85px; }
  .investment-container .user-investment-table td:first-child {
    min-width: 180px; }

.investment-buttons.invb-users > div {
  display: flex;
  flex-wrap: wrap;
  width: 100%; }
  .investment-buttons.invb-users > div button {
    margin: 0 5px 5px 0;
    flex: 0 1 24%;
    padding: 3px; }

@media (max-width: 767px) {
  .investment-buttons.invb-users > div > button {
    margin: 0 0 5px 0;
    flex: 1 0 100%; } }

.invstmnt-table-actions {
  display: flex;
  justify-content: center; }
  .invstmnt-table-actions button {
    margin: 0 3px;
    flex: 0 1 85px; }

.invstmnt-spinner .ant-spin-spinning {
  position: absolute;
  right: 50%;
  top: 50%; }

.investment-plans {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }
  .investment-plans .invest-card {
    flex: 1 1;
    position: relative;
    margin: 0 5px 30px 5px;
    background-color: #333333;
    padding: 7px;
    border-radius: 7px; }
    .investment-plans .invest-card .ic-header {
      text-align: center;
      background-color: #414141;
      margin: -7px -7px 0;
      border-radius: 7px 7px 0 0;
      padding-bottom: 1px;
      margin-bottom: 15px;
      border-bottom: 2px solid #d4a71b; }
      .investment-plans .invest-card .ic-header .ic-title {
        background-color: #414141;
        max-width: 130px;
        text-align: center;
        margin: 0 auto;
        border-radius: 20px;
        padding: 4px 0;
        font-size: 16px;
        font-weight: bold;
        position: relative;
        top: -10px;
        border: 1px solid #333232; }
      .investment-plans .invest-card .ic-header .ic-amount {
        text-shadow: 0 0 4px rgba(0, 0, 0, 0.6); }
        .investment-plans .invest-card .ic-header .ic-amount > div {
          color: #fff; }
        .investment-plans .invest-card .ic-header .ic-amount h3 {
          color: #fff;
          font-size: 30px;
          letter-spacing: 4px; }
    .investment-plans .invest-card ul {
      margin-bottom: 0;
      padding-left: 15px; }
      @media (min-width: 1097px) {
        .investment-plans .invest-card ul {
          padding-left: 25px; } }
    .investment-plans .invest-card.ic-active .ic-header {
      background-color: #f0b90b; }
      .investment-plans .invest-card.ic-active .ic-header .ic-title {
        color: #f0b90b;
        border-color: #f0b90b; }
    .investment-plans .invest-card .ic-active-text {
      position: absolute;
      left: 0;
      right: 0;
      bottom: -25px;
      text-align: center;
      color: #727272; }

.investment-plans-title {
  margin: 20px 0;
  text-align: center; }

.dark-theme .withdraw-box .ant-radio-wrapper {
  color: #e1e2d8; }

.dark-theme .withdraw-box .ant-radio-inner {
  background-color: #fff; }
  .dark-theme .withdraw-box .ant-radio-inner:after {
    background-color: #000; }

.white-theme .investment-plans-title {
  color: #404040; }

.white-theme .investment-plans .invest-card {
  background-color: #ebebeb; }
  .white-theme .investment-plans .invest-card .ic-header {
    background-color: #d0d0d0;
    border-bottom-color: #f0ad0b; }
    .white-theme .investment-plans .invest-card .ic-header .ic-title {
      background-color: #ebebeb;
      border-color: #d6d6d6;
      color: #5a5a5a; }
    .white-theme .investment-plans .invest-card .ic-header .ic-amount {
      text-shadow: none; }
      .white-theme .investment-plans .invest-card .ic-header .ic-amount > div {
        color: #444; }
      .white-theme .investment-plans .invest-card .ic-header .ic-amount h3 {
        color: #444; }
  .white-theme .investment-plans .invest-card.ic-active .ic-header {
    background-color: #f0b90b; }
    .white-theme .investment-plans .invest-card.ic-active .ic-header .ic-title {
      color: #dca200;
      border-color: #f0b90b; }
  .white-theme .investment-plans .invest-card .ic-active-text {
    color: #727272; }

.review-wrapper .review-icon {
  width: 8rem;
  height: 8rem;
  margin: 0 auto; }
  .review-wrapper .review-icon svg {
    width: 8rem;
    height: 8rem; }

.review-wrapper .review-info_container {
  margin: 2rem; }

.review-wrapper .review-crypto-amount {
  font-size: 1.8rem;
  border-bottom: 1px solid var(--labels_important-active-labels-text-graphics);
  text-align: center; }
  .review-wrapper .review-crypto-amount .review-fee_message {
    font-size: 14px; }

.review-wrapper .review-warning_arrow {
  position: relative;
  width: 1px;
  height: 3rem;
  background-color: var(--labels_secondary-inactive-label-text-graphics); }
  .review-wrapper .review-warning_arrow:after {
    content: '';
    position: absolute;
    height: 0;
    width: 0;
    bottom: 0;
    left: calc(50% - 4px);
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 8px solid var(--labels_secondary-inactive-label-text-graphics); }

.review-wrapper .review-crypto-address {
  font-size: 1.8rem;
  border: 1px solid var(--labels_secondary-inactive-label-text-graphics);
  padding: 0.25rem 1rem; }

.review-wrapper .review-info_message {
  padding: 0.5rem;
  font-size: 1rem;
  font-weight: bold; }

.review-wrapper .button-separator {
  width: 2rem; }

.bank_account-information-wrapper {
  margin: 2rem 0; }

.withdrawal-container .icon_title-wrapper {
  flex-direction: row;
  justify-content: flex-start; }

.withdrawal-container .withdraw-form-wrapper .withdraw-form {
  border-radius: 4px;
  margin: 0 0 40px;
  background-color: var(--base_wallet-sidebar-and-popup);
  padding: 30px; }

.withdrawal-container .withdraw-form-wrapper .btn-wrapper {
  display: flex;
  justify-content: center;
  align-items: center; }
  .withdrawal-container .withdraw-form-wrapper .btn-wrapper .holla-button {
    width: 300px; }

.layout-mobile .review-wrapper {
  min-width: 0;
  margin: auto; }

.layout-mobile .presentation_container .action_notification-svg svg {
  width: 1.5rem !important;
  height: 1.5rem !important; }

.layout-mobile .presentation_container .inner_container .result-message {
  font-size: 1.2rem; }

.layout-mobile .presentation_container .inner_container .deposit_info-wrapper .deposit_info-crypto-wrapper {
  width: 100%; }
  .layout-mobile .presentation_container .inner_container .deposit_info-wrapper .deposit_info-crypto-wrapper .field-label {
    font-size: 1.75rem; }
  .layout-mobile .presentation_container .inner_container .deposit_info-wrapper .deposit_info-crypto-wrapper .field-children .custom {
    font-size: 1.5rem; }

.layout-mobile .presentation_container .inner_container .qr_code-wrapper {
  width: 24rem;
  max-width: 24rem; }
  .layout-mobile .presentation_container .inner_container .qr_code-wrapper .qr-code-bg > *:first-child {
    width: 24rem !important;
    height: 24rem !important; }
  .layout-mobile .presentation_container .inner_container .qr_code-wrapper .qr-text {
    font-size: 11px !important; }

.layout-mobile .presentation_container .inner_container .information_block .information_block-text_wrapper .title {
  font-size: 15px; }

.layout-mobile .presentation_container .inner_container .information_block .information_block-text_wrapper .text {
  font-size: 13px !important; }

.layout-mobile .presentation_container .inner_container .field-label,
.layout-mobile .presentation_container .inner_container .field-children,
.layout-mobile .presentation_container .inner_container .input_field-input {
  font-size: 13px !important; }

.layout-mobile .presentation_container .inner_container .field-error-content {
  font-size: 1.2rem; }

.layout-mobile .presentation_container .inner_container .action_notification-wrapper .action_notification-text {
  font-size: 1.2rem; }

.layout-mobile .presentation_container .inner_container .holla-button {
  font-size: 13px !important; }

.layout-mobile .review_email-wrapper {
  margin: 0px auto !important; }

.layout-mobile .withdrawal-container .withdraw-form-wrapper .withdraw-form .field-wrapper .field-label-wrapper {
  flex-direction: column; }

.review_email-wrapper {
  margin: auto; }
  .review_email-wrapper .review_email-content {
    border-top: 1px solid;
    padding: 1rem; }

@media screen and (max-width: 1096px) {
  .withdrawal-container .withdraw-form-wrapper .withdraw-form .field-wrapper .field-label {
    font-size: 11px; }
  .withdrawal-container .withdraw-form-wrapper .withdraw-form .field-wrapper .input-box-field .input_field-input::-webkit-input-placeholder {
    font-size: 11px; }
  .withdrawal-container .withdraw-form-wrapper .withdraw-form .field-wrapper .input-box-field .input_field-input::placeholder {
    font-size: 11px; }
  .withdrawal-container .withdraw-form-wrapper .btn-wrapper .holla-button {
    width: 200px; } }

.extra_information-wrapper {
  margin-top: 1rem;
  padding: 0.5rem;
  font-size: 1rem; }
  .extra_information-wrapper .link {
    color: var(--specials_buttons-links-and-highlights);
    padding: 0 0.25rem; }

.deposit_info-wrapper > div {
  flex: 1 1; }

.qr_code-wrapper {
  margin: 2rem;
  width: 12rem;
  max-width: 12rem; }
  .qr_code-wrapper .qr-code-bg {
    background-color: white;
    margin: 1rem; }
    .qr_code-wrapper .qr-code-bg > *:first-child {
      width: 11rem !important;
      height: 11rem !important; }
  .qr_code-wrapper .qr-text {
    display: flex;
    font-size: 1rem;
    padding-top: 0.25rem;
    margin-left: 1rem; }
    .qr_code-wrapper .qr-text:before {
      padding-top: 0.25rem;
      content: "";
      display: block;
      position: relative;
      width: 0;
      height: 0;
      border-top: none !important;
      border-left: 0.5rem solid transparent !important;
      border-right: 0.5rem solid transparent !important;
      border-bottom: 0.5rem solid var(--labels_important-active-labels-text-graphics);
      margin-bottom: 0rem;
      border-bottom: 0.75rem solid var(--labels_important-active-labels-text-graphics); }

.review-wrapper {
  min-width: 575px; }
  .review-wrapper .review-section {
    font-size: 1rem; }
  .review-wrapper .review-title {
    font-size: 1.8rem;
    margin-bottom: 0.5rem; }
  .review-wrapper .separator {
    margin: 1rem 0;
    height: 2px;
    width: 100%;
    background-color: var(--labels_secondary-inactive-label-text-graphics); }

.verification-wrapper .verification-content-wrapper {
  position: relative; }

.verification-wrapper .error {
  font-size: 1.8rem; }

.verification-wrapper .block-wrapper {
  margin: 1rem; }

.form_currency-ball {
  width: 30px;
  height: 30px; }

.transaction-history-wrapper .ant-row {
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding: 0.5rem; }

.transaction-history-wrapper .ant-form-item {
  margin-bottom: 0; }

.transaction-history-wrapper .ant-radio-button-wrapper {
  color: var(--labels_secondary-inactive-label-text-graphics);
  background-color: transparent !important;
  border: none !important; }
  .transaction-history-wrapper .ant-radio-button-wrapper:active, .transaction-history-wrapper .ant-radio-button-wrapper:hover {
    color: var(--labels_important-active-labels-text-graphics); }

.transaction-history-wrapper .ant-radio-button-wrapper-checked::before,
.transaction-history-wrapper .ant-radio-button-wrapper::before {
  background-color: transparent !important; }

.transaction-history-wrapper .ant-radio-button-wrapper-checked {
  color: var(--labels_important-active-labels-text-graphics);
  border: var(--labels_important-active-labels-text-graphics) 1px solid !important;
  border-radius: 0.2rem; }

.transaction-history-wrapper .ant-picker {
  color: var(--labels_secondary-inactive-label-text-graphics);
  background-color: transparent;
  border-bottom: 1px solid var(--calculated_secondary-border);
  border-top: none;
  border-left: none;
  border-right: none;
  margin: 0 15px; }

.transaction-history-wrapper .ant-picker-separator {
  color: var(--labels_secondary-inactive-label-text-graphics); }

.transaction-history-wrapper .ant-picker-input > input {
  color: var(--labels_important-active-labels-text-graphics); }

.transaction-history-wrapper .ant-picker-focused,
.transaction-history-wrapper .ant-picker:hover,
.transaction-history-wrapper .ant-picker:active {
  border-color: var(--calculated_important-border); }

.transaction-history-wrapper .ant-picker-clear {
  color: var(--labels_secondary-inactive-label-text-graphics); }

.transaction-history-wrapper .ant-picker-active-bar {
  background: var(--specials_buttons-links-and-highlights); }

.transaction-history-wrapper .ant-form-item-label > label {
  color: var(--labels_important-active-labels-text-graphics); }

.transaction-history-wrapper .ant-radio-group {
  margin-top: 0;
  padding: 0 15px;
  border-right: 1px var(--calculated_secondary-border) solid;
  border-left: 1px var(--calculated_secondary-border) solid; }

.transaction-history-wrapper .ant-btn-ghost {
  color: var(--labels_secondary-inactive-label-text-graphics);
  border-color: var(--labels_secondary-inactive-label-text-graphics); }
  .transaction-history-wrapper .ant-btn-ghost:hover,
  .transaction-history-wrapper .ant-btn-ghost :active {
    color: var(--labels_important-active-labels-text-graphics);
    border-color: var(--labels_important-active-labels-text-graphics); }
  .transaction-history-wrapper .ant-btn-ghost.active_search_button {
    -webkit-animation: shine 2s linear infinite alternate;
            animation: shine 2s linear infinite alternate;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    color: var(--labels_secondary-inactive-label-text-graphics);
    box-shadow: 0 0 8px 0.1px var(--base_wallet-sidebar-and-popup);
    position: relative; }

@-webkit-keyframes shine {
  10% {
    color: var(--labels_secondary-inactive-label-text-graphics);
    box-shadow: 0 0 8px 0.1px var(--base_wallet-sidebar-and-popup);
    transition-property: color, box-shadow;
    transition-duration: 0.7s, 0.7s;
    transition-timing-function: ease; }
  100% {
    color: var(--labels_important-active-labels-text-graphics);
    box-shadow: 0 0 8px 0.1px var(--labels_secondary-inactive-label-text-graphics);
    transition-property: color, box-shadow; } }

@keyframes shine {
  10% {
    color: var(--labels_secondary-inactive-label-text-graphics);
    box-shadow: 0 0 8px 0.1px var(--base_wallet-sidebar-and-popup);
    transition-property: color, box-shadow;
    transition-duration: 0.7s, 0.7s;
    transition-timing-function: ease; }
  100% {
    color: var(--labels_important-active-labels-text-graphics);
    box-shadow: 0 0 8px 0.1px var(--labels_secondary-inactive-label-text-graphics);
    transition-property: color, box-shadow; } }

.layout-mobile .tab_controller-wrapper {
  height: auto; }

.layout-mobile .inner_container {
  height: calc(100% - 4.5rem); }
  .layout-mobile .inner_container .history_block-wrapper {
    max-height: calc(100% - 1rem);
    overflow-x: scroll; }
    .layout-mobile .inner_container .history_block-wrapper .table-content {
      overflow-x: scroll;
      white-space: nowrap;
      width: 100%; }
    .layout-mobile .inner_container .history_block-wrapper .table-wrapper .table_header-wrapper,
    .layout-mobile .inner_container .history_block-wrapper .table-wrapper .table_body-wrapper {
      font-size: 1rem !important; }
    .layout-mobile .inner_container .history_block-wrapper .table-wrapper .table_body-wrapper {
      height: 4.5rem; }

.layout-mobile .display_table-wrapper .display_table-cell {
  min-height: 2rem !important; }

.layout-mobile .table_container .table-content.transactions-history-table {
  overflow-x: auto;
  position: relative; }
  .layout-mobile .table_container .table-content.transactions-history-table thead,
  .layout-mobile .table_container .table-content.transactions-history-table td {
    font-size: 1.2rem !important; }
  .layout-mobile .table_container .table-content.transactions-history-table td {
    padding: 0.5rem;
    min-width: 9rem; }
  .layout-mobile .table_container .table-content.transactions-history-table .sticky-col {
    position: -webkit-sticky;
    position: sticky;
    background-color: var(--base_wallet-sidebar-and-popup);
    left: 0;
    width: 10rem !important;
    min-width: 10rem !important;
    max-width: 10rem !important;
    z-index: 1; }
    .layout-mobile .table_container .table-content.transactions-history-table .sticky-col.coin-cell {
      width: 12rem !important;
      min-width: 12rem !important;
      max-width: 12rem !important; }

.icon-cell {
  width: 3rem; }
  .icon-cell > div {
    margin: auto; }

.cell_box-type > div {
  color: white; }

.cell_box-type .withdraw,
.cell_box-type .sell {
  background-color: var(--trading_selling-related-elements);
  color: var(--calculated_trading_selling-related-text); }

.cell_box-type .deposit,
.cell_box-type .buy {
  background-color: var(--trading_buying-related-elements);
  color: var(--calculated_trading_buying-related-text); }

.cell_box-type.fullfilled .cell-wrapper {
  border: 1px solid var(--calculated_important-border);
  margin: 0;
  padding: 1px;
  min-width: 5rem;
  max-width: 7rem; }
  .cell_box-type.fullfilled .cell-wrapper > .cell_value-wrapper {
    padding: 0 0.15rem;
    position: relative;
    width: 100%;
    color: var(--labels_secondary-inactive-label-text-graphics);
    z-index: 2; }
    .cell_box-type.fullfilled .cell-wrapper > .cell_value-wrapper .cell_value-bar {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      background-color: var(--calculated_specials_highlight-box);
      z-index: -1; }

.amount_text .positive {
  color: var(--specials_checks-okay-done); }

.amount_text .negative {
  color: var(--specials_notifications-alerts-warnings); }

.history_block-wrapper {
  position: relative;
  background-color: var(--base_wallet-sidebar-and-popup);
  padding: 20px;
  margin: 20px 0; }
  .history_block-wrapper .action_notification-svg svg {
    width: 1.2rem !important;
    height: 1.2rem !important; }
  .history_block-wrapper .check-deposit-wrapper {
    position: absolute;
    right: 100px;
    top: 2px;
    text-transform: uppercase;
    color: var(--specials_buttons-links-and-highlights);
    font-size: 11px;
    cursor: pointer; }
  .history_block-wrapper .new-tag-wrapper {
    position: relative; }
    .history_block-wrapper .new-tag-wrapper .new-tag {
      position: absolute;
      right: 100%;
      background-color: #008509;
      padding: 0 7px;
      color: #ffffff;
      border-radius: 20px;
      margin-right: 10px; }
  .history_block-wrapper .check-deposit-txt {
    right: 100px !important; }
  .history_block-wrapper .loader_wrapper {
    top: 0;
    left: 0; }
  .history_block-wrapper .table-wrapper .table_body-wrapper.with_icon tr td:first-child {
    border-top: 1px solid var(--calculated_secondary-border) !important;
    border-bottom: 1px solid var(--calculated_secondary-border) !important; }
  .history_block-wrapper .table-wrapper .table_body-wrapper.with_icon .coin-icons {
    width: 30px;
    padding-top: 4px; }

.action_notification-svg svg {
  width: 2rem;
  height: 2rem; }

.btn-tx,
.withdrawal-cancel {
  cursor: pointer; }

.transaction-history-wrapper svg .trade-history-1,
.transaction-history-wrapper svg .deposit-history-3,
.transaction-history-wrapper svg .withdrawals-history-3,
.transaction-history-wrapper svg .transaction-history-1,
.transaction-history-wrapper svg .transaction-history-5 {
  fill: var(--labels_secondary-inactive-label-text-graphics); }

.transaction-history-wrapper .icon_title-wrapper {
  flex-direction: row;
  justify-content: flex-start; }

.transaction-history-wrapper .with_border_top {
  border-top: none !important; }

.display_table-wrapper .display_table-cell {
  min-height: 1.2rem !important;
  padding: 0 0.1rem !important; }

.display_table-wrapper:first-child .display_table-body {
  padding-top: 0.1rem !important; }

.transaction-status {
  font-weight: bold; }
  .transaction-status .completed {
    color: var(--specials_checks-okay-done); }
  .transaction-status .pending {
    color: var(--specials_pending-waiting-caution); }
  .transaction-status .rejected {
    color: var(--specials_notifications-alerts-warnings); }

.trade-container {
  flex: 1 1;
  flex-wrap: wrap; }
  .trade-container .dialog-link {
    color: var(--specials_buttons-links-and-highlights);
    padding: 0 0.25rem; }
  .trade-container .trade-main_content {
    flex: 1 0 100%; }
  .trade-container .trade-col_main_wrapper .trade-main_content {
    flex: 6 1;
    min-height: 25vh;
    max-height: 60vh; }
  .trade-container .trade-col_main_wrapper .trade-tabs_content {
    flex: 3 1;
    height: 30vh; }
    .trade-container .trade-col_main_wrapper .trade-tabs_content > * {
      flex: 1 1 !important; }
    .trade-container .trade-col_main_wrapper .trade-tabs_content .xht-logo {
      width: 3rem;
      height: 3rem; }
  .trade-container .trade-col_side_wrapper {
    flex: 1 1;
    flex-wrap: wrap;
    /*min-width: $side-column--width;1/
		/*max-width: $side-column--width;*/ }
    .trade-container .trade-col_side_wrapper .trade_block-wrapper {
      max-height: calc(49vh + 5rem) !important; }
    .trade-container .trade-col_side_wrapper > div {
      flex: 1 1 250px; }
  .trade-container .trade-col_action_wrapper {
    flex: 0 1;
    min-width: 20rem;
    max-width: 20rem; }
  .trade-container .table-wrapper {
    margin: 0; }

.xht-order-heading {
  font-weight: bold;
  font-size: 1.1rem; }

.xht-order-content {
  color: var(--labels_secondary-inactive-label-text-graphics); }

.layout-mobile .trade_order_entry-wrapper.order_side-selector-sell .trade_order_entry-form-action, .layout-mobile .trade_order_entry-wrapper.order_side-selector-buy .trade_order_entry-form-action {
  height: 4.5rem !important; }

.layout-mobile .trade_order_entry-wrapper .trade_order_entry-review,
.layout-mobile .trade_order_entry-wrapper .trade_order_entry-selector,
.layout-mobile .trade_order_entry-wrapper .trade_order_entry-action_selector,
.layout-mobile .trade_order_entry-wrapper.order_side-selector-sell .trade_order_entry-form_fields-wrapper,
.layout-mobile .trade_order_entry-wrapper.order_side-selector-buy .trade_order_entry-form_fields-wrapper {
  margin-bottom: 3rem !important; }

.layout-mobile .trade_block-title {
  font-size: 1.75rem;
  border-top-width: 0.4rem !important;
  margin-bottom: unset !important; }

.layout-mobile .trade_order_entry-action_selector {
  height: 4rem !important; }

.layout-mobile .trade_order_entry-selector {
  font-size: 1.2rem; }
  .layout-mobile .trade_order_entry-selector > div {
    line-height: 3rem !important; }

.layout-mobile .trade_orderbook-headers {
  border-bottom: none !important;
  font-size: 1.2rem; }

.layout-mobile .trade_orderbook-asks,
.layout-mobile .trade_orderbook-bids,
.layout-mobile .trade_orderbook-spread {
  font-size: 1.5rem; }

.layout-mobile .orderbook-limit_bar-wrapper .orderbook-limit_bar-text {
  font-size: 1.2rem !important; }

.layout-mobile .trade_orderbook-spread {
  min-height: 3rem !important;
  margin: 1rem 0; }

.layout-mobile .trade_orderbook-asks,
.layout-mobile .trade_orderbook-bids {
  min-height: unset; }

.layout-mobile .trade_orderbook-wrapper {
  height: 100% !important; }

.layout-mobile .trade_input-wrapper .trade_input-label {
  font-size: 1.2rem; }

.layout-mobile .trade_input-wrapper .trade_input-input-wrapper > input {
  height: 4.5rem !important;
  font-size: 1.5rem; }

.layout-mobile .trade_input-wrapper .trade_input-input-wrapper .trade_input-input-currency {
  height: 4.5rem !important;
  width: 3.5rem !important;
  font-size: 1.2rem; }

.layout-mobile .trade_order_entry-review > div {
  margin-bottom: 0.5rem;
  font-size: 1.2rem; }

.layout-mobile .xht-logo {
  width: 3rem;
  height: 3rem; }

@media (min-width: 1920px) {
  /*
	.trade-container {
		.trade-col_side_wrapper {
			min-width: $side-column--width-lg;
			max-width: $side-column--width-lg;
		}
		.trade-col_action_wrapper {
			min-width: $order_entry-column--width-lg;
			max-width: $order_entry-column--width-lg;
		}
	}*/ }

@media screen and (min-width: 1920px) {
  .trade-container .trade-col_main_wrapper .trade-main_content .trade_block-wrapper {
    max-height: unset; } }

@media screen and (max-width: 1096px) {
  /*
	.trade-container {
		.trade-col_side_wrapper {
			min-width: $side-column--width-sm;
			max-width: $side-column--width-sm;
		}
		.trade-col_action_wrapper {
			min-width: $order_entry-column--width-sm;
			max-width: $order_entry-column--width-sm;
		}
	}*/ }

.trade__active-orders_cancel-All {
  position: relative;
  min-height: 2rem;
  min-width: 6rem; }

.trade__active-orders-header_cancel-All {
  position: relative; }

.trade_cancel-All-confirmation .ant-modal-header,
.trade_cancel-All-confirmation .ant-modal-content,
.trade_cancel-All-confirmation .ant-modal-confirm-body .ant-modal-confirm-content {
  background-color: var(--base_wallet-sidebar-and-popup);
  color: var(--labels_important-active-labels-text-graphics); }

.layout-mobile .action_notification-wrapper .action_notification-svg svg {
  width: 1.5rem;
  height: 1.5rem; }

.layout-mobile .action_notification-wrapper .action_notification-text {
  font-size: 1.2rem; }

.layout-mobile .trade_block-wrapper .trade_block-content {
  font-size: 1.2rem; }
  .layout-mobile .trade_block-wrapper .trade_block-content .table_body-wrapper {
    font-size: 1.2rem; }

.layout-mobile .trade_block-wrapper .trade_block-title .action_notification-wrapper .action_notification-text {
  font-size: 13px !important; }

.layout-mobile .trade_history-wrapper {
  height: 38vh; }
  .layout-mobile .trade_history-wrapper .display_table-wrapper .display_table-body {
    font-size: 13px; }

.trade_block-column-wrapper .trade_block-wrapper {
  max-height: 23rem; }

.trade_block-wrapper {
  padding: 1rem;
  height: 50vh;
  margin: 6px 3px 3px 3px;
  background-color: var(--base_wallet-sidebar-and-popup); }
  .trade_block-wrapper .trade_block-title {
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 1rem;
    flex: none;
    position: relative; }
    .trade_block-wrapper .trade_block-title .trade_block-icon {
      width: 1.4rem;
      height: 1.4rem;
      margin-top: 0.08rem; }
    .trade_block-wrapper .trade_block-title .trade_block-title-currency {
      color: var(--labels_important-active-labels-text-graphics);
      font-size: 0.9rem;
      margin-left: 0.3rem;
      margin-top: 0.1rem; }
  .trade_block-wrapper .trade_block-content {
    flex: auto;
    font-size: 1rem;
    overflow-y: scroll;
    height: 100%; }
    .trade_block-wrapper .trade_block-content .sidebar_hub-content {
      min-height: 42vh;
      max-height: 56vh;
      overflow-y: auto; }

.trade_block_tabs-wrapper .trade_block-title {
  position: relative; }
  .trade_block_tabs-wrapper .trade_block-title .trade_block-title-items {
    display: flex;
    font-weight: normal;
    line-height: 25px; }
    .trade_block_tabs-wrapper .trade_block-title .trade_block-title-items .active {
      font-weight: bold;
      position: relative; }
      .trade_block_tabs-wrapper .trade_block-title .trade_block-title-items .active:after {
        content: '';
        height: 4px;
        width: 100%;
        background-color: var(--calculated_important-border);
        top: 0;
        left: 0;
        right: 0;
        position: absolute; }

.trade_block-column-wrapper > div {
  flex: 1 1 !important;
  overflow-y: scroll; }

.direction_ltr .trade_block-title {
  font-family: "Roboto";
  font-weight: 900; }

.direction_ltr .trade_block_tabs-wrapper .trade_block-title .trade_block-title-items > div:not(:last-child) {
  margin-right: 2rem; }

.direction_rtl .trade_block_tabs-wrapper .trade_block-title .trade_block-title-items > div:not(:last-child) {
  margin-left: 2rem; }

.trade_history-wrapper {
  height: 49vh; }
  .trade_history-wrapper .display_table-wrapper .display_table-header :nth-child(2) {
    text-align: center; }
  .trade_history-wrapper .display_table-wrapper .display_table-header .ant-select-arrow {
    z-index: 1; }
  .trade_history-wrapper .display_table-wrapper .display_table-body .display_table-cell :nth-child(2),
  .trade_history-wrapper .display_table-wrapper .display_table-body .display_table-cell :nth-child(3) {
    text-align: right; }
  .trade_history-wrapper .display_table-wrapper .display_table-body .display_table-cell div {
    overflow: unset !important; }
  .trade_history-wrapper .display_table-wrapper .display_table-body .display_table-cell .fill {
    background-repeat: no-repeat;
    background-position: 100% 100%;
    background-size: 0; }
  .trade_history-wrapper .display_table-wrapper .display_table-body .display_table-cell .fill-buy {
    background-image: linear-gradient(var(--calculated_trading_buying-orderbook-bar), var(--calculated_trading_buying-orderbook-bar)); }
  .trade_history-wrapper .display_table-wrapper .display_table-body .display_table-cell .fill-sell {
    background-image: linear-gradient(var(--calculated_trading_selling-orderbook-bar), var(--calculated_trading_selling-orderbook-bar)); }

@media screen and (max-width: 1060px) {
  .trade_block-column-wrapper .trade_block-wrapper {
    max-height: 20rem; } }

.layout-mobile .trade-container .orderBook-wrapper {
  max-height: 100% !important;
  min-height: 100% !important; }

.trade_order_entry-wrapper.order_side-selector-sell {
  flex: 1 1;
  color: var(--labels_secondary-inactive-label-text-graphics); }
  .trade_order_entry-wrapper.order_side-selector-sell > div:not(:last-child) {
    margin-bottom: 0.5rem; }
  .trade_order_entry-wrapper.order_side-selector-sell .trade_order_entry-selector {
    border-top: 1px solid var(--calculated_important-border);
    position: relative; }
    .trade_order_entry-wrapper.order_side-selector-sell .trade_order_entry-selector > div {
      line-height: 2rem; }
    .trade_order_entry-wrapper.order_side-selector-sell .trade_order_entry-selector .active {
      position: relative;
      color: var(--labels_important-active-labels-text-graphics);
      font-weight: bold; }
      .trade_order_entry-wrapper.order_side-selector-sell .trade_order_entry-selector .active:after {
        content: '';
        position: absolute;
        top: -2px;
        background: var(--calculated_important-border);
        left: 0;
        width: 100%;
        height: 5px; }
  .trade_order_entry-wrapper.order_side-selector-sell .trade_order_entry-action_selector {
    border: 1px solid var(--trading_selling-related-elements); }
    .trade_order_entry-wrapper.order_side-selector-sell .trade_order_entry-action_selector .active {
      background-color: var(--trading_selling-related-elements);
      color: var(--calculated_trading_selling-related-text);
      font-weight: bold; }
  .trade_order_entry-wrapper.order_side-selector-sell .trade_order_entry-review {
    border-top: 1px solid var(--calculated_secondary-border);
    line-height: 1.5rem; }
    .trade_order_entry-wrapper.order_side-selector-sell .trade_order_entry-review .text-price {
      color: var(--labels_important-active-labels-text-graphics); }
    .trade_order_entry-wrapper.order_side-selector-sell .trade_order_entry-review .blue-link {
      color: var(--specials_buttons-links-and-highlights); }
  .trade_order_entry-wrapper.order_side-selector-sell .trade_order_entry-action_selector,
  .trade_order_entry-wrapper.order_side-selector-sell .trade_order_entry-form-action {
    height: 2.5rem; }
  .trade_order_entry-wrapper.order_side-selector-sell .trade_order_entry-selector > *,
  .trade_order_entry-wrapper.order_side-selector-sell .trade_order_entry-action_selector > *,
  .trade_order_entry-wrapper.order_side-selector-sell .trade_order_entry-form > * {
    flex: 1 1; }
  .trade_order_entry-wrapper.order_side-selector-sell .trade_order_entry-form_inputs-wrapper .trade_order_entry-form_fields-wrapper > div:not(:last-child) {
    margin-bottom: 0.5rem; }
  .trade_order_entry-wrapper.order_side-selector-sell .trade_order_entry-form_inputs-wrapper .trade_order_entry-form-action {
    margin-top: 0.5rem;
    background-color: var(--trading_selling-related-elements) !important;
    color: var(--calculated_trading_selling-related-text);
    font-weight: bold; }
    .trade_order_entry-wrapper.order_side-selector-sell .trade_order_entry-form_inputs-wrapper .trade_order_entry-form-action:disabled {
      background-color: var(--labels_inactive-button) !important;
      color: var(--labels_secondary-inactive-label-text-graphics) !important; }
  .trade_order_entry-wrapper.order_side-selector-sell .trade_order_entry-form_inputs-wrapper .warning_text {
    margin-top: 0.25rem; }
  .trade_order_entry-wrapper.order_side-selector-sell .trade_order_entry-form_inputs-wrapper .form-error {
    margin: 0.5rem 0; }
  .trade_order_entry-wrapper.order_side-selector-sell .trade_order_entry-form_fields-wrapper {
    margin-bottom: 0.2rem !important; }
    .trade_order_entry-wrapper.order_side-selector-sell .trade_order_entry-form_fields-wrapper .ant-collapse-header {
      text-align: right;
      color: var(--labels_secondary-inactive-label-text-graphics);
      padding: 0;
      font-size: 13px; }
    .trade_order_entry-wrapper.order_side-selector-sell .trade_order_entry-form_fields-wrapper .ant-collapse-content-box {
      padding: 0; }
    .trade_order_entry-wrapper.order_side-selector-sell .trade_order_entry-form_fields-wrapper .underline-text {
      font-size: 11px; }

.trade_order_entry-wrapper.order_side-selector-buy {
  flex: 1 1;
  color: var(--labels_secondary-inactive-label-text-graphics); }
  .trade_order_entry-wrapper.order_side-selector-buy > div:not(:last-child) {
    margin-bottom: 0.5rem; }
  .trade_order_entry-wrapper.order_side-selector-buy .trade_order_entry-selector {
    border-top: 1px solid var(--calculated_important-border);
    position: relative; }
    .trade_order_entry-wrapper.order_side-selector-buy .trade_order_entry-selector > div {
      line-height: 2rem; }
    .trade_order_entry-wrapper.order_side-selector-buy .trade_order_entry-selector .active {
      position: relative;
      color: var(--labels_important-active-labels-text-graphics);
      font-weight: bold; }
      .trade_order_entry-wrapper.order_side-selector-buy .trade_order_entry-selector .active:after {
        content: '';
        position: absolute;
        top: -2px;
        background: var(--calculated_important-border);
        left: 0;
        width: 100%;
        height: 5px; }
  .trade_order_entry-wrapper.order_side-selector-buy .trade_order_entry-action_selector {
    border: 1px solid var(--trading_buying-related-elements); }
    .trade_order_entry-wrapper.order_side-selector-buy .trade_order_entry-action_selector .active {
      background-color: var(--trading_buying-related-elements);
      color: var(--calculated_trading_buying-related-text);
      font-weight: bold; }
  .trade_order_entry-wrapper.order_side-selector-buy .trade_order_entry-review {
    border-top: 1px solid var(--calculated_secondary-border);
    line-height: 1.5rem; }
    .trade_order_entry-wrapper.order_side-selector-buy .trade_order_entry-review .text-price {
      color: var(--labels_important-active-labels-text-graphics); }
    .trade_order_entry-wrapper.order_side-selector-buy .trade_order_entry-review .blue-link {
      color: var(--specials_buttons-links-and-highlights); }
  .trade_order_entry-wrapper.order_side-selector-buy .trade_order_entry-action_selector,
  .trade_order_entry-wrapper.order_side-selector-buy .trade_order_entry-form-action {
    height: 2.5rem; }
  .trade_order_entry-wrapper.order_side-selector-buy .trade_order_entry-selector > *,
  .trade_order_entry-wrapper.order_side-selector-buy .trade_order_entry-action_selector > *,
  .trade_order_entry-wrapper.order_side-selector-buy .trade_order_entry-form > * {
    flex: 1 1; }
  .trade_order_entry-wrapper.order_side-selector-buy .trade_order_entry-form_inputs-wrapper .trade_order_entry-form_fields-wrapper > div:not(:last-child) {
    margin-bottom: 0.5rem; }
  .trade_order_entry-wrapper.order_side-selector-buy .trade_order_entry-form_inputs-wrapper .trade_order_entry-form-action {
    margin-top: 0.5rem;
    background-color: var(--trading_buying-related-elements) !important;
    color: var(--calculated_trading_buying-related-text);
    font-weight: bold; }
    .trade_order_entry-wrapper.order_side-selector-buy .trade_order_entry-form_inputs-wrapper .trade_order_entry-form-action:disabled {
      background-color: var(--labels_inactive-button) !important;
      color: var(--labels_secondary-inactive-label-text-graphics) !important; }
  .trade_order_entry-wrapper.order_side-selector-buy .trade_order_entry-form_inputs-wrapper .warning_text {
    margin-top: 0.25rem; }
  .trade_order_entry-wrapper.order_side-selector-buy .trade_order_entry-form_inputs-wrapper .form-error {
    margin: 0.5rem 0; }
  .trade_order_entry-wrapper.order_side-selector-buy .trade_order_entry-form_fields-wrapper {
    margin-bottom: 0.2rem !important; }
    .trade_order_entry-wrapper.order_side-selector-buy .trade_order_entry-form_fields-wrapper .ant-collapse-header {
      text-align: right;
      color: var(--labels_secondary-inactive-label-text-graphics);
      padding: 0;
      font-size: 13px; }
    .trade_order_entry-wrapper.order_side-selector-buy .trade_order_entry-form_fields-wrapper .ant-collapse-content-box {
      padding: 0; }
    .trade_order_entry-wrapper.order_side-selector-buy .trade_order_entry-form_fields-wrapper .underline-text {
      font-size: 11px; }

.trade_order_entry-wrapper .post-only-txt {
  font-size: 1rem;
  color: var(--labels_secondary-inactive-label-text-graphics);
  cursor: pointer; }

.risky-trade-wrapper {
  width: 32rem; }
  .risky-trade-wrapper .icon_title-wrapper .icon_title-svg {
    height: 10rem;
    width: 10rem; }
  .risky-trade-wrapper .icon_title-wrapper .icon_title-svg svg {
    height: 10rem;
    width: 10rem; }
  .risky-trade-wrapper .icon_title-wrapper .icon_title-text.title {
    font-size: 2rem; }
  .risky-trade-wrapper .icon_title-wrapper .icon_title-text {
    margin-top: 3rem;
    text-align: left !important;
    width: 100%; }
  .risky-trade-wrapper .risky_managment_percentage {
    color: var(--specials_notifications-alerts-warnings); }

.layout-mobile .trade_order_entry-selector {
  font-size: 13px !important; }

.layout-mobile .trade_orderbook-bids .trade_orderbook-cell {
  font-size: 13px !important; }

.layout-mobile .trade_input-wrapper .trade_input-label {
  font-size: 11px !important; }

.layout-mobile .trade_input-wrapper .trade_input-input-wrapper > input {
  font-size: 13px !important; }

.layout-mobile .trade_order_entry-review > div {
  font-size: 11px !important; }

.layout-mobile .holla-button {
  font-size: 13px !important; }

.layout-mobile .trade_orderbook-wrapper .trade_asks-limit_bar .orderbook-limit_bar-wrapper .orderbook-limit_bar-text {
  font-size: 11px !important; }

.layout-mobile .risky-trade-wrapper {
  margin: auto; }

.trade_orderbook-wrapper {
  height: 49vh; }
  .trade_orderbook-wrapper .trade_orderbook-headers .trade_orderbook-cell {
    font-size: 1rem !important;
    flex-direction: column; }
    .trade_orderbook-wrapper .trade_orderbook-headers .trade_orderbook-cell .custom-select-input-style {
      font-size: 1rem !important;
      max-width: 8rem; }
      .trade_orderbook-wrapper .trade_orderbook-headers .trade_orderbook-cell .custom-select-input-style .ant-select-selector {
        height: 1.5rem !important;
        line-height: 1.5rem !important;
        padding: 0;
        font-size: 1rem !important; }
      .trade_orderbook-wrapper .trade_orderbook-headers .trade_orderbook-cell .custom-select-input-style .ant-select-selection-item {
        height: 1.5rem !important;
        line-height: 1.5rem !important;
        font-weight: bold; }
      .trade_orderbook-wrapper .trade_orderbook-headers .trade_orderbook-cell .custom-select-input-style .ant-select-arrow {
        right: 2px; }
  .trade_orderbook-wrapper .trade_orderbook-cell {
    padding: 0 0.25rem; }
    .trade_orderbook-wrapper .trade_orderbook-cell .ant-select-arrow {
      color: var(--labels_important-active-labels-text-graphics);
      z-index: 1; }
    .trade_orderbook-wrapper .trade_orderbook-cell .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
      padding-right: 0; }
  .trade_orderbook-wrapper .price-row-wrapper {
    margin: 1px 0;
    text-align: right; }
    .trade_orderbook-wrapper .price-row-wrapper .trade_orderbook-cell-price {
      text-align: left; }
    .trade_orderbook-wrapper .price-row-wrapper .trade_orderbook-cell-amount {
      text-align: right; }
    .trade_orderbook-wrapper .price-row-wrapper .trade_orderbook-cell-amount {
      color: var(--calculated_trading-orderbook-text); }
    .trade_orderbook-wrapper .price-row-wrapper .trade_orderbook-cell_total {
      color: var(--calculated_trading-orderbook-secondary-text); }
    .trade_orderbook-wrapper .price-row-wrapper .trade_orderbook-cell-price:hover,
    .trade_orderbook-wrapper .price-row-wrapper .trade_orderbook-cell-amount:hover,
    .trade_orderbook-wrapper .price-row-wrapper .trade_orderbook-cell_total:hover {
      color: var(--labels_important-active-labels-text-graphics) !important; }
  .trade_orderbook-wrapper .fill {
    background-repeat: no-repeat;
    background-size: 0;
    background-position: right; }
  .trade_orderbook-wrapper .fill-bid {
    background-image: linear-gradient(var(--calculated_trading_buying-orderbook-bar), var(--calculated_trading_buying-orderbook-bar)); }
  .trade_orderbook-wrapper .fill-ask {
    background-image: linear-gradient(var(--calculated_trading_selling-orderbook-bar), var(--calculated_trading_selling-orderbook-bar)); }
  .trade_orderbook-wrapper .wave-header {
    color: var(--labels_secondary-inactive-label-text-graphics); }
  .trade_orderbook-wrapper .trade_orderbook-headers {
    font-weight: bold;
    border-bottom: 1px solid var(--calculated_secondary-border);
    text-transform: capitalize;
    min-height: 1.5rem;
    font-size: 0.85rem; }
    .trade_orderbook-wrapper .trade_orderbook-headers .waves-icon {
      width: 3rem; }
  .trade_orderbook-wrapper .trade_orderbook-content .trade_orderbook-spread {
    flex: 0 1;
    min-height: 4rem;
    color: var(--labels_secondary-inactive-label-text-graphics);
    padding: 0 0.25rem; }
    .trade_orderbook-wrapper .trade_orderbook-content .trade_orderbook-spread .trade_orderbook-spread-text {
      color: var(--labels_important-active-labels-text-graphics);
      margin-left: 0.25rem; }
    .trade_orderbook-wrapper .trade_orderbook-content .trade_orderbook-spread .trade_orderbook-market-price {
      margin-left: 0.25rem;
      font-size: 1.3rem;
      font-weight: bold;
      color: var(--labels_important-active-labels-text-graphics); }
  .trade_orderbook-wrapper .trade_orderbook-content .trade_orderbook-asks .value-row,
  .trade_orderbook-wrapper .trade_orderbook-content .trade_orderbook-bids .value-row {
    min-height: 1.5rem; }
  .trade_orderbook-wrapper .trade_orderbook-content .trade_orderbook-bids .trade_orderbook-cell-price {
    color: var(--trading_buying-related-elements); }
  .trade_orderbook-wrapper .trade_orderbook-content .trade_orderbook-bids .price-row-wrapper:hover {
    background-color: var(--calculated_trading_buying-orderbook-hover); }
  .trade_orderbook-wrapper .trade_orderbook-content .trade_orderbook-asks .trade_orderbook-cell-price {
    color: var(--trading_selling-related-elements); }
  .trade_orderbook-wrapper .trade_orderbook-content .trade_orderbook-asks .price-row-wrapper:hover {
    background-color: var(--calculated_trading_selling-orderbook-hover); }
  .trade_orderbook-wrapper .trade_asks-limit_bar .orderbook-limit_bar-wrapper {
    color: var(--trading_selling-related-elements); }
    .trade_orderbook-wrapper .trade_asks-limit_bar .orderbook-limit_bar-wrapper .orderbook-limit_bar-line {
      background-color: var(--trading_selling-related-elements); }
  .trade_orderbook-wrapper .trade_orderbook-asks {
    color: var(--trading_selling-related-elements); }
  .trade_orderbook-wrapper .trade_orderbook-asks > .price-row-wrapper:first-child {
    background-color: var(--calculated_trading_selling-orderbook-best-bar);
    font-weight: bold; }
    .trade_orderbook-wrapper .trade_orderbook-asks > .price-row-wrapper:first-child .trade_orderbook-cell-price {
      color: var(--calculated_trading-orderbook-text); }
  .trade_orderbook-wrapper .trade_orderbook-bids > .price-row-wrapper:first-child {
    background-color: var(--calculated_trading_buying-orderbook-best-bar);
    font-weight: bold; }
    .trade_orderbook-wrapper .trade_orderbook-bids > .price-row-wrapper:first-child .trade_orderbook-cell-price {
      color: var(--calculated_trading-orderbook-text); }
  .trade_orderbook-wrapper .trade_orderbook-bids {
    color: var(--trading_buying-related-elements); }
  .trade_orderbook-wrapper .trade_bids-limit_bar .orderbook-limit_bar-wrapper {
    color: var(--trading_buying-related-elements); }
    .trade_orderbook-wrapper .trade_bids-limit_bar .orderbook-limit_bar-wrapper .orderbook-limit_bar-line {
      background-color: var(--trading_buying-related-elements); }
  .trade_orderbook-wrapper .orderbook-limit_bar-wrapper .orderbook-limit_bar-line {
    height: 2px; }
  .trade_orderbook-wrapper .orderbook-limit_bar-wrapper .orderbook-limit_bar-text {
    font-size: 14px; }

.trade-select-option-wrapper .ant-select-item,
.trade-select-option-wrapper .ant-select-item-option-content {
  font-size: 11px; }

.order-book-select-coin .ant-select-item,
.order-book-select-coin .ant-select-item-option {
  font-size: 0.8rem; }

.trade_orderbook-depth-selector {
  padding: 4px; }
  .trade_orderbook-depth-selector .ant-btn-text {
    color: var(--labels_important-active-labels-text-graphics); }
  .trade_orderbook-depth-selector .ant-btn-text[disabled] {
    color: var(--labels_secondary-inactive-label-text-graphics); }
  .trade_orderbook-depth-selector .ant-btn {
    padding: 0;
    height: 16px;
    margin: 4px 0;
    line-height: 1; }

.trade_orderbook-depth {
  padding: 4px;
  min-width: 40px;
  text-align: center; }

.direction_ltr .orderbook-limit_bar-wrapper .orderbook-limit_bar-text {
  padding-left: 0.5rem; }

.direction_rtl .orderbook-limit_bar-wrapper .orderbook-limit_bar-text {
  padding-right: 0.5rem; }

.layout-mobile .trade_orderbook-wrapper .trade_orderbook-content .trade_orderbook-bids .value-row .trade_orderbook-cell {
  font-size: 1.5rem !important; }

.trade_orderbook-cell .ant-select-selector {
  color: var(--labels_important-active-labels-text-graphics) !important; }

.ask,
.buy {
  color: var(--trading_selling-related-elements); }

.bid,
.sell {
  color: var(--trading_buying-related-elements); }

.trade_history-row-wrapper {
  margin: 1px 0; }

.trade_history-row {
  height: 100%; }
  .trade_history-row .trade_history-icon {
    width: 1rem;
    margin-right: 3px; }
  .trade_history-row.sell {
    color: var(--trading_selling-related-elements); }
    .trade_history-row.sell .trade_history-icon svg .up-icon,
    .trade_history-row.sell .trade_history-icon svg .down-icon {
      fill: var(--trading_selling-related-elements); }
    .trade_history-row.sell .trade_history-icon svg polygon {
      fill: var(--calculated_trading_selling-related-text); }
  .trade_history-row.buy {
    color: var(--trading_buying-related-elements); }
    .trade_history-row.buy .trade_history-icon svg .up-icon,
    .trade_history-row.buy .trade_history-icon svg .down-icon {
      fill: var(--trading_buying-related-elements); }
    .trade_history-row.buy .trade_history-icon svg polygon {
      fill: var(--calculated_trading_buying-related-text); }

.layout-mobile .trade_active_orders-wrapper {
  max-height: 35vh !important;
  white-space: nowrap; }
  .layout-mobile .trade_active_orders-wrapper .table_header-wrapper,
  .layout-mobile .trade_active_orders-wrapper .table_body-wrapper {
    font-size: 1rem !important; }

.trade_active-lg-view {
  max-height: 17vh; }

.trade_active_orders-wrapper {
  overflow-y: auto;
  flex: 1 1; }
  .trade_active_orders-wrapper .cell_box-type {
    padding: 0.1rem; }
    .trade_active_orders-wrapper .cell_box-type > div {
      margin: 0; }
    .trade_active_orders-wrapper .cell_box-type .buy {
      color: var(--calculated_trading_buying-related-text) !important;
      background-color: var(--trading_buying-related-elements); }
    .trade_active_orders-wrapper .cell_box-type .sell {
      color: var(--calculated_trading_selling-related-text) !important;
      background-color: var(--trading_selling-related-elements); }
    .trade_active_orders-wrapper .cell_box-type.fullfilled .cell-wrapper {
      border: 1px solid var(--calculated_important-border);
      margin: 0;
      padding: 1px;
      min-width: 5rem;
      max-width: 7rem; }
      .trade_active_orders-wrapper .cell_box-type.fullfilled .cell-wrapper > .cell_value-wrapper {
        padding: 0 0.15rem;
        position: relative;
        width: 100%;
        color: var(--labels_secondary-inactive-label-text-graphics);
        z-index: 2; }
        .trade_active_orders-wrapper .cell_box-type.fullfilled .cell-wrapper > .cell_value-wrapper .cell_value-bar {
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          background-color: var(--calculated_specials_highlight-box);
          z-index: -1; }
  .trade_active_orders-wrapper .recent-trades.cell_box-type {
    display: flex; }
    .trade_active_orders-wrapper .recent-trades.cell_box-type .buy {
      min-width: 3rem;
      padding-left: 0.5rem;
      padding-right: 0.5rem; }
    .trade_active_orders-wrapper .recent-trades.cell_box-type .sell {
      min-width: 3rem;
      padding-left: 0.5rem;
      padding-right: 0.5rem; }

.quick-trade-tab {
  border: 1px solid var(--calculated_secondary-border);
  border-radius: 9px;
  vertical-align: middle;
  text-align: center; }

.direction_ltr .trade_active_orders-wrapper .cell_box-type {
  padding-left: 0; }
  .direction_ltr .trade_active_orders-wrapper .cell_box-type.fullfilled .cell-wrapper > .cell_value-wrapper .cell_value-bar {
    left: 0; }

.direction_rtl .trade_active_orders-wrapper .cell_box-type {
  padding-right: 0; }
  .direction_rtl .trade_active_orders-wrapper .cell_box-type.fullfilled .cell-wrapper > .cell_value-wrapper .cell_value-bar {
    right: 0; }

.trade-tab__market-selector {
  text-transform: uppercase;
  font-weight: 900;
  font-size: 1.75rem;
  font-family: "Roboto"; }

.mobile-chart__market-selector-wrapper {
  font-family: 'Open Sans' !important;
  font-weight: normal; }

.mobile-market-selector-trigger {
  width: 100%;
  min-width: 100vw;
  max-width: 100vw;
  min-height: 3rem;
  max-height: 3rem; }

.public-history__header {
  font-size: 1rem !important; }
  .public-history__header .custom-select-input-style {
    font-size: 1rem !important;
    max-width: 8rem; }
    .public-history__header .custom-select-input-style .ant-select-selector {
      height: 1.5rem !important;
      line-height: 1.5rem !important;
      padding: 0;
      font-size: 1rem !important; }
    .public-history__header .custom-select-input-style .ant-select-selection-item {
      height: 1.5rem !important;
      line-height: 1.5rem !important;
      font-weight: bold; }
    .public-history__header .custom-select-input-style .ant-select-arrow {
      right: 2px; }

.mobile-tab_market-indicator {
  width: 2.5rem !important;
  margin: 0.3rem 0.3rem 0 0.3rem; }
  .mobile-tab_market-indicator svg .btc0,
  .mobile-tab_market-indicator svg .btc1,
  .mobile-tab_market-indicator svg .eth0,
  .mobile-tab_market-indicator svg .eth1,
  .mobile-tab_market-indicator svg .bch-icon,
  .mobile-tab_market-indicator svg .xrp-icon,
  .mobile-tab_market-indicator svg .xht-icon,
  .mobile-tab_market-indicator svg .xmr-icon,
  .mobile-tab_market-indicator svg .xlm-icon,
  .mobile-tab_market-indicator svg .eos-icon,
  .mobile-tab_market-indicator svg .trx-icon,
  .mobile-tab_market-indicator svg .ada-icon,
  .mobile-tab_market-indicator svg .bnb-icon,
  .mobile-tab_market-indicator svg .tusd-icon,
  .mobile-tab_market-indicator svg .dai-icon,
  .mobile-tab_market-indicator svg .ltc-icon,
  .mobile-tab_market-indicator svg .default-icon {
    fill: var(--labels_secondary-inactive-label-text-graphics) !important; }
  .mobile-tab_market-indicator svg .plus,
  .mobile-tab_market-indicator svg .minus {
    stroke: var(--labels_secondary-inactive-label-text-graphics) !important;
    stroke-width: 0.2rem; }
  .mobile-tab_market-indicator svg .st0 {
    fill: var(--calculated_base_top-bar-navigation_text);
    stroke-width: 0rem; }
  .mobile-tab_market-indicator svg .icon-check-1 {
    stroke: var(--calculated_base_top-bar-navigation_text); }
  .mobile-tab_market-indicator svg .xht-icon-1 {
    fill: var(--base_background) !important; }

.quote-container {
  background-color: var(--base_background);
  overflow-y: scroll; }

.base_negative_balance,
.success-review,
.quote-review-wrapper {
  margin: 1rem 0;
  width: 30rem; }

.quote-countdown-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center; }
  .quote-countdown-wrapper .counter {
    font-size: 2.6rem; }

.quote-success-review-text {
  margin: 1rem 0 2rem;
  font-size: 14px;
  text-align: center; }

.quote-success__title {
  text-transform: uppercase;
  font-family: Raleway;
  font-weight: bold;
  font-size: 24px;
  text-align: center; }

.quick-trade__icon {
  height: 7rem;
  width: 7rem;
  margin-bottom: 2rem;
  margin-right: auto;
  margin-left: auto; }

.layout-mobile .quote-container {
  background-color: transparent;
  overflow-y: scroll;
  min-height: calc( 100vh - 23rem);
  max-height: calc( 100vh - 23rem); }
  .layout-mobile .quote-container .quick_trade-wrapper {
    flex: 1 1;
    min-width: 95vw;
    max-width: 100vw;
    margin: 1rem;
    padding: 1rem; }

.layout-mobile .base_negative_balance,
.layout-mobile .success-review,
.layout-mobile .quote-review-wrapper {
  margin: auto; }

.contact_form-wrapper {
  min-width: 30rem; }
  .contact_form-wrapper .icon_title-wrapper .icon_title-text.title {
    text-transform: capitalize;
    margin: 1.5rem auto; }
  .contact_form-wrapper .icon_title-wrapper .icon_title-image {
    height: 6rem;
    width: 6rem; }
  .contact_form-wrapper .field-wrapper {
    margin: 1rem 0; }
  .contact_form-wrapper .buttons-wrapper {
    margin-top: 1.5rem; }

.layout-mobile .contact_form-wrapper {
  min-width: auto;
  flex: 1 1; }

.verification_container {
  margin: 0 auto;
  padding: 1rem; }
  .verification_container .icon_title-wrapper {
    flex-direction: row;
    justify-content: flex-start; }
  .verification_container .title-inactive {
    color: var(--labels_secondary-inactive-label-text-graphics); }
  .verification_container .verification-buttons-wrapper {
    width: 100%; }
    .verification_container .verification-buttons-wrapper .holla-button {
      width: 300px; }
  .verification_container .warning_text {
    margin: 1rem 0;
    padding: 0; }
  .verification_container .header-content {
    color: var(--labels_secondary-inactive-label-text-graphics);
    font-size: 14px; }
  .verification_container .content-text {
    font-size: 14px; }
  .verification_container .document-note-icon {
    width: 3.5rem; }
  .verification_container .inner-content {
    border-radius: 4px;
    padding: 30px;
    margin: 30px 0px 70px;
    background-color: var(--base_wallet-sidebar-and-popup);
    padding-bottom: 20px; }
    .verification_container .inner-content .custom-tab-wrapper .tab_item {
      min-width: 9rem; }
    .verification_container .inner-content .btn-wrapper {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center; }
      .verification_container .inner-content .btn-wrapper .holla-verification-button {
        position: absolute;
        top: 60px;
        width: 300px; }

.verification-form-panel {
  padding: 30px 50px;
  background-color: var(--base_wallet-sidebar-and-popup); }
  .verification-form-panel .action_notification-wrapper .notification-info {
    white-space: nowrap; }

.verification_content-form-wrapper {
  padding: 0.25rem; }

.account-pending-icon {
  width: 3rem; }

.verification_link {
  color: var(--specials_buttons-links-and-highlights); }

.verification-info-txt {
  width: 70%; }

.verification_document-sample {
  width: 65%; }

.header_title-wrapper .header_title-children .important_information {
  color: var(--labels_important-active-labels-text-graphics);
  font-weight: bold; }

.header_title-wrapper .header_title-children .header_title-list {
  padding: 0 1.5rem; }

.header_title-wrapper .header_title-children .header_title-content:not(:last-child) {
  margin-bottom: 0.75rem; }

.header_title-wrapper .id-warning {
  color: var(--specials_notifications-alerts-warnings); }

.layout-mobile .verification_container .verification-info-txt {
  width: 100%;
  font-size: 13px !important; }

.layout-mobile .verification_container .holla-verification-button {
  font-size: 13px !important;
  position: unset !important;
  top: 0 !important;
  width: 100% !important; }

.layout-mobile .verification_container .inner-content {
  padding: 5px 8px; }

.layout-mobile .verification_container .icon_title-wrapper {
  margin-top: 0; }
  .layout-mobile .verification_container .icon_title-wrapper .icon_title-text.title {
    font-size: 2.6rem; }

.layout-mobile .verification_container .verification_content-form-wrapper .verification-form-panel {
  padding: 5px 8px; }
  .layout-mobile .verification_container .verification_content-form-wrapper .verification-form-panel .field-wrapper .field-label-wrapper {
    flex-direction: column; }

.layout-mobile .verification_container .verification_content-form-wrapper .content-text {
  width: 100%; }

.layout-mobile .verification_container .input-box-field .input_field-input::-webkit-input-placeholder {
  font-size: 10px; }

.layout-mobile .verification_container .input-box-field .input_field-input::placeholder {
  font-size: 10px; }

.layout-mobile .verification_container .verification-buttons-wrapper .holla-button {
  width: 100% !important;
  flex: 1 1; }

.layout-mobile .verification_container .separator {
  width: auto;
  margin: 1rem; }

.layout-mobile .mobile-bar-tab .bartab-text-wrapper {
  font-size: 1.5rem;
  line-height: 1.5rem; }

.layout-mobile .presentation_container.verification_container {
  padding-top: 0 !important;
  max-height: calc( 100vh - 13rem); }
  .layout-mobile .presentation_container.verification_container .header-content {
    font-size: 13px !important; }

.layout-mobile .header_title-wrapper .header_title-text {
  font-size: 15px; }

.layout-mobile .header_title-wrapper .header_title-children {
  font-size: 13px !important; }

.layout-mobile .field-wrapper .field-content .field-label,
.layout-mobile .field-wrapper .field-content .field-children,
.layout-mobile .field-wrapper .field-content .inner_container,
.layout-mobile .field-wrapper .field-content .input_field-input,
.layout-mobile .field-wrapper .field-content .placeholder {
  font-size: 13px !important; }

.layout-mobile .content-text {
  font-size: 13px; }

.direction_ltr .divider {
  margin-right: 0.5rem; }

.direction_rtl .divider {
  margin-left: 0.5rem; }

@media (max-width: 1060px) {
  .verification_container .inner-content .custom-tab-wrapper {
    overflow-x: auto; }
  .verification_container .verification-buttons-wrapper .holla-button {
    width: 80%; }
  .verification_container .verification_content-form-wrapper .verification-form-panel .field-wrapper .field-label {
    font-size: 11px; }
  .verification_container .verification_content-form-wrapper .verification-form-panel .field-wrapper .input-box-field .input_field-input::-webkit-input-placeholder {
    font-size: 11px; }
  .verification_container .verification_content-form-wrapper .verification-form-panel .field-wrapper .input-box-field .input_field-input::placeholder {
    font-size: 11px; } }

.help-wrapper {
  min-width: 30rem;
  max-width: 38rem; }
  .help-wrapper .icon_title-wrapper .icon_title-text.title {
    text-transform: capitalize;
    margin: 1.5rem auto; }
  .help-wrapper .icon_title-wrapper .icon_title-image {
    height: 6rem;
    width: 6rem; }
  .help-wrapper .field-wrapper {
    margin: 1rem 0; }
  .help-wrapper .buttons-wrapper {
    margin-top: 1.5rem; }
  .help-wrapper .text {
    font-size: 12px;
    flex: 6 1;
    margin-left: 1rem; }
  .help-wrapper .separator {
    width: 2rem; }
  .help-wrapper .help_icons {
    width: 3rem;
    height: 3rem; }

.layout-mobile .contact_form-wrapper {
  min-width: auto;
  flex: 1 1; }

.withdrawal-confirm-warpper .withdrawal-confirm-title {
  font-size: 3.5rem;
  font-weight: normal; }

.withdrawal-confirm-warpper .withdrawal-confirm--image {
  width: 22rem; }

.direction_ltr .summary-container .trader-account-icon {
  margin-right: 1rem; }

.direction_rtl .summary-container .trader-account-icon {
  margin-left: 1rem; }

.logout-wrapper {
  width: 32rem; }

.layout-mobile .fee-limits-wrapper {
  margin: 1rem auto 3rem; }
  .layout-mobile .fee-limits-wrapper .icon_title-wrapper .icon_title-svg {
    height: 12rem;
    width: 12rem; }
  .layout-mobile .fee-limits-wrapper .icon_title-wrapper .icon_title-svg svg {
    height: 12rem;
    width: 12rem; }

.layout-mobile .logout-wrapper {
  margin: 0 auto; }

.layout-mobile .confirm-text {
  font-size: 2rem; }

.layout-mobile .summary-container .trader-account-wrapper .summary-block_wrapper .trade-account-secondary-txt {
  width: 100%; }
  .layout-mobile .summary-container .trader-account-wrapper .summary-block_wrapper .trade-account-secondary-txt .account-details-content {
    font-size: 13px; }
  .layout-mobile .summary-container .trader-account-wrapper .summary-block_wrapper .trade-account-secondary-txt .trade-account-link {
    font-size: 13px; }

.layout-mobile .summary-container .account-details-wrapper .requirement-content {
  font-size: 13px; }

.layout-mobile .summary-container .summary-section_1,
.layout-mobile .summary-container .summary-section_2 {
  height: auto !important; }

.layout-mobile .summary-container .summary-content-txt {
  font-size: 13px !important; }

.layout-mobile .summary-container .assets-wrapper .price-text {
  font-size: 13px !important; }

.layout-mobile .summary-container .assets-wrapper .donut-container {
  height: 30rem; }

.layout-mobile .summary-container .account-details-wrapper .account-type-menu {
  height: auto !important; }

.summary-container {
  height: inherit;
  max-width: 100%;
  margin: 1rem auto;
  width: 75rem; }
  .summary-container .icon_title-wrapper {
    justify-content: flex-start;
    flex-direction: row; }
  .summary-container .account-details-text {
    padding-top: 1rem;
    padding-bottom: 2rem; }
  .summary-container .summary-block_wrapper {
    color: var(--labels_secondary-inactive-label-text-graphics);
    margin: 11px 0;
    padding: 1.5rem 1rem 1rem 1.5rem;
    background-color: var(--base_wallet-sidebar-and-popup);
    border-radius: 3px;
    border: 1px solid var(--calculated_super-pale_label-text-graphics); }
    .summary-container .summary-block_wrapper .summary-block-title {
      color: var(--labels_secondary-inactive-label-text-graphics);
      font-size: 1.2rem;
      font-weight: bold; }
    .summary-container .summary-block_wrapper .summary-title-icon {
      width: 2rem; }
  .summary-container .summary-content-txt {
    font-size: 14px; }
  .summary-container .trader-account-wrapper {
    margin-right: 1.5rem;
    width: 25rem; }
    .summary-container .trader-account-wrapper .trade-account-secondary-txt {
      margin: auto 0;
      width: 55%; }
  .summary-container .trader-wrapper-icon {
    width: 5rem;
    margin-right: 1rem; }
    .summary-container .trader-wrapper-icon svg .level_icon,
    .summary-container .trader-wrapper-icon svg .hap-icon {
      fill: var(--labels_secondary-inactive-label-text-graphics); }
  .summary-container .trader-acc-detail-icon {
    width: 8rem;
    margin-right: 1rem; }
    .summary-container .trader-acc-detail-icon svg .level_icon {
      fill: var(--labels_secondary-inactive-label-text-graphics); }
  .summary-container .trader-account-icon {
    height: 4.5rem;
    width: 4.5rem;
    margin-right: 1rem; }
  .summary-container .trade-account-link {
    color: var(--specials_buttons-links-and-highlights);
    font-weight: bold; }
  .summary-container .summary-section_1 .assets-wrapper .donut-container {
    height: 16rem;
    width: 100%; }
  .summary-container .summary-section_2 {
    height: 40rem; }
  .summary-container .requirements-content-text {
    margin: auto 0; }
  .summary-container .requirements-content {
    width: 11rem; }
  .summary-container .requirement-verified {
    color: var(--specials_checks-okay-done); }
    .summary-container .requirement-verified svg circle {
      fill: var(--specials_checks-okay-done); }
    .summary-container .requirement-verified svg path,
    .summary-container .requirement-verified svg polygon,
    .summary-container .requirement-verified svg polyline {
      fill: var(--calculated_specials_notifications-success-text); }
  .summary-container .requirement-not-verified {
    color: var(--specials_notifications-alerts-warnings); }
    .summary-container .requirement-not-verified svg circle {
      fill: var(--specials_notifications-alerts-warnings); }
    .summary-container .requirement-not-verified svg path,
    .summary-container .requirement-not-verified svg polygon,
    .summary-container .requirement-not-verified svg polyline {
      fill: var(--calculated_specials_notifications-alerts-text); }
  .summary-container .requirement-rejected {
    color: var(--specials_notifications-alerts-warnings); }
    .summary-container .requirement-rejected svg circle {
      fill: var(--specials_notifications-alerts-warnings); }
    .summary-container .requirement-rejected svg path,
    .summary-container .requirement-rejected svg polygon,
    .summary-container .requirement-rejected svg polyline {
      fill: var(--calculated_specials_notifications-alerts-text); }
  .summary-container .requirement-pending {
    color: var(--specials_pending-waiting-caution); }
    .summary-container .requirement-pending svg circle {
      fill: var(--specials_pending-waiting-caution); }
    .summary-container .requirement-pending svg path,
    .summary-container .requirement-pending svg polygon,
    .summary-container .requirement-pending svg polyline {
      fill: var(--calculated_specials_notifications-pending-text); }
  .summary-container .requirement-incomplete {
    color: var(--labels_secondary-inactive-label-text-graphics); }
    .summary-container .requirement-incomplete svg circle {
      fill: var(--labels_secondary-inactive-label-text-graphics); }
    .summary-container .requirement-incomplete svg path,
    .summary-container .requirement-incomplete svg polygon,
    .summary-container .requirement-incomplete svg polyline {
      fill: var(--labels_secondary-inactive-label-text-graphics); }
  .summary-container .requirement-wrapper {
    width: 50rem; }
    .summary-container .requirement-wrapper .requirement-doc-icon {
      width: 6rem;
      margin: 1rem 1rem 0.5rem 0; }
      .summary-container .requirement-wrapper .requirement-doc-icon svg .verification-doc {
        fill: var(--labels_secondary-inactive-label-text-graphics); }
  .summary-container .requirement-stauts {
    width: 1rem; }
  .summary-container .requirement-status-warning {
    height: 1rem;
    width: 1rem;
    border-radius: 1.5rem;
    background-color: var(--specials_notifications-alerts-warnings);
    color: var(--calculated_specials_notifications-alerts-text);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    line-height: 1;
    font-weight: bold; }
  .summary-container .assets-wrapper {
    font-size: 0.8rem; }
    .summary-container .assets-wrapper .assets-description {
      width: 86%; }
    .summary-container .assets-wrapper .donut-container {
      height: 23rem;
      width: 100%; }
    .summary-container .assets-wrapper .donut-container-xht {
      height: 40rem; }
    .summary-container .assets-wrapper .price-content {
      padding: 0 0.5rem; }
    .summary-container .assets-wrapper .price-text {
      color: var(--labels_secondary-inactive-label-text-graphics);
      font-weight: bold; }
    .summary-container .assets-wrapper .total-assets {
      background: repeating-linear-gradient(-45deg, var(--base_background), var(--base_background) 2px, #cccbcb 2px, #cccbcb 4px);
      font-size: 1.2rem;
      font-weight: bold;
      padding: 0.5rem;
      color: var(--labels_secondary-inactive-label-text-graphics); }
    .summary-container .assets-wrapper .asset-changes {
      background-color: var(--specials_buttons-links-and-highlights); }
    .summary-container .assets-wrapper .coin-price-container {
      height: 3.5rem; }
      .summary-container .assets-wrapper .coin-price-container .coin-price {
        width: 3rem; }
        .summary-container .assets-wrapper .coin-price-container .coin-price svg .btc0,
        .summary-container .assets-wrapper .coin-price-container .coin-price svg .btc1 {
          fill: var(--coin-btc); }
        .summary-container .assets-wrapper .coin-price-container .coin-price svg .eth0,
        .summary-container .assets-wrapper .coin-price-container .coin-price svg .eth1 {
          fill: var(--coin-eth); }
        .summary-container .assets-wrapper .coin-price-container .coin-price svg .bch-icon {
          fill: var(--coin-bch); }
        .summary-container .assets-wrapper .coin-price-container .coin-price svg .xrp-icon {
          fill: var(--coin-xrp); }
        .summary-container .assets-wrapper .coin-price-container .coin-price svg .xmr-icon {
          fill: var(--coin-xmr); }
        .summary-container .assets-wrapper .coin-price-container .coin-price svg .ada-icon {
          fill: var(--coin-ada); }
        .summary-container .assets-wrapper .coin-price-container .coin-price svg .bnb-icon {
          fill: var(--coin-bnb); }
        .summary-container .assets-wrapper .coin-price-container .coin-price svg .dai-icon {
          fill: var(--coin-dai); }
        .summary-container .assets-wrapper .coin-price-container .coin-price svg .eos-icon {
          fill: var(--coin-eos); }
        .summary-container .assets-wrapper .coin-price-container .coin-price svg .ltc-icon {
          fill: var(--coin-ltc); }
        .summary-container .assets-wrapper .coin-price-container .coin-price svg .trx-icon {
          fill: var(--coin-trx); }
        .summary-container .assets-wrapper .coin-price-container .coin-price svg .tusd-icon {
          fill: var(--coin-tusd); }
        .summary-container .assets-wrapper .coin-price-container .coin-price svg .xlm-icon {
          fill: var(--coin-xlm); }
        .summary-container .assets-wrapper .coin-price-container .coin-price svg .default-icon {
          fill: var(--labels_secondary-inactive-label-text-graphics) !important; }
  .summary-container .asset_wrapper_width {
    width: 100%; }
  .summary-container .xht_asset_wrapper_width {
    width: 100% !important; }
  .summary-container .trading-volume-wrapper {
    width: 50rem; }
    .summary-container .trading-volume-wrapper .wave-auction-icon {
      width: 1.5rem; }
    .summary-container .trading-volume-wrapper .wave-action-phase {
      border-top: 1px solid var(--calculated_secondary-border); }
      .summary-container .trading-volume-wrapper .wave-action-phase .table-wrapper {
        margin: 0.5rem 0 2rem; }
    .summary-container .trading-volume-wrapper .wave-phase-completed {
      color: var(--specials_checks-okay-done); }
    .summary-container .trading-volume-wrapper .wave-phase-pending {
      color: var(--labels_secondary-inactive-label-text-graphics);
      font-weight: bold; }
  .summary-container .account-details-wrapper .account-type-container {
    width: 30%; }
  .summary-container .account-details-wrapper .mobile-account-type {
    width: 80%; }
  .summary-container .account-details-wrapper .account-type-icon {
    width: 4rem; }
  .summary-container .account-details-wrapper .account-type-menu {
    border-top: 1px solid var(--calculated_secondary-border);
    color: var(--specials_buttons-links-and-highlights);
    cursor: pointer;
    font-weight: bold;
    text-transform: uppercase; }
    .summary-container .account-details-wrapper .account-type-menu .account-current {
      color: var(--labels_secondary-inactive-label-text-graphics);
      text-transform: lowercase; }
  .summary-container .account-details-wrapper .account-type-menu-active {
    border-top: 2px solid var(--calculated_secondary-border);
    border-bottom: 1px solid var(--calculated_secondary-border);
    color: var(--labels_secondary-inactive-label-text-graphics);
    font-size: 1rem;
    text-transform: capitalize; }
    .summary-container .account-details-wrapper .account-type-menu-active .account-current {
      color: var(--labels_secondary-inactive-label-text-graphics);
      text-transform: lowercase; }
    .summary-container .account-details-wrapper .account-type-menu-active .trader-account-icon svg .level_icon {
      fill: var(--labels_secondary-inactive-label-text-graphics); }
  .summary-container .account-details-wrapper .accounnt-type-menu-last-active {
    border-bottom: none !important; }
  .summary-container .account-details-wrapper .requirement-header {
    border-bottom: 1px solid var(--calculated_secondary-border);
    color: var(--labels_secondary-inactive-label-text-graphics);
    font-weight: bold; }
  .summary-container .account-details-wrapper .status-header {
    width: 6rem; }
  .summary-container .price-diff-down {
    color: var(--trading_selling-related-elements);
    padding: 0 5px;
    border-radius: 2px;
    transition: all 0.2s ease-out 0s; }
  .summary-container .glance-price-diff-down.entering, .summary-container .glance-price-diff-down.exiting {
    color: var(--calculated_trading_selling-related-text);
    background-color: var(--trading_selling-related-elements); }
    .summary-container .glance-price-diff-down.entering.glance-trade-tab-price_diff_down:before, .summary-container .glance-price-diff-down.exiting.glance-trade-tab-price_diff_down:before {
      border-color: var(--calculated_trading_selling-related-text); }
  .summary-container .price-diff-up {
    color: var(--trading_buying-related-elements);
    padding: 0 5px;
    border-radius: 2px;
    transition: all 0.2s ease-out 0s; }
  .summary-container .glance-price-diff-up.entering, .summary-container .glance-price-diff-up.exiting {
    color: var(--calculated_trading_buying-related-text);
    background-color: var(--trading_buying-related-elements); }
    .summary-container .glance-price-diff-up.entering.glance-trade-tab-price_diff_up:before, .summary-container .glance-price-diff-up.exiting.glance-trade-tab-price_diff_up:before {
      border-color: var(--calculated_trading_buying-related-text); }
  .summary-container .trade-tab-price_diff_up {
    margin: auto 0; }
  .summary-container .trade-tab-price_diff_up:before {
    content: "";
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-top: none !important;
    border-left: 0.4rem solid transparent !important;
    border-right: 0.4rem solid transparent !important;
    border-bottom: 0.4rem solid var(--trading_buying-related-elements);
    margin-bottom: 0rem;
    display: inline-block;
    margin-bottom: 0.2rem;
    margin-right: 0.5rem;
    position: relative;
    top: calc(50% - 0.6rem); }
  .summary-container .trade-tab-price_diff_down {
    margin: auto 0; }
  .summary-container .trade-tab-price_diff_down:before {
    content: "";
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-bottom: none !important;
    border-left: 0.4rem solid transparent !important;
    border-right: 0.4rem solid transparent !important;
    border-top: 0.4rem solid var(--trading_selling-related-elements);
    margin-top: 0rem;
    display: inline-block;
    margin-right: 0.5rem;
    position: relative;
    top: calc(50% - 0.6rem); }
  .summary-container .trade_tabs-search-field {
    background-color: var(--base_wallet-sidebar-and-popup);
    color: var(--labels_inactive-button);
    margin: 0;
    padding-right: 0.5rem; }
    .summary-container .trade_tabs-search-field::-webkit-input-placeholder {
      color: var(--labels_inactive-button); }
    .summary-container .trade_tabs-search-field::placeholder {
      color: var(--labels_inactive-button); }
    .summary-container .trade_tabs-search-field .field-error-content {
      display: none; }
  .summary-container .trade_tabs-search-outline {
    background: var(--labels_secondary-inactive-label-text-graphics); }

.fee-limits-wrapper {
  width: 38rem; }
  .fee-limits-wrapper .fee-limits-link {
    color: var(--specials_buttons-links-and-highlights);
    cursor: pointer;
    font-weight: bold; }
  .fee-limits-wrapper .content-txt {
    font-size: 1rem; }
    .fee-limits-wrapper .content-txt .fee-reduction-container {
      border: 1px solid var(--calculated_secondary-border); }
  .fee-limits-wrapper .content-title {
    font-size: 14px;
    font-weight: bold;
    margin: 1.5rem 0; }
  .fee-limits-wrapper .icon_title-wrapper {
    margin-top: 1rem; }
    .fee-limits-wrapper .icon_title-wrapper .icon_title-svg {
      height: 11rem;
      width: 12rem; }
    .fee-limits-wrapper .icon_title-wrapper .icon_title-svg svg {
      height: 12rem;
      width: 12rem; }
      .fee-limits-wrapper .icon_title-wrapper .icon_title-svg svg .level_icon {
        fill: var(--labels_secondary-inactive-label-text-graphics); }
    .fee-limits-wrapper .icon_title-wrapper .icon_title-text {
      margin: 0; }
      .fee-limits-wrapper .icon_title-wrapper .icon_title-text.title {
        font-size: 2rem; }
  .fee-limits-wrapper table {
    border-collapse: collapse;
    border: 1px solid var(--calculated_secondary-border); }
  .fee-limits-wrapper td,
  .fee-limits-wrapper th {
    border: 1px solid var(--calculated_secondary-border); }
  .fee-limits-wrapper .account-limits {
    margin: 1rem 0;
    width: 100%; }
  .fee-limits-wrapper .account-limits-content {
    border: 1px solid var(--calculated_secondary-border); }
  .fee-limits-wrapper .account-limits-maker {
    border-left: 1px solid var(--calculated_secondary-border);
    border-right: 1px solid var(--calculated_secondary-border);
    border-top: 1px solid var(--calculated_secondary-border); }
  .fee-limits-wrapper .account-limits-taker {
    border-bottom: 1px solid var(--calculated_secondary-border);
    border-left: 1px solid var(--calculated_secondary-border);
    border-right: 1px solid var(--calculated_secondary-border); }
  .fee-limits-wrapper .account-limits-coin {
    border-bottom: 1px solid var(--calculated_secondary-border);
    padding: 0.25rem 0.5rem;
    width: 14rem; }
  .fee-limits-wrapper .account-limits-status {
    padding: 0.25rem 0.5rem; }
  .fee-limits-wrapper .account-limits-value {
    padding: 0.25rem 1rem; }
  .fee-limits-wrapper .limit-head-currency {
    font-weight: normal !important;
    padding: 0.25rem 0.5rem; }
  .fee-limits-wrapper .limit-head {
    font-weight: normal !important;
    padding: 0.25rem 1rem; }

.markets-section-wrapper .market-coin {
  width: 3rem; }

.markets-section-wrapper .price-diff-down {
  color: var(--trading_selling-related-elements); }

.markets-section-wrapper .price-diff-up {
  color: var(--trading_buying-related-elements); }

.markets-section-wrapper .app-price_diff_up {
  margin-left: 0.5rem; }

.markets-section-wrapper .app-price_diff_up:before {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border-top: none !important;
  border-left: 0.4rem solid transparent !important;
  border-right: 0.4rem solid transparent !important;
  border-bottom: 0.4rem solid var(--trading_buying-related-elements);
  margin-bottom: 0rem;
  display: inline-block;
  margin-bottom: 0.2rem;
  margin-right: 0.5rem;
  position: relative;
  top: calc(50% - 0.6rem); }

.markets-section-wrapper .app-price_diff_down {
  margin-left: 0.5rem; }

.markets-section-wrapper .app-price_diff_down:before {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border-bottom: none !important;
  border-left: 0.4rem solid transparent !important;
  border-right: 0.4rem solid transparent !important;
  border-top: 0.4rem solid var(--trading_selling-related-elements);
  margin-top: 0rem;
  display: inline-block;
  margin-right: 0.5rem;
  position: relative;
  top: calc(50% - 0.6rem); }

@media (max-width: 920px) {
  .summary-container .summary-block_wrapper .summary-block-title,
  .summary-container .summary-block-secondaryTitle {
    font-size: 1.8rem; }
  .summary-container .summary-content-txt {
    font-size: 1.2rem !important; } }

@media (min-width: 1920px) {
  .summary-container {
    width: 100rem !important; }
    .summary-container .summary-block_wrapper .summary-block-title {
      font-size: 1.2rem; }
    .summary-container .summary-content-txt {
      font-size: 1rem; }
    .summary-container .asset_wrapper_width {
      width: 100% !important; }
    .summary-container .xht_asset_wrapper_width {
      width: 100% !important; }
    .summary-container .assets-wrapper {
      font-size: 1rem; }
    .summary-container .trader-account-wrapper {
      font-size: 1rem;
      width: 35% !important; }
    .summary-container .trading-volume-wrapper,
    .summary-container .requirement-wrapper {
      width: 65% !important; }
    .summary-container .summary-section_2 {
      height: 43rem !important; }
  .fee-limits-wrapper .content-txt {
    font-size: 1rem; } }

@media screen and (max-width: 1096px) {
  .summary-container .summary-content-txt {
    font-size: 0.9rem; }
  .summary-container .assets-wrapper,
  .summary-container .trader-account-wrapper {
    font-size: 0.9rem; }
  .summary-container .summary-section_2 {
    height: 44rem !important; }
  .fee-limits-wrapper .content-txt {
    font-size: 0.9rem; } }

.highcharts-graph {
  stroke: var(--trading_buying-related-elements); }
  .highcharts-graph.highcharts-negative {
    stroke: var(--trading_selling-related-elements); }

.highcharts-area {
  fill: var(--calculated_trading_buying-sparkline-fill); }
  .highcharts-area.highcharts-negative {
    fill: var(--calculated_trading_selling-sparkline-fill); }

.highcharts-point {
  stroke: var(--trading_buying-related-elements);
  fill: var(--trading_buying-related-elements); }
  .highcharts-point.highcharts-negative {
    stroke: var(--trading_selling-related-elements);
    fill: var(--trading_selling-related-elements); }

.highcharts-background {
  fill: transparent; }

.highcharts-credits {
  display: none; }

.highcharts-container {
  width: 100% !important; }

.highcharts-root {
  width: 100% !important; }

.market-list__container .market-list__block .market-list__coin-icons {
  width: 2.8rem;
  margin: 0.3rem 0.3rem 0 0.3rem; }
  .market-list__container .market-list__block .market-list__coin-icons > div {
    text-align: center; }
    .market-list__container .market-list__block .market-list__coin-icons > div svg, .market-list__container .market-list__block .market-list__coin-icons > div svg > g > g {
      fill: var(--trading_buying-related-elements) !important; }
  .market-list__container .market-list__block .market-list__coin-icons svg .btc0,
  .market-list__container .market-list__block .market-list__coin-icons svg .btc1 {
    fill: var(--coin-btc); }
  .market-list__container .market-list__block .market-list__coin-icons svg .eth0,
  .market-list__container .market-list__block .market-list__coin-icons svg .eth1 {
    fill: var(--coin-eth); }
  .market-list__container .market-list__block .market-list__coin-icons svg .bch-icon {
    fill: var(--coin-bch); }
  .market-list__container .market-list__block .market-list__coin-icons svg .xrp-icon {
    fill: var(--coin-xrp); }
  .market-list__container .market-list__block .market-list__coin-icons svg .xmr-icon {
    fill: var(--coin-xmr); }
  .market-list__container .market-list__block .market-list__coin-icons svg .ada-icon {
    fill: var(--coin-ada); }
  .market-list__container .market-list__block .market-list__coin-icons svg .bnb-icon {
    fill: var(--coin-bnb); }
  .market-list__container .market-list__block .market-list__coin-icons svg .dai-icon {
    fill: var(--coin-dai); }
  .market-list__container .market-list__block .market-list__coin-icons svg .eos-icon {
    fill: var(--coin-eos); }
  .market-list__container .market-list__block .market-list__coin-icons svg .ltc-icon {
    fill: var(--coin-ltc); }
  .market-list__container .market-list__block .market-list__coin-icons svg .trx-icon {
    fill: var(--coin-trx); }
  .market-list__container .market-list__block .market-list__coin-icons svg .tusd-icon {
    fill: var(--coin-tusd); }
  .market-list__container .market-list__block .market-list__coin-icons svg .xlm-icon {
    fill: var(--coin-xlm); }
  .market-list__container .market-list__block .market-list__coin-icons svg .default-icon {
    fill: var(--labels_secondary-inactive-label-text-graphics) !important; }

.market-list__container .market-list__block .market-list__block-table {
  width: 100%;
  margin-bottom: 3rem; }
  .market-list__container .market-list__block .market-list__block-table thead {
    color: var(--labels_secondary-inactive-label-text-graphics); }
    .market-list__container .market-list__block .market-list__block-table thead tr {
      height: 3.5rem;
      vertical-align: bottom; }
  .market-list__container .market-list__block .market-list__block-table .table-row {
    color: var(--labels_secondary-inactive-label-text-graphics);
    height: 2.5rem;
    cursor: pointer; }
    .market-list__container .market-list__block .market-list__block-table .table-row:hover {
      background-color: var(--base_secondary-navigation-bar);
      color: var(--labels_secondary-inactive-label-text-graphics); }
    .market-list__container .market-list__block .market-list__block-table .table-row .td-chart {
      width: 150px;
      height: 50px; }
  .market-list__container .market-list__block .market-list__block-table .table-bottom-border td,
  .market-list__container .market-list__block .market-list__block-table th {
    position: relative;
    border-bottom: 1px solid var(--calculated_secondary-border);
    padding: 0 0.25rem; }
  .market-list__container .market-list__block .market-list__block-table .td-fit {
    width: 1%;
    white-space: nowrap; }
  .market-list__container .market-list__block .market-list__block-table .td-name {
    min-width: 200px !important; }
  .market-list__container .market-list__block .market-list__block-table .table-icon > div {
    vertical-align: middle;
    margin: auto; }
  .market-list__container .market-list__block .market-list__block-table .td-amount {
    min-width: 150px !important;
    direction: ltr; }

.direction_ltr .market-list__container .market-list__block .market-list__block-table th {
  font-family: "Roboto";
  vertical-align: middle;
  padding: 0.5rem; }

.direction_ltr .market-list__container .market-list__block .market-list__block-table th,
.direction_ltr .market-list__container .market-list__block .market-list__block-table td {
  text-align: left; }

.direction_ltr .market-list__container .market-list__block .market-list__block-table .align-opposite {
  text-align: right; }

.direction_ltr .market-list__container .market-list__block .market-list__block-table .table-icon {
  padding-right: 0.5rem; }

.direction_rtl .market-list__container .market-list__block .market-list__block-table th,
.direction_rtl .market-list__container .market-list__block .market-list__block-table td {
  text-align: right; }

.direction_rtl .market-list__container .market-list__block .market-list__block-table .align-opposite {
  text-align: left; }

.direction_rtl .market-list__container .market-list__block .market-list__block-table .table-icon {
  padding-left: 0.5rem; }

.layout-mobile .market-list__container .market-list__block {
  overflow-x: auto;
  position: relative; }
  .layout-mobile .market-list__container .market-list__block .market-list__block-table .table-row td {
    padding: 0.5rem;
    min-width: 10rem; }
  .layout-mobile .market-list__container .market-list__block .market-list__block-table .table-row .td-chart {
    min-width: 15rem;
    padding: 0 !important; }
  .layout-mobile .market-list__container .market-list__block .market-list__block-table .sticky-col {
    position: -webkit-sticky;
    position: sticky;
    background-color: var(--base_wallet-sidebar-and-popup);
    left: 0;
    width: 12rem !important;
    min-width: 12rem !important;
    max-width: 12rem !important;
    z-index: 1; }

.layout-mobile .trade_tabs-container .sticky-col {
  background-color: var(--base_background) !important; }

.trade_tabs-container {
  color: var(--labels_secondary-inactive-label-text-graphics);
  height: inherit;
  max-width: 100%;
  margin: 3rem auto;
  width: 75rem; }
  .trade_tabs-container .app-icon {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    height: 5rem !important;
    margin: 1rem auto;
    width: 8rem; }
  .trade_tabs-container .trade-tab-app-title {
    font-family: "Roboto";
    font-weight: 300;
    letter-spacing: 0.3rem;
    font-size: 0.8rem; }
  .trade_tabs-container .trade_tabs-content {
    border-top: 2px solid var(--calculated_important-border);
    font-size: 0.8rem; }
  .trade_tabs-container .trade_tabs-link {
    color: var(--specials_buttons-links-and-highlights);
    margin: 0.5rem 0;
    padding: 0 0.5rem;
    text-transform: uppercase; }
  .trade_tabs-container .link-separator {
    border-right: 1px solid var(--calculated_secondary-border); }
  .trade_tabs-container .trade_tabs-search-field {
    background-color: var(--base_background);
    color: var(--labels_inactive-button);
    margin: 0;
    padding-right: 0.5rem; }
    .trade_tabs-container .trade_tabs-search-field::-webkit-input-placeholder {
      color: var(--labels_inactive-button); }
    .trade_tabs-container .trade_tabs-search-field::placeholder {
      color: var(--labels_inactive-button); }
  .trade_tabs-container .trade_tabs-search-outline {
    background: var(--labels_secondary-inactive-label-text-graphics); }
  .trade_tabs-container .tabs-pair-details {
    font-size: 1.2rem;
    width: 32rem; }
  .trade_tabs-container .trade_tab-icons {
    width: 3rem; }
    .trade_tabs-container .trade_tab-icons svg .btc0,
    .trade_tabs-container .trade_tab-icons svg .btc1 {
      fill: var(--coin-btc); }
    .trade_tabs-container .trade_tab-icons svg .eth0,
    .trade_tabs-container .trade_tab-icons svg .eth1 {
      fill: var(--coin-eth); }
    .trade_tabs-container .trade_tab-icons svg .bch-icon {
      fill: var(--coin-bch); }
    .trade_tabs-container .trade_tab-icons svg .xrp-icon {
      fill: var(--coin-xrp); }
    .trade_tabs-container .trade_tab-icons svg .xmr-icon {
      fill: var(--coin-xmr); }
    .trade_tabs-container .trade_tab-icons svg .ada-icon {
      fill: var(--coin-ada); }
    .trade_tabs-container .trade_tab-icons svg .bnb-icon {
      fill: var(--coin-bnb); }
    .trade_tabs-container .trade_tab-icons svg .dai-icon {
      fill: var(--coin-dai); }
    .trade_tabs-container .trade_tab-icons svg .eos-icon {
      fill: var(--coin-eos); }
    .trade_tabs-container .trade_tab-icons svg .ltc-icon {
      fill: var(--coin-ltc); }
    .trade_tabs-container .trade_tab-icons svg .trx-icon {
      fill: var(--coin-trx); }
    .trade_tabs-container .trade_tab-icons svg .tusd-icon {
      fill: var(--coin-tusd); }
    .trade_tabs-container .trade_tab-icons svg .xlm-icon {
      fill: var(--coin-xlm); }
    .trade_tabs-container .trade_tab-icons svg .default-icon {
      fill: var(--labels_secondary-inactive-label-text-graphics) !important; }
  .trade_tabs-container .trade_tab-pair-title {
    color: var(--labels_important-active-labels-text-graphics);
    font-size: 14px;
    font-weight: bold; }
  .trade_tabs-container .trade_tab-pair-sub-title {
    font-size: 1rem; }
  .trade_tabs-container .trade_tab-pair-volume {
    font-size: 1rem;
    text-align: right;
    padding-right: 0.3rem; }
  .trade_tabs-container .trade_tab-pair-price {
    color: var(--labels_important-active-labels-text-graphics);
    font-size: 1.8rem;
    font-weight: bold; }
  .trade_tabs-container .trade_tab-ticker-container {
    flex-direction: column;
    align-items: flex-end; }
  .trade_tabs-container .price-diff-down {
    color: var(--trading_selling-related-elements);
    padding: 0 5px;
    border-radius: 2px;
    transition: all 0.2s ease-out 0s; }
  .trade_tabs-container .glance-price-diff-down.entering, .trade_tabs-container .glance-price-diff-down.exiting {
    color: var(--calculated_trading_selling-related-text);
    background-color: var(--trading_selling-related-elements); }
    .trade_tabs-container .glance-price-diff-down.entering.glance-trade-tab-price_diff_down:before, .trade_tabs-container .glance-price-diff-down.exiting.glance-trade-tab-price_diff_down:before {
      border-color: var(--calculated_trading_selling-related-text); }
  .trade_tabs-container .price-diff-up {
    color: var(--trading_buying-related-elements);
    padding: 0 5px;
    border-radius: 2px;
    transition: all 0.2s ease-out 0s; }
  .trade_tabs-container .glance-price-diff-up.entering, .trade_tabs-container .glance-price-diff-up.exiting {
    color: var(--calculated_trading_buying-related-text);
    background-color: var(--trading_buying-related-elements); }
    .trade_tabs-container .glance-price-diff-up.entering.glance-trade-tab-price_diff_up:before, .trade_tabs-container .glance-price-diff-up.exiting.glance-trade-tab-price_diff_up:before {
      border-color: var(--calculated_trading_buying-related-text); }
  .trade_tabs-container .trade-tab-price_diff_up {
    margin: auto 0; }
  .trade_tabs-container .trade-tab-price_diff_up:before {
    content: "";
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-top: none !important;
    border-left: 0.4rem solid transparent !important;
    border-right: 0.4rem solid transparent !important;
    border-bottom: 0.4rem solid var(--trading_buying-related-elements);
    margin-bottom: 0rem;
    display: inline-block;
    margin-bottom: 0.2rem;
    margin-right: 0.5rem;
    position: relative;
    top: calc(50% - 0.6rem); }
  .trade_tabs-container .trade-tab-price_diff_down {
    margin: auto 0; }
  .trade_tabs-container .trade-tab-price_diff_down:before {
    content: "";
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-bottom: none !important;
    border-left: 0.4rem solid transparent !important;
    border-right: 0.4rem solid transparent !important;
    border-top: 0.4rem solid var(--trading_selling-related-elements);
    margin-top: 0rem;
    display: inline-block;
    margin-right: 0.5rem;
    position: relative;
    top: calc(50% - 0.6rem); }
  .trade_tabs-container .trade-tab-list {
    background-image: linear-gradient(var(--base_background), var(--calculated_market-card_gradient_background));
    border: 1px solid var(--calculated_super-pale_label-text-graphics);
    border-radius: 0.5rem;
    padding: 1rem 0 0;
    margin: 2rem 1.5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16); }
    .trade_tabs-container .trade-tab-list svg .xht-icon-1 {
      fill: var(--base_background) !important; }
    .trade_tabs-container .trade-tab-list:hover {
      border: 1px solid var(--calculated_secondary-border);
      color: var(--labels_secondary-inactive-label-text-graphics);
      position: relative;
      bottom: 0.5rem;
      right: 0.5rem; }
      .trade_tabs-container .trade-tab-list:hover svg .btc0,
      .trade_tabs-container .trade-tab-list:hover svg .btc1 {
        fill: var(--coin-btc); }
      .trade_tabs-container .trade-tab-list:hover svg .eth0,
      .trade_tabs-container .trade-tab-list:hover svg .eth1 {
        fill: var(--coin-eth); }
      .trade_tabs-container .trade-tab-list:hover svg .bch-icon {
        fill: var(--coin-bch); }
      .trade_tabs-container .trade-tab-list:hover svg .xrp-icon {
        fill: var(--coin-xrp); }
      .trade_tabs-container .trade-tab-list:hover svg .xmr-icon {
        fill: var(--coin-xmr); }
      .trade_tabs-container .trade-tab-list:hover svg .ada-icon {
        fill: var(--coin-ada); }
      .trade_tabs-container .trade-tab-list:hover svg .bnb-icon {
        fill: var(--coin-bnb); }
      .trade_tabs-container .trade-tab-list:hover svg .dai-icon {
        fill: var(--coin-dai); }
      .trade_tabs-container .trade-tab-list:hover svg .eos-icon {
        fill: var(--coin-eos); }
      .trade_tabs-container .trade-tab-list:hover svg .ltc-icon {
        fill: var(--coin-ltc); }
      .trade_tabs-container .trade-tab-list:hover svg .trx-icon {
        fill: var(--coin-trx); }
      .trade_tabs-container .trade-tab-list:hover svg .tusd-icon {
        fill: var(--coin-tusd); }
      .trade_tabs-container .trade-tab-list:hover svg .xlm-icon {
        fill: var(--coin-xlm); }
      .trade_tabs-container .trade-tab-list:hover svg .default-icon {
        fill: var(--labels_secondary-inactive-label-text-graphics) !important; }
  .trade_tabs-container .market-card__sparkline-wrapper {
    padding-top: 10px;
    height: 12rem; }

.layout-mobile .trade_tabs-container {
  overflow-y: scroll; }

@media screen and (max-width: 1096px) {
  .layout-mobile .trade_tabs-container .trade-tab-list {
    margin: 1.5rem 1rem; }
  .layout-mobile .trade_tabs-container .trade_tab-icons {
    width: 2.25rem; }
  .layout-mobile .trade_tabs-container .tabs-pair-details {
    font-size: 1rem;
    width: 28rem; }
  .layout-mobile .trade_tabs-container .market-card__sparkline-wrapper {
    height: 10rem; } }

@media screen and (max-width: 575px) {
  .layout-mobile .trade_tabs-container {
    overflow-y: scroll; }
    .layout-mobile .trade_tabs-container .trade-tab-list {
      margin: 1.5rem 1rem; }
    .layout-mobile .trade_tabs-container .trade_tab-icons {
      width: 2.25rem; }
    .layout-mobile .trade_tabs-container .tabs-pair-details {
      font-size: 1.5rem;
      width: 34rem;
      max-width: 96%; }
    .layout-mobile .trade_tabs-container .market-card__sparkline-wrapper {
      height: 12rem; } }

@media screen and (max-width: 1096px) {
  .trade_tabs-container .trade-tab-list {
    margin: 1.5rem 1rem; }
  .trade_tabs-container .trade_tab-icons {
    width: 2.25rem; }
  .trade_tabs-container .tabs-pair-details {
    font-size: 1rem;
    width: 26rem; }
  .trade_tabs-container .market-card__sparkline-wrapper {
    height: 9rem; } }

@media screen and (max-width: 575px) {
  .trade_tabs-container .trade_tab-icons {
    width: 1.5rem; }
  .trade_tabs-container .tabs-pair-details {
    font-size: 1rem;
    width: 20.5rem; }
  .trade_tabs-container .market-card__sparkline-wrapper {
    height: 7rem; } }

.toggle-wrapper {
  border-top: 1px solid var(--calculated_secondary-border);
  border-bottom: 1px solid var(--calculated_secondary-border); }

.toggle-wrapper-all {
  border-bottom: 2px solid var(--calculated_secondary-border);
  margin-bottom: 3rem !important; }

.portfolio-wrapper {
  width: 32rem; }
  .portfolio-wrapper .icon_title-wrapper .icon_title-svg {
    height: 10rem;
    width: 10rem; }
  .portfolio-wrapper .icon_title-wrapper .icon_title-svg svg {
    height: 10rem;
    width: 10rem; }
  .portfolio-wrapper .icon_title-wrapper .icon_title-text.title {
    font-size: 2rem; }
  .portfolio-wrapper .icon_title-wrapper .icon_title-text {
    margin-top: 3rem;
    text-align: left !important;
    width: 100%; }

.deactive_risk_data {
  color: var(--labels_secondary-inactive-label-text-graphics); }

.settings_container .icon_title-wrapper {
  flex-direction: row;
  justify-content: flex-start; }

.settings_container .header_title-wrapper .action_notification-wrapper {
  top: 30px; }

.settings_container .settings-form-wrapper .settings-form {
  padding: 10px 30px 30px 30px;
  border-radius: 4px;
  margin: 30px 0px;
  background-color: var(--base_wallet-sidebar-and-popup); }
  .settings_container .settings-form-wrapper .settings-form .notification-icon {
    width: 50px; }
  .settings_container .settings-form-wrapper .settings-form .icon_title-wrapper {
    margin: 0;
    flex-direction: row;
    justify-content: flex-start; }
    .settings_container .settings-form-wrapper .settings-form .icon_title-wrapper .icon_title-svg svg {
      width: 50px; }
    .settings_container .settings-form-wrapper .settings-form .icon_title-wrapper .icon_title-text {
      font-size: 15px;
      margin: 0; }

.settings_container .settings-form-wrapper .holla-button {
  width: 300px; }

.layout-mobile .portfolio-wrapper {
  margin: 25% auto; }

.layout-mobile .settings_container .header_title-wrapper .action_notification-wrapper {
  top: 0; }

.layout-mobile .settings_container .settings-form-wrapper .settings-form {
  margin: 15px 0 25px !important;
  padding: 10px; }
  .layout-mobile .settings_container .settings-form-wrapper .settings-form .field-wrapper .field-label-wrapper {
    flex-direction: column; }

@media (max-width: 1060px) {
  .settings_container .settings-form-wrapper .settings-form .icon_title-wrapper .icon_title-text {
    margin: 1rem;
    margin-bottom: 0; }
  .settings_container .settings-form-wrapper .settings-form .field-wrapper .field-label {
    font-size: 12px; }
  .settings_container .tab_controller-wrapper .tab_item {
    font-size: 13px; } }

.expired_exchange_wrapper {
  padding: 2rem; }
  .expired_exchange_wrapper .flex-cloumn {
    flex-direction: column; }
  .expired_exchange_wrapper .expired_img_icon {
    width: 7rem;
    height: 3.7rem;
    margin-top: 15rem; }
    .expired_exchange_wrapper .expired_img_icon svg .expired_icon {
      fill: #777677; }
  .expired_exchange_wrapper .expired_text {
    font-size: 2rem;
    font-weight: bold; }
  .expired_exchange_wrapper .expired_button {
    padding: 2rem 3rem; }

.terms_wrapper {
  width: 40rem; }
  .terms_wrapper .title_wrapper {
    font-size: 2rem;
    font-weight: bold; }
  .terms_wrapper .agreement_wrapper {
    font-size: 1rem;
    background-color: var(--calculated_specials_highlight-box);
    height: 32rem;
    overflow-y: scroll; }
  .terms_wrapper .download-wrapper {
    color: var(--specials_buttons-links-and-highlights);
    display: flex;
    align-items: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content; }
    .terms_wrapper .download-wrapper .download_pdf-svg > div {
      display: flex;
      align-items: center; }
    .terms_wrapper .download-wrapper .download_pdf-svg svg {
      width: 1rem;
      height: 1rem; }
    .terms_wrapper .download-wrapper .trans-history-1,
    .terms_wrapper .download-wrapper .trans-history-2,
    .terms_wrapper .download-wrapper .trans-history-3 {
      fill: var(--specials_buttons-links-and-highlights); }
    .terms_wrapper .download-wrapper .trans-history-4 {
      stroke: var(--specials_buttons-links-and-highlights); }
  .terms_wrapper .checkfield-wrapper {
    margin: 0.5rem 0; }

.deposit_funds-wrapper {
  width: 42rem; }
  .deposit_funds-wrapper .funds-svg svg {
    width: 2rem;
    height: 2rem; }
  .deposit_funds-wrapper .icon_title-wrapper .icon_title-svg {
    width: 8rem;
    height: 8rem; }
    .deposit_funds-wrapper .icon_title-wrapper .icon_title-svg svg {
      width: 8rem;
      height: 8rem; }

.contact_form-wrapper {
  min-width: 30rem; }
  .contact_form-wrapper .icon_title-wrapper .icon_title-text.title {
    text-transform: capitalize;
    margin: 1.5rem auto; }
  .contact_form-wrapper .icon_title-wrapper .icon_title-image {
    height: 6rem;
    width: 6rem; }
  .contact_form-wrapper .field-wrapper {
    margin: 1rem 0; }
  .contact_form-wrapper .buttons-wrapper {
    margin-top: 1.5rem; }

.layout-mobile .contact_form-wrapper {
  min-width: auto;
  flex: 1 1; }

.layout-mobile.home_container-mobile .app-icon {
  margin: 1rem auto;
  width: 15rem; }

.layout-mobile.home_container-mobile .trade-tab-app-title {
  font-family: 'Raleway Light';
  letter-spacing: 0.3rem;
  font-size: 1.2rem; }

.layout-mobile.home_container-mobile .market-button {
  background-color: #ffffff !important;
  border: 1px solid var(--calculated_secondary-border);
  color: var(--labels_secondary-inactive-label-text-graphics); }

.layout-mobile.home_container-mobile .post-wrapper {
  max-height: 45rem;
  overflow-y: auto;
  border-top: 0.4rem solid var(--calculated_secondary-border) !important; }
  .layout-mobile.home_container-mobile .post-wrapper .post-title {
    font-size: 1.75rem;
    font-weight: bold;
    text-transform: uppercase; }
  .layout-mobile.home_container-mobile .post-wrapper .trade_post_icon {
    width: 2rem; }
  .layout-mobile.home_container-mobile .post-wrapper .post_header {
    font-weight: bold;
    font-size: 12px; }
  .layout-mobile.home_container-mobile .post-wrapper .post-content {
    font-size: 12px; }
  .layout-mobile.home_container-mobile .post-wrapper .trade_announcement {
    font-size: 1.5rem; }

.layout-mobile.home_container-mobile .holla-button {
  font-size: 1.2rem; }

.image-upload {
  color: #ffffff;
  margin: 10px 0; }

.image-upload__anchor {
  text-decoration: underline;
  cursor: pointer;
  color: #ffffff; }

.image-upload__input {
  opacity: 0;
  position: fixed;
  left: -999px; }

.image-upload__file-container {
  border: 1px solid #ffffff;
  width: 320px;
  display: block;
  padding-top: 20px;
  padding-left: 120px;
  white-space: nowrap;
  position: relative;
  height: 88px; }

.image-upload__file-img-content,
.image-upload_image {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 90px;
  height: 86px;
  object-fit: contain; }

.image-upload__file-img-content {
  border-right: 1px solid #ffffff; }

.image-upload_image {
  padding: 5px;
  overflow: hidden; }

.operator-controls__wrapper {
  position: fixed;
  bottom: -106px;
  width: 100vw;
  transition: all 0.25s ease-out 0s;
  color: #ffffff; }
  .operator-controls__wrapper.open {
    bottom: 0;
    z-index: 10002 !important; }
  @media (max-width: 720px) {
    .operator-controls__wrapper {
      bottom: -44px; } }

.operator-controls__buttons-wrapper {
  display: flex;
  margin-left: 57px; }

.operator-controls__button {
  height: 31px;
  -webkit-transform: translateY(1px);
          transform: translateY(1px);
  font-size: 16px;
  background-color: #0000ff;
  width: 162px;
  margin-right: 8px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center; }
  .operator-controls__button.disabled {
    color: #ffffff80;
    cursor: not-allowed;
    height: 31px;
    -webkit-transform: translateY(0);
            transform: translateY(0); }

.operator-controls__panel {
  display: flex;
  justify-content: space-between;
  height: 106px;
  width: 100%;
  border-top: solid #a7a7a7 1px;
  background-color: #0000ff; }
  .operator-controls__panel.deep {
    height: 306px; }
  .operator-controls__panel .ant-tabs {
    -webkit-transform: translateY(-36px);
            transform: translateY(-36px); }
  .operator-controls__panel .ant-tabs-content {
    height: 100% !important; }
  .operator-controls__panel textarea {
    border: none;
    padding: 0 46px; }
    .operator-controls__panel textarea.ant-input {
      font-weight: 600; }
  .operator-controls__panel .ant-tabs-nav-wrap {
    padding-left: 46px; }
  .operator-controls__panel .ant-tabs-tab {
    color: #ffffffcc; }
  .operator-controls__panel .ant-tabs-tab-active {
    color: #ffffff; }
    .operator-controls__panel .ant-tabs-tab-active .ant-tabs-tab-btn {
      font-weight: bold; }
  .operator-controls__panel .ant-tabs-ink-bar {
    background: #ffffff; }
  .operator-controls__panel .ant-tabs-top > .ant-tabs-nav::before {
    border-color: #ffffffcc; }

.operator-controls__panel-list {
  display: flex;
  margin: 0 35px; }

.operator-controls__panel-item {
  display: flex;
  font-size: 21px;
  font-family: NHaasGroteskDSPro-95Blk;
  text-align: center;
  cursor: pointer;
  align-items: center;
  margin: 0 35px; }

.operator-controls__publish-button {
  color: #ffffff;
  background-color: #ff0000;
  border: 1px solid #ff0000;
  border-radius: 10px;
  height: 50px;
  width: 145px;
  margin-right: 37px;
  text-transform: uppercase; }
  .operator-controls__publish-button.small {
    height: 37px;
    width: 99px;
    margin-top: 8px;
    margin-right: 12px;
    font-size: 1rem; }

.operator-controls__save-button {
  color: #ffffff;
  background-color: #288500;
  border: 1px solid #288500;
  font-weight: bold; }
  .operator-controls__save-button.confirm {
    width: 101px; }

.operator-controls__modal .ant-collapse,
.operator-controls__modal .ant-collapse-item,
.operator-controls__modal .ant-collapse-header,
.operator-controls__modal .ant-collapse-content {
  color: #ffffff !important; }

.operator-controls__modal .ant-table-container {
  background-color: #0000ff; }

.operator-controls__modal .ant-pagination,
.operator-controls__modal .ant-pagination-item,
.operator-controls__modal .ant-pagination-next,
.operator-controls__modal .ant-pagination-item-active,
.operator-controls__modal .ant-pagination-item-active :focus {
  color: #ffffff;
  background-color: transparent; }
  .operator-controls__modal .ant-pagination .anticon,
  .operator-controls__modal .ant-pagination-item .anticon,
  .operator-controls__modal .ant-pagination-next .anticon,
  .operator-controls__modal .ant-pagination-item-active .anticon,
  .operator-controls__modal .ant-pagination-item-active :focus .anticon {
    color: #ffffff; }

.operator-controls__modal .ant-pagination-item-active {
  font-weight: bolder;
  border-color: #ffffff; }

.operator-controls__modal .ant-pagination-item:focus a,
.operator-controls__modal .ant-pagination-item:hover a {
  font-weight: bolder;
  color: #ffffff; }

.operator-controls__modal .ant-collapse-header {
  text-align: center; }

.operator-controls__modal .ant-collapse-item-disabled {
  cursor: auto !important; }

.operator-controls__modal .ant-collapse .ant-collapse-item-disabled > .ant-collapse-header {
  cursor: auto; }

.operator-controls__modal .ant-select-selector {
  background-color: #ffffff !important; }

.operator-controls__modal .ant-input {
  color: #0a0a0a !important;
  background-color: #ffffff !important; }

.operator-controls__modal .ant-radio,
.operator-controls__modal .ant-radio-wrapper,
.operator-controls__modal .ant-radio-group {
  color: #ffffff; }

.operator-controls__modal .ant-radio-inner {
  background-color: #0a0a0a; }

.operator-controls__modal .ant-radio-checked .ant-radio-inner {
  border-color: #0a0a0a; }

.operator-controls__modal .ant-radio-inner::after {
  background-color: #ffffff; }

.operator-controls__modal .ant-radio-wrapper-checked {
  border: 1px solid #c4c4c4; }

.operator-controls__modal .ant-radio-group {
  width: 100%; }

.operator-controls__modal .ant-radio-wrapper {
  display: block;
  padding: 12px; }

.operator-controls__modal .ant-divider-with-text,
.operator-controls__modal .ant-divider-horizontal,
.operator-controls__modal .ant-divider,
.operator-controls__modal .ant-divider-inner-text {
  color: #ffffff80 !important;
  font-size: small !important;
  font-weight: 600;
  border-color: #ffffff80 !important; }

.operator-controls__modal .ant-divider-with-text::after,
.operator-controls__modal .ant-divider-with-text::before {
  border-color: #ffffff80 !important; }

.operator-controls__modal .ant-divider-inner-text {
  width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.operator-controls__modal .rc-color-picker-trigger {
  width: 20px;
  height: 20px;
  border: 1px solid #322d2d99;
  border-radius: 38px; }

.operator-controls__modal .ReactModal__Content {
  background-color: #0000ff !important;
  color: #ffffff !important;
  padding-top: 20px !important;
  margin-bottom: 136px !important;
  max-width: calc(100vw - 2rem) !important;
  max-height: calc(100vh - 160px) !important;
  z-index: 10001 !important; }
  .operator-controls__modal .ReactModal__Content .action_notification-image {
    fill: #ffffff !important; }

.operator-controls__modal.extended .ReactModal__Content {
  height: calc(100vh - 160px) !important; }

.operator-controls__modal.add-theme .ReactModal__Content {
  width: 532px !important; }

.operator-controls__modal .ReactModal__Overlay {
  position: fixed;
  z-index: 10001 !important;
  background-color: transparent !important;
  color: #ffffff !important; }

.operator-controls__modal-open #root {
  -webkit-filter: none !important;
          filter: none !important;
  background-color: transparent !important; }

.operator-controls__modal-open {
  overflow: hidden; }

.operator-controls__all-strings-header {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .operator-controls__all-strings-header .ant-input-suffix {
    display: none !important; }
  .operator-controls__all-strings-header .ant-input-search {
    border-bottom: 1px solid white; }
  .operator-controls__all-strings-header .ant-input-search,
  .operator-controls__all-strings-header .ant-input {
    color: #ffffff !important;
    background-color: #0000ff !important; }

.operator-controls__modal-title {
  font-size: 28px;
  font-weight: bold;
  font-family: NHaasGroteskDSPro-95Blk; }

.operator-controls__all-strings-settings-button {
  color: #ffffff !important;
  border-color: #ffffff !important; }

.operator-controls__table .ant-select-arrow,
.operator-controls__table .ant-select-selector {
  color: #ffffff !important;
  background-color: #0000ff !important;
  font-weight: bold; }

.operator-controls__tabletable {
  border-collapse: collapse !important; }

.operator-controls__table thead > tr > th,
.operator-controls__table tbody > tr > td {
  background-color: #0000ff !important;
  color: #ffffff !important; }

.operator-controls__table tbody > tr > td {
  border: none !important; }

.operator-button_underline.ant-btn > span {
  text-decoration: underline; }

.operator-button_underline.ant-btn-link {
  padding: 4px 8px;
  color: white; }

.sortable_section {
  cursor: grab; }
  .sortable_section td {
    cursor: grab; }
    .sortable_section td:active {
      cursor: grabbing; }
  .sortable_section:active {
    cursor: grabbing; }

.init-container {
  flex: 1 1;
  height: 100%; }
  .init-container input {
    border: 1px solid #c4c4c4; }
  .init-container input:focus {
    border: 1px solid #ffffff;
    outline: 1px solid #ffffff; }
  .init-container .message {
    color: #ffffff;
    width: 100%;
    text-align: center;
    position: absolute;
    top: 0;
    padding: 3px; }
  .init-container .success {
    background-color: #288500; }
  .init-container .error {
    background-color: #ff0000; }
  .init-container .header {
    font-size: 40px;
    font-weight: bold;
    margin: 1rem 0rem; }
  .init-container .description {
    font-size: 16px; }
    .init-container .description .desc-warning {
      color: #f28041; }
  .init-container .btn-container {
    margin: 2rem 0rem; }
  .init-container .ant-btn {
    background-color: #c4c4c4;
    color: #0000ff;
    font-size: 16px;
    font-weight: bold;
    width: 100%; }
  .init-container .loading_container {
    align-items: center;
    background-color: var(--labels_secondary-inactive-label-text-graphics);
    display: flex;
    height: 100%;
    justify-content: center; }
    .init-container .loading_container .loading-content {
      color: #ffffff; }
    .init-container .loading_container .delayed-show {
      visibility: hidden;
      -webkit-animation: 10s ease forwards delayedShow;
              animation: 10s ease forwards delayedShow; }
      .init-container .loading_container .delayed-show.dot-1 {
        -webkit-animation-delay: 0.5s;
                animation-delay: 0.5s; }
      .init-container .loading_container .delayed-show.dot-2 {
        -webkit-animation-delay: 1s;
                animation-delay: 1s; }
      .init-container .loading_container .delayed-show.dot-3 {
        -webkit-animation-delay: 1.5s;
                animation-delay: 1.5s; }

@-webkit-keyframes delayedShow {
  from {
    visibility: hidden; }
  to {
    visibility: visible; } }

@keyframes delayedShow {
  from {
    visibility: hidden; }
  to {
    visibility: visible; } }
  .init-container .setup-container,
  .init-container .welcome-screen {
    align-items: center;
    background-color: #0808df;
    display: flex;
    height: 100%;
    justify-content: center;
    flex-direction: column; }
  .init-container .welcome-screen .footer {
    color: #c4c4c4;
    font-size: 10px;
    text-align: center;
    -webkit-animation-name: content-animate;
            animation-name: content-animate;
    -webkit-animation-duration: 0.1s;
            animation-duration: 0.1s;
    position: absolute;
    bottom: 10px; }
    .init-container .welcome-screen .footer a {
      -webkit-text-decoration-line: underline;
              text-decoration-line: underline;
      font-weight: bold; }
  .init-container .welcome-screen .content {
    color: #c4c4c4;
    text-align: center;
    width: 70rem; }
    .init-container .welcome-screen .content .logo {
      height: 4rem;
      width: 4rem;
      margin: auto;
      position: relative;
      -webkit-animation-name: image-animate;
              animation-name: image-animate;
      -webkit-animation-duration: 2s;
              animation-duration: 2s;
      z-index: 2; }
    .init-container .welcome-screen .content .logo-box {
      width: 6rem;
      height: 6rem; }

@-webkit-keyframes image-animate {
  0% {
    background-color: #c4c4c4;
    top: 0rem;
    width: 50rem;
    height: 50rem; }
  25% {
    background-color: #c4c4c4;
    top: 0rem;
    width: 4rem;
    height: 4rem; }
  50% {
    background-color: #c4c4c4;
    top: 0rem; }
  75% {
    background-color: #c4c4c4;
    top: 10rem; }
  100% {
    background-color: #c4c4c4;
    top: 0rem; } }

@keyframes image-animate {
  0% {
    background-color: #c4c4c4;
    top: 0rem;
    width: 50rem;
    height: 50rem; }
  25% {
    background-color: #c4c4c4;
    top: 0rem;
    width: 4rem;
    height: 4rem; }
  50% {
    background-color: #c4c4c4;
    top: 0rem; }
  75% {
    background-color: #c4c4c4;
    top: 10rem; }
  100% {
    background-color: #c4c4c4;
    top: 0rem; } }
    .init-container .welcome-screen .content .body-content {
      -webkit-animation-name: content-animate;
              animation-name: content-animate;
      -webkit-animation-duration: 2s;
              animation-duration: 2s;
      overflow-y: auto; }

@-webkit-keyframes content-animate {
  0% {
    height: 0rem; }
  25% {
    height: 0rem; }
  50% {
    height: 0rem; }
  75% {
    height: 0rem; }
  100% {
    height: 25rem; } }

@keyframes content-animate {
  0% {
    height: 0rem; }
  25% {
    height: 0rem; }
  50% {
    height: 0rem; }
  75% {
    height: 0rem; }
  100% {
    height: 25rem; } }
    .init-container .welcome-screen .content .ant-btn {
      width: 22rem; }
  .init-container .setup-container .info-container {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .init-container .setup-container .email-icon {
    width: 10rem; }
  .init-container .setup-container .map-icon {
    width: 15rem;
    opacity: 0.6; }
  .init-container .setup-container .wrapper {
    align-items: center;
    color: #c4c4c4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 70rem; }
  .init-container .setup-container .setup-field-wrapper {
    margin: 3rem 0rem 1rem 0rem; }
  .init-container .setup-container .setup-field-content {
    text-align: left;
    width: 22rem; }
    .init-container .setup-container .setup-field-content .input_field label {
      font-size: 15px;
      color: #ffffff; }
    .init-container .setup-container .setup-field-content .ant-input-affix-wrapper,
    .init-container .setup-container .setup-field-content .ant-input,
    .init-container .setup-container .setup-field-content .ant-input-number {
      background-color: #0000ff;
      color: #c4c4c4; }
      .init-container .setup-container .setup-field-content .ant-input-affix-wrapper:hover, .init-container .setup-container .setup-field-content .ant-input-affix-wrapper:focus,
      .init-container .setup-container .setup-field-content .ant-input:hover,
      .init-container .setup-container .setup-field-content .ant-input:focus,
      .init-container .setup-container .setup-field-content .ant-input-number:hover,
      .init-container .setup-container .setup-field-content .ant-input-number:focus {
        border-color: #c4c4c4;
        box-shadow: #c4c4c4; }
    .init-container .setup-container .setup-field-content .ant-input-password-icon {
      color: #c4c4c4; }
  .init-container .setup-container .setup-field-label {
    font-size: 15px;
    color: #ffffff;
    margin-bottom: 0.25rem; }
  .init-container .setup-container .btn-wrapper {
    display: flex; }
    .init-container .setup-container .btn-wrapper .separator {
      margin: 0.5rem; }
    .init-container .setup-container .btn-wrapper .ant-btn {
      flex: 1 1; }

@media screen and (max-width: 1096px) and (min-width: 768px) {
  .init-container .setup-container .setup-field-wrapper .captcha-wrapper {
    height: 5.5rem; } }

@media screen and (max-width: 334px) and (min-width: 280px) {
  .init-container .setup-container .setup-field-wrapper .captcha-wrapper {
    height: 7rem; }
  #rc-imageselect,
  .g-recaptcha {
    transform: scale(0.6);
    -webkit-transform: scale(0.6);
    transform-origin: 0 0;
    -webkit-transform-origin: 0% 0%; } }

@media screen and (max-width: 460px) and (min-width: 335px) {
  .init-container .setup-container .setup-field-wrapper .captcha-wrapper {
    height: 7rem; }
  #rc-imageselect,
  .g-recaptcha {
    transform: scale(0.62);
    -webkit-transform: scale(0.62);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0; } }

.sidebar-fit-to-screen {
  height: 100vh !important; }

.sidebar-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%; }
  .sidebar-container .sidebar-row {
    border-top: 1px solid var(--calculated_secondary-border);
    flex: 0 1; }
  .sidebar-container .sidebar-notifications {
    flex: 1 1;
    overflow-y: scroll; }
    .sidebar-container .sidebar-notifications .sidebar-title {
      font-size: 1.3rem;
      font-weight: bold; }

.sidebar-notifications-wrapper {
  overflow-y: scroll; }

.sidebar-row {
  min-height: 3rem;
  min-width: 10rem; }
  .sidebar-row .cell-wrapper {
    padding-left: 1rem;
    padding-right: 1rem; }
  .sidebar-row .sidebar-row--right-icon {
    width: 1.5rem;
    height: 1.5rem; }
    .sidebar-row .sidebar-row--right-icon.icon--help:hover svg path {
      fill: var(--labels_important-active-labels-text-graphics) !important;
      stroke: var(--labels_important-active-labels-text-graphics) !important; }
    .sidebar-row .sidebar-row--right-icon.icon--help svg path {
      fill: var(--labels_secondary-inactive-label-text-graphics) !important;
      stroke: var(--labels_secondary-inactive-label-text-graphics) !important; }
    .sidebar-row .sidebar-row--right-icon.icon--help circle {
      fill: transparent;
      stroke: transparent; }
    .sidebar-row .sidebar-row--right-icon.icon--logout:hover svg path {
      fill: var(--labels_important-active-labels-text-graphics) !important;
      stroke: var(--labels_important-active-labels-text-graphics) !important; }
    .sidebar-row .sidebar-row--right-icon.icon--logout svg path {
      fill: var(--labels_secondary-inactive-label-text-graphics) !important;
      stroke: var(--labels_secondary-inactive-label-text-graphics) !important; }
    .sidebar-row .sidebar-row--right-icon.icon--chat:hover svg path {
      fill: var(--labels_important-active-labels-text-graphics) !important;
      stroke: var(--labels_important-active-labels-text-graphics) !important; }
    .sidebar-row .sidebar-row--right-icon.icon--chat svg path {
      fill: var(--labels_secondary-inactive-label-text-graphics) !important;
      stroke: var(--labels_secondary-inactive-label-text-graphics) !important; }

.app_bar .sidebar-row {
  min-width: 3rem; }

.sidebar-bottom-wrapper {
  min-height: 6rem;
  max-height: 6rem;
  background-color: var(--base_top-bar-navigation);
  color: var(--calculated_base_top-bar-navigation_text);
  width: 100%;
  padding: 0.5rem 0.5rem 0.8rem 0.5rem;
  justify-content: space-evenly; }
  .sidebar-bottom-wrapper .sidebar-bottom-button {
    justify-content: space-between;
    flex-direction: column;
    display: flex;
    align-items: center;
    width: 20%; }
    .sidebar-bottom-wrapper .sidebar-bottom-button .sidebar-bottom-icon svg .post-icon,
    .sidebar-bottom-wrapper .sidebar-bottom-button .sidebar-bottom-icon svg .chat-0,
    .sidebar-bottom-wrapper .sidebar-bottom-button .sidebar-bottom-icon svg .account-svg-1,
    .sidebar-bottom-wrapper .sidebar-bottom-button .sidebar-bottom-icon svg .quick-trade-tab-active {
      fill: var(--calculated_base_top-bar-navigation_text-inactive); }
    .sidebar-bottom-wrapper .sidebar-bottom-button .sidebar-bottom-icon svg .tab-wallet0,
    .sidebar-bottom-wrapper .sidebar-bottom-button .sidebar-bottom-icon svg .trade-active-2,
    .sidebar-bottom-wrapper .sidebar-bottom-button .sidebar-bottom-icon svg .chat-0,
    .sidebar-bottom-wrapper .sidebar-bottom-button .sidebar-bottom-icon svg .quick-trade-tab-active {
      stroke: var(--calculated_base_top-bar-navigation_text-inactive); }
    .sidebar-bottom-wrapper .sidebar-bottom-button .sidebar-bottom-icon svg .tab-wallet0,
    .sidebar-bottom-wrapper .sidebar-bottom-button .sidebar-bottom-icon svg .quick-trade-tab-active {
      fill: transparent; }
    .sidebar-bottom-wrapper .sidebar-bottom-button.active .sidebar-bottom-icon svg .post-icon,
    .sidebar-bottom-wrapper .sidebar-bottom-button.active .sidebar-bottom-icon svg .chat-0,
    .sidebar-bottom-wrapper .sidebar-bottom-button.active .sidebar-bottom-icon svg .account-svg-1 {
      fill: var(--calculated_base_top-bar-navigation_text); }
    .sidebar-bottom-wrapper .sidebar-bottom-button.active .sidebar-bottom-icon svg .tab-wallet0,
    .sidebar-bottom-wrapper .sidebar-bottom-button.active .sidebar-bottom-icon svg .trade-active-2,
    .sidebar-bottom-wrapper .sidebar-bottom-button.active .sidebar-bottom-icon svg .chat-0,
    .sidebar-bottom-wrapper .sidebar-bottom-button.active .sidebar-bottom-icon svg .quick-trade-tab-active {
      stroke: var(--calculated_base_top-bar-navigation_text); }
    .sidebar-bottom-wrapper .sidebar-bottom-button .bottom-bar-text {
      font-size: 10px;
      text-align: center;
      margin-top: -17px;
      font-weight: lighter;
      color: var(--calculated_base_top-bar-navigation_text-inactive);
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis; }
    .sidebar-bottom-wrapper .sidebar-bottom-button .bottom-text-acttive {
      color: var(--calculated_base_top-bar-navigation_text) !important;
      font-weight: bolder; }
    .sidebar-bottom-wrapper .sidebar-bottom-button .sidebar-bottom-icon {
      width: 3rem;
      height: 3rem; }
      .sidebar-bottom-wrapper .sidebar-bottom-button .sidebar-bottom-icon > div {
        display: flex;
        justify-content: center; }
      .sidebar-bottom-wrapper .sidebar-bottom-button .sidebar-bottom-icon svg {
        width: 3rem;
        height: 3rem;
        stroke: var(--labels_secondary-inactive-label-text-graphics);
        fill: transparent; }
    .sidebar-bottom-wrapper .sidebar-bottom-button img.sidebar-bottom-icon {
      width: 3rem;
      height: 3rem; }

.direction_ltr .sidebar-row .cell-wrapper:not(:last-child) {
  border-right: 1px solid var(--calculated_secondary-border); }

.direction_rtl .sidebar-row .cell-wrapper:not(:last-child) {
  border-left: 1px solid var(--calculated_secondary-border); }

@media screen and (max-height: 600px) {
  .sidebar-container .sidebar_hub-wrapper {
    max-height: 35rem !important; }
  .sidebar-container .sidebar-notifications {
    min-height: 20rem; }
  .sidebar-bottom-icon svg {
    stroke: none !important; } }

.tab_controller-title {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }
  .tab_controller-title .tab_controller-title-icon {
    width: 6rem;
    height: 6rem; }
  .tab_controller-title .tab_controller-title-text {
    font-size: 4rem; }

.layout-mobile .tab_controller-tabs,
.layout-mobile .tab_item {
  font-size: 1.5rem !important; }

.tab_controller-tabs {
  width: 100%;
  display: flex;
  align-content: center;
  border-bottom: 2px solid var(--calculated_secondary-border);
  margin-bottom: 1rem; }
  .tab_controller-tabs .tab_item {
    border-bottom: var(--labels_important-active-labels-text-graphics) solid transparent;
    padding: 0 8px 8px;
    display: flex;
    align-items: center;
    min-width: 8rem;
    justify-content: center;
    color: var(--labels_secondary-inactive-label-text-graphics);
    font-size: 14px; }
    .tab_controller-tabs .tab_item svg path {
      opacity: 0.7; }
  .tab_controller-tabs .tab-trade {
    padding: 0 1px 12px !important; }
  .tab_controller-tabs .tab_item-active {
    border-bottom: 4px solid var(--labels_important-active-labels-text-graphics);
    color: var(--labels_important-active-labels-text-graphics); }
    .tab_controller-tabs .tab_item-active svg path {
      opacity: 1; }
      .tab_controller-tabs .tab_item-active svg path .st0,
      .tab_controller-tabs .tab_item-active svg path .g1_4,
      .tab_controller-tabs .tab_item-active svg path .g1_2 {
        fill: var(--labels_important-active-labels-text-graphics) !important; }
      .tab_controller-tabs .tab_item-active svg path .st0 {
        stroke: var(--labels_important-active-labels-text-graphics) !important; }

.layout-mobile .transaction-history-wrapper {
  padding-top: 0 !important; }

.app_bar {
  position: relative;
  width: 100%;
  min-height: 64px;
  height: 64px;
  font-size: 1rem;
  font-weight: bold;
  background: var(--base_top-bar-navigation);
  border-bottom: 1px solid var(--calculated_super-pale_label-text-graphics); }
  .app_bar.no-borders {
    border-bottom: none; }
    .app_bar.no-borders > div:not(:first-child) {
      border-left: none; }
  .app_bar > div {
    display: flex;
    align-items: center;
    padding: 0 8px; }
  .app_bar .app_bar-icon-logo {
    height: auto;
    width: 185px;
    background-size: contain;
    background-position: left center;
    background-repeat: no-repeat; }
  .app_bar .app_bar-icon {
    flex: 0 1;
    padding-right: 8px;
    width: 100%; }
  .app_bar .app_bar-main {
    flex-grow: 1; }
  .app_bar .app_bar-controllers-splash {
    padding: 0; }
    .app_bar .app_bar-controllers-splash .action_button {
      min-width: 7.5rem;
      height: 100%;
      padding: 0 0.5rem; }
  .app_bar .bar-logout {
    max-height: 64px;
    min-height: 64px; }
  .app_bar .app_bar-quicktrade-container {
    margin: 0 0.25rem; }
  .app_bar .app_bar-quicktrade {
    border: 1px solid var(--calculated_base_top-bar-navigation_text-inactive);
    border-radius: 0.35rem;
    color: var(--calculated_base_top-bar-navigation_text-inactive);
    font-weight: 400;
    margin: 0 6px;
    padding: 0 0.4rem;
    text-transform: capitalize;
    white-space: nowrap; }
    .app_bar .app_bar-quicktrade svg .trade-active-2,
    .app_bar .app_bar-quicktrade svg .st0 {
      fill: var(--labels_secondary-inactive-label-text-graphics) !important;
      stroke: var(--calculated_base_top-bar-navigation_text-inactive) !important;
      stroke-width: 3 !important; }
    .app_bar .app_bar-quicktrade svg .admin-dash {
      fill: var(--calculated_base_top-bar-navigation_text-inactive) !important; }
  .app_bar .overflow {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }
  .app_bar .quick_trade-active {
    border: 1px solid var(--calculated_base_top-bar-navigation_text);
    color: var(--calculated_base_top-bar-navigation_text); }
    .app_bar .quick_trade-active svg .trade-active-2,
    .app_bar .quick_trade-active svg .st0 {
      fill: var(--calculated_base_top-bar-navigation_text) !important;
      stroke: var(--calculated_base_top-bar-navigation_text) !important;
      stroke-width: 3 !important; }
  .app_bar .quicktrade_icon {
    margin-top: 0.2rem;
    width: 1rem; }
  .app_bar .app-bar-account {
    color: var(--calculated_base_top-bar-navigation_text);
    cursor: pointer;
    margin-right: 0.8rem; }
    .app_bar .app-bar-account svg .account-inactive {
      fill: var(--calculated_base_top-bar-navigation_text-inactive);
      stroke: var(--calculated_base_top-bar-navigation_text-inactive); }
  .app_bar .app_bar-pair-overflow {
    align-items: center;
    color: var(--calculated_base_top-bar-navigation_text-inactive);
    cursor: pointer;
    display: flex;
    font-size: 14px;
    font-weight: 400;
    height: 100%;
    padding: 0 0.5rem;
    position: relative; }
  .app_bar .active-tab-overflow {
    background-color: var(--base_background);
    border-left: 1px solid var(--base_background);
    color: var(--labels_secondary-inactive-label-text-graphics);
    top: 1px; }
    .app_bar .active-tab-overflow svg .double-arrow {
      stroke: var(--labels_important-active-labels-text-graphics); }
  .app_bar .app-bar-currency-icon {
    margin: 0.5rem 0.3rem 0 0.3rem;
    width: 1.5rem; }
  .app_bar .app-bar-account-icon {
    width: 1.5rem; }
  .app_bar .app-bar-account-moon-icon {
    margin: 0.2rem 0.3rem 0 0.3rem;
    width: 1rem; }
    .app_bar .app-bar-account-moon-icon svg .moon-icon,
    .app_bar .app-bar-account-moon-icon svg .sun-icon {
      fill: var(--calculated_base_top-bar-navigation_text-inactive); }
  .app_bar .app-bar-account-moon-content {
    border-radius: 5px; }
  .app_bar .toggle_button-wrapper .toggle-content {
    color: var(--calculated_base_top-bar-navigation_text);
    border-radius: 0.35rem;
    padding: 0px 0.1rem; }
    .app_bar .toggle_button-wrapper .toggle-content .dark .moon-icon {
      fill: var(--calculated_base_top-bar-navigation_text); }
    .app_bar .toggle_button-wrapper .toggle-content .white .sun-icon {
      fill: var(--calculated_base_top-bar-navigation_text); }
    .app_bar .toggle_button-wrapper .toggle-content .toggle-action_button {
      border-color: var(--calculated_base_top-bar-navigation_text);
      width: 34px;
      height: 75.5%;
      margin: 0;
      cursor: pointer; }
      .app_bar .toggle_button-wrapper .toggle-content .toggle-action_button.left .toggle-action_button-display {
        right: calc(100% - 14px); }
      .app_bar .toggle_button-wrapper .toggle-content .toggle-action_button.right .toggle-action_button-display {
        right: -1px; }
      .app_bar .toggle_button-wrapper .toggle-content .toggle-action_button .toggle-action_button-display {
        background: var(--calculated_base_top-bar-navigation_text);
        height: 9px;
        width: 9px;
        margin: 1.2px 3px 1px 0 !important; }
  .app_bar .app_bar-pair-content .btc0,
  .app_bar .app_bar-pair-content .btc1,
  .app_bar .app_bar-pair-content .eth0,
  .app_bar .app_bar-pair-content .eth1,
  .app_bar .app_bar-pair-content .bch-icon,
  .app_bar .app_bar-pair-content .xrp-icon,
  .app_bar .app_bar-pair-content .xht-icon,
  .app_bar .app_bar-pair-content .xmr-icon,
  .app_bar .app_bar-pair-content .xlm-icon,
  .app_bar .app_bar-pair-content .eos-icon,
  .app_bar .app_bar-pair-content .trx-icon,
  .app_bar .app_bar-pair-content .ada-icon,
  .app_bar .app_bar-pair-content .bnb-icon,
  .app_bar .app_bar-pair-content .tusd-icon,
  .app_bar .app_bar-pair-content .dai-icon,
  .app_bar .app_bar-pair-content .ltc-icon,
  .app_bar .app-bar-tab-close .btc0,
  .app_bar .app-bar-tab-close .btc1,
  .app_bar .app-bar-tab-close .eth0,
  .app_bar .app-bar-tab-close .eth1,
  .app_bar .app-bar-tab-close .bch-icon,
  .app_bar .app-bar-tab-close .xrp-icon,
  .app_bar .app-bar-tab-close .xht-icon,
  .app_bar .app-bar-tab-close .xmr-icon,
  .app_bar .app-bar-tab-close .xlm-icon,
  .app_bar .app-bar-tab-close .eos-icon,
  .app_bar .app-bar-tab-close .trx-icon,
  .app_bar .app-bar-tab-close .ada-icon,
  .app_bar .app-bar-tab-close .bnb-icon,
  .app_bar .app-bar-tab-close .tusd-icon,
  .app_bar .app-bar-tab-close .dai-icon,
  .app_bar .app-bar-tab-close .ltc-icon,
  .app_bar .app-bar-currency-icon .btc0,
  .app_bar .app-bar-currency-icon .btc1,
  .app_bar .app-bar-currency-icon .eth0,
  .app_bar .app-bar-currency-icon .eth1,
  .app_bar .app-bar-currency-icon .bch-icon,
  .app_bar .app-bar-currency-icon .xrp-icon,
  .app_bar .app-bar-currency-icon .xht-icon,
  .app_bar .app-bar-currency-icon .xmr-icon,
  .app_bar .app-bar-currency-icon .xlm-icon,
  .app_bar .app-bar-currency-icon .eos-icon,
  .app_bar .app-bar-currency-icon .trx-icon,
  .app_bar .app-bar-currency-icon .ada-icon,
  .app_bar .app-bar-currency-icon .bnb-icon,
  .app_bar .app-bar-currency-icon .tusd-icon,
  .app_bar .app-bar-currency-icon .dai-icon,
  .app_bar .app-bar-currency-icon .ltc-icon,
  .app_bar .app-bar-account-icon svg .btc0,
  .app_bar .app-bar-account-icon svg .btc1,
  .app_bar .app-bar-account-icon svg .eth0,
  .app_bar .app-bar-account-icon svg .eth1,
  .app_bar .app-bar-account-icon svg .bch-icon,
  .app_bar .app-bar-account-icon svg .xrp-icon,
  .app_bar .app-bar-account-icon svg .xht-icon,
  .app_bar .app-bar-account-icon svg .xmr-icon,
  .app_bar .app-bar-account-icon svg .xlm-icon,
  .app_bar .app-bar-account-icon svg .eos-icon,
  .app_bar .app-bar-account-icon svg .trx-icon,
  .app_bar .app-bar-account-icon svg .ada-icon,
  .app_bar .app-bar-account-icon svg .bnb-icon,
  .app_bar .app-bar-account-icon svg .tusd-icon,
  .app_bar .app-bar-account-icon svg .dai-icon,
  .app_bar .app-bar-account-icon svg .ltc-icon {
    fill: var(--calculated_base_top-bar-navigation_text-inactive); }
  .app_bar .app_bar-pair-content .tab-close,
  .app_bar .app_bar-pair-content .plus,
  .app_bar .app-bar-tab-close .tab-close,
  .app_bar .app-bar-tab-close .plus,
  .app_bar .app-bar-currency-icon .tab-close,
  .app_bar .app-bar-currency-icon .plus,
  .app_bar .app-bar-account-icon svg .tab-close,
  .app_bar .app-bar-account-icon svg .plus {
    stroke: var(--calculated_base_top-bar-navigation_text-inactive); }
  .app_bar .app_bar-pair-content .st0,
  .app_bar .app-bar-tab-close .st0,
  .app_bar .app-bar-currency-icon .st0,
  .app_bar .app-bar-account-icon svg .st0 {
    fill: var(--calculated_base_top-bar-navigation_text) !important;
    stroke-width: 0rem; }
  .app_bar .app-bar-account-icon.mr-3 svg {
    margin-top: 5px; }
  .app_bar .account-inactive .app-bar-account-icon svg .st0 {
    fill: var(--calculated_base_top-bar-navigation_text-inactive) !important; }
  .app_bar .app_bar-currency-txt {
    font-weight: 600; }
  .app_bar .app-price-diff-down {
    color: var(--trading_selling-related-elements); }
  .app_bar .app-price-diff-up {
    color: var(--trading_buying-related-elements); }
  .app_bar .app-bar-price_diff_up {
    margin-left: 0.5rem; }
  .app_bar .app-bar-price_diff_up:before {
    content: "";
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-top: none !important;
    border-left: 0.4rem solid transparent !important;
    border-right: 0.4rem solid transparent !important;
    border-bottom: 0.4rem solid var(--trading_buying-related-elements);
    margin-bottom: 0rem;
    display: inline-block;
    margin-bottom: 0.2rem;
    margin-right: 0.5rem;
    position: relative;
    top: calc(50% - 0.6rem); }
  .app_bar .app-bar-price_diff_down {
    margin-left: 0.5rem; }
  .app_bar .app-bar-price_diff_down:before {
    content: "";
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-bottom: none !important;
    border-left: 0.4rem solid transparent !important;
    border-right: 0.4rem solid transparent !important;
    border-top: 0.4rem solid var(--trading_selling-related-elements);
    margin-top: 0rem;
    display: inline-block;
    margin-right: 0.5rem;
    position: relative;
    top: calc(50% - 0.6rem); }
  .app_bar .app-bar-account-content {
    align-items: center;
    justify-content: center;
    position: relative;
    color: var(--calculated_base_top-bar-navigation_text-inactive); }
  .app_bar .app-announcement-list {
    height: 335px;
    width: 280px; }
  .app_bar .app-bar-account-notification {
    position: absolute;
    left: 15px;
    bottom: 12px;
    height: 1.2rem;
    width: 1.2rem;
    border-radius: 1.2rem;
    background-color: var(--specials_notifications-alerts-warnings);
    color: var(--calculated_specials_notifications-alerts-text);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    line-height: 1;
    font-weight: bold; }
  .app_bar .app-bar-account-menu {
    display: block;
    position: absolute;
    top: 36px;
    min-width: 10rem;
    min-height: 8rem;
    background-color: var(--base_background);
    z-index: 1052;
    padding: 1rem;
    border-top: 2px solid var(--specials_buttons-links-and-highlights);
    box-shadow: 0px 4px 6px 1px rgba(0, 0, 0, 0.4); }
    .app_bar .app-bar-account-menu .app-bar-account-menu-list {
      color: var(--labels_secondary-inactive-label-text-graphics);
      cursor: pointer;
      padding: 0.2rem 0; }
    .app_bar .app-bar-account-menu .app-bar-account-list-icon {
      margin-right: 0.4rem;
      width: 1rem; }
      .app_bar .app-bar-account-menu .app-bar-account-list-icon svg .trade-active-2 {
        stroke: var(--labels_secondary-inactive-label-text-graphics); }
      .app_bar .app-bar-account-menu .app-bar-account-list-icon svg .trade-active-2,
      .app_bar .app-bar-account-menu .app-bar-account-list-icon svg .quick-trade-tab-active,
      .app_bar .app-bar-account-menu .app-bar-account-list-icon svg .tab-summary0,
      .app_bar .app-bar-account-menu .app-bar-account-list-icon svg .tab-wallet0,
      .app_bar .app-bar-account-menu .app-bar-account-list-icon svg .tab-setting0,
      .app_bar .app-bar-account-menu .app-bar-account-list-icon svg .tab-verify0,
      .app_bar .app-bar-account-menu .app-bar-account-list-icon svg .tab-security0,
      .app_bar .app-bar-account-menu .app-bar-account-list-icon svg .tab-history-st,
      .app_bar .app-bar-account-menu .app-bar-account-list-icon svg .help-question,
      .app_bar .app-bar-account-menu .app-bar-account-list-icon svg .tab-signout,
      .app_bar .app-bar-account-menu .app-bar-account-list-icon svg .tab-api0 {
        fill: var(--labels_secondary-inactive-label-text-graphics); }
    .app_bar .app-bar-account-menu .app-bar-account-list-icon svg .account-active-1 {
      fill: var(--calculated_base_top-bar-navigation_text-inactive); }
    .app_bar .app-bar-account-menu .menu-notification-active {
      color: var(--specials_notifications-alerts-warnings); }
      .app_bar .app-bar-account-menu .menu-notification-active svg .tab-summary0,
      .app_bar .app-bar-account-menu .menu-notification-active svg .tab-wallet0,
      .app_bar .app-bar-account-menu .menu-notification-active svg .tab-setting0,
      .app_bar .app-bar-account-menu .menu-notification-active svg .tab-verify0,
      .app_bar .app-bar-account-menu .menu-notification-active svg .tab-security0,
      .app_bar .app-bar-account-menu .menu-notification-active svg .tab-api0 {
        fill: var(--specials_notifications-alerts-warnings); }
    .app_bar .app-bar-account-menu .menu-active {
      color: var(--labels_important-active-labels-text-graphics); }
      .app_bar .app-bar-account-menu .menu-active svg .trade-active-2 {
        stroke: var(--labels_important-active-labels-text-graphics); }
      .app_bar .app-bar-account-menu .menu-active svg .trade-active-2,
      .app_bar .app-bar-account-menu .menu-active svg .quick-trade-tab-active,
      .app_bar .app-bar-account-menu .menu-active svg .tab-summary0,
      .app_bar .app-bar-account-menu .menu-active svg .tab-wallet0,
      .app_bar .app-bar-account-menu .menu-active svg .tab-setting0,
      .app_bar .app-bar-account-menu .menu-active svg .tab-verify0,
      .app_bar .app-bar-account-menu .menu-active svg .tab-security0,
      .app_bar .app-bar-account-menu .menu-active svg .tab-api0,
      .app_bar .app-bar-account-menu .menu-active svg .account-active-1 {
        fill: var(--labels_important-active-labels-text-graphics); }
    .app_bar .app-bar-account-menu .notification-content {
      width: 1.2rem; }
    .app_bar .app-bar-account-menu .notification {
      color: var(--specials_notifications-alerts-warnings); }
      .app_bar .app-bar-account-menu .notification svg .tab-summary0,
      .app_bar .app-bar-account-menu .notification svg .tab-wallet0,
      .app_bar .app-bar-account-menu .notification svg .tab-setting0,
      .app_bar .app-bar-account-menu .notification svg .tab-verify0,
      .app_bar .app-bar-account-menu .notification svg .tab-security0,
      .app_bar .app-bar-account-menu .notification svg .tab-api0 {
        fill: var(--specials_notifications-alerts-warnings); }
    .app_bar .app-bar-account-menu .security_notification,
    .app_bar .app-bar-account-menu .verification_notification,
    .app_bar .app-bar-account-menu .wallet_notification {
      color: var(--calculated_specials_notifications-alerts-inactive); }
      .app_bar .app-bar-account-menu .security_notification svg .tab-summary0,
      .app_bar .app-bar-account-menu .security_notification svg .tab-wallet0,
      .app_bar .app-bar-account-menu .security_notification svg .tab-setting0,
      .app_bar .app-bar-account-menu .security_notification svg .tab-verify0,
      .app_bar .app-bar-account-menu .security_notification svg .tab-security0,
      .app_bar .app-bar-account-menu .security_notification svg .tab-api0,
      .app_bar .app-bar-account-menu .verification_notification svg .tab-summary0,
      .app_bar .app-bar-account-menu .verification_notification svg .tab-wallet0,
      .app_bar .app-bar-account-menu .verification_notification svg .tab-setting0,
      .app_bar .app-bar-account-menu .verification_notification svg .tab-verify0,
      .app_bar .app-bar-account-menu .verification_notification svg .tab-security0,
      .app_bar .app-bar-account-menu .verification_notification svg .tab-api0,
      .app_bar .app-bar-account-menu .wallet_notification svg .tab-summary0,
      .app_bar .app-bar-account-menu .wallet_notification svg .tab-wallet0,
      .app_bar .app-bar-account-menu .wallet_notification svg .tab-setting0,
      .app_bar .app-bar-account-menu .wallet_notification svg .tab-verify0,
      .app_bar .app-bar-account-menu .wallet_notification svg .tab-security0,
      .app_bar .app-bar-account-menu .wallet_notification svg .tab-api0 {
        fill: var(--calculated_specials_notifications-alerts-inactive); }
    .app_bar .app-bar-account-menu .app-bar-account-menu-notification {
      position: absolute;
      right: -10px;
      top: -10px;
      height: 1.2rem;
      width: 1.2rem;
      border-radius: 1.2rem;
      background-color: var(--specials_notifications-alerts-warnings);
      color: var(--calculated_specials_notifications-alerts-text);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.8rem;
      line-height: 1;
      font-weight: bold; }
    .app_bar .app-bar-account-menu .security_selected,
    .app_bar .app-bar-account-menu .verification_selected,
    .app_bar .app-bar-account-menu .wallet_selected {
      background-color: var(--specials_notifications-alerts-warnings) !important; }
    .app_bar .app-bar-account-menu .security_selected_inactive,
    .app_bar .app-bar-account-menu .verification_selected_inactive,
    .app_bar .app-bar-account-menu .wallet_selected_inactive {
      background-color: var(--calculated_specials_notifications-alerts-inactive) !important; }
    .app_bar .app-bar-account-menu .app-bar-account-list-notification {
      position: relative;
      right: 0;
      bottom: 8px;
      height: 1.2rem;
      width: 1.2rem;
      border-radius: 1.2rem;
      background-color: var(--specials_notifications-alerts-warnings);
      color: var(--calculated_specials_notifications-alerts-text);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.8rem;
      line-height: 1;
      font-weight: bold; }
  .app_bar .login-container {
    background-color: var(--specials_buttons-links-and-highlights);
    padding: 2px 20px;
    border-radius: 3px;
    cursor: pointer;
    font-size: 11px; }

.home_app_bar, .app_bar {
  background-color: #12161c;
  color: #fff; }

.select-option-wrapper .ant-select-item,
.select-option-wrapper .ant-select-item-option {
  font-size: 12px; }

.app-bar-tab-menu {
  background-color: #dedede; }

.app_bar-mobile {
  padding: 1rem 0; }
  .app_bar-mobile .homeicon-svg {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    height: 4rem;
    width: 14rem; }
  .app_bar-mobile .app_bar-controllers-splash .action_button {
    min-width: 4rem;
    margin: 0 0.75rem; }
  .app_bar-mobile .app-bar-account {
    color: var(--calculated_base_top-bar-navigation_text);
    cursor: pointer;
    margin-right: 0.5rem; }
  .app_bar-mobile .app-bar-account-content {
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: relative; }
  .app_bar-mobile .app-bar-currency-icon {
    margin: 0.5rem 0.3rem 0 0.3rem;
    width: 1.5rem; }
    .app_bar-mobile .app-bar-currency-icon .st0 {
      fill: var(--calculated_base_top-bar-navigation_text) !important;
      stroke-width: 0rem; }
  .app_bar-mobile .app-bar-account-notification {
    position: absolute;
    right: -6px;
    top: 0;
    height: 1.2rem;
    width: 1.2rem;
    border-radius: 1.2rem;
    background-color: var(--calculated_specials_notifications-alerts-inactive);
    color: var(--calculated_specials_notifications-alerts-text);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    line-height: 1;
    font-weight: bold; }

.app-menu-bar {
  display: flex;
  font-size: 1rem;
  position: relative;
  width: 100%;
  margin: auto; }
  .app-menu-bar .app-menu-bar-content {
    color: var(--calculated_base_top-bar-navigation_text-inactive);
    cursor: pointer;
    margin: 0 20px;
    height: 100%;
    padding: 8px 0;
    opacity: 0.8;
    display: flex;
    align-items: center;
    border-top: 3px solid transparent;
    transition: 0.3s all; }
    .app-menu-bar .app-menu-bar-content:hover {
      color: #fff;
      opacity: 1; }
  .app-menu-bar .app-menu-bar-content-item {
    margin: auto;
    position: relative; }
  .app-menu-bar .app-menu-bar-icon {
    margin-right: 0.4rem;
    width: 1rem; }
    .app-menu-bar .app-menu-bar-icon svg .trade-active-2 {
      stroke: var(--calculated_base_top-bar-navigation_text-inactive); }
    .app-menu-bar .app-menu-bar-icon svg .trade-active-2,
    .app-menu-bar .app-menu-bar-icon svg .quick-trade-tab-active,
    .app-menu-bar .app-menu-bar-icon svg .tab-history-st,
    .app-menu-bar .app-menu-bar-icon svg .tab-summary0,
    .app-menu-bar .app-menu-bar-icon svg .tab-wallet0,
    .app-menu-bar .app-menu-bar-icon svg .tab-setting0,
    .app-menu-bar .app-menu-bar-icon svg .tab-verify0,
    .app-menu-bar .app-menu-bar-icon svg .tab-security0,
    .app-menu-bar .app-menu-bar-icon svg .tab-api0 {
      fill: var(--calculated_base_top-bar-navigation_text-inactive); }
  .app-menu-bar .active-menu {
    color: var(--calculated_base_top-bar-navigation_text);
    border-top: 3px solid var(--calculated_base_top-bar-navigation_text);
    height: 100%;
    opacity: 1; }
    .app-menu-bar .active-menu svg .trade-active-2 {
      stroke: var(--calculated_base_top-bar-navigation_text); }
    .app-menu-bar .active-menu svg .trade-active-2,
    .app-menu-bar .active-menu svg .quick-trade-tab-active,
    .app-menu-bar .active-menu svg .tab-history-st,
    .app-menu-bar .active-menu svg .tab-summary0,
    .app-menu-bar .active-menu svg .tab-wallet0,
    .app-menu-bar .active-menu svg .tab-setting0,
    .app-menu-bar .active-menu svg .tab-verify0,
    .app-menu-bar .active-menu svg .tab-security0,
    .app-menu-bar .active-menu svg .tab-api0 {
      fill: var(--calculated_base_top-bar-navigation_text); }
  .app-menu-bar .menu-notification-active {
    background-color: var(--calculated_base_top-bar-navigation_text);
    height: 2.5rem;
    position: relative;
    color: var(--specials_notifications-alerts-warnings); }
    .app-menu-bar .menu-notification-active svg .tab-summary0,
    .app-menu-bar .menu-notification-active svg .tab-wallet0,
    .app-menu-bar .menu-notification-active svg .tab-setting0,
    .app-menu-bar .menu-notification-active svg .tab-verify0,
    .app-menu-bar .menu-notification-active svg .tab-security0,
    .app-menu-bar .menu-notification-active svg .tab-api0 {
      fill: var(--specials_notifications-alerts-warnings); }
  .app-menu-bar .active-menu.notification {
    color: var(--specials_notifications-alerts-warnings); }
    .app-menu-bar .active-menu.notification svg .tab-summary0,
    .app-menu-bar .active-menu.notification svg .tab-wallet0,
    .app-menu-bar .active-menu.notification svg .tab-setting0,
    .app-menu-bar .active-menu.notification svg .tab-verify0,
    .app-menu-bar .active-menu.notification svg .tab-security0,
    .app-menu-bar .active-menu.notification svg .tab-api0 {
      fill: var(--specials_notifications-alerts-warnings); }
  .app-menu-bar .active-menu .app-menu-bar-icon-notification {
    background-color: var(--specials_notifications-alerts-warnings); }
  .app-menu-bar .notification {
    color: var(--calculated_specials_notifications-alerts-inactive); }
    .app-menu-bar .notification svg .tab-summary0,
    .app-menu-bar .notification svg .tab-wallet0,
    .app-menu-bar .notification svg .tab-setting0,
    .app-menu-bar .notification svg .tab-verify0,
    .app-menu-bar .notification svg .tab-security0,
    .app-menu-bar .notification svg .tab-api0 {
      fill: var(--calculated_specials_notifications-alerts-inactive); }
  .app-menu-bar .security_notification,
  .app-menu-bar .verification_notification,
  .app-menu-bar .wallet_notification {
    color: var(--calculated_specials_notifications-alerts-inactive); }
    .app-menu-bar .security_notification svg .tab-summary0,
    .app-menu-bar .security_notification svg .tab-wallet0,
    .app-menu-bar .security_notification svg .tab-setting0,
    .app-menu-bar .security_notification svg .tab-verify0,
    .app-menu-bar .security_notification svg .tab-security0,
    .app-menu-bar .security_notification svg .tab-api0,
    .app-menu-bar .verification_notification svg .tab-summary0,
    .app-menu-bar .verification_notification svg .tab-wallet0,
    .app-menu-bar .verification_notification svg .tab-setting0,
    .app-menu-bar .verification_notification svg .tab-verify0,
    .app-menu-bar .verification_notification svg .tab-security0,
    .app-menu-bar .verification_notification svg .tab-api0,
    .app-menu-bar .wallet_notification svg .tab-summary0,
    .app-menu-bar .wallet_notification svg .tab-wallet0,
    .app-menu-bar .wallet_notification svg .tab-setting0,
    .app-menu-bar .wallet_notification svg .tab-verify0,
    .app-menu-bar .wallet_notification svg .tab-security0,
    .app-menu-bar .wallet_notification svg .tab-api0 {
      fill: var(--calculated_specials_notifications-alerts-inactive); }
  .app-menu-bar .app-menu-bar-icon-notification {
    position: absolute;
    left: -14px;
    top: -5px;
    height: 1.2rem;
    width: 1.2rem;
    border-radius: 1.2rem;
    background-color: var(--calculated_specials_notifications-alerts-inactive);
    color: var(--calculated_specials_notifications-alerts-text);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    line-height: 1;
    font-weight: bold; }

.favourite-list {
  padding-left: 0.5rem;
  border-bottom: 1px solid var(--calculated_super-pale_label-text-graphics);
  overflow-y: auto; }
  .favourite-list.border-left {
    border-left: 1px solid var(--calculated_super-pale_label-text-graphics); }

.market-bar {
  background: var(--base_secondary-navigation-bar);
  display: flex;
  font-size: 1rem;
  height: 64px;
  min-height: 64px;
  position: relative;
  width: 100%; }
  .market-bar svg .plus {
    stroke: var(--labels_secondary-inactive-label-text-graphics) !important; }
  .market-bar .selector-trigger .active-tab-pair {
    background-color: var(--base_background); }
  .market-bar .selector-trigger .app_bar-pair-tab {
    min-width: 17rem;
    max-width: 17rem; }
  .market-bar .selector-trigger:hover {
    background-color: var(--base_secondary-navigation-bar);
    border-radius: 0.2rem; }
  .market-bar .app_bar-currency-txt {
    font-weight: 600; }
  .market-bar .app-price-diff-down {
    color: var(--trading_selling-related-elements); }
  .market-bar .app-price-diff-up {
    color: var(--trading_buying-related-elements); }
  .market-bar .app-bar-price_diff_up {
    margin-left: 0.5rem; }
  .market-bar .app-bar-price_diff_up:before {
    content: "";
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-top: none !important;
    border-left: 0.4rem solid transparent !important;
    border-right: 0.4rem solid transparent !important;
    border-bottom: 0.4rem solid var(--trading_buying-related-elements);
    margin-bottom: 0rem;
    display: inline-block;
    margin-bottom: 0.2rem;
    margin-right: 0.5rem;
    position: relative;
    top: calc(50% - 0.6rem); }
  .market-bar .app-bar-price_diff_down {
    margin-left: 0.5rem; }
  .market-bar .app-bar-price_diff_down:before {
    content: "";
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-bottom: none !important;
    border-left: 0.4rem solid transparent !important;
    border-right: 0.4rem solid transparent !important;
    border-top: 0.4rem solid var(--trading_selling-related-elements);
    margin-top: 0rem;
    display: inline-block;
    margin-right: 0.5rem;
    position: relative;
    top: calc(50% - 0.6rem); }
  .market-bar .app_bar-pair-content .btc0,
  .market-bar .app_bar-pair-content .btc1,
  .market-bar .app_bar-pair-content .eth0,
  .market-bar .app_bar-pair-content .eth1,
  .market-bar .app_bar-pair-content .bch-icon,
  .market-bar .app_bar-pair-content .xrp-icon,
  .market-bar .app_bar-pair-content .xht-icon,
  .market-bar .app_bar-pair-content .xmr-icon,
  .market-bar .app_bar-pair-content .xlm-icon,
  .market-bar .app_bar-pair-content .eos-icon,
  .market-bar .app_bar-pair-content .trx-icon,
  .market-bar .app_bar-pair-content .ada-icon,
  .market-bar .app_bar-pair-content .bnb-icon,
  .market-bar .app_bar-pair-content .tusd-icon,
  .market-bar .app_bar-pair-content .dai-icon,
  .market-bar .app_bar-pair-content .ltc-icon,
  .market-bar .app-bar-tab-close .btc0,
  .market-bar .app-bar-tab-close .btc1,
  .market-bar .app-bar-tab-close .eth0,
  .market-bar .app-bar-tab-close .eth1,
  .market-bar .app-bar-tab-close .bch-icon,
  .market-bar .app-bar-tab-close .xrp-icon,
  .market-bar .app-bar-tab-close .xht-icon,
  .market-bar .app-bar-tab-close .xmr-icon,
  .market-bar .app-bar-tab-close .xlm-icon,
  .market-bar .app-bar-tab-close .eos-icon,
  .market-bar .app-bar-tab-close .trx-icon,
  .market-bar .app-bar-tab-close .ada-icon,
  .market-bar .app-bar-tab-close .bnb-icon,
  .market-bar .app-bar-tab-close .tusd-icon,
  .market-bar .app-bar-tab-close .dai-icon,
  .market-bar .app-bar-tab-close .ltc-icon,
  .market-bar .app-bar-currency-icon .btc0,
  .market-bar .app-bar-currency-icon .btc1,
  .market-bar .app-bar-currency-icon .eth0,
  .market-bar .app-bar-currency-icon .eth1,
  .market-bar .app-bar-currency-icon .bch-icon,
  .market-bar .app-bar-currency-icon .xrp-icon,
  .market-bar .app-bar-currency-icon .xht-icon,
  .market-bar .app-bar-currency-icon .xmr-icon,
  .market-bar .app-bar-currency-icon .xlm-icon,
  .market-bar .app-bar-currency-icon .eos-icon,
  .market-bar .app-bar-currency-icon .trx-icon,
  .market-bar .app-bar-currency-icon .ada-icon,
  .market-bar .app-bar-currency-icon .bnb-icon,
  .market-bar .app-bar-currency-icon .tusd-icon,
  .market-bar .app-bar-currency-icon .dai-icon,
  .market-bar .app-bar-currency-icon .ltc-icon,
  .market-bar .app-bar-account-icon svg .btc0,
  .market-bar .app-bar-account-icon svg .btc1,
  .market-bar .app-bar-account-icon svg .eth0,
  .market-bar .app-bar-account-icon svg .eth1,
  .market-bar .app-bar-account-icon svg .bch-icon,
  .market-bar .app-bar-account-icon svg .xrp-icon,
  .market-bar .app-bar-account-icon svg .xht-icon,
  .market-bar .app-bar-account-icon svg .xmr-icon,
  .market-bar .app-bar-account-icon svg .xlm-icon,
  .market-bar .app-bar-account-icon svg .eos-icon,
  .market-bar .app-bar-account-icon svg .trx-icon,
  .market-bar .app-bar-account-icon svg .ada-icon,
  .market-bar .app-bar-account-icon svg .bnb-icon,
  .market-bar .app-bar-account-icon svg .tusd-icon,
  .market-bar .app-bar-account-icon svg .dai-icon,
  .market-bar .app-bar-account-icon svg .ltc-icon {
    fill: var(--calculated_base_top-bar-navigation_text-inactive); }
  .market-bar .app_bar-pair-content .tab-close,
  .market-bar .app_bar-pair-content .plus,
  .market-bar .app-bar-tab-close .tab-close,
  .market-bar .app-bar-tab-close .plus,
  .market-bar .app-bar-currency-icon .tab-close,
  .market-bar .app-bar-currency-icon .plus,
  .market-bar .app-bar-account-icon svg .tab-close,
  .market-bar .app-bar-account-icon svg .plus {
    stroke: var(--calculated_base_top-bar-navigation_text-inactive); }
  .market-bar .app_bar-pair-content .st0,
  .market-bar .app-bar-tab-close .st0,
  .market-bar .app-bar-currency-icon .st0,
  .market-bar .app-bar-account-icon svg .st0 {
    fill: var(--calculated_base_top-bar-navigation_text) !important;
    stroke-width: 0rem; }
  .market-bar .app_bar-pair-font {
    font-size: 0.9rem; }
  .market-bar .app-bar-tab-close {
    margin: 0.2rem 0.4rem 0 0.4rem;
    width: 0.9rem; }
  .market-bar .app_bar-pair-content {
    align-items: center;
    color: var(--labels_secondary-inactive-label-text-graphics);
    cursor: pointer;
    display: flex;
    font-size: 1.2rem;
    font-weight: 400;
    height: 100%;
    position: relative; }
  .market-bar .app_bar-pair-tab {
    padding: 0 0.5rem;
    min-width: 14rem;
    max-width: 14rem; }
    .market-bar .app_bar-pair-tab.selector-trigger {
      min-width: 17rem;
      max-width: 17rem; }
  .market-bar .transition_open {
    left: -3.5rem;
    -webkit-animation: slide 0.5s forwards;
    animation: slide 0.5s forwards; }
  .market-bar .transition_close {
    left: 0;
    -webkit-animation: slideRev 0.5s backwards;
    animation: slideRev 0.5s backwards;
    z-index: 99; }

@-webkit-keyframes slide {
  100% {
    left: 0; } }

@keyframes slide {
  100% {
    left: 0; } }

@-webkit-keyframes slideRev {
  100% {
    left: -4rem; } }

@keyframes slideRev {
  100% {
    left: -4rem; } }
  .market-bar .favourite-tab {
    padding: 0.2rem 0;
    border-radius: 0.7rem; }
    .market-bar .favourite-tab:hover {
      background-color: var(--base_background); }
  .market-bar .active-tab-pair {
    color: var(--labels_secondary-inactive-label-text-graphics); }
    .market-bar .active-tab-pair svg .btc0,
    .market-bar .active-tab-pair svg .btc1,
    .market-bar .active-tab-pair svg .eth0,
    .market-bar .active-tab-pair svg .eth1,
    .market-bar .active-tab-pair svg .bch-icon,
    .market-bar .active-tab-pair svg .xrp-icon,
    .market-bar .active-tab-pair svg .xht-icon,
    .market-bar .active-tab-pair svg .xmr-icon,
    .market-bar .active-tab-pair svg .xlm-icon,
    .market-bar .active-tab-pair svg .eos-icon,
    .market-bar .active-tab-pair svg .trx-icon,
    .market-bar .active-tab-pair svg .ada-icon,
    .market-bar .active-tab-pair svg .bnb-icon,
    .market-bar .active-tab-pair svg .tusd-icon,
    .market-bar .active-tab-pair svg .dai-icon,
    .market-bar .active-tab-pair svg .ltc-icon {
      fill: var(--labels_important-active-labels-text-graphics); }
    .market-bar .active-tab-pair svg .tab-close,
    .market-bar .active-tab-pair svg .plus,
    .market-bar .active-tab-pair svg .double-arrow {
      stroke: var(--labels_important-active-labels-text-graphics); }
    .market-bar .active-tab-pair svg .st0 {
      fill: var(--labels_important-active-labels-text-graphics);
      stroke-width: 0rem; }

.market-bar-side {
  min-width: 20rem;
  max-width: 20rem; }

.app-bar-add-tab-search {
  color: var(--labels_secondary-inactive-label-text-graphics);
  height: 2.2rem;
  margin-bottom: 0.5rem; }

.search-field {
  color: var(--labels_secondary-inactive-label-text-graphics);
  background-color: var(--base_background); }
  .search-field ::-webkit-input-placeholder {
    color: var(--labels_secondary-inactive-label-text-graphics); }
  .search-field ::placeholder {
    color: var(--labels_secondary-inactive-label-text-graphics); }

.app-bar-search-field {
  flex-direction: row-reverse;
  margin: 0;
  padding-right: 0.5rem; }

.app-bar-search-field-outline {
  height: 0.5px; }

.app-bar-add-tab-menu {
  background-color: var(--base_background);
  border: 1px solid var(--calculated_secondary-border);
  color: var(--labels_secondary-inactive-label-text-graphics);
  width: 22rem;
  min-height: 8rem;
  z-index: 20;
  box-shadow: 0px 4px 6px 1px rgba(0, 0, 0, 0.4); }
  .app-bar-add-tab-menu .app-bar-tab-menu-list {
    background-color: #dedede;
    color: var(--labels_secondary-inactive-label-text-graphics);
    font-size: 0.9rem;
    height: 2.5rem;
    padding: 0.5rem 0.8rem;
    width: 3.2rem;
    max-width: 3.2rem;
    /* (22 -6) / 5 */ }
  .app-bar-add-tab-menu .active-tab {
    background-color: var(--base_background);
    color: var(--labels_secondary-inactive-label-text-graphics);
    font-weight: bold; }
  .app-bar-add-tab-menu .app-bar-add-tab-content {
    font-size: 0.9rem;
    overflow-y: scroll;
    padding: 0.3rem 0; }
    .app-bar-add-tab-menu .app-bar-add-tab-content .scroll-view {
      overflow-y: auto;
      height: 22rem; }
  .app-bar-add-tab-menu .app-bar-tab-overflow-content {
    background-color: var(--base_background);
    color: var(--labels_secondary-inactive-label-text-graphics);
    font-size: 0.9rem;
    overflow-y: scroll;
    padding: 0.3rem 0; }
  .app-bar-add-tab-menu .app-bar-add-tab-content-list {
    color: var(--labels_secondary-inactive-label-text-graphics);
    height: 2.2rem; }
    .app-bar-add-tab-menu .app-bar-add-tab-content-list:hover {
      background-color: var(--base_secondary-navigation-bar);
      color: var(--labels_secondary-inactive-label-text-graphics); }
      .app-bar-add-tab-menu .app-bar-add-tab-content-list:hover svg .btc0,
      .app-bar-add-tab-menu .app-bar-add-tab-content-list:hover svg .btc1,
      .app-bar-add-tab-menu .app-bar-add-tab-content-list:hover svg .eth0,
      .app-bar-add-tab-menu .app-bar-add-tab-content-list:hover svg .eth1,
      .app-bar-add-tab-menu .app-bar-add-tab-content-list:hover svg .bch-icon,
      .app-bar-add-tab-menu .app-bar-add-tab-content-list:hover svg .xrp-icon,
      .app-bar-add-tab-menu .app-bar-add-tab-content-list:hover svg .xht-icon,
      .app-bar-add-tab-menu .app-bar-add-tab-content-list:hover svg .xmr-icon,
      .app-bar-add-tab-menu .app-bar-add-tab-content-list:hover svg .xlm-icon,
      .app-bar-add-tab-menu .app-bar-add-tab-content-list:hover svg .eos-icon,
      .app-bar-add-tab-menu .app-bar-add-tab-content-list:hover svg .trx-icon,
      .app-bar-add-tab-menu .app-bar-add-tab-content-list:hover svg .ada-icon,
      .app-bar-add-tab-menu .app-bar-add-tab-content-list:hover svg .bnb-icon,
      .app-bar-add-tab-menu .app-bar-add-tab-content-list:hover svg .tusd-icon,
      .app-bar-add-tab-menu .app-bar-add-tab-content-list:hover svg .dai-icon,
      .app-bar-add-tab-menu .app-bar-add-tab-content-list:hover svg .ltc-icon,
      .app-bar-add-tab-menu .app-bar-add-tab-content-list:hover svg .default-icon {
        fill: var(--labels_secondary-inactive-label-text-graphics) !important; }
      .app-bar-add-tab-menu .app-bar-add-tab-content-list:hover svg .st0 {
        fill: var(--labels_secondary-inactive-label-text-graphics) !important;
        stroke-width: 0rem; }
  .app-bar-add-tab-menu .app-bar-tab-setting {
    margin: 0.3rem 0.4rem 0 0.4rem;
    width: 1rem; }
  .app-bar-add-tab-menu .app-bar-add-tab-icons {
    width: 1.2rem;
    margin: 0.6rem 0.3rem 0 0.3rem; }
  .app-bar-add-tab-menu .app-bar-tab-setting svg .btc0,
  .app-bar-add-tab-menu .app-bar-tab-setting svg .btc1,
  .app-bar-add-tab-menu .app-bar-tab-setting svg .eth0,
  .app-bar-add-tab-menu .app-bar-tab-setting svg .eth1,
  .app-bar-add-tab-menu .app-bar-tab-setting svg .bch-icon,
  .app-bar-add-tab-menu .app-bar-tab-setting svg .xrp-icon,
  .app-bar-add-tab-menu .app-bar-tab-setting svg .xht-icon,
  .app-bar-add-tab-menu .app-bar-tab-setting svg .xmr-icon,
  .app-bar-add-tab-menu .app-bar-tab-setting svg .xlm-icon,
  .app-bar-add-tab-menu .app-bar-tab-setting svg .eos-icon,
  .app-bar-add-tab-menu .app-bar-tab-setting svg .trx-icon,
  .app-bar-add-tab-menu .app-bar-tab-setting svg .ada-icon,
  .app-bar-add-tab-menu .app-bar-tab-setting svg .bnb-icon,
  .app-bar-add-tab-menu .app-bar-tab-setting svg .tusd-icon,
  .app-bar-add-tab-menu .app-bar-tab-setting svg .dai-icon,
  .app-bar-add-tab-menu .app-bar-tab-setting svg .ltc-icon,
  .app-bar-add-tab-menu .app-bar-tab-setting svg .default-icon,
  .app-bar-add-tab-menu .app-bar-add-tab-icons svg .btc0,
  .app-bar-add-tab-menu .app-bar-add-tab-icons svg .btc1,
  .app-bar-add-tab-menu .app-bar-add-tab-icons svg .eth0,
  .app-bar-add-tab-menu .app-bar-add-tab-icons svg .eth1,
  .app-bar-add-tab-menu .app-bar-add-tab-icons svg .bch-icon,
  .app-bar-add-tab-menu .app-bar-add-tab-icons svg .xrp-icon,
  .app-bar-add-tab-menu .app-bar-add-tab-icons svg .xht-icon,
  .app-bar-add-tab-menu .app-bar-add-tab-icons svg .xmr-icon,
  .app-bar-add-tab-menu .app-bar-add-tab-icons svg .xlm-icon,
  .app-bar-add-tab-menu .app-bar-add-tab-icons svg .eos-icon,
  .app-bar-add-tab-menu .app-bar-add-tab-icons svg .trx-icon,
  .app-bar-add-tab-menu .app-bar-add-tab-icons svg .ada-icon,
  .app-bar-add-tab-menu .app-bar-add-tab-icons svg .bnb-icon,
  .app-bar-add-tab-menu .app-bar-add-tab-icons svg .tusd-icon,
  .app-bar-add-tab-menu .app-bar-add-tab-icons svg .dai-icon,
  .app-bar-add-tab-menu .app-bar-add-tab-icons svg .ltc-icon,
  .app-bar-add-tab-menu .app-bar-add-tab-icons svg .default-icon {
    fill: var(--labels_secondary-inactive-label-text-graphics) !important; }
  .app-bar-add-tab-menu .app-bar-tab-setting svg .plus,
  .app-bar-add-tab-menu .app-bar-tab-setting svg .minus,
  .app-bar-add-tab-menu .app-bar-add-tab-icons svg .plus,
  .app-bar-add-tab-menu .app-bar-add-tab-icons svg .minus {
    stroke: var(--labels_secondary-inactive-label-text-graphics) !important;
    stroke-width: 0.2rem; }
  .app-bar-add-tab-menu .app-bar-tab-setting svg .st0,
  .app-bar-add-tab-menu .app-bar-add-tab-icons svg .st0 {
    fill: var(--calculated_base_top-bar-navigation_text);
    stroke-width: 0rem; }
  .app-bar-add-tab-menu .app-bar-tab-setting svg .icon-check-1,
  .app-bar-add-tab-menu .app-bar-add-tab-icons svg .icon-check-1 {
    stroke: var(--calculated_base_top-bar-navigation_text); }
  .app-bar-add-tab-menu .app-bar-tab-setting svg .xht-icon-1,
  .app-bar-add-tab-menu .app-bar-add-tab-icons svg .xht-icon-1 {
    fill: var(--base_background) !important; }
  .app-bar-add-tab-menu .app-bar-tab-setting .app-price-diff-down,
  .app-bar-add-tab-menu .app-bar-add-tab-icons .app-price-diff-down {
    color: var(--trading_selling-related-elements); }
  .app-bar-add-tab-menu .app-bar-tab-setting .app-price-diff-up,
  .app-bar-add-tab-menu .app-bar-add-tab-icons .app-price-diff-up {
    color: var(--trading_buying-related-elements); }
  .app-bar-add-tab-menu .app-price-diff-down {
    color: var(--trading_selling-related-elements); }
  .app-bar-add-tab-menu .app-price-diff-up {
    color: var(--trading_buying-related-elements); }

.app_bar-link {
  margin: 0.5rem 0;
  padding: 0 0.5rem;
  text-transform: uppercase; }

.app-bar_add-tab-price {
  color: var(--labels_secondary-inactive-label-text-graphics); }

@media (min-width: 1920px) {
  .app_bar {
    font-size: 1rem;
    height: 64px;
    min-height: 64px; }
    .app_bar .app_bar-pair-font {
      font-size: 1rem; }
    .app_bar .app-bar-tab-close {
      width: 1.1rem; }
  .market-bar {
    font-size: 1rem; }
    .market-bar .app_bar-pair-font {
      font-size: 1rem; }
  .app-menu-bar {
    font-size: 1rem !important;
    height: 64px;
    min-height: 64px; }
    .app-menu-bar .app-menu-bar-content {
      width: 12rem; }
  .app-bar-add-tab-menu {
    width: 24rem; }
    .app-bar-add-tab-menu .app-bar-tab-menu-list {
      width: 3.6rem;
      max-width: 3.6rem;
      /* (24 -6) / 5 */ }
    .app-bar-add-tab-menu .app-bar-add-tab-content,
    .app-bar-add-tab-menu .app-bar-tab-menu-list,
    .app-bar-add-tab-menu .app-bar-tab-overflow-content {
      font-size: 1rem; } }

@media screen and (max-width: 1096px) {
  .app_bar {
    font-size: 0.9rem; }
    .app_bar .app_bar-pair-font {
      font-size: 0.9rem; }
  .market-bar {
    font-size: 0.9rem; }
    .market-bar .app_bar-pair-font {
      font-size: 0.9rem; }
  .app-menu-bar {
    font-size: 0.9rem !important; }
  .app-bar-add-tab-menu .app-bar-add-tab-content,
  .app-bar-add-tab-menu .app-bar-tab-menu-list,
  .app-bar-add-tab-menu .app-bar-tab-overflow-content {
    font-size: 0.9rem; } }

.layout-mobile .st0 {
  fill: #ffffff;
  stroke-miterlimit: 10; }

.layout-mobile .app-bar-add-tab-menu {
  width: 36rem;
  position: absolute;
  top: 7.8rem;
  left: 1.2rem; }
  .layout-mobile .app-bar-add-tab-menu .app-bar-add-tab-content,
  .layout-mobile .app-bar-add-tab-menu .app-bar-tab-menu-list,
  .layout-mobile .app-bar-add-tab-menu .app-bar-tab-overflow-content {
    font-size: 1.3rem; }
  .layout-mobile .app-bar-add-tab-menu .app-bar-tab-menu-list {
    height: 3.5rem;
    width: 6rem;
    max-width: 6rem;
    /* (40 -6) / 5 */ }
  .layout-mobile .app-bar-add-tab-menu .app-bar-add-tab-icons {
    width: 1.5rem !important; }
  .layout-mobile .app-bar-add-tab-menu .app-bar-add-tab-content-list {
    height: 4rem; }

.direction_rtl .app-menu-bar .app-menu-bar-icon {
  margin-left: 0.4rem;
  margin-right: 0; }

.direction_rtl .app-menu-bar .app-menu-bar-icon-notification {
  right: -14px;
  top: -5px;
  left: unset; }

.direction_rtl .app_bar .app-bar-account-menu .app-bar-account-list-icon {
  margin-right: 0;
  margin-left: 0.4rem; }

.market-selector-dropdown.ant-dropdown-trigger {
  height: 100% !important;
  width: 100% !important; }

.app-menu-bar-wrapper {
  flex-grow: 1;
  flex-shrink: 1;
  overflow-y: auto;
  align-items: flex-start !important; }

.stared-market svg {
  fill: var(--specials_my-username-in-chat); }

.active-market-trigger {
  color: var(--labels_important-active-labels-text-graphics);
  font-weight: bold; }

.market-trigger {
  background-color: var(--base_background); }

.landing-page-bar {
  background: linear-gradient(to bottom, var(--calculated_landing_background), var(--calculated_landing_background)) !important; }

@media (max-width: 768px) {
  .select-option-wrapper .ant-select-item,
  .select-option-wrapper .ant-select-item-option {
    font-size: 0.9rem !important; } }

.check_title-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .check_title-container .check_title-label {
    font-size: 1.8rem;
    line-height: 1.8rem;
    padding: 0.25rem 0; }
  .check_title-container .custom_title-label {
    font-size: 1.3rem;
    line-height: 1.3rem;
    padding: 0.25rem 0;
    text-align: center; }
  .check_title-container .check_title-icon {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative; }
  .check_title-container .check_title-img {
    height: 4rem;
    width: 4rem; }
  .check_title-container .check_title-svg {
    height: 4rem;
    width: 4rem; }
    .check_title-container .check_title-svg svg {
      max-width: 100%;
      height: 4rem;
      width: 4rem; }
      .check_title-container .check_title-svg svg .trade-history-3,
      .check_title-container .check_title-svg svg .deposit-history-1,
      .check_title-container .check_title-svg svg .withdrawals-history-1 {
        fill: var(--labels_important-active-labels-text-graphics); }
      .check_title-container .check_title-svg svg .trade-history-1,
      .check_title-container .check_title-svg svg .deposit-history-3,
      .check_title-container .check_title-svg svg .withdrawals-history-3 {
        fill: var(--labels_secondary-inactive-label-text-graphics); }
      .check_title-container .check_title-svg svg .trade-history-2,
      .check_title-container .check_title-svg svg .trade-history-4,
      .check_title-container .check_title-svg svg .trade-history-5,
      .check_title-container .check_title-svg svg .deposit-history-2,
      .check_title-container .check_title-svg svg .deposit-history-4,
      .check_title-container .check_title-svg svg .withdrawals-history-2,
      .check_title-container .check_title-svg svg .withdrawals-history-4 {
        stroke: none; }
    .check_title-container .check_title-svg .st0,
    .check_title-container .check_title-svg .g1_4,
    .check_title-container .check_title-svg .g1_2 {
      stroke: var(--labels_secondary-inactive-label-text-graphics); }
    .check_title-container .check_title-svg .st0 {
      fill: var(--labels_secondary-inactive-label-text-graphics); }
  .check_title-container .custom_title-img {
    height: 6rem;
    width: 6rem; }
  .check_title-container .custom_title-svg {
    height: 6rem;
    width: 6rem; }
    .check_title-container .custom_title-svg svg {
      max-width: 100%;
      height: 6rem;
      width: 6rem; }
      .check_title-container .custom_title-svg svg .verification-email,
      .check_title-container .custom_title-svg svg .verification-id,
      .check_title-container .custom_title-svg svg .verification-document,
      .check_title-container .custom_title-svg svg .verification-phone,
      .check_title-container .custom_title-svg svg .verification-bank,
      .check_title-container .custom_title-svg svg .notification-setting,
      .check_title-container .custom_title-svg svg .language-setting,
      .check_title-container .custom_title-svg svg .interface-setting,
      .check_title-container .custom_title-svg svg .chat-setting,
      .check_title-container .custom_title-svg svg .audio-setting,
      .check_title-container .custom_title-svg svg .risk-setting {
        fill: var(--labels_secondary-inactive-label-text-graphics); }
    .check_title-container .custom_title-svg .st0,
    .check_title-container .custom_title-svg .g1_4,
    .check_title-container .custom_title-svg .g1_2 {
      stroke: var(--labels_secondary-inactive-label-text-graphics); }
    .check_title-container .custom_title-svg .st0 {
      fill: var(--labels_secondary-inactive-label-text-graphics); }
  .check_title-container .check_title-notification {
    position: absolute;
    right: 0;
    top: 0;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 1.5rem;
    background-color: var(--specials_notifications-alerts-warnings);
    color: var(--calculated_specials_notifications-alerts-text);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    line-height: 1;
    font-weight: bold; }

.panel-information-row {
  background-color: var(--labels_fields);
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  flex: 1 1; }

.panel-information-row-disable {
  color: var(--labels_secondary-inactive-label-text-graphics); }

.verification-status-container {
  position: relative;
  top: 15px; }

.verification-stauts {
  width: 1rem; }
  .verification-stauts.incomplete svg circle, .verification-stauts.pending svg circle {
    fill: var(--specials_pending-waiting-caution); }
  .verification-stauts.incomplete svg path,
  .verification-stauts.incomplete svg polygon,
  .verification-stauts.incomplete svg polyline, .verification-stauts.pending svg path,
  .verification-stauts.pending svg polygon,
  .verification-stauts.pending svg polyline {
    fill: var(--calculated_specials_notifications-pending-text); }
  .verification-stauts.rejected svg circle {
    fill: var(--specials_notifications-alerts-warnings); }
  .verification-stauts.rejected svg path,
  .verification-stauts.rejected svg polygon,
  .verification-stauts.rejected svg polyline {
    fill: var(--calculated_specials_notifications-alerts-text); }
  .verification-stauts.verified svg circle {
    fill: var(--specials_checks-okay-done); }
  .verification-stauts.verified svg path,
  .verification-stauts.verified svg polygon,
  .verification-stauts.verified svg polyline {
    fill: var(--calculated_specials_notifications-success-text); }

.empty-notification {
  height: 1.5rem; }

.header_title-wrapper {
  margin-bottom: 1rem;
  padding-top: 0.5rem;
  position: relative; }
  .header_title-wrapper .header_title-children {
    font-size: 14px;
    color: var(--labels_secondary-inactive-label-text-graphics);
    padding: 0.5rem 0; }
  .header_title-wrapper .header_title-icon {
    width: 4rem; }
    .header_title-wrapper .header_title-icon svg .verification-email,
    .header_title-wrapper .header_title-icon svg .verification-id,
    .header_title-wrapper .header_title-icon svg .verification-document,
    .header_title-wrapper .header_title-icon svg .verification-phone,
    .header_title-wrapper .header_title-icon svg .verification-bank {
      fill: var(--labels_important-active-labels-text-graphics); }

.custom-tab-wrapper .tab_item {
  color: var(--labels_secondary-inactive-label-text-graphics);
  height: 10rem;
  margin: 1rem;
  max-width: 10rem;
  min-width: 10rem; }

.custom-tab-wrapper .tab_item-active {
  border: 1px solid var(--calculated_important-border);
  color: var(--labels_important-active-labels-text-graphics) !important;
  font-weight: bold; }
  .custom-tab-wrapper .tab_item-active svg path {
    opacity: 1; }
    .custom-tab-wrapper .tab_item-active svg path .st0,
    .custom-tab-wrapper .tab_item-active svg path .g1_4,
    .custom-tab-wrapper .tab_item-active svg path .g1_2 {
      fill: var(--labels_important-active-labels-text-graphics) !important; }
    .custom-tab-wrapper .tab_item-active svg path .st0 {
      stroke: var(--labels_important-active-labels-text-graphics) !important; }
  .custom-tab-wrapper .tab_item-active .custom_title-svg svg .verification-email,
  .custom-tab-wrapper .tab_item-active .custom_title-svg svg .verification-id,
  .custom-tab-wrapper .tab_item-active .custom_title-svg svg .verification-document,
  .custom-tab-wrapper .tab_item-active .custom_title-svg svg .verification-phone,
  .custom-tab-wrapper .tab_item-active .custom_title-svg svg .verification-bank,
  .custom-tab-wrapper .tab_item-active .custom_title-svg svg .notification-setting,
  .custom-tab-wrapper .tab_item-active .custom_title-svg svg .language-setting,
  .custom-tab-wrapper .tab_item-active .custom_title-svg svg .interface-setting,
  .custom-tab-wrapper .tab_item-active .custom_title-svg svg .chat-setting,
  .custom-tab-wrapper .tab_item-active .custom_title-svg svg .audio-setting,
  .custom-tab-wrapper .tab_item-active .custom_title-svg svg .risk-setting {
    fill: var(--labels_important-active-labels-text-graphics); }

.mobile_tab-wrapper {
  border-top: 1px solid var(--calculated_secondary-border);
  color: var(--labels_secondary-inactive-label-text-graphics);
  padding: 0.5rem;
  font-size: 1.2rem; }
  .mobile_tab-wrapper .custom_tab_icon-mobile {
    width: 4rem; }
  .mobile_tab-wrapper .custom_tab_status-icon {
    width: 1.7rem; }
  .mobile_tab-wrapper .mobile-tab-title {
    font-size: 2rem;
    font-weight: bold; }
  .mobile_tab-wrapper .verified {
    color: var(--specials_checks-okay-done); }
  .mobile_tab-wrapper .pending {
    color: var(--specials_pending-waiting-caution); }
  .mobile_tab-wrapper .incompleted {
    color: var(--specials_pending-waiting-caution); }
  .mobile_tab-wrapper .rejected {
    color: var(--specials_notifications-alerts-warnings); }

.mobile_tab_last {
  border-bottom: 1px solid var(--calculated_secondary-border); }

.mobile_tab_last-active {
  border-bottom: 2px solid var(--calculated_secondary-border) !important; }

.active_mobile_tab {
  color: var(--labels_secondary-inactive-label-text-graphics);
  border-top: 2px solid var(--calculated_secondary-border);
  border-bottom: 1px solid var(--calculated_secondary-border); }
  .active_mobile_tab svg .verification-email,
  .active_mobile_tab svg .verification-id,
  .active_mobile_tab svg .verification-document,
  .active_mobile_tab svg .verification-phone,
  .active_mobile_tab svg .verification-bank,
  .active_mobile_tab svg .notification-setting,
  .active_mobile_tab svg .language-setting,
  .active_mobile_tab svg .interface-setting,
  .active_mobile_tab svg .chat-setting,
  .active_mobile_tab svg .audio-setting,
  .active_mobile_tab svg .risk-setting {
    fill: var(--labels_important-active-labels-text-graphics); }

.direction_ltr .check_title-container {
  font-family: "Roboto"; }

.layout-mobile .mobile_tab-wrapper .status_txt {
  font-size: 13px; }

.wallet_section-wrapper {
  font-size: 1rem;
  padding: 0.25rem 0; }
  .wallet_section-wrapper .wallet_section-content > * {
    margin-bottom: 0.5rem; }
    .wallet_section-wrapper .wallet_section-content > *:last-child {
      margin-bottom: 0; }
  .wallet_section-wrapper.wallet_section-total_asset > * {
    margin: 0.5rem 0; }

.wallet-wrapper .icon_title-wrapper {
  flex-direction: row;
  justify-content: flex-start; }

.wallet-wrapper .donut-container {
  height: 18rem; }
  .wallet-wrapper .donut-container .donut-label-pair {
    font-size: 0.8rem; }

.wallet_section-title {
  text-transform: uppercase;
  color: var(--labels_secondary-inactive-label-text-graphics);
  font-size: 1rem; }
  .wallet_section-title > * {
    margin: 0.5rem; }

.wallet_section-title-amount {
  color: var(--labels_secondary-inactive-label-text-graphics);
  font-size: 1rem; }

.wallet_section-total_asset {
  font-size: 1rem;
  color: var(--labels_secondary-inactive-label-text-graphics); }
  .wallet_section-total_asset > span {
    font-size: 1.8rem;
    line-height: 1; }
  .wallet_section-total_asset:first-child {
    margin-bottom: 0; }
  .wallet_section-total_asset:last-child {
    margin-top: 0; }

.wallet_link {
  margin: 0.5rem 0;
  padding: 0 0.5rem;
  text-transform: uppercase; }

.direction_ltr .sidebar_section-container .wallet-wrapper .accordion_section_title .wallet_section-title:after {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border-right: none !important;
  border-top: 0.3rem solid transparent !important;
  border-bottom: 0.3rem solid transparent !important;
  border-left: 0.3rem solid var(--labels_secondary-inactive-label-text-graphics);
  margin-right: -0.3rem;
  right: -0.3rem;
  top: calc(50% - 0.3rem); }

.direction_ltr .sidebar_section-container .wallet-wrapper .accordion_section--open .accordion_section_title .wallet_section-title {
  color: var(--labels_important-active-labels-text-graphics); }
  .direction_ltr .sidebar_section-container .wallet-wrapper .accordion_section--open .accordion_section_title .wallet_section-title:after {
    border-left-color: var(--labels_important-active-labels-text-graphics); }

.wallet-wrapper .icon-title-wrapper {
  flex-direction: row;
  justify-content: flex-start; }

.direction_rtl .sidebar_section-container .wallet-wrapper .accordion_section_title .wallet_section-title:after {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  border-left: none !important;
  border-top: 0.3rem solid transparent !important;
  border-bottom: 0.3rem solid transparent !important;
  border-right: 0.3rem solid var(--labels_secondary-inactive-label-text-graphics);
  margin-left: -0.3rem;
  left: -0.3rem;
  top: calc(50% - 0.3rem); }

.direction_rtl .sidebar_section-container .wallet-wrapper .accordion_section--open .accordion_section_title .wallet_section-title {
  color: var(--labels_important-active-labels-text-graphics); }
  .direction_rtl .sidebar_section-container .wallet-wrapper .accordion_section--open .accordion_section_title .wallet_section-title:after {
    border-right-color: var(--labels_important-active-labels-text-graphics); }

@media (min-width: 1920px) {
  .wallet-wrapper .donut-container {
    height: 19rem; }
    .wallet-wrapper .donut-container .donut-label-pair {
      font-size: 1rem; } }

.sidebar_hub-wrapper {
  overflow-y: scroll;
  border-bottom: 4px solid var(--calculated_super-pale_label-text-graphics); }
  .sidebar_hub-wrapper .currency-selector .tab-action {
    height: 64px; }
    .sidebar_hub-wrapper .currency-selector .tab-action .tab-colors {
      height: 6px; }
    .sidebar_hub-wrapper .currency-selector .tab-action.not-active {
      background-color: var(--labels_inactive-button);
      position: relative;
      color: var(--labels_secondary-inactive-label-text-graphics); }
      .sidebar_hub-wrapper .currency-selector .tab-action.not-active:after {
        content: '';
        position: absolute;
        height: 1px;
        bottom: 0;
        right: -1px;
        left: -1px;
        border-bottom: 1px solid var(--calculated_super-pale_label-text-graphics);
        background-color: var(--calculated_super-pale_label-text-graphics); }
    .sidebar_hub-wrapper .currency-selector .tab-action.tab-active {
      font-weight: bold;
      position: relative;
      background-color: var(--base_background); }
      .sidebar_hub-wrapper .currency-selector .tab-action.tab-active:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        border-right: 1px solid var(--calculated_super-pale_label-text-graphics);
        border-left: 1px solid var(--calculated_super-pale_label-text-graphics); }
      .sidebar_hub-wrapper .currency-selector .tab-action.tab-active:first-child:after {
        border-left: none; }
      .sidebar_hub-wrapper .currency-selector .tab-action.tab-active:last-child:after {
        border-right: none; }
      .sidebar_hub-wrapper .currency-selector .tab-action.tab-active.tab-btc .tab-colors {
        height: 6px;
        position: relative;
        background-color: var(--coin-btc); }
        .sidebar_hub-wrapper .currency-selector .tab-action.tab-active.tab-btc .tab-colors:before {
          content: '';
          position: absolute;
          top: 0;
          width: 100%;
          height: 3px;
          background-color: var(--coin-btc); }
      .sidebar_hub-wrapper .currency-selector .tab-action.tab-active.tab-eur .tab-colors {
        height: 6px;
        position: relative;
        background-color: var(--coin-eur); }
        .sidebar_hub-wrapper .currency-selector .tab-action.tab-active.tab-eur .tab-colors:before {
          content: '';
          position: absolute;
          top: 0;
          width: 100%;
          height: 3px;
          background-color: var(--coin-eur); }
  .sidebar_hub-wrapper .sidebar_hub-content .sidebar_hub-section {
    border-top: 1px solid var(--calculated_super-pale_label-text-graphics); }
    .sidebar_hub-wrapper .sidebar_hub-content .sidebar_hub-section:not(:last-child) {
      margin-bottom: 1.5rem; }
    .sidebar_hub-wrapper .sidebar_hub-content .sidebar_hub-section > div:nth-child(n + 2) {
      margin-top: 1rem; }
    .sidebar_hub-wrapper .sidebar_hub-content .sidebar_hub-section .sidebar_hub-section-header {
      padding: 0.5rem 0.25rem; }
      .sidebar_hub-wrapper .sidebar_hub-content .sidebar_hub-section .sidebar_hub-section-header .sidebar_hub-section-icon {
        width: 2rem;
        height: 2rem; }
        .sidebar_hub-wrapper .sidebar_hub-content .sidebar_hub-section .sidebar_hub-section-header .sidebar_hub-section-icon svg path {
          fill: transparent;
          stroke: var(--labels_secondary-inactive-label-text-graphics); }
      .sidebar_hub-wrapper .sidebar_hub-content .sidebar_hub-section .sidebar_hub-section-header .sidebar_hub-section-title {
        font-size: 1.3rem;
        margin: 0 0.5rem;
        color: var(--labels_secondary-inactive-label-text-graphics); }
    .sidebar_hub-wrapper .sidebar_hub-content .sidebar_hub-section .sidebar_hub-section-content .accordion_wrapper .accordion_section {
      border-color: var(--labels_secondary-inactive-label-text-graphics); }
    .sidebar_hub-wrapper .sidebar_hub-content .sidebar_hub-section.section-active {
      border-top-width: 2px;
      border-color: var(--labels_important-active-labels-text-graphics); }
      .sidebar_hub-wrapper .sidebar_hub-content .sidebar_hub-section.section-active .sidebar_hub-section-header .sidebar_hub-section-icon svg path {
        fill: var(--labels_important-active-labels-text-graphics);
        stroke: var(--labels_important-active-labels-text-graphics); }
      .sidebar_hub-wrapper .sidebar_hub-content .sidebar_hub-section.section-active .sidebar_hub-section-header .sidebar_hub-section-title {
        color: var(--labels_important-active-labels-text-graphics);
        font-weight: bold; }
  .sidebar_hub-wrapper .sign_up-btn,
  .sidebar_hub-wrapper .log_in-btn {
    background: var(--specials_buttons-links-and-highlights) !important;
    color: var(--labels_important-active-labels-text-graphics) !important;
    border: 1px solid var(--calculated_super-pale_label-text-graphics); }
  .sidebar_hub-wrapper .sidebar_hub-trade .sidebar_hub-button.not-active {
    background: var(--base_background) !important;
    color: var(--labels_important-active-labels-text-graphics) !important;
    border: 1px solid var(--calculated_super-pale_label-text-graphics); }
  .sidebar_hub-wrapper .sidebar_hub-trade .sidebar_hub-button.active {
    background: var(--labels_important-active-labels-text-graphics) !important;
    border: 1px solid var(--labels_important-active-labels-text-graphics); }

.active-wallet .wallet_section-total_asset,
.active-wallet .wallet_section-title,
.active-trade .wallet_section-total_asset,
.active-trade .wallet_section-title,
.active-quick-trade .wallet_section-total_asset,
.active-quick-trade .wallet_section-title {
  color: var(--labels_important-active-labels-text-graphics); }

.direction_ltr .sidebar_hub-wrapper .currency-selector {
  flex-direction: row; }

.direction_rtl .sidebar_hub-wrapper .currency-selector {
  flex-direction: row-reverse; }

@media only screen and (max-width: 991px) {
  .sidebar_hub-wrapper .sidebar_hub-trade {
    flex-direction: column; }
    .sidebar_hub-wrapper .sidebar_hub-trade .separator {
      height: 1rem;
      width: 100%; } }

.notification-wrapper {
  min-width: 30rem;
  max-width: 40rem; }
  .notification-wrapper .notification-title-wrapper {
    height: 15rem;
    padding: 0.5rem;
    border-bottom: 1px solid var(--calculated_important-border); }
    .notification-wrapper .notification-title-wrapper .notification-title-icon {
      max-height: 8rem;
      margin: 2rem 0; }
      .notification-wrapper .notification-title-wrapper .notification-title-icon svg {
        width: 8rem;
        height: 8rem; }
        .notification-wrapper .notification-title-wrapper .notification-title-icon svg path {
          fill: var(--labels_important-active-labels-text-graphics); }
        .notification-wrapper .notification-title-wrapper .notification-title-icon svg polyline {
          fill: transparent; }
    .notification-wrapper .notification-title-wrapper .notification-title-text {
      flex: 0 1;
      text-align: center;
      font-size: 1.3rem;
      color: var(--labels_important-active-labels-text-graphics); }
  .notification-wrapper .notification-content-wrapper {
    position: relative;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    flex: 1 1;
    color: var(--labels_secondary-inactive-label-text-graphics); }
    .notification-wrapper .notification-content-wrapper .notification-content-title.with-border-bottom {
      border-bottom: 1px solid var(--calculated_important-border); }
    .notification-wrapper .notification-content-wrapper.notification_verification {
      text-align: center; }
      .notification-wrapper .notification-content-wrapper.notification_verification .notification-content-title {
        text-transform: capitalize; }
      .notification-wrapper .notification-content-wrapper.notification_verification .notification-content-information {
        padding: 1rem; }
    .notification-wrapper .notification-content-wrapper.logout-notification, .notification-wrapper .notification-content-wrapper.contact-form-notification {
      text-align: center; }
      .notification-wrapper .notification-content-wrapper.logout-notification .notification-content-information, .notification-wrapper .notification-content-wrapper.contact-form-notification .notification-content-information {
        margin: 3rem auto; }
    .notification-wrapper .notification-content-wrapper.logout-notification .notification-content-icon {
      width: 7rem;
      margin: 4rem auto; }
    .notification-wrapper .notification-content-wrapper.contact-form-notification .notification-content-title {
      font-size: 3.5rem;
      text-transform: uppercase;
      font-weight: normal; }
    .notification-wrapper .notification-content-wrapper .notification-content-icon {
      width: 10rem;
      max-height: 10rem;
      margin: 1rem auto; }
    .notification-wrapper .notification-content-wrapper .notification-content-information {
      width: 100%;
      margin: 1rem 0;
      font-weight: bold; }
      .notification-wrapper .notification-content-wrapper .notification-content-information .text_disabled {
        color: var(--labels_secondary-inactive-label-text-graphics); }
    .notification-wrapper .notification-content-wrapper .notification-content-header {
      position: relative;
      font-size: 1.3rem;
      color: var(--labels_important-active-labels-text-graphics); }
  .notification-wrapper .notification-buttons-wrapper .button-eur {
    background-color: var(--coin-eur); }
  .notification-wrapper .notification-information {
    padding: 1rem 0;
    font-size: 1rem; }
  .notification-wrapper .trade-notification,
  .notification-wrapper .new-order-notification,
  .notification-wrapper .order-notification {
    margin: 1rem; }
    .notification-wrapper .trade-notification .notification-content-icon,
    .notification-wrapper .new-order-notification .notification-content-icon,
    .notification-wrapper .order-notification .notification-content-icon {
      margin-top: 0;
      margin-bottom: 2.5rem; }
    .notification-wrapper .trade-notification .notification-content-title,
    .notification-wrapper .new-order-notification .notification-content-title,
    .notification-wrapper .order-notification .notification-content-title {
      margin: 1rem 0; }
  .notification-wrapper .notification-buttons-wrapper .holla-button {
    font-size: 14px;
    font-weight: bold; }
  .notification-wrapper .with_price-block_amount {
    margin: 3rem 0 4rem;
    line-height: 3.5rem; }
  .notification-wrapper .invite_friends_wrapper {
    width: 36rem; }
    .notification-wrapper .invite_friends_wrapper .user_refer_info {
      background-color: var(--calculated_specials_highlight-box);
      height: 5.5rem; }
    .notification-wrapper .invite_friends_wrapper .icon_title-wrapper .icon_title-svg {
      height: 8rem;
      width: 8rem; }
    .notification-wrapper .invite_friends_wrapper .icon_title-wrapper .icon_title-svg svg {
      height: 8rem;
      width: 8rem; }
    .notification-wrapper .invite_friends_wrapper .icon_title-wrapper .icon_title-text.title {
      font-size: 2rem; }
  .notification-wrapper svg .check-order-1,
  .notification-wrapper svg .check-order-2,
  .notification-wrapper svg .coin-btc-4,
  .notification-wrapper svg .coin-base-4 {
    stroke: var(--labels_important-active-labels-text-graphics); }
  .notification-wrapper svg .market-buy,
  .notification-wrapper svg .check-order-2,
  .notification-wrapper svg .coin-btc-1,
  .notification-wrapper svg .coin-btc-5,
  .notification-wrapper svg .coin-base-1,
  .notification-wrapper svg .coin-base-5,
  .notification-wrapper svg .email_sent,
  .notification-wrapper svg .limit-sell-03,
  .notification-wrapper svg .limit-sell-04,
  .notification-wrapper svg .limit-buy-03,
  .notification-wrapper svg .limit-buy-04,
  .notification-wrapper svg .refer-icon,
  .notification-wrapper svg .stake_icon {
    fill: var(--labels_important-active-labels-text-graphics); }
  .notification-wrapper svg .limit-sell-01,
  .notification-wrapper svg .limit-buy-01,
  .notification-wrapper svg .limit-buy-02,
  .notification-wrapper svg .limit-sell-02,
  .notification-wrapper svg .wallet-selected-2 {
    stroke: var(--labels_important-active-labels-text-graphics);
    fill: transparent; }
  .notification-wrapper svg g .wallet-selected-2 {
    stroke: transparent;
    fill: var(--labels_important-active-labels-text-graphics); }
  .notification-wrapper .partial-fill {
    fill: var(--labels_important-active-labels-text-graphics); }
  .notification-wrapper .limit-trade-tick {
    fill: var(--labels_secondary-inactive-label-text-graphics); }
  .notification-wrapper .new-order-notification svg {
    fill: var(--labels_important-active-labels-text-graphics); }
    .notification-wrapper .new-order-notification svg .newWave-icon {
      fill: var(--labels_important-active-labels-text-graphics); }
    .notification-wrapper .new-order-notification svg .generate_api {
      fill: var(--labels_important-active-labels-text-graphics); }
  .notification-wrapper .notification-wrapper {
    color: var(--labels_secondary-inactive-label-text-graphics); }
    .notification-wrapper .notification-wrapper .notification-title-wrapper {
      border-bottom-color: var(--calculated_important-border); }
      .notification-wrapper .notification-wrapper .notification-title-wrapper .notification-title-icon svg .deposit-02,
      .notification-wrapper .notification-wrapper .notification-title-wrapper .notification-title-icon svg .deposit-complete-01,
      .notification-wrapper .notification-wrapper .notification-title-wrapper .notification-title-icon svg .deposit-complete-btc-01 {
        fill: var(--labels_important-active-labels-text-graphics); }
      .notification-wrapper .notification-wrapper .notification-title-wrapper .notification-title-text {
        color: var(--labels_important-active-labels-text-graphics); }
    .notification-wrapper .notification-wrapper .notification-content-information .text_disabled {
      color: var(--labels_secondary-inactive-label-text-graphics); }

.notification-wrapper .notification-content-wrapper.notification-withdrawal .notification-content-icon {
  width: 80% !important; }

.notification-wrapper .notification-content-wrapper.notification-withdrawal .notification-link-wrapper {
  font-size: 1rem; }

.direction_ltr .notification-title-text,
.direction_ltr .notification-withdrawal .notification-content-title {
  font-family: "Roboto";
  font-weight: 500; }

.layout-mobile .notification-wrapper {
  flex: 1 1;
  margin: auto; }
  .layout-mobile .notification-wrapper .notification-withdrawal {
    justify-content: space-evenly; }
  .layout-mobile .notification-wrapper .trade-notification,
  .layout-mobile .notification-wrapper .order-notification {
    margin: 0.75rem;
    flex-direction: row;
    color: var(--labels_important-active-labels-text-graphics); }
    .layout-mobile .notification-wrapper .trade-notification .notification-content-icon,
    .layout-mobile .notification-wrapper .order-notification .notification-content-icon {
      margin: 0;
      padding: 1rem;
      display: flex;
      align-items: center;
      justify-content: center; }
      .layout-mobile .notification-wrapper .trade-notification .notification-content-icon svg,
      .layout-mobile .notification-wrapper .order-notification .notification-content-icon svg {
        width: 6rem;
        height: 6rem; }
    .layout-mobile .notification-wrapper .trade-notification .notification-content-title,
    .layout-mobile .notification-wrapper .order-notification .notification-content-title {
      margin: 0; }

.layout-mobile .trade_active_orders-wrapper {
  max-height: 35vh !important; }

.trade_post_icon {
  width: 1.5rem; }
  .trade_post_icon svg .wave-icon {
    fill: var(--labels_secondary-inactive-label-text-graphics); }

.post_header {
  font-weight: bold; }

.trade_announcement {
  font-size: 1.5rem; }

.announcement-notification-list-item {
  color: var(--labels_important-active-labels-text-graphics);
  border-bottom: 1px solid var(--calculated_secondary-border); }

.post-content .notifications_list-item-text a {
  color: var(--specials_buttons-links-and-highlights); }

.post-content .notifications_list-item-text span,
.post-content .notifications_list-item-text p {
  background-color: var(--base_wallet-sidebar-and-popup) !important; }

.post-content .notifications_list-item-timestamp {
  color: var(--labels_secondary-inactive-label-text-graphics); }

.dropdown-content .dropdown-option {
  padding: 0; }

.dropdown-content.disabled {
  cursor: not-allowed;
  color: var(--labels_secondary-inactive-label-text-graphics); }

.disabled .field-children {
  color: var(--labels_secondary-inactive-label-text-graphics); }
  .disabled .field-children .dropdown-triangle:after {
    border: none; }

.dropdown-options-wrapper {
  position: absolute;
  top: 100%;
  width: 100%;
  background: var(--base_background);
  z-index: 10000;
  max-height: 10rem;
  overflow-y: auto;
  border: 1px solid var(--calculated_secondary-border);
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-top: 0; }
  .dropdown-options-wrapper.dropdown-date {
    max-height: 15rem; }

.dropdown-option {
  padding: 0.25rem 1rem;
  display: flex;
  align-items: center; }
  .dropdown-option > .icon {
    max-height: 1rem; }
  .dropdown-option .option-icon {
    width: 0.8rem; }

.direction_ltr .dropdown-options-wrapper {
  left: 0; }

.direction_ltr .dropdown-option > .icon {
  margin-right: 0.25rem; }

.direction_rtl .dropdown-options-wrapper {
  right: 0; }

.direction_rtl .dropdown-option > .icon {
  margin-left: 0.25rem; }

.layout-mobile .dropdown-option > .icon {
  max-height: 1.8rem; }

.layout-mobile .dropdown-option .option-icon {
  width: 1.8rem; }

.field-wrapper {
  position: relative;
  width: 100%;
  font-size: 1rem;
  margin: 1rem 0; }
  .field-wrapper.error {
    color: var(--specials_notifications-alerts-warnings);
    margin-bottom: 15px; }
  .field-wrapper.inline {
    display: flex;
    align-items: flex-end; }
    .field-wrapper.inline > div {
      flex: 1 1; }
      .field-wrapper.inline > div.field-error-content {
        padding-bottom: 0.5rem; }
  .field-wrapper .checkfield-input-wrapper {
    align-items: center; }

.field-label-wrapper {
  display: flex;
  align-items: center; }
  .field-label-wrapper > div {
    flex: 1 1; }
  .field-label-wrapper .field_warning_wrapper {
    width: 80%; }

.field-label {
  color: var(--labels_important-active-labels-text-graphics);
  font-size: 14px; }

.field-content {
  position: relative;
  display: flex;
  flex-direction: column; }

.field-children {
  padding: 0.4rem 1rem 0.2rem;
  position: relative; }
  .field-children.custom {
    padding: 0; }
  .field-children .field-valid {
    position: absolute;
    width: 1.25rem;
    height: 1.25rem;
    bottom: 0.25rem; }
    .field-children .field-valid svg {
      width: 1.25rem;
      height: 1.25rem; }

.input-box-field {
  width: 100%;
  height: 38px;
  padding: 10px 10px;
  border-radius: 2px;
  border: 1px solid #e4e4e4;
  background-color: var(--base_wallet-sidebar-and-popup); }
  .input-box-field .input_field-input::-webkit-input-placeholder {
    color: var(--labels_secondary-inactive-label-text-graphics);
    font-size: 14px; }
  .input-box-field .input_field-input::placeholder {
    color: var(--labels_secondary-inactive-label-text-graphics);
    font-size: 14px; }

.field-content-outline:before {
  content: '';
  height: 1.5px;
  width: 100%;
  bottom: 0;
  position: absolute;
  transition: 0.3s ease all;
  background: var(--labels_secondary-inactive-label-text-graphics); }

.field-content-outline:after {
  content: '';
  height: 1.5px;
  width: 0;
  bottom: 0;
  position: absolute;
  transition: 0.3s ease all;
  background: var(--labels_secondary-inactive-label-text-graphics); }

.field-content-outline.focused:after {
  width: 100%;
  background: var(--labels_important-active-labels-text-graphics); }

.field-wrapper.error .field-content-outline:before {
  background: var(--specials_notifications-alerts-warnings); }

.field-error-content {
  position: relative;
  display: flex;
  align-items: center;
  height: 1.25rem; }
  .field-error-content.field-error-hidden {
    visibility: hidden; }

.field-error-icon {
  height: 1.25rem;
  width: 1.25rem; }

.dumb-field-wrapper {
  color: var(--specials_buttons-links-and-highlights); }
  .dumb-field-wrapper > .field-content > .field-children {
    padding-left: 0.25rem; }
  .dumb-field-wrapper .field-children {
    padding-bottom: 0; }
  .dumb-field-wrapper .address-line {
    word-break: break-all; }
  .dumb-field-wrapper .copy-wrapper {
    top: 0;
    position: unset; }

.field-wrapper .action_notification-wrapper .action_notification-svg svg {
  width: 1rem;
  height: 1rem; }

.field_warning_wrapper {
  font-size: 1rem;
  color: var(--specials_notifications-alerts-warnings); }

.direction_ltr .field-error-text {
  padding-left: 0.25rem; }

.direction_ltr .field_warning_text {
  padding-left: 0.25rem; }

.direction_ltr .field-children.valid > *:not(:last-child) {
  padding-right: 1.25rem; }

.direction_ltr .field-children .field-valid {
  right: 0; }

.direction_ltr .field-wrapper.inline .field-error-content {
  margin: 0.5rem 0 0 0; }

.direction_ltr .field-wrapper.inline input {
  padding-right: 0; }

.direction_ltr .field-wrapper.inline .field-valid {
  right: -1.75rem; }

.direction_ltr .field-wrapper .action_notification-wrapper.with-tick-icon {
  right: 1.25rem; }

.direction_rtl .field-error-text {
  padding-right: 0.25rem; }

.direction_rtl .field_warning_text {
  padding-right: 0.25rem; }

.direction_rtl .field-children.valid > *:not(:last-child) {
  padding-left: 1.25rem; }

.direction_rtl .field-children .field-valid {
  left: 0; }

.direction_rtl .field-wrapper.inline .field-error-content {
  margin-right: 0.5rem; }

.direction_rtl .field-wrapper.inline input {
  padding-left: 0; }

.direction_rtl .field-wrapper.inline .field-valid {
  left: -1.75rem; }

.direction_rtl .field-wrapper .action_notification-wrapper.with-tick-icon {
  left: 1.25rem; }

.layout-mobile.direction_ltr .field-wrapper.field-valid.with-notification .action_notification-wrapper {
  right: 1.5rem; }

.layout-mobile.direction_rtl .field-wrapper.field-valid.with-notification .action_notification-wrapper {
  left: 1.5rem; }

.layout-mobile .field-label-wrapper > div {
  flex: 1 1;
  width: 100%; }

.layout-mobile .field-label-wrapper .field_warning_wrapper {
  width: 100%; }

.layout-mobile .field_warning_wrapper {
  padding-bottom: 1rem; }

.input_icon {
  margin-right: 0.25rem;
  width: 1.2rem; }

.custom-field-width {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 50%; }

.input_field-input {
  width: 100%;
  position: relative;
  background: transparent;
  border: none !important;
  line-height: 1rem; }
  .input_field-input:focus {
    outline: none; }
  .input_field-input::-webkit-input-placeholder {
    color: var(--labels_secondary-inactive-label-text-graphics) !important; }
  .input_field-input::placeholder {
    color: var(--labels_secondary-inactive-label-text-graphics) !important; }
  .input_field-input:disabled {
    cursor: not-allowed; }

.dropdown-triangle:after {
  content: '';
  height: 0;
  width: 0;
  border-bottom: 0.25rem solid var(--labels_important-active-labels-text-graphics);
  border-top: 0.25rem solid transparent;
  position: absolute;
  bottom: 0.35rem; }

.datefield-wrapper {
  position: relative; }
  .datefield-wrapper .field-children:last-child .year .field-content,
  .datefield-wrapper .field-children:last-child .month .field-content,
  .datefield-wrapper .field-children:last-child .day .field-content {
    border: 1px solid var(--calculated_important-border);
    background-color: var(--base_wallet-sidebar-and-popup);
    padding: 6px 0; }
  .datefield-wrapper .datefield-values-wrapper {
    display: flex; }
    .datefield-wrapper .datefield-values-wrapper > * {
      flex: 1 1; }
    .datefield-wrapper .datefield-values-wrapper .datefield-toggle.fa {
      min-width: 10rem; }
    .datefield-wrapper .datefield-values-wrapper .datefield-toggle.en {
      flex: 0 1;
      min-width: 4rem; }
  .datefield-wrapper .SingleDatePicker {
    position: absolute; }
  .datefield-wrapper .SingleDatePickerInput {
    display: none; }
  .datefield-wrapper .datefield-farsi_picker #farsiDatePicker {
    display: none; }

.direction_ltr .datefield-wrapper .datefield-values-wrapper {
  padding-right: 0.25rem !important; }
  .direction_ltr .datefield-wrapper .datefield-values-wrapper > *:not(:last-child) {
    margin-right: 1rem; }
  .direction_ltr .datefield-wrapper .datefield-values-wrapper .dropdown-triangle::after {
    bottom: 0 !important; }

.direction_rtl .datefield-wrapper .datefield-values-wrapper {
  padding-left: 0.25rem !important; }
  .direction_rtl .datefield-wrapper .datefield-values-wrapper > *:not(:last-child) {
    margin-left: 1rem; }

.placeholder {
  font-size: 0.9rem;
  font-weight: bold;
  color: var(--labels_secondary-inactive-label-text-graphics); }

.no_bottom {
  bottom: 0; }

.file_wrapper,
.multiple_file_wrapper {
  display: flex;
  justify-content: space-between;
  padding-right: 0 !important; }
  .file_wrapper > .action_notification-wrapper,
  .multiple_file_wrapper > .action_notification-wrapper {
    position: relative;
    top: 0 !important; }
  .file_wrapper .paper-clip-icon .action_notification-svg,
  .multiple_file_wrapper .paper-clip-icon .action_notification-svg {
    background-color: var(--specials_buttons-links-and-highlights);
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;
    display: flex;
    margin-left: 5px; }
    .file_wrapper .paper-clip-icon .action_notification-svg svg,
    .multiple_file_wrapper .paper-clip-icon .action_notification-svg svg {
      width: 0.8rem !important;
      height: 0.8rem !important; }

.multiple_file_wrapper {
  display: unset; }

.checkfield-wrapper {
  margin: 1rem 0; }
  .checkfield-wrapper .checkfield-label {
    padding: 0 0.25rem;
    margin-bottom: 0;
    margin-left: 0.25rem;
    vertical-align: middle;
    display: flex;
    align-items: center;
    flex-wrap: wrap; }
  .checkfield-wrapper .checkfield-input {
    margin-top: 2px; }

.toggle_button-wrapper .toggle-content {
  padding: 0 1rem;
  text-transform: uppercase;
  padding: 0 0.5rem;
  font-size: 1rem;
  color: var(--labels_secondary-inactive-label-text-graphics);
  height: 22px;
  font-family: "Roboto";
  font-weight: 500; }
  .toggle_button-wrapper .toggle-content .selected {
    font-family: "Roboto";
    font-weight: 900;
    color: var(--labels_important-active-labels-text-graphics); }
  .toggle_button-wrapper .toggle-content .toggle-action_button {
    border: 2px solid var(--calculated_important-border);
    height: 100%;
    width: 42px;
    border-radius: 14px;
    margin: 0 0.5rem;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer; }
    .toggle_button-wrapper .toggle-content .toggle-action_button.left .toggle-action_button-display {
      right: calc(100% - 18px); }
    .toggle_button-wrapper .toggle-content .toggle-action_button.right .toggle-action_button-display {
      right: 0; }
    .toggle_button-wrapper .toggle-content .toggle-action_button .toggle-action_button-display {
      position: absolute;
      transition: 0.5s;
      background: var(--labels_important-active-labels-text-graphics);
      height: 14px;
      width: 14px;
      border-radius: 50%;
      margin: 2px;
      top: 0; }

.toggle_button-wrapper .toggle-side_line {
  position: relative; }
  .toggle_button-wrapper .toggle-side_line::after {
    content: '';
    height: 1px;
    width: 100%;
    position: absolute;
    background-color: var(--calculated_important-border);
    left: 0;
    right: 0;
    top: 50%; }

.direction_ltr .dropdown-triangle:after {
  border-right: 0.25rem solid var(--labels_important-active-labels-text-graphics);
  border-left: 0.25rem solid transparent;
  right: 3px; }

.direction_ltr .placeholder {
  padding-right: 0.5rem; }

.direction_rtl .dropdown-triangle:after {
  border-right: 0.25rem solid transparent;
  border-left: 0.25rem solid var(--labels_important-active-labels-text-graphics);
  left: 0px; }

.direction_rtl .placeholder {
  padding-left: 0.5rem; }

.direction_rtl .toggle_button-wrapper .toggle-content {
  font-family: inherit; }
  .direction_rtl .toggle_button-wrapper .toggle-content .selected {
    font-family: inherit; }

.toggle-disabled {
  cursor: not-allowed;
  opacity: 0.35; }

.trade_input-wrapper {
  display: flex;
  flex-direction: column; }
  .trade_input-wrapper > * {
    flex: 1 1;
    position: relative; }
  .trade_input-wrapper .trade_input-label {
    padding-bottom: 0.25rem; }
  .trade_input-wrapper .trade_input-input-wrapper {
    position: relative; }
    .trade_input-wrapper .trade_input-input-wrapper > input {
      padding: 0.25rem;
      position: relative;
      width: 100%;
      height: 2.5rem;
      background: var(--labels_fields);
      border: 1px solid var(--calculated_secondary-border);
      border-radius: 2px;
      color: var(--labels_important-active-labels-text-graphics); }
      .trade_input-wrapper .trade_input-input-wrapper > input:focus {
        outline: none;
        border: 1.5px solid var(--specials_buttons-links-and-highlights); }
  .trade_input-wrapper .trade_input-input-currency {
    width: 2.5rem;
    height: 2.5rem;
    position: absolute;
    top: 0;
    font-weight: bold;
    color: var(--calculated_important-border); }
  .trade_input-wrapper .initialize-animation {
    /* Safari 4.0 - 8.0 */
    /* Standard syntax */ }
    .trade_input-wrapper .initialize-animation > input {
      -webkit-animation-name: bounce;
      /* Safari 4.0 - 8.0 */
      -webkit-animation-duration: 3s;
      /* Safari 4.0 - 8.0 */
      animation-name: bounce;
      animation-duration: 3s;
      border: 1px solid var(--calculated_important-border); }

@-webkit-keyframes bounce {
  15% {
    border-color: var(--specials_buttons-links-and-highlights); }
  75% {
    border-color: var(--calculated_important-border); } }

@keyframes bounce {
  15% {
    border-color: var(--specials_buttons-links-and-highlights); }
  75% {
    border-color: var(--calculated_important-border); } }

.direction_ltr .trade_input-input-wrapper > input {
  padding-right: 2.5rem; }

.direction_ltr .trade_input-input-currency {
  right: 0; }

.direction_rtl .trade_input-input-wrapper > input {
  padding-left: 2.5rem; }

.direction_rtl .trade_input-input-currency {
  left: 0; }

.size-slider .ant-slider-with-marks {
  margin-bottom: 10px; }

.size-slider .ant-slider-rail,
.size-slider .ant-slider-dot {
  background-color: var(--labels_secondary-inactive-label-text-graphics);
  border-color: var(--labels_secondary-inactive-label-text-graphics); }

.size-slider .ant-slider-dot-active,
.size-slider .ant-slider-track,
.size-slider .ant-slider-handle {
  background-color: var(--specials_buttons-links-and-highlights);
  border-color: var(--specials_buttons-links-and-highlights); }

.trade-form-select {
  padding-bottom: 1.5rem;
  font-size: 11px !important; }

.ant-slider-mark-text {
  color: var(--labels_secondary-inactive-label-text-graphics);
  font-family: 'Open Sans' !important;
  font-size: 0.9rem !important; }

.ant-slider-mark-text-active {
  color: var(--labels_important-active-labels-text-graphics);
  font-weight: bold; }

.holla-button {
  width: 100%;
  font-size: 1rem;
  padding: 0 4px;
  background-color: var(--specials_buttons-links-and-highlights) !important;
  border-radius: 5px; }
  .holla-button.button-success {
    background-color: var(--specials_checks-okay-done) !important; }
  .holla-button.button-fail {
    background-color: var(--specials_notifications-alerts-warnings) !important; }
  .holla-button.disabled {
    background-color: #b1b1b1 !important;
    color: #fff; }
  .holla-button:active, .holla-button:focus {
    outline: none;
    border: none; }

.checkbutton-wrapper {
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .checkbutton-wrapper .checkbutton-input-wrapper {
    width: 300px;
    padding: 10px;
    margin-top: 35px;
    justify-content: center;
    background-color: var(--theme_color_1);
    color: #000;
    border-radius: 5px; }
    .checkbutton-wrapper .checkbutton-input-wrapper .checkbutton-input-wrapper--label {
      margin: 0;
      padding-left: 0.5rem; }
    .checkbutton-wrapper .checkbutton-input-wrapper .checkbutton-input-wrapper--image,
    .checkbutton-wrapper .checkbutton-input-wrapper .checkbutton-input-wrapper--loader {
      width: 2rem; }
    .checkbutton-wrapper .checkbutton-input-wrapper .checkbutton-input-wrapper--image svg {
      width: 2rem;
      height: 2rem;
      stroke: #000; }
  .checkbutton-wrapper .checkbutton-content-wrapper {
    margin-left: 2rem; }

.buttons-row > *:not(:last-child) {
  margin-right: 1rem; }

.direction_ltr .holla-button-font {
  font-family: "Roboto"; }

.direction_rtl .holla-button-font {
  font-family: inherit !important; }

.ReactModal__Overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100 !important;
  background-color: var(--calculated_base_modal-overlay) !important; }

.ReactModal__Content {
  padding: 2.5rem !important;
  top: auto !important;
  bottom: auto !important;
  left: auto !important;
  right: auto !important;
  max-width: calc(100% - 2.5rem);
  max-height: calc(100% - 2.5rem);
  overflow: auto;
  border: none !important;
  background-color: var(--base_wallet-sidebar-and-popup) !important; }
  .ReactModal__Content .action_notification-wrapper.close-button {
    margin-top: 1.5rem; }

.close-button.action_notification-wrapper .action_notification-image {
  width: 2rem;
  height: 2rem;
  fill: var(--labels_important-active-labels-text-graphics); }

.success_display-wrapper {
  min-width: 25rem;
  min-height: 30rem; }
  .success_display-wrapper > * {
    flex: 0 1; }

.success_display-content {
  height: auto;
  flex: 1 1; }

.success_display-content-image {
  width: 7.5rem;
  height: 7.5rem; }
  .success_display-content-image svg {
    width: 7.5rem;
    height: 7.5rem; }

.success_display-content-text {
  margin: 1rem 0; }

.direction_ltr .ReactModal__Content .action_notification-wrapper.close-button {
  margin-right: 1.5rem; }

.direction_rtl .ReactModal__Content .action_notification-wrapper.close-button {
  margin-left: 1.5rem; }

.app-dialog .ReactModal__Overlay {
  z-index: 110 !important; }

.app-dialog.full .ReactModal__Overlay {
  background-color: var(--base_wallet-sidebar-and-popup) !important; }

.app-dialog-flex .ReactModal__Overlay {
  z-index: 10000 !important; }

.layout-mobile .ReactModal__Overlay {
  background-color: transparent !important; }

.layout-mobile .success_display-wrapper {
  margin: auto; }

.layout-mobile .ReactModal__Content {
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  min-width: 100vw;
  max-width: 100vw;
  min-height: calc( 100vh - 7rem);
  max-height: calc( 100vh - 7rem);
  border-radius: 0 !important;
  padding: 0 !important; }
  .layout-mobile .ReactModal__Content .dialog-mobile-content {
    padding: 2.5rem !important;
    min-height: calc( 100vh - 14rem);
    max-height: calc( 100vh - 14rem);
    display: flex; }

.layout-mobile.LogoutModal .ReactModal__Content {
  top: 0 !important; }

.layout-mobile.dialog_full-screen .ReactModal__Content {
  top: 0 !important;
  min-height: 100vh;
  max-height: 100vh; }
  .layout-mobile.dialog_full-screen .ReactModal__Content .dialog-mobile-content {
    min-height: 100vh;
    max-height: 100vh; }

.layout-mobile.dialog_full-screen.login-dialog .dialog-mobile-content {
  align-items: center; }

.layout-mobile.compressed .ReactModal__Content {
  top: 0 !important;
  min-height: inherit;
  max-height: inherit;
  background: transparent !important; }
  .layout-mobile.compressed .ReactModal__Content .dialog-mobile-content {
    min-height: inherit;
    max-height: inherit; }

.dialog-compressed-wrapper {
  background-color: var(--labels_important-active-labels-text-graphics);
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0; }

.accordion_section {
  position: relative; }

.layout-mobile .holla-button-font {
  font-size: 1.2rem; }

.layout-mobile .accordion_wrapper .accordion_section .accordion_section_title {
  font-size: 1.75rem; }

.layout-mobile .accordion_wrapper .accordion_section .accordion_section_content {
  font-size: 13px !important; }

.layout-mobile .accordion_wrapper .action_notification-svg svg {
  width: 1.75rem !important;
  height: 1.75rem !important; }

.layout-mobile .accordion_wrapper .accordion_section_content {
  overflow-x: auto;
  font-size: 1.2rem; }
  .layout-mobile .accordion_wrapper .accordion_section_content .information_section {
    font-size: 1.2rem; }
  .layout-mobile .accordion_wrapper .accordion_section_content .field-wrapper {
    font-size: 1.2rem; }
  .layout-mobile .accordion_wrapper .accordion_section_content table tbody .table-row {
    font-size: 1.25rem; }
    .layout-mobile .accordion_wrapper .accordion_section_content table tbody .table-row .td-levelball .td-levelball-content {
      width: 2.5rem;
      height: 2.5rem; }
  .layout-mobile .accordion_wrapper .accordion_section_content table tbody td:not(:first-child) {
    padding: 1.5rem 0.25rem; }

.layout-mobile .direction_ltr .accordion_wrapper .accordion_section .accordion_section_content_text.with_arrow:after {
  border: solid var(--labels_secondary-inactive-label-text-graphics) !important;
  border-width: 1px 0 0 1px !important;
  display: inline-block !important;
  padding: 3px !important;
  -webkit-transform: rotate(131deg) !important;
          transform: rotate(131deg) !important;
  content: '';
  margin-right: -0.6rem;
  position: absolute;
  right: -0.7rem;
  top: calc(50% - 0.3rem); }

.layout-mobile .direction_ltr .accordion_wrapper .accordion_section .accordion_section_title.accordion_section--open .accordion_section_content_text:after {
  -webkit-transform: rotate(-135deg) !important;
          transform: rotate(-135deg) !important;
  right: -1.1rem;
  top: calc(50% - 0.38rem); }

.layout-mobile .direction_ltr .accordion_wrapper .accordion_section.disabled .accordion_section_content_text:after {
  border-left-color: var(--labels_secondary-inactive-label-text-graphics); }

.accordion_wrapper .accordion_section {
  border-top: 1px solid var(--calculated_important-border);
  font-size: 1.3rem;
  padding: 0; }
  .accordion_wrapper .accordion_section .action_notification-svg svg {
    width: 1rem;
    height: 1rem; }
  .accordion_wrapper .accordion_section .accordion_section_title {
    padding: 0.5rem 0;
    position: relative; }
    .accordion_wrapper .accordion_section .accordion_section_title .sidebar_hub-section-icon {
      height: 1.5rem;
      width: 1.5rem;
      margin-right: 0.5rem; }
      .accordion_wrapper .accordion_section .accordion_section_title .sidebar_hub-section-icon svg path {
        fill: transparent;
        stroke: var(--labels_secondary-inactive-label-text-graphics); }
    .accordion_wrapper .accordion_section .accordion_section_title.accordion_section--open .accordion_section_content {
      margin-bottom: 1.5rem; }
    .accordion_wrapper .accordion_section .accordion_section_title.accordion_section--open .accordion_section_content_text {
      color: var(--labels_important-active-labels-text-graphics); }
      .accordion_wrapper .accordion_section .accordion_section_title.accordion_section--open .accordion_section_content_text.with_arrow:after {
        border-left-color: var(--labels_important-active-labels-text-graphics) !important;
        border-top-color: var(--labels_important-active-labels-text-graphics) !important; }
    .accordion_wrapper .accordion_section .accordion_section_title.accordion_section--open .sidebar_hub-section-icon svg path {
      fill: var(--labels_important-active-labels-text-graphics);
      stroke: var(--labels_important-active-labels-text-graphics); }
    .accordion_wrapper .accordion_section .accordion_section_title.accordion_section--open .wallet_section-title-amount {
      color: var(--labels_important-active-labels-text-graphics) !important; }
  .accordion_wrapper .accordion_section .accordion_section_content_text {
    position: relative;
    color: var(--labels_secondary-inactive-label-text-graphics); }
    .accordion_wrapper .accordion_section .accordion_section_content_text .accordion_section_content_text-subtitle {
      color: var(--labels_inactive-button); }
  .accordion_wrapper .accordion_section.disabled {
    color: var(--labels_secondary-inactive-label-text-graphics); }
  .accordion_wrapper .accordion_section:last-child {
    border-bottom: 1px solid var(--calculated_important-border); }
  .accordion_wrapper .accordion_section .accordion_section_content {
    font-size: 14px;
    padding: 0; }
  .accordion_wrapper .accordion_section .action_notification-wrapper {
    top: calc(50% - 0.65rem);
    margin: 0 0.5rem; }

.direction_ltr .accordion_wrapper .accordion_section .accordion_section_content_text.with_arrow:after {
  border: solid var(--labels_secondary-inactive-label-text-graphics) !important;
  border-width: 2px 0 0 2px !important;
  display: inline-block !important;
  padding: 3px !important;
  -webkit-transform: rotate(131deg) !important;
          transform: rotate(131deg) !important;
  content: '';
  margin-right: -0.6rem;
  position: absolute;
  right: -0.7rem;
  top: calc(50% - 0.3rem); }

.direction_ltr .accordion_wrapper .accordion_section .accordion_section_title.accordion_section--open .accordion_section_content_text:after {
  -webkit-transform: rotate(-135deg) !important;
          transform: rotate(-135deg) !important; }

.direction_ltr .accordion_wrapper .accordion_section.disabled .accordion_section_content_text:after {
  border-left-color: var(--labels_secondary-inactive-label-text-graphics); }

.direction_rtl .accordion_wrapper .accordion_section .accordion_section_content_text.with_arrow:after {
  border: solid var(--labels_secondary-inactive-label-text-graphics);
  border-width: 2px 0 0 2px !important;
  display: inline-block !important;
  padding: 3px !important;
  -webkit-transform: rotate(-50deg) !important;
          transform: rotate(-50deg) !important;
  content: '';
  left: -0.7rem;
  top: calc(50% - 0.3rem); }

.direction_rtl .accordion_wrapper .accordion_section .accordion_section_title.accordion_section--open .accordion_section_content_text:after {
  -webkit-transform: rotate(-135deg) !important;
          transform: rotate(-135deg) !important;
  top: calc(50% - 0.4rem); }

.direction_rtl .accordion_wrapper .accordion_section .accordion_section_title .sidebar_hub-section-icon {
  margin-left: 0.5rem; }

.direction_rtl .accordion_wrapper .accordion_section.disabled .accordion_section_content_text:after {
  border-top-color: var(--calculated_secondary-border); }

.action_notification-wrapper {
  position: absolute;
  top: 0.25rem;
  display: flex;
  align-items: center; }
  .action_notification-wrapper .notification-info {
    color: var(--specials_buttons-links-and-highlights); }
  .action_notification-wrapper .notification-warning {
    color: var(--specials_notifications-alerts-warnings); }
  .action_notification-wrapper .notification-success {
    color: var(--specials_checks-okay-done); }
  .action_notification-wrapper .notification-disabled {
    color: var(--labels_secondary-inactive-label-text-graphics); }
  .action_notification-wrapper .notification-loading {
    color: var(--labels_secondary-inactive-label-text-graphics); }
  .action_notification-wrapper.disabled {
    opacity: 0.3 !important; }
  .action_notification-wrapper .relative {
    position: relative; }
  .action_notification-wrapper .action_notification-text {
    font-size: 1rem;
    text-transform: uppercase; }
  .action_notification-wrapper .action_notification-image {
    width: 1rem;
    height: 1rem; }
  .action_notification-wrapper.icon_on-right {
    flex-direction: row; }
  .action_notification-wrapper.icon_on-left {
    flex-direction: row-reverse; }
  .action_notification-wrapper .action_notification-svg > div {
    display: flex;
    align-items: center; }
  .action_notification-wrapper .action_notification-svg svg {
    width: 1rem;
    height: 1rem; }
  .action_notification-wrapper .cancel-cross-2 {
    fill: none;
    stroke: var(--specials_buttons-links-and-highlights); }
  .action_notification-wrapper .icon-clip-1,
  .action_notification-wrapper .icon-plus-1,
  .action_notification-wrapper .icon-edit-1,
  .action_notification-wrapper .icon-help-1,
  .action_notification-wrapper .icon-copy-1,
  .action_notification-wrapper .icon-tamer-01,
  .action_notification-wrapper .arrow-1,
  .action_notification-wrapper .trans-history-1,
  .action_notification-wrapper .trans-history-2,
  .action_notification-wrapper .trans-history-3,
  .action_notification-wrapper .cancel-cross-1 {
    fill: var(--specials_buttons-links-and-highlights); }
  .action_notification-wrapper .trans-history-4,
  .action_notification-wrapper .cancel-cross-3 {
    stroke: var(--specials_buttons-links-and-highlights); }

.direction_ltr .action_notification-wrapper {
  font-family: "Roboto" !important;
  font-weight: 500; }
  .direction_ltr .action_notification-wrapper.left {
    left: 0; }
  .direction_ltr .action_notification-wrapper.right {
    right: 0; }
  .direction_ltr .action_notification-wrapper .action_notification-image.rotate, .direction_ltr .action_notification-wrapper .action_notification-image.reverse, .direction_ltr .action_notification-wrapper .action_notification-image.rotate_ltr,
  .direction_ltr .action_notification-wrapper .action_notification-svg svg.rotate,
  .direction_ltr .action_notification-wrapper .action_notification-svg svg.reverse,
  .direction_ltr .action_notification-wrapper .action_notification-svg svg.rotate_ltr {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
  .direction_ltr .action_notification-wrapper.icon_on-right .action_notification-image {
    margin-left: 0.25rem; }
  .direction_ltr .action_notification-wrapper.icon_on-left .action_notification-image {
    margin-right: 0.25rem; }
  .direction_ltr .action_notification-wrapper.icon_on-left .action_notification-svg {
    padding-right: 0.25rem; }
  .direction_ltr .action_notification-wrapper.icon_on-right .action_notification-svg {
    padding-left: 0.25rem; }

.direction_rtl .action_notification-wrapper.left {
  right: 0; }

.direction_rtl .action_notification-wrapper.right {
  left: 0; }

.direction_rtl .action_notification-wrapper .action_notification-image.rotate, .direction_rtl .action_notification-wrapper .action_notification-image.reverse, .direction_rtl .action_notification-wrapper .action_notification-image.rotate_rtl,
.direction_rtl .action_notification-wrapper .action_notification-svg svg.rotate,
.direction_rtl .action_notification-wrapper .action_notification-svg svg.reverse,
.direction_rtl .action_notification-wrapper .action_notification-svg svg.rotate_rtl {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg); }

.direction_rtl .action_notification-wrapper.icon_on-right .action_notification-image {
  margin-right: 0.25rem; }

.direction_rtl .action_notification-wrapper.icon_on-left .action_notification-image {
  margin-left: 0.25rem; }

.direction_rtl .action_notification-wrapper.icon_on-left .action_notification-svg {
  padding-left: 0.25rem; }

.direction_rtl .action_notification-wrapper.icon_on-right .action_notification-svg {
  padding-right: 0.25rem; }

.layout-mobile .action_notification-text {
  color: var(--specials_buttons-links-and-highlights); }

.icon_title-wrapper {
  margin-top: 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative; }
  .icon_title-wrapper.underline:after {
    content: '';
    width: 100%;
    height: 1px;
    background: var(--calculated_important-border);
    position: absolute;
    bottom: 0;
    left: 0; }
  .icon_title-wrapper .icon_title-image,
  .icon_title-wrapper .icon_title-svg {
    width: 5rem;
    height: 5rem; }
    .icon_title-wrapper .icon_title-image svg .risk-order-warning,
    .icon_title-wrapper .icon_title-image svg .otp-key,
    .icon_title-wrapper .icon_title-image svg .refer-icon,
    .icon_title-wrapper .icon_title-image svg .send-request,
    .icon_title-wrapper .icon_title-image svg .stake_icon,
    .icon_title-wrapper .icon_title-image svg .otp-code,
    .icon_title-wrapper .icon_title-image svg .st0,
    .icon_title-wrapper .icon_title-image svg .st9,
    .icon_title-wrapper .icon_title-image svg .st10,
    .icon_title-wrapper .icon_title-image svg .trade-history-3,
    .icon_title-wrapper .icon_title-image svg .contact-us-icon,
    .icon_title-wrapper .icon_title-image svg .xht-coin-stack,
    .icon_title-wrapper .icon_title-image svg .account-recovery-1,
    .icon_title-wrapper .icon_title-image svg .account-recovery-success-1,
    .icon_title-wrapper .icon_title-image svg .password-reset-1,
    .icon_title-wrapper .icon_title-image svg .success-black-1,
    .icon_title-wrapper .icon_title-image svg .check-st,
    .icon_title-wrapper .icon_title-image svg .level_icon,
    .icon_title-wrapper .icon_title-image svg .warning-icon,
    .icon_title-wrapper .icon_title-image svg .transaction-history-2,
    .icon_title-wrapper .icon_title-image svg .transaction-history-3,
    .icon_title-wrapper .icon_title-image svg .resend-email-st,
    .icon_title-wrapper .icon_title-image svg .email-confirmation,
    .icon_title-wrapper .icon_title-svg svg .risk-order-warning,
    .icon_title-wrapper .icon_title-svg svg .otp-key,
    .icon_title-wrapper .icon_title-svg svg .refer-icon,
    .icon_title-wrapper .icon_title-svg svg .send-request,
    .icon_title-wrapper .icon_title-svg svg .stake_icon,
    .icon_title-wrapper .icon_title-svg svg .otp-code,
    .icon_title-wrapper .icon_title-svg svg .st0,
    .icon_title-wrapper .icon_title-svg svg .st9,
    .icon_title-wrapper .icon_title-svg svg .st10,
    .icon_title-wrapper .icon_title-svg svg .trade-history-3,
    .icon_title-wrapper .icon_title-svg svg .contact-us-icon,
    .icon_title-wrapper .icon_title-svg svg .xht-coin-stack,
    .icon_title-wrapper .icon_title-svg svg .account-recovery-1,
    .icon_title-wrapper .icon_title-svg svg .account-recovery-success-1,
    .icon_title-wrapper .icon_title-svg svg .password-reset-1,
    .icon_title-wrapper .icon_title-svg svg .success-black-1,
    .icon_title-wrapper .icon_title-svg svg .check-st,
    .icon_title-wrapper .icon_title-svg svg .level_icon,
    .icon_title-wrapper .icon_title-svg svg .warning-icon,
    .icon_title-wrapper .icon_title-svg svg .transaction-history-2,
    .icon_title-wrapper .icon_title-svg svg .transaction-history-3,
    .icon_title-wrapper .icon_title-svg svg .resend-email-st,
    .icon_title-wrapper .icon_title-svg svg .email-confirmation {
      fill: var(--labels_important-active-labels-text-graphics); }
    .icon_title-wrapper .icon_title-image svg .st1,
    .icon_title-wrapper .icon_title-image svg .trade-history-1,
    .icon_title-wrapper .icon_title-image svg .account-recovery-success-2,
    .icon_title-wrapper .icon_title-image svg .password-reset-2,
    .icon_title-wrapper .icon_title-image svg .transaction-history-1,
    .icon_title-wrapper .icon_title-image svg .transaction-history-4,
    .icon_title-wrapper .icon_title-image svg .st11,
    .icon_title-wrapper .icon_title-image svg .wallet-coins-2,
    .icon_title-wrapper .icon_title-svg svg .st1,
    .icon_title-wrapper .icon_title-svg svg .trade-history-1,
    .icon_title-wrapper .icon_title-svg svg .account-recovery-success-2,
    .icon_title-wrapper .icon_title-svg svg .password-reset-2,
    .icon_title-wrapper .icon_title-svg svg .transaction-history-1,
    .icon_title-wrapper .icon_title-svg svg .transaction-history-4,
    .icon_title-wrapper .icon_title-svg svg .st11,
    .icon_title-wrapper .icon_title-svg svg .wallet-coins-2 {
      fill: var(--labels_secondary-inactive-label-text-graphics); }
    .icon_title-wrapper .icon_title-image svg .trade-history-2,
    .icon_title-wrapper .icon_title-image svg .trade-history-4,
    .icon_title-wrapper .icon_title-image svg .trade-history-5,
    .icon_title-wrapper .icon_title-image svg .transaction-history-4,
    .icon_title-wrapper .icon_title-svg svg .trade-history-2,
    .icon_title-wrapper .icon_title-svg svg .trade-history-4,
    .icon_title-wrapper .icon_title-svg svg .trade-history-5,
    .icon_title-wrapper .icon_title-svg svg .transaction-history-4 {
      stroke: none; }
    .icon_title-wrapper .icon_title-image svg .wallet-coins-1,
    .icon_title-wrapper .icon_title-svg svg .wallet-coins-1 {
      stroke: var(--labels_important-active-labels-text-graphics); }
    .icon_title-wrapper .icon_title-image svg .transaction-history-5,
    .icon_title-wrapper .icon_title-svg svg .transaction-history-5 {
      fill: none; }
  .icon_title-wrapper .icon_title-svg svg {
    width: 5rem;
    height: 5rem; }
  .icon_title-wrapper .icon_title-text {
    font-size: 1.3rem;
    margin: 1rem; }
    .icon_title-wrapper .icon_title-text.title {
      font-size: 3.5rem;
      text-transform: capitalize; }
  .icon_title-wrapper .auth_logo-wrapper {
    height: 43px !important;
    width: 200px !important;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat; }

.direction_ltr .icon_title-wrapper {
  font-family: "Roboto"; }

.layout-mobile .icon_title-wrapper .auth_logo-wrapper svg {
  height: 8rem !important;
  width: 15rem !important; }

.layout-mobile .auth-container .action_notification-text,
.layout-mobile .auth-container .font-small {
  font-size: 1rem !important; }

.otp_form-wrapper {
  width: 38rem; }

.otp_form-title-wrapper {
  border-top: 1px solid var(--calculated_secondary-border);
  margin: 1rem 0;
  position: relative; }
  .otp_form-title-wrapper .otp_form-title-text {
    font-weight: bold;
    font-size: 14px; }

.otp_form-fields {
  margin-bottom: 1rem; }

.layout-mobile .otp_form-wrapper {
  flex: 1 1;
  overflow-y: scroll; }

.loader_wrapper {
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: 99999; }
  .loader_wrapper .loader_background {
    height: 100vh; }

.loader_wrapper_relative {
  position: relative;
  width: 100%;
  min-width: 5rem;
  height: 5rem;
  max-width: 100%;
  max-height: 100%; }

.loader {
  width: 5rem;
  height: 5rem;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  pointer-events: none;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center; }

.loader_background {
  background-color: var(--labels_inactive-button);
  -webkit-filter: opacity(0.75);
          filter: opacity(0.75);
  width: 100%;
  height: 100%; }

.currency_ball-wrapper {
  font-family: "Roboto" !important;
  text-transform: uppercase;
  border-radius: 50%;
  font-weight: 500;
  color: var(--base_background); }
  .currency_ball-wrapper.default-coin {
    border: 1px solid var(--calculated_secondary-border);
    color: var(--labels_secondary-inactive-label-text-graphics); }
  .currency_ball-wrapper.btc {
    background-color: var(--coin-btc);
    border: none !important;
    color: var(--coin-btc-text) !important; }
  .currency_ball-wrapper.eur {
    background-color: var(--coin-eur);
    border: none !important;
    color: var(--coin-eur-text) !important; }
  .currency_ball-wrapper.bch {
    background-color: var(--coin-bch);
    border: none !important;
    color: var(--coin-bch-text) !important; }
  .currency_ball-wrapper.eth {
    background-color: var(--coin-eth);
    border: none !important;
    color: var(--coin-eth-text) !important; }
  .currency_ball-wrapper.ltc {
    background-color: var(--coin-ltc);
    border: none !important;
    color: var(--coin-ltc-text) !important; }
  .currency_ball-wrapper.xrp {
    background-color: var(--coin-xrp);
    border: none !important;
    color: var(--coin-xrp-text) !important; }
  .currency_ball-wrapper.xht {
    background-color: var(--coin-xht);
    border: none !important;
    color: var(--coin-xht-text) !important; }
  .currency_ball-wrapper.eos {
    background-color: var(--coin-eos);
    border: none !important;
    color: var(--coin-eos-text) !important; }
  .currency_ball-wrapper.bnb {
    background-color: var(--coin-bnb);
    border: none !important;
    color: var(--coin-bnb-text) !important; }
  .currency_ball-wrapper.link {
    background-color: var(--coin-link);
    border: none !important;
    color: var(--coin-link-text) !important; }
  .currency_ball-wrapper.trx {
    background-color: var(--coin-trx);
    border: none !important;
    color: var(--coin-trx-text) !important; }
  .currency_ball-wrapper.ada {
    background-color: var(--coin-ada);
    border: none !important;
    color: var(--coin-ada-text) !important; }
  .currency_ball-wrapper.matic {
    background-color: var(--coin-matic);
    border: none !important;
    color: var(--coin-matic-text) !important; }
  .currency_ball-wrapper.neo {
    background-color: var(--coin-neo);
    border: none !important;
    color: var(--coin-neo-text) !important; }
  .currency_ball-wrapper.etc {
    background-color: var(--coin-etc);
    border: none !important;
    color: var(--coin-etc-text) !important; }
  .currency_ball-wrapper.xlm {
    background-color: var(--coin-xlm);
    border: none !important;
    color: var(--coin-xlm-text) !important; }
  .currency_ball-wrapper.xmr {
    background-color: var(--coin-xmr);
    border: none !important;
    color: var(--coin-xmr-text) !important; }
  .currency_ball-wrapper.zec {
    background-color: var(--coin-zec);
    border: none !important;
    color: var(--coin-zec-text) !important; }
  .currency_ball-wrapper.one {
    background-color: var(--coin-one);
    border: none !important;
    color: var(--coin-one-text) !important; }
  .currency_ball-wrapper.pax {
    background-color: var(--coin-pax);
    border: none !important;
    color: var(--coin-pax-text) !important; }
  .currency_ball-wrapper.usdc {
    background-color: var(--coin-usdc);
    border: none !important;
    color: var(--coin-usdc-text) !important; }
  .currency_ball-wrapper.usdt {
    background-color: var(--coin-usdt);
    border: none !important;
    color: var(--coin-usdt-text) !important; }
  .currency_ball-wrapper.vet {
    background-color: var(--coin-vet);
    border: none !important;
    color: var(--coin-vet-text) !important; }
  .currency_ball-wrapper.btt {
    background-color: var(--coin-btt);
    border: none !important;
    color: var(--coin-btt-text) !important; }
  .currency_ball-wrapper.qtum {
    background-color: var(--coin-qtum);
    border: none !important;
    color: var(--coin-qtum-text) !important; }
  .currency_ball-wrapper.algo {
    background-color: var(--coin-algo);
    border: none !important;
    color: var(--coin-algo-text) !important; }
  .currency_ball-wrapper.rvn {
    background-color: var(--coin-rvn);
    border: none !important;
    color: var(--coin-rvn-text) !important; }
  .currency_ball-wrapper.iota {
    background-color: var(--coin-iota);
    border: none !important;
    color: var(--coin-iota-text) !important; }
  .currency_ball-wrapper.dash {
    background-color: var(--coin-dash);
    border: none !important;
    color: var(--coin-dash-text) !important; }
  .currency_ball-wrapper.bsv {
    background-color: var(--coin-bsv);
    border: none !important;
    color: var(--coin-bsv-text) !important; }
  .currency_ball-wrapper.ont {
    background-color: var(--coin-ont);
    border: none !important;
    color: var(--coin-ont-text) !important; }
  .currency_ball-wrapper.atom {
    background-color: var(--coin-atom);
    border: none !important;
    color: var(--coin-atom-text) !important; }
  .currency_ball-wrapper.celr {
    background-color: var(--coin-celr);
    border: none !important;
    color: var(--coin-celr-text) !important; }
  .currency_ball-wrapper.omg {
    background-color: var(--coin-omg);
    border: none !important;
    color: var(--coin-omg-text) !important; }
  .currency_ball-wrapper.hot {
    background-color: var(--coin-hot);
    border: none !important;
    color: var(--coin-hot-text) !important; }
  .currency_ball-wrapper.usd {
    background-color: var(--coin-usd);
    border: none !important;
    color: var(--coin-usd-text) !important; }
  .currency_ball-wrapper.jpy {
    background-color: var(--coin-jpy);
    border: none !important;
    color: var(--coin-jpy-text) !important; }
  .currency_ball-wrapper.gbp {
    background-color: var(--coin-gbp);
    border: none !important;
    color: var(--coin-gbp-text) !important; }
  .currency_ball-wrapper.chf {
    background-color: var(--coin-chf);
    border: none !important;
    color: var(--coin-chf-text) !important; }
  .currency_ball-wrapper.cad {
    background-color: var(--coin-cad);
    border: none !important;
    color: var(--coin-cad-text) !important; }
  .currency_ball-wrapper.aud {
    background-color: var(--coin-aud);
    border: none !important;
    color: var(--coin-aud-text) !important; }
  .currency_ball-wrapper.nzd {
    background-color: var(--coin-nzd);
    border: none !important;
    color: var(--coin-nzd-text) !important; }
  .currency_ball-wrapper.zar {
    background-color: var(--coin-zar);
    border: none !important;
    color: var(--coin-zar-text) !important; }
  .currency_ball-wrapper.hkd {
    background-color: var(--coin-hkd);
    border: none !important;
    color: var(--coin-hkd-text) !important; }
  .currency_ball-wrapper.krw {
    background-color: var(--coin-krw);
    border: none !important;
    color: var(--coin-krw-text) !important; }
  .currency_ball-wrapper.cny {
    background-color: var(--coin-cny);
    border: none !important;
    color: var(--coin-cny-text) !important; }
  .currency_ball-wrapper.sgd {
    background-color: var(--coin-sgd);
    border: none !important;
    color: var(--coin-sgd-text) !important; }
  .currency_ball-wrapper.tusd {
    background-color: var(--coin-tusd);
    border: none !important;
    color: var(--coin-tusd-text) !important; }
  .currency_ball-wrapper.dai {
    background-color: var(--coin-dai);
    border: none !important;
    color: var(--coin-dai-text) !important; }
  .currency_ball-wrapper.ama {
    background-color: var(--coin-ama);
    border: none !important;
    color: var(--coin-ama-text) !important; }
  .currency_ball-wrapper.xtz {
    background-color: var(--coin-xtz);
    border: none !important;
    color: var(--coin-xtz-text) !important; }
  .currency_ball-wrapper.doge {
    background-color: var(--coin-doge);
    border: none !important;
    color: var(--coin-doge-text) !important; }
  .currency_ball-wrapper.uni {
    background-color: var(--coin-uni);
    border: none !important;
    color: var(--coin-uni-text) !important; }
  .currency_ball-wrapper.dot {
    background-color: var(--coin-dot);
    border: none !important;
    color: var(--coin-dot-text) !important; }
  .currency_ball-wrapper.s {
    font-size: 0.6rem;
    width: 1.8rem;
    height: 1.8rem; }
  .currency_ball-wrapper.m {
    font-size: 0.9rem;
    width: 2.7rem;
    height: 2.7rem; }
  .currency_ball-wrapper.l {
    font-size: 1.35rem;
    width: 4.05rem;
    height: 4.05rem; }

.with_price-block_amount {
  margin: 2rem 0;
  font-size: 4rem;
  line-height: 4rem; }
  .with_price-block_amount .with_price-block_amount-value {
    margin-left: 0.5rem; }
  .with_price-block_amount .with_price-block_amount-value-eur {
    color: var(--labels_secondary-inactive-label-text-graphics);
    font-size: 1.8rem;
    line-height: 1.8rem; }

.direction_ltr .with_price-block_amount {
  justify-content: flex-start; }

.direction_rtl .with_price-block_amount {
  justify-content: flex-end; }

.table-wrapper {
  width: 100%;
  margin: 2rem 0 2rem; }
  .table-wrapper tr {
    height: 2rem; }
  .table-wrapper th,
  .table-wrapper td {
    padding: 0.25rem; }
  .table-wrapper th {
    font-weight: bold; }
  .table-wrapper .table_header-wrapper {
    color: var(--labels_secondary-inactive-label-text-graphics); }
    .table-wrapper .table_header-wrapper .action_notification-text {
      white-space: nowrap; }
  .table-wrapper .table_body-wrapper {
    font-size: 1rem; }
    .table-wrapper .table_body-wrapper.with_icon tr td:first-child {
      border: none !important; }
    .table-wrapper .table_body-wrapper tr.table_body-row td {
      border-top: 1px solid var(--calculated_secondary-border);
      border-bottom: 1px solid var(--calculated_secondary-border); }
    .table-wrapper .table_body-wrapper .cancel-animate, .table-wrapper .table_body-wrapper .cancel-row-color, .table-wrapper .table_body-wrapper .cancel-row-color .cell_box-type .sell,
    .table-wrapper .table_body-wrapper .cancel-row-color .cell_box-type .buy, .table-wrapper .table_body-wrapper .cancel-row-color .action_notification-wrapper .notification-info, .table-wrapper .table_body-wrapper .cancel-row-color .cell-wrapper, .table-wrapper .table_body-wrapper .cancel-row-color .cell-wrapper .cell_value-wrapper, .table-wrapper .table_body-wrapper .cancel-row-color .cell-wrapper .cell_value-wrapper .cell_value-bar {
      -webkit-animation: cancelRow 1.4s;
      animation: cancelRow 1.4s; }

@-webkit-keyframes cancelRow {
  from { }
  to {
    color: var(--base_background);
    background-color: var(--base_background);
    border-color: var(--base_background); } }

@keyframes cancelRow {
  from { }
  to {
    color: var(--base_background);
    background-color: var(--base_background);
    border-color: var(--base_background); } }
  .table-wrapper .cell_box-type {
    color: var(--calculated_trading_buying-related-text);
    text-transform: capitalize; }
    .table-wrapper .cell_box-type > div {
      padding: 0.15rem 0.25rem; }

.layout-mobile .table_controllers-wrapper {
  font-size: 1.2rem; }
  .layout-mobile .table_controllers-wrapper .action_notification-wrapper .action_notification-svg svg {
    height: 2rem;
    width: 2rem; }

.table_controllers-wrapper {
  margin: 2rem 0;
  color: var(--labels_important-active-labels-text-graphics);
  border-top: 2px solid var(--calculated_secondary-border);
  position: relative; }
  .table_controllers-wrapper .disabled {
    cursor: default;
    pointer-events: none; }
    .table_controllers-wrapper .disabled .action_notification-wrapper .notification-info {
      color: var(--labels_inactive-button); }
    .table_controllers-wrapper .disabled .action_notification-wrapper .action_notification-svg .arrow-1 {
      fill: var(--labels_inactive-button); }

.direction_ltr .cell_box-type > div {
  margin-right: 0.5rem; }

.direction_ltr .table_header-wrapper {
  font-family: "Roboto";
  font-weight: 900; }

.direction_rtl .cell_box-type > div {
  margin-left: 0.5rem; }

.layout-mobile .display_table-wrapper {
  font-size: 1.2rem; }

.display_table-wrapper {
  position: relative;
  height: 100%; }
  .display_table-wrapper .display_table-header {
    font-weight: bold;
    border-bottom: 1px solid var(--calculated_secondary-border); }
  .display_table-wrapper .display_table-body {
    overflow-y: auto; }
  .display_table-wrapper .display_table-cell {
    min-height: 1.5rem;
    padding: 0.1rem; }
  .display_table-wrapper .ant-select-arrow {
    z-index: 1; }

.f-1 {
  flex: 1 1; }

.ohlc_wrapper {
  x: 0;
  y: 0; }
  .ohlc_wrapper .ohlc_data_wrapper .ohlc_label {
    font-size: 1rem; }
  .ohlc_wrapper .ohlc_data_wrapper .ohlc_value {
    font-size: 1rem;
    font-weight: bold;
    padding-left: 0.25rem; }

.donut-label-percentage {
  font-size: 0.8rem;
  font-weight: bold;
  fill: var(--labels_secondary-inactive-label-text-graphics); }

.donut-label-link {
  font-size: 0.7rem;
  font-weight: bold;
  fill: var(--specials_buttons-links-and-highlights); }

.donut-label-no-price {
  font-size: 0.7rem;
  font-weight: bold;
  fill: var(--labels_secondary-inactive-label-text-graphics); }

.donut-label-pair {
  font-size: 1rem;
  font-weight: bold;
  fill: var(--labels_secondary-inactive-label-text-graphics); }

.deposit-asset {
  fill: var(--specials_buttons-links-and-highlights);
  font-weight: bold; }

.chart_btc,
.chart_bch,
.chart_eth,
.chart_eur,
.chart_ltc,
.chart_xrp,
.chart_xht,
.chart_eos,
.chart_bnb,
.chart_link,
.chart_trx,
.chart_ada,
.chart_matic,
.chart_neo,
.chart_etc,
.chart_xlm,
.chart_xmr,
.chart_zec,
.chart_one,
.chart_pax,
.chart_usdc,
.chart_usdt,
.chart_vet,
.chart_btt,
.chart_qtum,
.chart_algo,
.chart_rvn,
.chart_iota,
.chart_dash,
.chart_bsv,
.chart_ont,
.chart_atom,
.chart_celr,
.chart_omg,
.chart_hot,
.chart_usd,
.chart_jpy,
.chart_gbp,
.chart_chf,
.chart_cad,
.chart_aud,
.chart_nzd,
.chart_zar,
.chart_hkd,
.chart_krw,
.chart_cny,
.chart_sgd,
.chart_dai,
.chart_tusd {
  fill-opacity: 0.7; }

.slice_active {
  fill-opacity: 1; }

.chart_btc {
  fill: var(--coin-btc);
  stroke: var(--coin-btc); }

.chart_bch {
  fill: var(--coin-bch);
  stroke: var(--coin-bch); }

.chart_eth {
  fill: var(--coin-eth);
  stroke: var(--coin-eth); }

.chart_eur {
  fill: var(--coin-eur);
  stroke: var(--coin-eur); }

.chart_ltc {
  fill: var(--coin-ltc);
  stroke: var(--coin-ltc); }

.chart_xrp {
  fill: var(--coin-xrp);
  stroke: var(--coin-xrp); }

.chart_xht {
  fill: var(--coin-xht);
  stroke: var(--coin-xht); }

.chart_eos {
  fill: var(--coin-eos);
  stroke: var(--coin-eos); }

.chart_bnb {
  fill: var(--coin-bnb);
  stroke: var(--coin-bnb); }

.chart_link {
  fill: var(--coin-link);
  stroke: var(--coin-link); }

.chart_trx {
  fill: var(--coin-trx);
  stroke: var(--coin-trx); }

.chart_ada {
  fill: var(--coin-ada);
  stroke: var(--coin-ada); }

.chart_matic {
  fill: var(--coin-matic);
  stroke: var(--coin-matic); }

.chart_neo {
  fill: var(--coin-neo);
  stroke: var(--coin-neo); }

.chart_etc {
  fill: var(--coin-etc);
  stroke: var(--coin-etc); }

.chart_xlm {
  fill: var(--coin-xlm);
  stroke: var(--coin-xlm); }

.chart_xmr {
  fill: var(--coin-xmr);
  stroke: var(--coin-xmr); }

.chart_zec {
  fill: var(--coin-zec);
  stroke: var(--coin-zec); }

.chart_one {
  fill: var(--coin-one);
  stroke: var(--coin-one); }

.chart_pax {
  fill: var(--coin-pax);
  stroke: var(--coin-pax); }

.chart_usdc {
  fill: var(--coin-usdc);
  stroke: var(--coin-usdc); }

.chart_usdt {
  fill: var(--coin-usdt);
  stroke: var(--coin-usdt); }

.chart_vet {
  fill: var(--coin-vet);
  stroke: var(--coin-vet); }

.chart_btt {
  fill: var(--coin-btt);
  stroke: var(--coin-btt); }

.chart_qtum {
  fill: var(--coin-qtum);
  stroke: var(--coin-qtum); }

.chart_algo {
  fill: var(--coin-algo);
  stroke: var(--coin-algo); }

.chart_rvn {
  fill: var(--coin-rvn);
  stroke: var(--coin-rvn); }

.chart_iota {
  fill: var(--coin-iota);
  stroke: var(--coin-iota); }

.chart_dash {
  fill: var(--coin-dash);
  stroke: var(--coin-dash); }

.chart_bsv {
  fill: var(--coin-bsv);
  stroke: var(--coin-bsv); }

.chart_ont {
  fill: var(--coin-ont);
  stroke: var(--coin-ont); }

.chart_atom {
  fill: var(--coin-atom);
  stroke: var(--coin-atom); }

.chart_celr {
  fill: var(--coin-celr);
  stroke: var(--coin-celr); }

.chart_omg {
  fill: var(--coin-omg);
  stroke: var(--coin-omg); }

.chart_hot {
  fill: var(--coin-hot);
  stroke: var(--coin-hot); }

.chart_usd {
  fill: var(--coin-usd);
  stroke: var(--coin-usd); }

.chart_jpy {
  fill: var(--coin-jpy);
  stroke: var(--coin-jpy); }

.chart_gbp {
  fill: var(--coin-gbp);
  stroke: var(--coin-gbp); }

.chart_chf {
  fill: var(--coin-chf);
  stroke: var(--coin-chf); }

.chart_cad {
  fill: var(--coin-cad);
  stroke: var(--coin-cad); }

.chart_aud {
  fill: var(--coin-aud);
  stroke: var(--coin-aud); }

.chart_nzd {
  fill: var(--coin-nzd);
  stroke: var(--coin-nzd); }

.chart_zar {
  fill: var(--coin-zar);
  stroke: var(--coin-zar); }

.chart_hkd {
  fill: var(--coin-hkd);
  stroke: var(--coin-hkd); }

.chart_krw {
  fill: var(--coin-krw);
  stroke: var(--coin-krw); }

.chart_cny {
  fill: var(--coin-cny);
  stroke: var(--coin-cny); }

.chart_sgd {
  fill: var(--coin-sgd);
  stroke: var(--coin-sgd); }

.chart_tusd {
  fill: var(--coin-tusd);
  stroke: var(--coin-tusd); }

.chart_dai {
  fill: var(--coin-dai);
  stroke: var(--coin-dai); }

.bar_wrapper {
  height: 38rem; }
  .bar_wrapper .bar_yAxis line {
    fill: var(--labels_secondary-inactive-label-text-graphics);
    stroke-dasharray: 3 3;
    stroke-width: 0.5px; }
  .bar_wrapper .bar_yAxis .tick text {
    color: var(--labels_important-active-labels-text-graphics);
    font-family: "Roboto";
    font-size: 1.2rem;
    font-weight: bold; }
  .bar_wrapper .bar_xAxis line {
    visibility: hidden; }
  .bar_wrapper .bar_xAxis .axis_month {
    font-size: 1rem;
    font-family: "Roboto";
    color: var(--labels_secondary-inactive-label-text-graphics); }
  .bar_wrapper .bar_xAxis .axis_month-active {
    color: var(--labels_important-active-labels-text-graphics);
    font-weight: bold; }
  .bar_wrapper .bar_xAxis .axis_month-total {
    font-size: 0.8rem;
    font-weight: bold; }
  .bar_wrapper .bar_xAxis .axis_month-total-active {
    color: var(--labels_important-active-labels-text-graphics); }
  .bar_wrapper .bar_xAxis path {
    stroke: var(--labels_secondary-inactive-label-text-graphics);
    stroke-width: 1; }
  .bar_wrapper .limit_content-text {
    font-size: 0.72rem; }
  .bar_wrapper .bar_axis_triangle {
    fill: var(--labels_important-active-labels-text-graphics); }

.bar-tooltip,
.bar-tooltip-dark {
  padding: 5px;
  position: absolute;
  z-index: 10; }
  .bar-tooltip .tool_tip-pair-volume,
  .bar-tooltip-dark .tool_tip-pair-volume {
    padding-left: 0.8rem; }

.bar-tooltip {
  background-color: var(--base_background);
  border: 1px solid var(--calculated_secondary-border);
  color: var(--labels_secondary-inactive-label-text-graphics); }

.bar-tooltip::before,
.bar-tooltip::after,
.bar-tooltip-dark::before,
.bar-tooltip-dark::after {
  border: solid transparent;
  content: ' ';
  right: 100%;
  top: 50%;
  height: 0;
  width: 0;
  position: absolute; }

.bar-tooltip:after {
  border-color: rgba(136, 183, 213, 0);
  border-right-color: var(--base_background);
  border-width: 5px;
  margin-top: -5px; }

.bar-tooltip:before {
  border-color: rgba(194, 225, 245, 0);
  border-right-color: var(--labels_secondary-inactive-label-text-graphics);
  border-width: 7px;
  margin-top: -7px; }

.bar-tooltip-dark:after {
  border-color: rgba(136, 183, 213, 0);
  border-right-color: var(--base_background);
  border-width: 5px;
  margin-top: -5px; }

.bar-tooltip-dark:before {
  border-color: rgba(194, 225, 245, 0);
  border-right-color: var(--labels_secondary-inactive-label-text-graphics);
  border-width: 7px;
  margin-top: -7px; }

.bar-tooltip-dark {
  background-color: var(--base_background);
  border: 1px solid var(--labels_secondary-inactive-label-text-graphics);
  color: var(--labels_secondary-inactive-label-text-graphics); }

@media (min-width: 1920px) {
  .donut-label-pair {
    font-size: 1.2rem; }
  .donut-label-percentage {
    font-size: 1rem; }
  .bar_wrapper .limit_content-text {
    font-size: 0.9rem; }
  .bar_wrapper .bar_xAxis .axis_month-total {
    font-size: 1rem; } }

foreignObject {
  overflow: visible; }

.layout-mobile .donut-label-pair {
  font-size: 1.4rem; }

.layout-mobile .donut-label-percentage {
  font-size: 1.2rem; }

.layout-mobile .donut-label-link {
  font-size: 1rem; }

.input-group__container {
  border-radius: 4px;
  border: var(--labels_secondary-inactive-label-text-graphics) solid 1.5px; }
  .input-group__container .ant-select-selection,
  .input-group__container .ant-input,
  .input-group__container .ant-select-arrow,
  .input-group__container .ant-select-selector {
    text-align: right;
    background-color: var(--base_wallet-sidebar-and-popup) !important;
    color: var(--labels_secondary-inactive-label-text-graphics) !important; }
  .input-group__container .input-group__coin-icons-wrap {
    width: 2.5rem; }
  .input-group__container:focus-within {
    border-color: var(--specials_buttons-links-and-highlights) !important; }
  .input-group__container .input-group__select:hover {
    color: var(--labels_important-active-labels-text-graphics) !important; }
    .input-group__container .input-group__select:hover .ant-select-selection,
    .input-group__container .input-group__select:hover .ant-input,
    .input-group__container .input-group__select:hover .ant-select-arrow,
    .input-group__container .input-group__select:hover .ant-select-selector {
      color: var(--labels_important-active-labels-text-graphics) !important; }
    .input-group__container .input-group__select:hover .input-group__coin-icons {
      width: 2.2rem; }
  .input-group__container:hover {
    border-color: var(--labels_important-active-labels-text-graphics); }

.input-group__coin-icons {
  width: 1.8rem;
  height: 100%; }
  .input-group__coin-icons svg {
    margin: 0 auto;
    display: block; }
    .input-group__coin-icons svg .btc0,
    .input-group__coin-icons svg .btc1 {
      fill: var(--coin-btc); }
    .input-group__coin-icons svg .eth0,
    .input-group__coin-icons svg .eth1 {
      fill: var(--coin-eth); }
    .input-group__coin-icons svg .bch-icon {
      fill: var(--coin-bch); }
    .input-group__coin-icons svg .xrp-icon {
      fill: var(--coin-xrp); }
    .input-group__coin-icons svg .xmr-icon {
      fill: var(--coin-xmr); }
    .input-group__coin-icons svg .ada-icon {
      fill: var(--coin-ada); }
    .input-group__coin-icons svg .bnb-icon {
      fill: var(--coin-bnb); }
    .input-group__coin-icons svg .dai-icon {
      fill: var(--coin-dai); }
    .input-group__coin-icons svg .eos-icon {
      fill: var(--coin-eos); }
    .input-group__coin-icons svg .ltc-icon {
      fill: var(--coin-ltc); }
    .input-group__coin-icons svg .trx-icon {
      fill: var(--coin-trx); }
    .input-group__coin-icons svg .tusd-icon {
      fill: var(--coin-tusd); }
    .input-group__coin-icons svg .xlm-icon {
      fill: var(--coin-xlm); }
    .input-group__coin-icons svg .default-icon {
      fill: var(--labels_secondary-inactive-label-text-graphics) !important; }

.language_rtl .quick_trade-wrapper {
  font-family: inherit; }
  .language_rtl .quick_trade-wrapper .input_block-title {
    font-family: inherit; }
  .language_rtl .quick_trade-wrapper .toogle_button-wrapper .toogle-content {
    font-family: inherit; }
    .language_rtl .quick_trade-wrapper .toogle_button-wrapper .toogle-content .selected {
      font-family: inherit; }

.direction_ltr .quick_trade-wrapper {
  font-family: "Roboto"; }

.quick_trade-tab-icon {
  width: 10rem;
  height: 10rem;
  margin: 0 auto; }

.quick_trade-wrapper {
  min-width: 45rem;
  max-width: 50rem;
  padding: 3.5rem;
  background-color: var(--base_wallet-sidebar-and-popup);
  margin: 3rem auto;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content; }
  .quick_trade-wrapper .review-block-wrapper,
  .quick_trade-wrapper .input_block-wrapper,
  .quick_trade-wrapper .toogle_button-wrapper {
    margin: 1rem 0;
    width: auto; }
  .quick_trade-wrapper .quick_trade-bottom-padded {
    margin-bottom: 2rem; }
  .quick_trade-wrapper .quick_trade-icon svg {
    width: 10rem;
    height: 10rem; }
  .quick_trade-wrapper .title {
    font-size: 4rem; }
  .quick_trade-wrapper > .quick_trade-section_wrapper {
    width: 100%;
    position: relative; }
    .quick_trade-wrapper > .quick_trade-section_wrapper.fetching {
      color: var(--labels_secondary-inactive-label-text-graphics); }
  .quick_trade-wrapper .toogle_button-wrapper .toogle-content {
    padding: 0 1rem;
    text-transform: uppercase;
    padding: 0 0.5rem;
    font-size: 1rem;
    color: var(--labels_secondary-inactive-label-text-graphics);
    height: 22px;
    font-family: "Roboto";
    font-weight: 500; }
    .quick_trade-wrapper .toogle_button-wrapper .toogle-content .selected {
      font-family: "Roboto";
      font-weight: 900;
      color: var(--labels_important-active-labels-text-graphics); }
    .quick_trade-wrapper .toogle_button-wrapper .toogle-content .toogle-action_button {
      border: 2px solid var(--labels_important-active-labels-text-graphics);
      height: 100%;
      width: 42px;
      border-radius: 14px;
      margin: 0 0.5rem;
      display: flex;
      align-items: center;
      position: relative; }
      .quick_trade-wrapper .toogle_button-wrapper .toogle-content .toogle-action_button.left .toogle-action_button-display {
        right: calc(100% - 18px); }
      .quick_trade-wrapper .toogle_button-wrapper .toogle-content .toogle-action_button.right .toogle-action_button-display {
        right: 0; }
      .quick_trade-wrapper .toogle_button-wrapper .toogle-content .toogle-action_button .toogle-action_button-display {
        position: absolute;
        transition: 0.5s;
        background: var(--labels_important-active-labels-text-graphics);
        height: 14px;
        width: 14px;
        border-radius: 50%;
        margin: 2px;
        top: 0; }
  .quick_trade-wrapper .toogle_button-wrapper .toogle-side_line {
    position: relative; }
    .quick_trade-wrapper .toogle_button-wrapper .toogle-side_line::after {
      content: '';
      height: 1px;
      width: 100%;
      position: absolute;
      background-color: var(--calculated_important-border);
      left: 0;
      right: 0;
      top: 50%; }
  .quick_trade-wrapper .review-block-wrapper .currency-wrapper {
    margin: 0.5rem auto;
    font-size: 4rem;
    line-height: 4rem; }
    .quick_trade-wrapper .review-block-wrapper .currency-wrapper .currency_ball-wrapper {
      margin-top: 0.25rem;
      margin-right: 0.5rem; }
  .quick_trade-wrapper .review-block-wrapper .with_price-block_amount {
    margin: 0; }
  .quick_trade-wrapper .input-group__error-wrapper {
    color: red;
    margin-top: 0.5rem;
    float: right; }
  .quick_trade-wrapper .input_block-wrapper {
    margin-bottom: 0; }
  .quick_trade-wrapper .input_block-title {
    font-size: 1.3rem;
    font-family: "Roboto";
    font-weight: 500; }
  .quick_trade-wrapper .input_block-content {
    border-bottom: 2px solid var(--calculated_important-border);
    width: 100%; }
    .quick_trade-wrapper .input_block-content.has_error {
      border-bottom-color: var(--specials_notifications-alerts-warnings); }
    .quick_trade-wrapper .input_block-content .input_block-input-wrapper {
      width: auto;
      position: relative; }
      .quick_trade-wrapper .input_block-content .input_block-input-wrapper .input_block-inputbox {
        font-size: 1.8rem;
        margin: 0.5rem 1.5rem;
        border: none !important;
        border-radius: 0 !important;
        padding: 0 !important;
        text-align: left;
        margin-left: 2rem; }
      .quick_trade-wrapper .input_block-content .input_block-input-wrapper .input_block-currency_ball {
        position: absolute;
        left: -0.5rem; }
      .quick_trade-wrapper .input_block-content .input_block-input-wrapper .react-numeric-input > b {
        visibility: hidden; }
    .quick_trade-wrapper .input_block-content.loading {
      position: relative;
      border-bottom-color: var(--labels_secondary-inactive-label-text-graphics); }
      .quick_trade-wrapper .input_block-content.loading:after {
        content: '';
        display: block;
        -webkit-animation: linear_loading 2s linear infinite;
                animation: linear_loading 2s linear infinite;
        height: 2px;
        width: 100px;
        position: absolute;
        background-color: var(--coin-eur);
        bottom: -2px;
        left: 0;
        right: 0; }
    .quick_trade-wrapper .input_block-content.loading.btc::after {
      background-color: var(--coin-btc); }
    .quick_trade-wrapper .input_block-content.loading.bch::after {
      background-color: var(--coin-bch); }
    .quick_trade-wrapper .input_block-content.loading.eth::after {
      background-color: var(--coin-eth); }
    .quick_trade-wrapper .input_block-content.loading.ltc::after {
      background-color: var(--coin-ltc); }

@-webkit-keyframes linear_loading {
  0% {
    left: 0;
    width: 0; }
  15% {
    left: 0;
    width: 100px; }
  85% {
    width: 100px;
    left: calc(100% - 100px); }
  100% {
    width: 0;
    left: 100%; } }

@keyframes linear_loading {
  0% {
    left: 0;
    width: 0; }
  15% {
    left: 0;
    width: 100px; }
  85% {
    width: 100px;
    left: calc(100% - 100px); }
  100% {
    width: 0;
    left: 100%; } }

.layout-mobile .quick_trade-section_wrapper .toogle_button-wrapper .toogle-content {
  font-size: 13px !important; }

.layout-mobile .quick_trade-section_wrapper .input_block-wrapper .input_block-content .input_block-title {
  font-size: 13px !important; }

.layout-mobile .quick_trade-section_wrapper .input_block-wrapper .field-error-content {
  width: auto;
  font-size: 11px; }

.layout-mobile .quick_trade-section_wrapper .review-block-wrapper .input_block-title {
  font-size: 13px; }

.mobile_dropdown-section {
  border-top: solid; }

.review-block-wrapper {
  border-top: var(--calculated_secondary-border) solid 1px; }

.legal-container {
  width: 100%; }
  .legal-container .legal-wrapper {
    margin: 0; }

.legal-wrapper {
  width: 100%;
  margin: 1.5rem; }

.legal-content-wrapper {
  max-width: 50rem; }
  .legal-content-wrapper .legal-logo-wrapper {
    margin: 1.5rem; }
    .legal-content-wrapper .legal-logo-wrapper .legal-logo {
      height: 3rem; }
  .legal-content-wrapper .legal-title {
    font-size: 4rem;
    line-height: 4rem;
    text-align: center; }
  .legal-content-wrapper .legal-logo-wrapper,
  .legal-content-wrapper .legal-title,
  .legal-content-wrapper .legal-subtitle,
  .legal-content-wrapper .legal-content {
    margin: 1.5rem auto;
    flex: 1 1; }
  .legal-content-wrapper .legal-subtitle {
    border-top: 2px solid var(--calculated_important-border);
    font-weight: bold;
    width: 100%; }
  .legal-content-wrapper .legal-subtitle,
  .legal-content-wrapper .legal-content {
    font-size: 1rem; }

@media screen and (max-width: 767px) {
  .legal-container .legal-content-wrapper {
    padding: 1rem; } }

.countdown-wrapper .countdown-timer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem; }
  .countdown-wrapper .countdown-timer.invalid {
    color: var(--specials_notifications-alerts-warnings); }

.layout-mobile .countdown-wrapper {
  margin: 38% auto; }

.light-theme.holla-tooltip {
  opacity: 1 !important;
  background-color: transparent !important; }
  .light-theme.holla-tooltip .rc-tooltip-inner {
    color: var(--labels_important-active-labels-text-graphics) !important;
    background-color: var(--base_background);
    border-color: var(--labels_important-active-labels-text-graphics) !important; }
  .light-theme.holla-tooltip.rc-tooltip-placement-bottom .rc-tooltip-arrow, .light-theme.holla-tooltip.rc-tooltip-placement-bottomLeft .rc-tooltip-arrow, .light-theme.holla-tooltip.rc-tooltip-placement-bottomRight .rc-tooltip-arrow {
    border-bottom-color: var(--labels_important-active-labels-text-graphics) !important; }

.currency-list {
  width: auto; }
  .currency-list.horizontal-currency-list {
    border-left: none !important; }
    .currency-list.horizontal-currency-list .app_bar_currency-icon {
      width: 2rem;
      height: 2rem; }
    .currency-list.horizontal-currency-list .selected_currency-tab {
      background-color: var(--base_background);
      border-left: 1px solid black !important;
      border-right: 1px solid black !important;
      height: 3.1rem; }
    .currency-list.horizontal-currency-list .single-currency {
      position: relative;
      justify-content: center;
      min-width: 15rem;
      padding: 0 1rem;
      border-left: 1px solid;
      border-right: 1px solid;
      border-color: transparent; }
      .currency-list.horizontal-currency-list .single-currency:hover, .currency-list.horizontal-currency-list .single-currency.focused {
        background-color: #fafafa;
        border-color: black; }
        .currency-list.horizontal-currency-list .single-currency:hover::before, .currency-list.horizontal-currency-list .single-currency.focused::before {
          content: '';
          position: absolute;
          width: 100%;
          border-bottom: 1px solid;
          border-color: #fafafa;
          left: 0;
          right: 0;
          bottom: -1px; }
        .currency-list.horizontal-currency-list .single-currency:hover::after, .currency-list.horizontal-currency-list .single-currency.focused::after {
          content: '';
          position: absolute;
          width: 100%;
          top: 0;
          left: 0;
          right: 0;
          border-color: black;
          border-top: 0.25rem solid;
          border-left: 1px solid;
          border-right: 1px solid; }
  .currency-list .single-currency {
    height: 100%;
    font-size: 1rem;
    cursor: pointer; }

.markets-list {
  position: absolute;
  right: 0;
  top: 36px;
  min-width: 23.3rem;
  min-height: 8rem;
  background-color: #fafafa;
  z-index: 20;
  padding: 1rem 2rem;
  box-shadow: 0px 3px 1px -1px rgba(0, 0, 0, 0.4); }
  .markets-list .markets-wrapper {
    width: 1rem; }
    .markets-list .markets-wrapper .market {
      position: relative;
      margin: 0 1rem;
      cursor: pointer; }
      .markets-list .markets-wrapper .market:hover:after {
        content: '';
        position: absolute;
        width: 100%;
        bottom: 0;
        border-bottom: 1px solid black; }

.mobile-bar {
  width: 100%;
  min-width: 100vw;
  max-width: 100vw;
  min-height: 7rem;
  max-height: 7rem;
  background-color: var(--base_top-bar-navigation);
  color: var(--calculated_base_top-bar-navigation_text-inactive); }

.mobile-bar-tab {
  font-size: 2rem;
  font-weight: bold; }
  .mobile-bar-tab.active {
    position: relative;
    color: var(--calculated_base_top-bar-navigation_text); }
    .mobile-bar-tab.active:after {
      content: '';
      bottom: 0;
      content: "";
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      border-top: none !important;
      border-left: 1rem solid transparent !important;
      border-right: 1rem solid transparent !important;
      border-bottom: 1rem solid var(--base_background);
      margin-bottom: 0rem; }
  .mobile-bar-tab .bartab-text-wrapper {
    position: relative; }
    .mobile-bar-tab .bartab-text-wrapper .bartab-notification {
      position: absolute;
      right: -1.75rem;
      top: 0;
      height: 1.75rem;
      width: 1.75rem;
      border-radius: 1.75rem;
      background-color: red;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.3125rem;
      line-height: 1;
      font-weight: bold; }

.close-dialog {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  margin-left: 1rem;
  width: 2.5rem;
  height: 2.5rem; }
  .close-dialog svg {
    width: 2.5rem;
    height: 2.5rem; }
    .close-dialog svg .arrow-down {
      stroke: var(--calculated_base_top-bar-navigation_text); }

.dialog-compressed-close .close-dialog {
  margin-left: 0;
  width: 7.5rem;
  height: 7.5rem; }
  .dialog-compressed-close .close-dialog svg {
    width: 7.5rem;
    height: 7.5rem; }
    .dialog-compressed-close .close-dialog svg .cancel-cross-3 {
      stroke: white; }
    .dialog-compressed-close .close-dialog svg .cancel-cross-2 {
      stroke: transparent; }

.mobile-drop-down {
  background: unset;
  top: 3.5rem;
  border: none;
  outline: none !important;
  -webkit-appearance: none;
  appearance: none;
  text-indent: 1px;
  text-overflow: '';
  color: var(--labels_secondary-inactive-label-text-graphics);
  font-size: 2rem;
  font-weight: bold;
  padding-right: 2rem; }

.with_arrow_trade {
  margin-top: -5px; }

.mobile-drop-down.default-coin,
.drop-down.default-coin,
.option.default-coin {
  color: var(--labels_secondary-inactive-label-text-graphics) !important; }

.mobile-drop-down.btc,
.drop-down.btc,
.option.btc {
  color: var(--coin-btc) !important; }

.mobile-drop-down.eur,
.drop-down.eur,
.option.eur {
  color: var(--coin-eur) !important; }

.mobile-drop-down.bch,
.drop-down.bch,
.option.bch {
  color: var(--coin-bch) !important; }

.mobile-drop-down.eth,
.drop-down.eth,
.option.eth {
  color: var(--coin-eth) !important; }

.mobile-drop-down.ltc,
.drop-down.ltc,
.option.ltc {
  color: var(--coin-ltc) !important; }

.mobile-drop-down.xrp,
.drop-down.xrp,
.option.xrp {
  color: var(--coin-xrp) !important; }

.mobile-drop-down.xht,
.drop-down.xht,
.option.xht {
  color: var(--coin-xht) !important; }

.mobile-drop-down.eos,
.drop-down.eos,
.option.eos {
  color: var(--coin-eos) !important; }

.mobile-drop-down.bnb,
.drop-down.bnb,
.option.bnb {
  color: var(--coin-bnb) !important; }

.mobile-drop-down.link,
.drop-down.link,
.option.link {
  color: var(--coin-link) !important; }

.mobile-drop-down.trx,
.drop-down.trx,
.option.trx {
  color: var(--coin-trx) !important; }

.mobile-drop-down.ada,
.drop-down.ada,
.option.ada {
  color: var(--coin-ada) !important; }

.mobile-drop-down.matic,
.drop-down.matic,
.option.matic {
  color: var(--coin-matic) !important; }

.mobile-drop-down.neo,
.drop-down.neo,
.option.neo {
  color: var(--coin-neo) !important; }

.mobile-drop-down.etc,
.drop-down.etc,
.option.etc {
  color: var(--coin-etc) !important; }

.mobile-drop-down.xlm,
.drop-down.xlm,
.option.xlm {
  color: var(--coin-xlm) !important; }

.mobile-drop-down.xmr,
.drop-down.xmr,
.option.xmr {
  color: var(--coin-xmr) !important; }

.mobile-drop-down.zec,
.drop-down.zec,
.option.zec {
  color: var(--coin-zec) !important; }

.mobile-drop-down.one,
.drop-down.one,
.option.one {
  color: var(--coin-one) !important; }

.mobile-drop-down.pax,
.drop-down.pax,
.option.pax {
  color: var(--coin-pax) !important; }

.mobile-drop-down.usdc,
.drop-down.usdc,
.option.usdc {
  color: var(--coin-usdc) !important; }

.mobile-drop-down.usdt,
.drop-down.usdt,
.option.usdt {
  color: var(--coin-usdt) !important; }

.mobile-drop-down.vet,
.drop-down.vet,
.option.vet {
  color: var(--coin-vet) !important; }

.mobile-drop-down.btt,
.drop-down.btt,
.option.btt {
  color: var(--coin-btt) !important; }

.mobile-drop-down.qtum,
.drop-down.qtum,
.option.qtum {
  color: var(--coin-qtum) !important; }

.mobile-drop-down.algo,
.drop-down.algo,
.option.algo {
  color: var(--coin-algo) !important; }

.mobile-drop-down.rvn,
.drop-down.rvn,
.option.rvn {
  color: var(--coin-rvn) !important; }

.mobile-drop-down.iota,
.drop-down.iota,
.option.iota {
  color: var(--coin-iota) !important; }

.mobile-drop-down.dash,
.drop-down.dash,
.option.dash {
  color: var(--coin-dash) !important; }

.mobile-drop-down.bsv,
.drop-down.bsv,
.option.bsv {
  color: var(--coin-bsv) !important; }

.mobile-drop-down.ont,
.drop-down.ont,
.option.ont {
  color: var(--coin-ont) !important; }

.mobile-drop-down.atom,
.drop-down.atom,
.option.atom {
  color: var(--coin-atom) !important; }

.mobile-drop-down.celr,
.drop-down.celr,
.option.celr {
  color: var(--coin-celr) !important; }

.mobile-drop-down.omg,
.drop-down.omg,
.option.omg {
  color: var(--coin-omg) !important; }

.mobile-drop-down.hot,
.drop-down.hot,
.option.hot {
  color: var(--coin-hot) !important; }

.mobile-drop-down.usd,
.drop-down.usd,
.option.usd {
  color: var(--coin-usd) !important; }

.mobile-drop-down.jpy,
.drop-down.jpy,
.option.jpy {
  color: var(--coin-jpy) !important; }

.mobile-drop-down.gbp,
.drop-down.gbp,
.option.gbp {
  color: var(--coin-gbp) !important; }

.mobile-drop-down.chf,
.drop-down.chf,
.option.chf {
  color: var(--coin-chf) !important; }

.mobile-drop-down.cad,
.drop-down.cad,
.option.cad {
  color: var(--coin-cad) !important; }

.mobile-drop-down.aud,
.drop-down.aud,
.option.aud {
  color: var(--coin-aud) !important; }

.mobile-drop-down.nzd,
.drop-down.nzd,
.option.nzd {
  color: var(--coin-nzd) !important; }

.mobile-drop-down.zar,
.drop-down.zar,
.option.zar {
  color: var(--coin-zar) !important; }

.mobile-drop-down.hkd,
.drop-down.hkd,
.option.hkd {
  color: var(--coin-hkd) !important; }

.mobile-drop-down.krw,
.drop-down.krw,
.option.krw {
  color: var(--coin-krw) !important; }

.mobile-drop-down.cny,
.drop-down.cny,
.option.cny {
  color: var(--coin-cny) !important; }

.mobile-drop-down.sgd,
.drop-down.sgd,
.option.sgd {
  color: var(--coin-sgd) !important; }

.mobile-drop-down.tusd,
.drop-down.tusd,
.option.tusd {
  color: var(--coin-tusd) !important; }

.mobile-drop-down.dai,
.drop-down.dai,
.option.dai {
  color: var(--coin-dai) !important; }

.with_arrow_trade:after {
  border-bottom-color: var(--labels_important-active-labels-text-graphics) !important;
  border-right-color: var(--labels_important-active-labels-text-graphics) !important; }

.direction_ltr .with_arrow_trade:after {
  border: solid grey;
  border-width: 0px 2px 2px 0;
  display: inline-block;
  content: '';
  padding: 3px;
  height: 1rem;
  width: 1rem;
  -webkit-transform: rotate(46deg);
          transform: rotate(46deg);
  left: -1.5rem;
  position: relative;
  top: 0.1rem;
  z-index: 0; }

.direction_rtl .with_arrow_trade:after {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  content: "";
  display: block;
  position: relative;
  width: 0;
  height: 0;
  border-left: none !important;
  border-top: 0.7rem solid transparent !important;
  border-bottom: 0.7rem solid transparent !important;
  border-right: 0.7rem solid var(--dark-accordion-arrow-active);
  margin-left: -0.7rem;
  right: -1rem; }

.layout-mobile .chat-wrapper {
  position: relative;
  height: 100%;
  box-shadow: unset !important;
  right: 0;
  bottom: 0; }
  .layout-mobile .chat-wrapper .nondraggable {
    height: 100%; }
  .layout-mobile .chat-wrapper .timestamp {
    font-size: 11px; }
  .layout-mobile .chat-wrapper .chat-message-list {
    width: 100% !important; }
    .layout-mobile .chat-wrapper .chat-message-list .chat-message .message-content .username {
      font-size: 1.5rem;
      width: 100%; }
    .layout-mobile .chat-wrapper .chat-message-list .chat-message .message-content .message {
      font-size: 13px;
      line-height: unset !important; }
    .layout-mobile .chat-wrapper .chat-message-list .chat-message .item-options .item-options-wrapper {
      min-width: 5rem;
      font-size: 1rem;
      right: 1.5rem;
      top: -0.5rem; }
  .layout-mobile .chat-wrapper .chat-footer {
    padding: 0.5rem;
    background-color: var(--labels_fields); }
    .layout-mobile .chat-wrapper .chat-footer .chat-message-box {
      min-height: 4rem;
      font-size: 1.5rem;
      padding: 1rem 0.5rem; }
    .layout-mobile .chat-wrapper .chat-footer .emoji-container {
      width: 100% !important; }
      .layout-mobile .chat-wrapper .chat-footer .emoji-container .chat-category .emoji-close .cross-icon-back {
        width: 3rem; }
    .layout-mobile .chat-wrapper .chat-footer .chat-emoji-wrapper {
      background-color: var(--labels_fields); }
      .layout-mobile .chat-wrapper .chat-footer .chat-emoji-wrapper .chat-emoji-icon svg .emoji-face-icon {
        fill: var(--labels_secondary-inactive-label-text-graphics); }

.chat-wrapper {
  position: fixed;
  background-color: white;
  height: 40rem;
  right: 1rem;
  bottom: 0;
  color: #999999 !important;
  z-index: 100;
  box-shadow: 1px 0px 3px 0px rgba(0, 0, 0, 0.29);
  font-family: 'Open Sans' !important;
  border: 1px solid var(--calculated_important-border) !important;
  border-top: 2px solid var(--specials_buttons-links-and-highlights) !important; }
  .chat-wrapper .timestamp {
    color: #808080;
    font-size: 0.65rem;
    min-width: 3.2rem;
    text-align: right;
    margin-right: 0.3rem; }
  .chat-wrapper .chat-username-footer {
    border-top: 1px solid gray; }
  .chat-wrapper .username-to-chat {
    padding: 0.7rem !important;
    color: var(--specials_buttons-links-and-highlights) !important;
    font-size: 1rem !important;
    font-family: 'Open Sans' !important; }
  .chat-wrapper .nondraggable {
    height: calc(100% - 2.5rem); }
  .chat-wrapper.unread-messages {
    border-color: #b9b500;
    color: #b9b500; }
    .chat-wrapper.unread-messages:hover {
      border-color: var(--specials_my-username-in-chat);
      color: var(--specials_my-username-in-chat); }
      .chat-wrapper.unread-messages:hover::before {
        border-top-color: var(--specials_my-username-in-chat); }
    .chat-wrapper.unread-messages::before {
      border-top-color: #b9b500; }
  .chat-wrapper.minimized {
    height: 2.5rem; }
    .chat-wrapper.minimized .chat-footer,
    .chat-wrapper.minimized .chat-message-list {
      display: none !important; }
    .chat-wrapper.minimized .chat-header {
      border-top: 1px solid var(--calculated_secondary-border);
      cursor: pointer;
      width: 23rem; }
    .chat-wrapper.minimized .minimize-button {
      padding: 0.5rem; }
      .chat-wrapper.minimized .minimize-button:hover {
        background-color: white; }
  .chat-wrapper .minimize-button {
    height: 1.5rem;
    width: 1.5rem;
    padding: 0.2rem;
    margin-left: 1rem;
    cursor: pointer; }
    .chat-wrapper .minimize-button .minimize-button-content {
      width: 100%;
      height: 100%;
      border-bottom: 2px solid #999999; }
    .chat-wrapper .minimize-button .maximize-arrow {
      border: solid #999999;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 3px;
      transform: rotate(-135deg);
      -webkit-transform: rotate(-135deg); }
    .chat-wrapper .minimize-button:hover {
      background-color: #dfdfdf; }
  .chat-wrapper .loader_wrapper {
    position: relative;
    height: 100%; }
    .chat-wrapper .loader_wrapper .loader_background {
      height: 100%;
      background: white; }
    .chat-wrapper .loader_wrapper .loader {
      width: 5rem;
      height: 5rem; }
  .chat-wrapper .chat-header {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    font-weight: bold;
    padding: 0.5rem 1rem;
    background-color: var(--base_top-bar-navigation); }
    .chat-wrapper .chat-header .chat-header-icon {
      flex: 1 1;
      background-image: url("/assets/images/dotted-grip-chat-repeat-pattern.svg");
      background-repeat: repeat-x;
      background-size: 0.75rem;
      height: 1.2rem;
      cursor: -webkit-grab; }
  .chat-wrapper .chat-message-list {
    width: 23rem !important;
    background-color: var(--base_secondary-navigation-bar);
    flex: 1 1; }
    .chat-wrapper .chat-message-list > div {
      padding: 0.5rem 0; }
    .chat-wrapper .chat-message-list .view > div:nth-child(odd) {
      background-color: var(--base_secondary-navigation-bar); }
    .chat-wrapper .chat-message-list .view > div:nth-child(even) {
      background-color: var(--base_background); }
    .chat-wrapper .chat-message-list .chat-message {
      font-weight: bold;
      color: white;
      padding: 0.25rem 0;
      word-break: break-all; }
      .chat-wrapper .chat-message-list .chat-message .message-content {
        width: 100%; }
        .chat-wrapper .chat-message-list .chat-message .message-content .message {
          color: var(--specials_chat-messages);
          word-break: break-word;
          line-height: 1.5rem;
          font-size: 1rem;
          font-weight: normal; }
          .chat-wrapper .chat-message-list .chat-message .message-content .message line > span > span {
            display: inline; }
        .chat-wrapper .chat-message-list .chat-message .message-content .nonmobile .own-message {
          line-height: 1.2rem;
          word-break: keep-all;
          color: var(--specials_my-username-in-chat); }
      .chat-wrapper .chat-message-list .chat-message.user .own-message {
        color: var(--specials_my-username-in-chat);
        font-weight: bold; }
      .chat-wrapper .chat-message-list .chat-message .message-content span.toggle-content {
        position: relative;
        cursor: pointer;
        color: var(--specials_buttons-links-and-highlights); }
        .chat-wrapper .chat-message-list .chat-message .message-content span.toggle-content::before {
          display: inline-block;
          content: '\00bb';
          height: 1rem;
          margin: 0 0.25rem; }
        .chat-wrapper .chat-message-list .chat-message .message-content span.toggle-content::after {
          position: absolute;
          content: '';
          bottom: 0;
          left: 0.2rem;
          border-bottom: 1px dotted var(--specials_buttons-links-and-highlights);
          width: 100%; }
      .chat-wrapper .chat-message-list .chat-message .message-content .show .toggle-content::before {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg); }
      .chat-wrapper .chat-message-list .chat-message .message-content.image img.image {
        margin: 0.5rem 0;
        max-width: 100%; }
      .chat-wrapper .chat-message-list .chat-message .username {
        color: var(--labels_important-active-labels-text-graphics);
        font-weight: bold;
        margin-bottom: 0.5rem;
        width: 100%; }
      .chat-wrapper .chat-message-list .chat-message .item-options {
        position: relative;
        margin-left: 0.25rem;
        cursor: pointer; }
        .chat-wrapper .chat-message-list .chat-message .item-options .item-options-icon-wrapper {
          width: 1rem;
          height: 1rem; }
          .chat-wrapper .chat-message-list .chat-message .item-options .item-options-icon-wrapper .item-options-icon {
            width: 100%;
            height: 100%; }
            .chat-wrapper .chat-message-list .chat-message .item-options .item-options-icon-wrapper .item-options-icon > * {
              fill: #999999; }
        .chat-wrapper .chat-message-list .chat-message .item-options .item-options-wrapper {
          position: absolute;
          right: 0;
          top: 1rem;
          min-width: 4rem;
          font-size: 0.8rem; }
          .chat-wrapper .chat-message-list .chat-message .item-options .item-options-wrapper .item-option {
            width: 100%;
            padding: 0 0.25rem;
            background-color: white;
            border-bottom: 1px solid #464545;
            color: #999999; }
            .chat-wrapper .chat-message-list .chat-message .item-options .item-options-wrapper .item-option:hover {
              background-color: #535353;
              color: white; }
    .chat-wrapper .chat-message-list .user-icon {
      width: 2.5rem; }
      .chat-wrapper .chat-message-list .user-icon svg .level_icon {
        fill: var(--labels_secondary-inactive-label-text-graphics); }
  .chat-wrapper .chat-footer {
    min-height: 3rem;
    width: 100%;
    padding: 0;
    background-color: var(--base_secondary-navigation-bar); }
    .chat-wrapper .chat-footer button {
      width: 100%; }
    .chat-wrapper .chat-footer .chat-message-box {
      outline: none !important;
      border: none;
      background-color: var(--labels_fields);
      width: 100%;
      height: 100%;
      min-height: 3rem;
      line-height: 2rem;
      padding: 0.5rem;
      color: #999999;
      font-size: 0.9rem;
      resize: none;
      overflow: hidden;
      padding-left: 10px; }
      .chat-wrapper .chat-footer .chat-message-box::-webkit-input-placeholder {
        color: var(--labels_secondary-inactive-label-text-graphics); }
      .chat-wrapper .chat-footer .chat-message-box::placeholder {
        color: var(--labels_secondary-inactive-label-text-graphics); }
    .chat-wrapper .chat-footer .chat-emoji-wrapper {
      background-color: var(--labels_fields);
      cursor: pointer; }
      .chat-wrapper .chat-footer .chat-emoji-wrapper .chat-emoji-icon {
        width: 2rem; }
        .chat-wrapper .chat-footer .chat-emoji-wrapper .chat-emoji-icon svg .emoji-face-icon {
          fill: var(--labels_secondary-inactive-label-text-graphics); }
    .chat-wrapper .chat-footer .emoji-container {
      width: 23rem !important;
      height: 15rem;
      background-color: #cecece; }
      .chat-wrapper .chat-footer .emoji-container .chat-category {
        border-bottom: 1px solid grey;
        background-color: #dbdbdb;
        height: -webkit-max-content;
        height: max-content;
        width: 100%; }
        .chat-wrapper .chat-footer .emoji-container .chat-category .top-background .emoji-mart-emoji {
          background-color: #dbdbdb; }
        .chat-wrapper .chat-footer .emoji-container .chat-category .emoji-close {
          cursor: pointer; }
          .chat-wrapper .chat-footer .emoji-container .chat-category .emoji-close .cross-icon-back {
            width: 2rem; }
            .chat-wrapper .chat-footer .emoji-container .chat-category .emoji-close .cross-icon-back svg .cancel-cross-2 {
              stroke: #dbdbdb; }
            .chat-wrapper .chat-footer .emoji-container .chat-category .emoji-close .cross-icon-back svg .cancel-cross-3 {
              stroke: grey;
              stroke-width: 2; }
          .chat-wrapper .chat-footer .emoji-container .chat-category .emoji-close:hover .cross-icon-back svg .cancel-cross-3 {
            stroke: var(--labels_secondary-inactive-label-text-graphics); }
        .chat-wrapper .chat-footer .emoji-container .chat-category .activeEmoji {
          border-bottom: 2px solid var(--specials_buttons-links-and-highlights); }
        .chat-wrapper .chat-footer .emoji-container .chat-category .emoji-mart-emoji {
          background-color: #cecece;
          border: none; }
          .chat-wrapper .chat-footer .emoji-container .chat-category .emoji-mart-emoji span {
            cursor: pointer; }
        .chat-wrapper .chat-footer .emoji-container .chat-category button:focus {
          outline: none !important; }
      .chat-wrapper .chat-footer .emoji-container .emoji-content {
        height: 13rem;
        overflow-y: scroll; }
      .chat-wrapper .chat-footer .emoji-container .emoji-mart-emoji {
        background-color: #cecece;
        border: none; }
        .chat-wrapper .chat-footer .emoji-container .emoji-mart-emoji span {
          cursor: pointer; }
          .chat-wrapper .chat-footer .emoji-container .emoji-mart-emoji span:hover {
            background-color: rgba(255, 255, 255, 0.5);
            border-radius: 20%; }
      .chat-wrapper .chat-footer .emoji-container button:focus {
        outline: none !important; }
    .chat-wrapper .chat-footer .separator {
      width: 1rem; }

.elapsed-timer-wrapper {
  font-size: 0.8rem;
  color: var(--labels_secondary-inactive-label-text-graphics); }
  .elapsed-timer-wrapper .timer-time {
    color: var(--labels_secondary-inactive-label-text-graphics);
    font-weight: bold; }
  .elapsed-timer-wrapper .timer-svg {
    height: 1.2rem;
    width: 1.2rem; }
    .elapsed-timer-wrapper .timer-svg svg .pending-timer {
      stroke: var(--labels_secondary-inactive-label-text-graphics); }

.snack_notification-wrapper {
  background-color: var(--base_background);
  border-top: 0.3rem solid var(--calculated_secondary-border);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  font-family: "Roboto";
  height: 3.5rem;
  left: calc(50vw - 10rem);
  padding: 0.3rem 0.5rem !important;
  position: absolute;
  width: 20rem;
  z-index: 9999;
  box-shadow: 2px 2px 3px 2px rgba(0, 0, 0, 0.4); }
  .snack_notification-wrapper .notification-text {
    font-size: 1.2rem;
    font-weight: 400; }
  .snack_notification-wrapper .notification-icon {
    width: 1.5rem; }

.snack_open {
  top: -1rem;
  -webkit-animation: snackSlide 0.7s forwards;
  animation: snackSlide 0.7s forwards; }

.snack_close {
  top: 1rem;
  -webkit-animation: slideClose 1s forwards;
  animation: slideClose 1s forwards; }

@-webkit-keyframes snackSlide {
  0% {
    top: -2rem; }
  100% {
    top: 1rem; } }

@keyframes snackSlide {
  0% {
    top: -2rem; }
  100% {
    top: 1rem; } }

@-webkit-keyframes slideClose {
  0% {
    top: 1rem; }
  100% {
    top: -4rem; } }

@keyframes slideClose {
  0% {
    top: 1rem; }
  100% {
    top: -4rem; } }

.snack_dialog-wrapper {
  background-color: var(--labels_important-active-labels-text-graphics);
  font-family: "Roboto";
  padding: 0.3rem 0.5rem !important;
  position: absolute;
  width: 100%;
  z-index: 9998; }
  .snack_dialog-wrapper .notification-text {
    font-size: 1.2rem;
    font-weight: 400; }
  .snack_dialog-wrapper .notification-icon {
    width: 1.5rem; }
  .snack_dialog-wrapper .close-dialog {
    margin-left: 0;
    width: 7.5rem;
    height: 7.5rem;
    height: auto; }
    .snack_dialog-wrapper .close-dialog svg {
      width: 7.5rem;
      height: 7.5rem; }
      .snack_dialog-wrapper .close-dialog svg .cancel-cross-3 {
        stroke: white; }
      .snack_dialog-wrapper .close-dialog svg .cancel-cross-2 {
        stroke: transparent; }

.snack_dialog_open {
  bottom: 0rem;
  -webkit-animation: dialogSlide 1.5s backwards;
  animation: dialogSlide 1.5s backwards; }

.snack_dialog_close {
  bottom: 5.5rem;
  -webkit-animation: dialogClose 0.8s backwards;
  animation: dialogClose 0.8s backwards; }

@-webkit-keyframes dialogSlide {
  0% {
    bottom: 0rem; }
  100% {
    bottom: 7.5rem; } }

@keyframes dialogSlide {
  0% {
    bottom: 0rem; }
  100% {
    bottom: 7.5rem; } }

@-webkit-keyframes dialogClose {
  0% {
    bottom: 7.5rem; }
  100% {
    bottom: 5.5rem; } }

@keyframes dialogClose {
  0% {
    bottom: 7.5rem; }
  100% {
    bottom: 5.5rem; } }

.mobile-notification {
  border-top: 0;
  border-bottom: 0.5rem solid var(--calculated_secondary-border);
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  width: 22rem;
  left: calc(50vw - 11rem); }

.layout-mobile .snack_dialog-wrapper .notification-content-mobile-wrapper {
  font-size: 1rem !important; }

.layout-mobile .trade_active_orders-wrapper {
  max-height: 35vh !important; }

.trade_post_icon {
  width: 1.5rem; }
  .trade_post_icon svg .wave-icon {
    fill: var(--labels_secondary-inactive-label-text-graphics); }

.post_header {
  font-weight: bold; }

.trade_announcement {
  font-size: 1.5rem; }

.announcement-notification-list-item {
  color: var(--labels_important-active-labels-text-graphics);
  border-bottom: 1px solid var(--calculated_secondary-border); }

.post-content .notifications_list-item-text a {
  color: var(--specials_buttons-links-and-highlights); }

.post-content .notifications_list-item-text span,
.post-content .notifications_list-item-text p {
  background-color: var(--base_wallet-sidebar-and-popup) !important; }

.post-content .notifications_list-item-timestamp {
  color: var(--labels_secondary-inactive-label-text-graphics); }

.app_footer-container {
  border-top: 1px solid var(--calculated_footer_border);
  font-size: 1rem;
  background-color: var(--base_footer);
  color: var(--calculated_base_footer_text); }
  .app_footer-container.deep-footer {
    padding-bottom: 136px; }
  .app_footer-container .footer-row-content {
    margin-bottom: 2rem;
    padding: 4rem 1rem 0;
    width: 100%; }
    .app_footer-container .footer-row-content .footer-links-section .footer-links-group {
      margin: 1rem;
      font-size: 14px; }
      .app_footer-container .footer-row-content .footer-links-section .footer-links-group .footer-links-section--title {
        font-weight: bold;
        margin-bottom: 0.25rem;
        text-transform: uppercase; }
      .app_footer-container .footer-row-content .footer-links-section .footer-links-group .footer-links-section--list {
        min-width: 8rem;
        text-transform: capitalize; }
        .app_footer-container .footer-row-content .footer-links-section .footer-links-group .footer-links-section--list .social_icon {
          width: 1rem;
          margin-right: 0.25rem; }
        .app_footer-container .footer-row-content .footer-links-section .footer-links-group .footer-links-section--list .link-section {
          margin-top: 0.25rem; }
          .app_footer-container .footer-row-content .footer-links-section .footer-links-group .footer-links-section--list .link-section:hover {
            color: grey; }
    .app_footer-container .footer-row-content .footer_separter {
      border-left: 1px solid var(--calculated_footer_border);
      margin-left: 0.5rem; }
      .app_footer-container .footer-row-content .footer_separter .footer-txt {
        width: 23rem;
        margin-left: 1.5rem; }
      .app_footer-container .footer-row-content .footer_separter .footer-logo {
        background-size: contain;
        background-repeat: no-repeat;
        height: 6rem !important;
        margin-left: 1.5rem;
        width: 7rem; }
      .app_footer-container .footer-row-content .footer_separter svg .hollaEX_logo_gray {
        fill: var(--calculated_base_footer_text); }
      .app_footer-container .footer-row-content .footer_separter .blue-link {
        color: #00aeef !important; }
    .app_footer-container .footer-row-content .footer-public-texts .footer-public-texts-text {
      line-height: 2rem;
      font-weight: bold; }
    .app_footer-container .footer-row-content .footer-public-links {
      justify-content: flex-end;
      align-items: center;
      margin-top: 1rem; }
      .app_footer-container .footer-row-content .footer-public-links .footer-public-links-icon {
        width: 2rem;
        height: 2rem;
        margin: 0 1.5rem; }
  .app_footer-container .footer-row-bottom {
    border-top: 1px solid var(--calculated_footer_border);
    text-align: left;
    padding-left: 1rem;
    padding-bottom: 1rem;
    margin: auto; }

.direction_ltr .app_footer-container .footer-row-content .footer-public-texts {
  text-align: right; }

.direction_ltr .app_footer-container .footer-row-content .footer-public-links:first-child .footer-public-links-icon {
  margin-left: 0; }

.direction_ltr .app_footer-container .footer-row-content .footer-public-links:last-child .footer-public-links-icon {
  margin-right: 0; }

.direction_rtl .app_footer-container .footer-row-content .footer-links-section .footer-links-group .footer-links-section--list .social_icon {
  margin-right: 0;
  margin-left: 0.25rem; }

.direction_rtl .app_footer-container .footer-row-content .footer_separter {
  border-left: 0;
  border-right: 1px solid var(--calculated_footer_border); }
  .direction_rtl .app_footer-container .footer-row-content .footer_separter .footer-txt {
    margin-left: 0;
    margin-right: 1rem; }
  .direction_rtl .app_footer-container .footer-row-content .footer_separter .footer-logo {
    margin-left: 0;
    margin-right: 1rem; }

.direction_rtl .app_footer-container .footer-row-content .footer-public-texts {
  text-align: left; }

.direction_rtl .app_footer-container .footer-row-content .footer-public-links:first-child .footer-public-links-icon {
  margin-right: 0; }

.direction_rtl .app_footer-container .footer-row-content .footer-public-links:last-child .footer-public-links-icon {
  margin-left: 0; }

@media screen and (max-width: 991px) {
  .app_footer-container .footer-row-content {
    flex-direction: column; }
    .app_footer-container .footer-row-content > div:not(:last-child) {
      margin-bottom: 2rem; }
    .app_footer-container .footer-row-content .footer-public-texts {
      text-align: center !important; }
    .app_footer-container .footer-row-content .footer-public-links {
      justify-content: center; }
      .app_footer-container .footer-row-content .footer-public-links .footer-public-links-icon {
        margin: 0 1.5rem !important; } }

@media screen and (max-width: 767px) {
  .app_footer-container .footer-row-content .footer_separter .footer-txt {
    width: 12rem; } }

.layout-mobile .app_footer-container .footer-row-content .footer-links-section {
  width: 100%; }
  .layout-mobile .app_footer-container .footer-row-content .footer-links-section .footer-links-group {
    margin-left: 3rem;
    margin-bottom: 3rem !important;
    font-size: 12px; }
    .layout-mobile .app_footer-container .footer-row-content .footer-links-section .footer-links-group .footer-links-section--list .link-section {
      margin-top: 0.25rem; }
      .layout-mobile .app_footer-container .footer-row-content .footer-links-section .footer-links-group .footer-links-section--list .link-section:hover {
        color: grey; }
  .layout-mobile .app_footer-container .footer-row-content .footer-links-section .footer_separter {
    border-left: none !important;
    border-top: 1px solid var(--calculated_footer_border) !important; }
    .layout-mobile .app_footer-container .footer-row-content .footer-links-section .footer_separter .footer-content {
      display: flex;
      flex-direction: column;
      align-items: center; }
    .layout-mobile .app_footer-container .footer-row-content .footer-links-section .footer_separter .footer-txt {
      width: 100%; }
    .layout-mobile .app_footer-container .footer-row-content .footer-links-section .footer_separter .footer-logo {
      height: 3.5rem;
      margin-top: 11px;
      margin-bottom: 1rem;
      width: 6.5rem; }
    .layout-mobile .app_footer-container .footer-row-content .footer-links-section .footer_separter svg .hollaEX_logo_gray {
      fill: var(--calculated_base_footer_text); }
    .layout-mobile .app_footer-container .footer-row-content .footer-links-section .footer_separter .blue-link {
      color: #00aeef !important; }

.layout-mobile .app_footer-container .footer-row-content .blue-link {
  color: #00aeef !important; }

.layout-mobile .app_footer-container .footer-links-group {
  margin: 10rem; }

.carousel__container {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.carousel__alice-wrapper {
  width: calc(100% - 6rem); }

.carousel__button {
  color: var(--specials_buttons-links-and-highlights);
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 3rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  overflow: hidden; }
  .carousel__button.disabled {
    color: var(--labels_secondary-inactive-label-text-graphics);
    cursor: default; }

.controlled-scrollbar__button {
  color: var(--specials_buttons-links-and-highlights);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }
  .controlled-scrollbar__button.disabled {
    color: var(--labels_secondary-inactive-label-text-graphics);
    cursor: default; }

.controlled-scrollbar__arrow {
  border-style: solid;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  height: 1.1rem;
  width: 1.1rem; }
  .controlled-scrollbar__arrow.up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg); }
  .controlled-scrollbar__arrow.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg); }

.slider__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%; }

.slider__slides-wrapper {
  width: calc(100% - 6rem);
  max-width: calc(100% - 6rem); }

.slider__view {
  display: flex; }

.slider__button {
  color: var(--specials_buttons-links-and-highlights);
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 3rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  overflow: hidden; }
  .slider__button.disabled {
    color: var(--labels_secondary-inactive-label-text-graphics);
    cursor: default; }

.slider__arrow {
  border-style: solid;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  height: 1.5rem;
  width: 1.5rem; }
  .slider__arrow.right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg); }
  .slider__arrow.left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg); }
  .slider__arrow.small {
    height: 1rem;
    width: 1rem; }

.edit-wrapper__container {
  display: inline-flex !important; }
  .edit-wrapper__container.reverse {
    flex-direction: row-reverse !important; }

.edit-wrapper__icons-container {
  display: none !important; }

.edit-wrapper__icon-wrapper {
  display: none !important;
  position: relative;
  width: 19px !important;
  height: 19px !important;
  text-align: center;
  border-radius: 2px;
  cursor: pointer;
  font-size: 10px !important;
  text-decoration: none !important;
  font-weight: normal !important; }
  .edit-wrapper__icon-wrapper.large {
    width: 50px !important;
    height: 50px !important; }

.edit-wrapper__icon {
  position: relative;
  z-index: -1;
  height: 100% !important;
  width: 100% !important; }

.layout-edit .edit-wrapper__icon-wrapper {
  display: inline-block !important; }

.layout-edit .edit-wrapper__icons-container {
  display: inline-flex !important; }

.check-deposit-modal-wrapper h2 {
  font-size: 20px;
  color: var(--labels_important-active-labels-text-graphics); }

.check-deposit-modal-wrapper .search-icon {
  width: 70px; }

.check-deposit-modal-wrapper .inner-content {
  border-top: 1px solid var(--calculated_secondary-border);
  margin-top: 3rem;
  padding: 10px 0; }
  .check-deposit-modal-wrapper .inner-content .field-header {
    color: var(--labels_secondary-inactive-label-text-graphics); }
  .check-deposit-modal-wrapper .inner-content .success-msg {
    width: 320px;
    color: var(--specials_checks-okay-done);
    margin: 0 3px; }
  .check-deposit-modal-wrapper .inner-content .error-msg {
    width: 320px;
    color: var(--specials_pending-waiting-caution); }
  .check-deposit-modal-wrapper .inner-content .verification_icon {
    width: 15px; }

.app-menu-bar-side {
  min-width: 18rem;
  max-width: 18rem; }

.direction_rtl .apply_rtl .app-menu-bar-side .side-bar-txt {
  margin: 0 25px 0 0; }

.app-side-bar .app-menu-bar-side {
  background-color: var(--base_wallet-sidebar-and-popup);
  border-right: 1px solid var(--calculated_secondary-border); }
  .app-side-bar .app-menu-bar-side .app-menu-bar-icon {
    width: 20px; }

.app-side-bar .app-menu-bar-side_list {
  height: 50px;
  padding: 13px 17px;
  cursor: pointer;
  color: var(--labels_secondary-inactive-label-text-graphics) !important;
  transition: 0.2s all; }
  .app-side-bar .app-menu-bar-side_list .side-bar-txt {
    height: 24px;
    margin: 0 0 0 14px;
    font-size: 14px;
    transition: 0.2s all; }

.app-side-bar .list_active,
.app-side-bar .app-menu-bar-side_list:hover {
  background-color: #f5f5f5; }

.app-side-bar .list_active {
  background-color: #f5f5f5 !important; }

@media (max-width: 991px) {
  .app-menu-bar-side {
    padding: 20px 8px 0 8px !important;
    min-width: 6rem !important;
    max-width: 10rem !important; }
    .app-menu-bar-side .app-menu-bar-icon {
      width: 15px !important; }
  .app-menu-bar-side_list,
  .list_active,
  .app-menu-bar-side_list:hover {
    width: 100%;
    padding: 0px 24px !important;
    font-size: 12px !important; }
  .app-menu-bar-side_list .side-bar-txt,
  .app-menu-bar-side_list .side-bar-text-active,
  .list_active .side-bar-txt,
  .list_active .side-bar-text-active {
    display: none !important; } }

@media (min-width: 1920px) {
  .app-menu-bar-side {
    max-width: 26rem;
    min-width: 26rem; } }

@media (max-width: 1096px) {
  .app-menu-bar-side {
    min-width: 20rem;
    max-width: 20rem; } }

.layout-mobile .app-menu-bar-side {
  display: none !important; }

html {
  font-size: 12px !important;
  height: 100%; }

#root {
  height: 100%; }

body {
  height: 100%;
  background-color: var(--base_background) !important;
  color: var(--labels_important-active-labels-text-graphics);
  font-size: 14px !important;
  font-family: "Roboto" !important;
  height: 100%; }

.direction_rtl.apply_rtl {
  direction: rtl; }

.direction_rtl .apply_rtl {
  direction: rtl; }

.direction_ltr {
  direction: ltr; }

.warning_text {
  color: var(--specials_notifications-alerts-warnings); }

.ReactModal__Body--open #root {
  -webkit-filter: blur(5px);
          filter: blur(5px);
  background: var(--calculated_base_modal-overlay); }

.form {
  min-width: 25rem;
  max-width: 25rem; }

.error-icon {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 2rem !important; }
  .error-icon:before {
    content: '';
    width: 2rem;
    height: 100%;
    position: absolute;
    background-color: red;
    left: 0;
    top: calc(50% - 0.75rem); }

input,
input:focus {
  outline: none;
  border: none; }

input[type='number'] {
  -webkit-appearance: textfield;
          appearance: textfield; }

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

*:not(.show-scroll)::-webkit-scrollbar {
  display: none; }

a {
  text-decoration: none;
  color: inherit; }
  a:hover, a:visited, a:link, a:active {
    color: inherit;
    text-decoration: none; }

@media screen and (max-width: 767px) {
  html {
    font-size: 8px !important; } }

@media (max-width: 991px) {
  .ReactModal__Body--open #root {
    -webkit-filter: none !important;
            filter: none !important;
    background: transparent !important; } }

svg .tab-summary0,
svg .tab-wallet0,
svg .tab-setting0,
svg .tab-verify0,
svg .tab-security0,
svg .tab-history-st,
svg .help-question,
svg .tab-signout,
svg .tab-api0,
svg .trade-history-1,
svg .deposit-history-3,
svg .withdrawals-history-3,
svg .verification-email,
svg .verification-id,
svg .verification-document,
svg .verification-phone,
svg .verification-bank,
svg .notification-setting,
svg .language-setting,
svg .interface-setting,
svg .chat-setting,
svg .audio-setting,
svg .risk-setting,
svg .st1,
svg .trade-history-1,
svg .account-recovery-success-2,
svg .password-reset-2,
svg .transaction-history-1,
svg .transaction-history-4,
svg .st11,
svg .wallet-coins-2,
svg .verification-doc,
svg .copy-notification-0,
svg .account-svg-1,
svg .incoming-btc-01,
svg .incoming-toman-01,
svg .coin-btc-5 {
  fill: var(--labels_secondary-inactive-label-text-graphics); }

svg .logout-door,
svg .account-inactive {
  stroke: var(--labels_secondary-inactive-label-text-graphics); }

svg .withdraw-2,
svg .withdraw-3,
svg .deposit-bitcoin-2,
svg .deposit-bitcoin-3,
svg .trade-history-3,
svg .deposit-history-1,
svg .withdrawals-history-1,
svg .risk-order-warning,
svg .otp-key,
svg .refer-icon,
svg .send-request,
svg .stake_icon,
svg .otp-code,
svg .st0,
svg .st9,
svg .st10,
svg .trade-history-3,
svg .contact-us-icon,
svg .xht-coin-stack,
svg .account-recovery-1,
svg .account-recovery-success-1,
svg .password-reset-1,
svg .success-black-1,
svg .check-st,
svg .level_icon,
svg .warning-icon,
svg .transaction-history-2,
svg .transaction-history-3,
svg .resend-email-st,
svg .email-confirmation,
svg .secure-1,
svg .wallet-coins-1,
svg .api-key-active,
svg .api-key-st,
svg .generate_api,
svg .key-trashed,
svg .api-key-revoked,
svg .risk-order-warning,
svg .bonus-offer-icon,
svg .announcement,
svg .newWave-icon,
svg .xht-pdf,
svg .xht-faq,
svg .xht-email,
svg .wave-icon,
svg .xht-docs,
svg .account-summary1,
svg .hap-icon,
svg .expired_icon,
svg .default-icon,
svg .connect-loading,
svg .bottom-chat,
svg .note-kyc,
svg .partial-fill,
svg .limit-trade-tick,
svg .chat-0,
svg .trans-history-1,
svg .quick-trade-insufficient-funds,
svg .quick-trade-success,
svg .gender-female,
svg .gender-male,
svg .volume-pending,
svg .quick-trade-4,
svg .quick_trade-tab-icon,
svg .features-pro-trade-icons,
svg .features-payment,
svg .features-legal,
svg .features-lock,
svg .features-support-1,
svg .features-data,
svg .check-send-btc-1,
svg .copy-notification-1,
svg .data-1,
svg .deposit-complete-btc-01,
svg .deposit-complete-01,
svg .email_sent,
svg .gear-grey-1,
svg .g1_3,
svg .incoming-btc-2,
svg .incoming-toman-02,
svg .security-grey-1,
svg .coin-btc-1,
svg .wallet-selected-2,
svg .account-active-1,
svg .post-icon,
svg .item-options,
svg .check-order,
svg .otp-inactive,
svg .otp-active,
svg .unknown-error-icon,
svg .cancel-all,
svg .fiat-page-under-construction,
svg .generate-reference-number,
svg .osko-logo {
  fill: var(--labels_important-active-labels-text-graphics); }

svg .trade-history-2,
svg .trade-history-4,
svg .trade-history-5,
svg .deposit-history-2,
svg .deposit-history-4,
svg .withdrawals-history-2,
svg .withdrawals-history-4,
svg .trade-history-2,
svg .trade-history-4,
svg .trade-history-5,
svg .transaction-history-4,
svg .wallet-coins-1,
svg .quick-trade-3 {
  stroke: none; }

svg .deposit-bitcoin-1,
svg .withdraw-1,
svg .transaction-history-5,
svg .pending-timer,
svg .quick-trade-2,
svg .coin-btc-6,
svg .coin-btc-4,
svg .logout-door,
svg .cancel-cross-3,
svg .cancel-cross-2,
svg .account-inactive {
  fill: none; }

svg .pending-timer,
svg .tab-close,
svg .trans-history-4,
svg .icon-check-1,
svg .quick-trade-1,
svg .success-check-box-0,
svg .coin-btc-6,
svg .coin-btc-4,
svg .cancel-cross-3,
svg .cancel-cross-2 {
  stroke: var(--labels_important-active-labels-text-graphics); }

svg .quick-trade-tab-active {
  stroke: var(--calculated_base_top-bar-navigation_text-inactive);
  fill: var(--calculated_base_top-bar-navigation_text-inactive); }

svg .astrics-1 {
  fill: var(--labels_important-active-labels-text-graphics); }

svg .red-warning-0,
svg .astrics-3,
svg .astrics-2 {
  fill: var(--specials_notifications-alerts-warnings); }

svg .success-check-box-1 {
  stroke: var(--specials_checks-okay-done); }

svg .red-warning-1 {
  fill: var(--calculated_specials_notifications-alerts-text); }

svg .session-timeout-1 {
  fill: var(--specials_pending-waiting-caution); }

svg .session-timeout-0 {
  fill: var(--calculated_specials_notifications-pending-text); }

svg .deposit-complete-02,
svg .deposit-complete-btc-02 {
  fill: var(--specials_checks-okay-done); }

svg .btc0 {
  fill: var(--coin-btc); }

svg .btc1 {
  fill: var(--coin-btc); }

svg .eth0 {
  fill: var(--coin-eth); }

svg .eth1 {
  fill: var(--coin-eth); }

svg .bch-icon {
  fill: var(--coin-bch); }

svg .xrp-icon {
  fill: var(--coin-xrp); }

svg .xht-icon {
  fill: var(--coin-xht); }

svg .xmr-icon {
  fill: var(--coin-xmr); }

svg .xlm-icon {
  fill: var(--coin-xlm); }

svg .eos-icon {
  fill: var(--coin-eos); }

svg .trx-icon {
  fill: var(--coin-trx); }

svg .ada-icon {
  fill: var(--coin-ada); }

svg .bnb-icon {
  fill: var(--coin-bnb); }

svg .tusd-icon {
  fill: var(--coin-tusd); }

svg .dai-icon {
  fill: var(--coin-dai); }

svg .ltc-icon {
  fill: var(--coin-ltc); }

